import { billingAddressSchema } from './customer'
import { z } from 'zod'

import { PURCHASE_STATUS } from './purchase'
import { isGameId } from './game'
import { isSupportedLanguage } from './countries'
import { PaymentGateway, PaymentGatewayKind } from './checkout'
import { columnTypes } from './db'

const allowedStatuses: readonly string[] = [
  PURCHASE_STATUS.AUTHORISED_WEBHOOK,
  PURCHASE_STATUS.CHARGEBACK_WEBHOOK,
  PURCHASE_STATUS.REFUND_WEBHOOK,
] as const

export const isReceiptStatus = (status: string): status is ReceiptStatus => {
  return allowedStatuses.includes(status)
}

export type ReceiptStatus =
  | PURCHASE_STATUS.AUTHORISED_WEBHOOK
  | PURCHASE_STATUS.CHARGEBACK_WEBHOOK
  | PURCHASE_STATUS.REFUND_WEBHOOK

const receiptStatusSchema = z.string().refine(isReceiptStatus)

const paymentProviderSchema = PaymentGateway.default('ADYEN')
const taxTypeSchema = z.literal('VAT').or(z.literal('SALES_TAX')).default('VAT')

const receiptRowSchema = z.object({
  sku: z.string(),
  price: z.number().gte(0),
  quantity: z.number().int().gte(1),
  game: z.string().refine(isGameId),
  productTitle: z.string(),
})

const receiptRowSchemaV2 = receiptRowSchema.extend({ productImage: z.string().default('') })

const baseReceiptSchema = z.object({
  purchaseId: z.string(),
  customerId: z.string(),
  status: receiptStatusSchema,
  rows: z.array(receiptRowSchema),
  creatorBoostCode: z.string().optional(),
  timestamp: z.number().int().positive(),
  email: z.string(),
  country: columnTypes.countryCodeSchema,
  language: z.string().refine(isSupportedLanguage),
  currency: columnTypes.currencyCodeSchema,
  total: z.number().gte(0),
  subtotal: z.number().gte(0).optional(),
  taxType: taxTypeSchema.optional(),
  taxAmount: z.number().int().gte(0).optional(),
  // Tax percentage is stored as decimal such as 0.0777 for 7.77%
  taxPercentage: z.number().gte(0).lte(100).optional(),
  issuerCountry: z.string().optional(),
  paymentMethod: z.string().optional(),
  paymentMethodName: z.string().optional(),
  paymentProvider: paymentProviderSchema,

  migratedAt: z.number().int().positive().optional(),
})

const receiptSchemaV0 = baseReceiptSchema.extend({ version: z.literal('v0') })
const receiptSchemaV1NewFields = z.object({
  checkoutCurrency: columnTypes.currencyCodeSchema,
  checkoutTotal: z.number().int().positive(),
})
const receiptSchemaV1 = baseReceiptSchema.merge(receiptSchemaV1NewFields).extend({
  version: z.literal('v1'),
})
const receiptSchemaV2NewFields = z.object({
  billingAddress: billingAddressSchema.optional(),
})
const receiptSchemaV2 = baseReceiptSchema
  .merge(receiptSchemaV1NewFields)
  .merge(receiptSchemaV2NewFields)
  .extend({
    version: z.literal('v2'),
  })

const receiptSchemaV3NewFields = z.object({
  rows: z.array(receiptRowSchemaV2),
})

const receiptSchemaV3 = baseReceiptSchema
  .merge(receiptSchemaV1NewFields)
  .merge(receiptSchemaV2NewFields)
  .merge(receiptSchemaV3NewFields)
  .extend({ version: z.literal('v3') })

const receiptSchemaV4NewFields = z.object({
  paymentGatewayKind: PaymentGatewayKind,
  paymentGateway: PaymentGateway,
})

const receiptSchemaV4 = baseReceiptSchema
  .merge(receiptSchemaV1NewFields)
  .merge(receiptSchemaV2NewFields)
  .merge(receiptSchemaV3NewFields)
  .merge(receiptSchemaV4NewFields)
  .omit({ paymentProvider: true })
  .extend({
    version: z.literal('v4'),
  })

export const receiptSchema = z.discriminatedUnion('version', [
  receiptSchemaV0,
  receiptSchemaV1,
  receiptSchemaV2,
  receiptSchemaV3,
  receiptSchemaV4,
])

export type Receipt =
  | z.infer<typeof receiptSchemaV0>
  | z.infer<typeof receiptSchemaV1>
  | z.infer<typeof receiptSchemaV2>
  | z.infer<typeof receiptSchemaV3>
  | z.infer<typeof receiptSchemaV4>

export type FetchReceiptsResponse = {
  receipts: Receipt[]
  lastEvaluatedKey?: {
    purchaseId: string
    timestamp: number
  }
}

export const fetchReceiptsRequestSchema = z
  .object({
    purchaseId: z.string(),
    timestamp: z
      .number()
      .or(z.string())
      .transform((v) => {
        if (typeof v === 'string') {
          return parseInt(v, 10)
        }
        return v
      }),
  })
  .or(z.undefined())

export type FetchReceiptsRequest = z.infer<typeof fetchReceiptsRequestSchema>
