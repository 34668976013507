import React from 'react'

import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import StoreLogo from '@/assets/images/store_logo.png'
import { CartButton } from '@/features/storefronts/_storeBase/cart/CartButton'
import { UserAvatarImage } from '@/features/user/avatar/UserAvatarImage'
import { LOGIN_STATUS } from '@/features/user/userSlice'
import { useAppSelector } from '@/utils/useAppSelector'

import { LoginButton } from './LoginButton'
import { Loader } from '../../components/Loader'
import { COLOR_GRAY } from '../../utils/styles'
import { useIsLoggedIn } from '../common/useIsLoggedIn'

import styles from './Header.module.scss'

export interface NavLink {
  href: string
  label: string
  target?: string
}

export const HeaderUser: React.FC<{
  loginStatus: LOGIN_STATUS
  showFullUsername?: boolean
  useNewHeader?: boolean
}> = ({ showFullUsername = false, loginStatus, useNewHeader }) => {
  const isLoggedIn = useIsLoggedIn()
  const username = useAppSelector((state) => state.user.profile?.profile?.name)
  const userUninitialized = loginStatus === LOGIN_STATUS.SSO_LOGIN_MIGHT_HAPPEN
  if (userUninitialized) {
    return (
      <div className={styles.User}>
        <Loader strokeColor={COLOR_GRAY} dataCy="header-user-loading" />
      </div>
    )
  }
  if (!isLoggedIn) {
    const loggedOutStyles = cx({
      [styles.User]: true,
      [styles.LoggedOut]: !useNewHeader,
      [styles.LoggedOutNew]: useNewHeader,
    })
    return (
      <div className={loggedOutStyles}>
        <LoginButton data-cy="header-login" />
      </div>
    )
  }

  return (
    <div className={styles.User}>
      <Link legacyBehavior href="/account">
        <div data-cy="header-user-avatar">
          {showFullUsername && <p>{username}</p>}
          <UserAvatarImage
            showOutline
            showInitial={!showFullUsername}
            className={styles.UserAvatar}
            layout="fill"
            quality={100}
            objectFit="contain"
          />
        </div>
      </Link>
    </div>
  )
}

const CheckoutHeader: React.FC<{ loginStatus: LOGIN_STATUS }> = ({ loginStatus }) => {
  const t = useTranslations()
  return (
    <header className={styles.Header}>
      <Link href="/">
        <div className={styles.Logo}>
          <div className={styles.LogoContainer}>
            <Image src={StoreLogo} alt={t('sitename')} layout="fill" objectFit="contain" quality={100} />
          </div>
        </div>
      </Link>
      <HeaderUser showFullUsername loginStatus={loginStatus} />
    </header>
  )
}

export const LandingHeader: React.FC<{ loginStatus: LOGIN_STATUS }> = ({ loginStatus }) => {
  const t = useTranslations()
  const { asPath } = useRouter()
  const showCart = /\/squadbusters\/?|\/brawlstars\/?|\/clashroyale\/?|\/clashofclans\/?|\/hayday\/?|\/product\/?/.test(
    asPath,
  )

  return (
    <header className={styles.Header}>
      <Link href="/" tabIndex={0}>
        <div className={styles.Logo}>
          <div className={styles.LogoContainer}>
            <Image src={StoreLogo} alt={t('sitename')} layout="fill" objectFit="contain" quality={100} />
          </div>
        </div>
      </Link>
      <div className={styles.Actions}>
        {showCart ? <CartButton className={styles.Cart} /> : null}
        <HeaderUser loginStatus={loginStatus} />
      </div>
    </header>
  )
}

const HeaderWrapper = ({ children, isCheckout }: { children: React.ReactNode; isCheckout: boolean }) => (
  <div className={cx(styles.HeaderWrapper, { [styles.Checkout]: isCheckout })}>
    <div className={cx(styles.HeaderContainer)}>{children}</div>
  </div>
)

export const Header = ({ enabledStoreFronts }: { enabledStoreFronts: GameId[] }) => {
  const { pathname } = useRouter()
  const isCheckout = pathname.includes('/checkout')
  const loginStatus = useAppSelector((state) => state.user.loginStatus)
  const useStoreFronts = enabledStoreFronts?.length > 0

  if (useStoreFronts && isCheckout) {
    return null
  }

  return (
    <HeaderWrapper isCheckout={isCheckout}>
      {isCheckout ? <CheckoutHeader loginStatus={loginStatus} /> : <LandingHeader loginStatus={loginStatus} />}
    </HeaderWrapper>
  )
}
