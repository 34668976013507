import React from 'react'

import { supportedLanguages } from '@common'
import cx from 'classnames'

import { IconButton } from '@/components/buttons/IconButton'
import { CartButtonIcon } from '@/components/Icons'
import { useCartContext } from '@/contexts/CartContext'
import { useLocale } from '@/features/locale/useLocale'

import { cartItemQuantity } from '../../../../utils/cart'
import { usePreviousWithDelayedReset } from '../../../../utils/usePrevious'

import styles from './CartButton.module.scss'

type Props = {
  className: string
}

export function CartButton({ className }: Props) {
  const { isOpen, items, openCart, closeCart } = useCartContext()
  const locale = useLocale()
  const previousQuantity = usePreviousWithDelayedReset(cartItemQuantity(items), 1000)

  const toggleCart = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (isOpen) {
      closeCart()
    } else {
      openCart()
    }
  }

  const currentCount = cartItemQuantity(items)
  const showAnimation = currentCount > previousQuantity

  const formattedItemCount = new Intl.NumberFormat(supportedLanguages[locale].locale).format(Math.min(currentCount, 99))

  return (
    <div className={cx(className, styles.CartButton, { [styles.Open]: isOpen })} data-cy="cart-button-container">
      <IconButton
        onClick={toggleCart}
        data-cy="cart-button"
        className={cx(styles.CartIcon, {
          [styles.Empty]: currentCount === 0,
          [styles.ItemAdded]: showAnimation,
        })}
        data-after-content={formattedItemCount}
      >
        <CartButtonIcon />
      </IconButton>
    </div>
  )
}
