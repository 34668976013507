import { StoreOfferProduct } from '@common'

import BrawlStyles from './brawlstars/BrawlValueBadge.module.scss'
import { ClashValueBadge } from './clashofclans'
import { RoyaleValueBadge } from './clashroyale'
import { SquadBustersValueIndicator } from './squadbusters'
import { valueTagExtractors } from '../../../../offerValueBadge/OfferValueBadge'
import { BrawlValueIndicator } from '../../../../valueIndicator/BrawlValueIndicator'

type Props = {
  product: StoreOfferProduct
}

export const ValueBadge = ({ product }: Props) => {
  const value = valueTagExtractors[product.game](product)

  if (!value) {
    return null
  }

  switch (product.game) {
    case 'clashofclans':
      return <ClashValueBadge value={value} />
    case 'clashroyale':
      return <RoyaleValueBadge value={value} />
    case 'brawlstars':
      return (
        <BrawlValueIndicator value={Number(value)} variant="catalogOffer" className={BrawlStyles.BrawlValueBadge} />
      )
    case 'squadbusters':
      return <SquadBustersValueIndicator value={value} />
    default:
      return null
  }
}
