import { GameId, ShortGameId } from '@common'

export enum GAME_STATUS {
  ENABLED = 'ENABLED',
  COMING_SOON = 'COMING_SOON',
  DISABLED = 'DISABLED',
}

export interface Game {
  slug: GameId
  className: string
  shortSlug: ShortGameId
  internalId: string
  displayPriority: number
  status: GAME_STATUS
}

export const getShortSlugNotHD = (slug: GameId | undefined) => {
  switch (slug) {
    case 'clashofclans':
      return 'coc'
    case 'clashroyale':
      return 'cr'
    case 'brawlstars':
      return 'bs'
    case 'squadbusters':
      return 'sb'
    default:
      // TODO: not sure what's the meaning of this function
      return 'coc'
  }
}

export const games: Game[] = [
  {
    slug: 'brawlstars',
    shortSlug: 'bs',
    className: 'BrawlStars',
    internalId: 'laser',
    displayPriority: 1,
    status: GAME_STATUS.ENABLED,
  },
  {
    slug: 'clashofclans',
    shortSlug: 'coc',
    className: 'ClashOfClans',
    internalId: 'magic',
    displayPriority: 2,
    status: GAME_STATUS.ENABLED,
  },
  {
    slug: 'clashroyale',
    shortSlug: 'cr',
    className: 'ClashRoyale',
    internalId: 'scroll',
    displayPriority: 3,
    status: GAME_STATUS.ENABLED,
  },
  {
    slug: 'squadbusters',
    shortSlug: 'sb',
    className: 'SquadBusters',
    internalId: 'squad',
    displayPriority: 4,
    status: GAME_STATUS.ENABLED,
  },
  {
    slug: 'hayday',
    shortSlug: 'hd',
    className: 'HayDay',
    internalId: 'soil',
    displayPriority: 5,
    status: GAME_STATUS.ENABLED,
  },
]
