import cx from 'classnames'
import { useLocale, useTranslations } from 'next-intl'

import { TagIcon, TagVariant } from '@/features/storefronts/_storeBase/card/v2/shared/Tags/common'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'

import styles from './PointsTag.module.scss'

type Props = {
  points: number
  originalPoints?: number // Used for showing the original points when there is an override for points
  variant: TagVariant
  tagIcon?: TagIcon
  outline?: boolean
  skew?: boolean
}

export const PointsTag = ({ points, originalPoints, variant, outline, tagIcon, skew }: Props) => {
  const t = useTranslations()
  const locale = useLocale()
  const outlineStyle = outline ? 'outline' : ''

  const showOriginalPoints = originalPoints && originalPoints !== points

  const startAndEndStyle = `Tag${variant}${outline ? 'Outline' : ''}`
  const middleStyle = `TagMiddle${variant}${outline ? 'Outline' : ''}`
  const iconStyle = `Icon${tagIcon ?? 'Points'}`
  const skewStyle = skew ? 'Skew' : ''
  const striketroughStyle = variant !== 'Dark' ? 'StrikeTroughDark' : 'StrikeTroughLight'

  return (
    <div className={cx(styles.Container, styles[skewStyle])}>
      <div className={cx(styles.TagStart, styles[startAndEndStyle])} />
      <div className={cx(styles.TagMiddle, styles[middleStyle], styles[`locale-${locale}`], styles[variant])}>
        <span className={cx(styles.Icon, styles[iconStyle])} />
        {showOriginalPoints ? (
          <StoreTypography
            tag="p"
            textSize="sm"
            overrideFont="supercellText"
            style="plain"
            className={cx(styles.OriginalPointsLabel, styles[outlineStyle], styles[variant], styles[striketroughStyle])}
          >
            {originalPoints}
          </StoreTypography>
        ) : null}
        <StoreTypography
          style="plain"
          tag="p"
          textSize="sm"
          overrideFont="supercellTextBold"
          lineHeight="normal"
          className={cx(styles.PointsLabel, styles[outlineStyle], styles[variant])}
        >
          +{points} <span className={styles.pointsText}>{t('points')}</span>
        </StoreTypography>
        <div className={cx(styles.TagGlow, outlineStyle === 'outline' ? styles.Outline : styles.NoOutline)} />
      </div>
      <div className={cx(styles.TagEnd, styles[startAndEndStyle])} />
    </div>
  )
}
