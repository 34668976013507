export const badAssetsCR = [
  '5000000',
  '5000001',
  '14000000',
  '122000000',
  '122000001',
  '122000002',
  '122000003',
  '122000004',
  '122000005',
  '122000006',
  '122000007',
  '122000008',
  '122000009',
  '122000010',
  '122000011',
  '122000012',
  '159000001',
]
export const badStackableAssetsCR: string[] = []

export const animatedWebMs = [
  '14000001',
  '14000002',
  '14000003',
  '140000003',
  '122000000',
  '122000001',
  '122000002',
  '122000003',
  '122000004',
  '122000005',
  '122000006',
  '122000007',
  '122000008',
  '122000009',
  '122000010',
  '122000011',
  '122000012',
  '122000013',
]
