import * as React from 'react'

import { formatProductPrice, ProductV2 } from '@common'

import { useMarketContext } from '@/contexts/MarketContext'
import { useLocale } from '@/features/locale/useLocale'

type Props = {
  className?: string
  product: ProductV2
}

export function OriginalPrice({ product, className }: Props) {
  const market = useMarketContext('OriginalPrice')
  const locale = useLocale()

  const originalPrices = product.offerData && 'originalPrice' in product.offerData && product.offerData.originalPrice
  const showOriginalPrice = originalPrices && product.offerData?.isDiscount

  if (!showOriginalPrice) {
    return null
  }

  return <p className={className}>{formatProductPrice({ prices: originalPrices }, market, locale)}</p>
}
