import { v4 } from 'uuid'
import type { AttributeValue, DynamoDBClient } from '@aws-sdk/client-dynamodb'
import { BatchWriteItemCommand, QueryCommand } from '@aws-sdk/client-dynamodb'

import * as dynamoDbConfig from './config'
import { StoreError, StoreErrorMessage } from '../../utils/StoreError'
import {
  Stamp,
  stampCardEventSchema,
  StampCardEvent,
  StampCardClaimEvent,
  StampCardBalanceEvent,
  StampCardNonBalanceEvent,
} from '../../models/stampCard'
import {
  createInitialStampCardBalance,
  hasStampExpired,
  STAMP_CARD_INITIAL_BALANCE,
  StampCardRewardIds,
  STAMP_CARD_CLAIM_COST,
  STAMP_CARD_GAMES,
  StampCardGame,
  findEventMatchingToEvent,
  findEventMatchingToStamp,
} from '../../utils/stampCard'
import { unique, last } from '../../utils/array'

import { StoreApiClient } from '../scid/storeApiClient'
import { MetricsClient } from '../cloudwatch/metrics'
import { GameId } from '../../models/game'
import { StoreStampCardUpdatedSCIDEvent } from '../scid/types'
import { range } from '../../utils/range'

const { TABLE_NAME, tablePartitionKey, PARTITION_KEY, SORT_KEY } = dynamoDbConfig

const STAMP_CARD_PREFIX = 'STAMP_CARD' as const
const STAMP_CARD_EVENT_PREFIX = `${STAMP_CARD_PREFIX}#EVENT` as const
const STAMP_CARD_BALANCE_PREFIX = `${STAMP_CARD_PREFIX}#BALANCE` as const
const stampCardEventSortKey = (event: StampCardNonBalanceEvent, index: number) =>
  `${STAMP_CARD_EVENT_PREFIX}#${event.stampGame}#${event.stampType}${
    event.eventSource === 'FREE_STAMP' ? '#FREE_STAMP' : ''
  }#${index}#${event.timestamp}` as const
const stampCardBalanceSortKey = (event: StampCardBalanceEvent) =>
  `${STAMP_CARD_BALANCE_PREFIX}#${event.stampGame}#${event.stampType}` as const

type StampCardDependencies = {
  storeApiClient: StoreApiClient
  metricsClient: MetricsClient
}

export type StampCardClient = ReturnType<typeof createStampCardClient>

export function createStampCardClient(
  dynamoDbClient: DynamoDBClient,
  { storeApiClient, metricsClient }: StampCardDependencies,
) {
  return {
    process: metricsClient.withDurationMetrics('StampCard_Process', process(dynamoDbClient, storeApiClient)),
    revokePurchase: metricsClient.withDurationMetrics(
      'StampCard_RevokePurchase',
      revokePurchase(dynamoDbClient, storeApiClient),
    ),
    getBalance: metricsClient.withDurationMetrics('StampCard_GetBalance', getBalance(dynamoDbClient, storeApiClient)),
    getEventsByAccountId: metricsClient.withDurationMetrics(
      'StampCard_GetEventsByAccountId',
      getEventsByAccountId(dynamoDbClient),
    ),
    getClaimEventsByReference: metricsClient.withDurationMetrics(
      'StampCard_GetClaimEventByReference',
      getClaimEventsByReference(dynamoDbClient),
    ),
  }
}

export function createMockStampCardClient(): ReturnType<typeof createStampCardClient> {
  return {
    process: async () => createInitialStampCardBalance(STAMP_CARD_GAMES),
    revokePurchase: async () => createInitialStampCardBalance(STAMP_CARD_GAMES),
    getBalance: async (_, gameIds) => createInitialStampCardBalance(gameIds),
    getEventsByAccountId: async () => [],
    getClaimEventsByReference: async () => [],
  }
}

function process(dynamoDbClient: DynamoDBClient, storeApiClient: StoreApiClient) {
  const getCurrentBalance = getBalance(dynamoDbClient, storeApiClient)
  const getEvents = getEventsByReference(dynamoDbClient)
  const claimRewardsFromEvents = claimRewards(storeApiClient)

  return async (
    accountId: string,
    stamps: Stamp[],
    source: 'PURCHASE' | 'PANDORA' | 'BACKFILL',
    sourceReference: string,
    rewardIds: StampCardRewardIds,
    consents?: string[],
  ) => {
    const gameIds = unique(stamps.map((stamp) => stamp.game))

    if (gameIds.length === 0) {
      return getCurrentBalance(accountId, STAMP_CARD_GAMES)
    }

    if (source === 'PURCHASE') {
      const events = await getEvents(accountId, sourceReference)

      if (events.length > 0) {
        console.log(`Purchase ${sourceReference} already processed, skipping...`)
        return getCurrentBalance(accountId, STAMP_CARD_GAMES)
      }
    }

    const currentBalance = await getCurrentBalance(accountId, gameIds)

    if (stamps.length === 0) {
      return currentBalance.filter((event) => gameIds.includes(event.stampGame))
    }

    const events = stampsToEvents(currentBalance, stamps, source, sourceReference, rewardIds)
    const command = buildPutCommand(accountId, events)
    const sseTimestamp = Date.now()
    const SSEs = events
      .filter(isNonBalanceEvent)
      .map((event, index) => buildSSE(accountId, event, sourceReference, sseTimestamp + index, consents))

    try {
      await claimRewardsFromEvents(accountId, events.filter(isClaimEvent))
      await dynamoDbClient.send(command)
      await storeApiClient.addEvents(accountId, SSEs, undefined)
      return events.filter(isBalanceEvent).filter((event) => gameIds.includes(event.stampGame))
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_PUT_STAMP_FAILED, err)
    }
  }
}

function revokePurchase(dynamoDbClient: DynamoDBClient, storeApiClient: StoreApiClient) {
  const getEventsToRevoke = getEventsByReference(dynamoDbClient)
  const getCurrentBalance = getBalance(dynamoDbClient, storeApiClient)
  const revokeRewardsFromEvents = revokeRewards(storeApiClient)

  return async (accountId: string, sourceReference: string) => {
    const [originalEvents, currentBalance] = await Promise.all([
      getEventsToRevoke(accountId, sourceReference),
      getCurrentBalance(accountId, STAMP_CARD_GAMES),
    ])

    if (originalEvents.length === 0) {
      return currentBalance
    }

    const eventsToRevoke = invertEvents(
      currentBalance,
      originalEvents.filter((event) => !event.eventSourceReference.endsWith('#REVOKE')),
    )
    const command = buildPutCommand(accountId, eventsToRevoke)
    const sseTimestamp = Date.now()
    const SSEs = eventsToRevoke
      .filter(isNonBalanceEvent)
      .map((event, index) => buildSSE(accountId, event, 'REFUND', sseTimestamp + index))

    try {
      await revokeRewardsFromEvents(accountId, eventsToRevoke.filter(isClaimEvent))
      await dynamoDbClient.send(command)
      await storeApiClient.addEvents(accountId, SSEs, undefined)
      return eventsToRevoke.filter(isBalanceEvent)
    } catch (err) {
      console.error('Events:', JSON.stringify(eventsToRevoke, null, 2)) // TODO: remove, for debugging
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_PUT_STAMP_FAILED, err)
    }
  }
}

function getBalance(dynamoDbClient: DynamoDBClient, storeApiClient: StoreApiClient) {
  return async (accountId: string, gameIds: readonly StampCardGame[]) => {
    const initialBalance = createInitialStampCardBalance(gameIds)
    const gameIdExpressionAttributeValues = gameIds.reduce((expressionAttributeValues, gameId, index) => {
      return Object.assign({}, expressionAttributeValues, { [`:gameId${index}`]: { S: gameId } })
    }, {})
    const gameIdFilterExpression = `stampGame in (${Object.keys(gameIdExpressionAttributeValues).join(', ')})`

    const command = new QueryCommand({
      TableName: TABLE_NAME,
      KeyConditionExpression: '#PK = :PK and begins_with(#SK, :SK)',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: tablePartitionKey(accountId) },
        ':SK': { S: STAMP_CARD_BALANCE_PREFIX },
        ...gameIdExpressionAttributeValues,
      },
      FilterExpression: gameIdFilterExpression,
    })

    try {
      const response = await dynamoDbClient.send(command)

      if (!response.Items || response.Items.length === 0) {
        const initialBalanceEvents = gameIds.flatMap(createInitialBalanceEvents)
        const initialBalanceCommand = buildPutCommand(accountId, initialBalanceEvents)
        const SSEs = initialBalanceEvents
          .filter(isNonBalanceEvent)
          .map((event) => buildSSE(accountId, event, event.eventSourceReference, Date.now()))
        await dynamoDbClient.send(initialBalanceCommand)
        await storeApiClient.addEvents(accountId, SSEs, undefined)
        return initialBalance
      }

      const items = response.Items.map(dynamoDbItemToStampCardEvent)
      const events = stampCardEventSchema.array().parse(items)
      const balance = initialBalance.map((initialBalanceEvent) => {
        const matchingEvent = findEventMatchingToEvent(events, initialBalanceEvent)

        if (!matchingEvent) {
          return initialBalanceEvent
        }

        const hasExpired = hasStampExpired(matchingEvent.timestamp)

        if (hasExpired) {
          return initialBalanceEvent
        }

        return matchingEvent
      })

      return balance.filter(isBalanceEvent)
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_GET_BALANCE_FAILED, err)
    }
  }
}

function getEventsByAccountId(client: DynamoDBClient) {
  return async (accountId: string, game?: GameId) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      KeyConditionExpression: '#PK = :PK and begins_with(#SK, :SK)',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: tablePartitionKey(accountId) },
        ':SK': { S: STAMP_CARD_PREFIX },
        ...(game ? { ':stampGame': { S: game } } : {}),
      },
      ...(game ? { FilterExpression: 'stampGame = :stampGame' } : {}),
    })

    try {
      const response = await client.send(command)

      if (!response.Items) {
        return []
      }

      const items = response.Items.map(dynamoDbItemToStampCardEvent)
      return stampCardEventSchema.array().parse(items)
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_GET_EVENT_FAILED, err)
    }
  }
}

function getClaimEventsByReference(client: DynamoDBClient) {
  return async (accountId: string, purchaseId: string) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      KeyConditionExpression: '#PK = :PK and begins_with(#SK, :SK)',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: tablePartitionKey(accountId) },
        ':SK': { S: STAMP_CARD_EVENT_PREFIX },
        ':eventType': { S: 'CLAIM' },
        ':eventSourceReference': { S: purchaseId },
      },
      FilterExpression: 'eventType = :eventType and begins_with(eventSourceReference, :eventSourceReference)',
    })

    try {
      const response = await client.send(command)

      if (!response.Items) {
        return []
      }

      const items = response.Items.map(dynamoDbItemToStampCardEvent)
      return stampCardEventSchema.array().parse(items).filter(isClaimEvent)
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_GET_EVENT_FAILED, err)
    }
  }
}

function claimRewards(storeApiClient: StoreApiClient) {
  return async (accountId: string, events: StampCardClaimEvent[]) => {
    if (events.length === 0) {
      return
    }

    try {
      // FIXME: allow adding items with quantity > 1 in SCID
      await Promise.all(
        events.map((event) => {
          const purchaseId =
            event.eventSource === 'BACKFILL' ? `${v4()}#${event.eventSourceReference}` : event.eventSourceReference
          const receipt = { purchaseId }
          const reward = { [event.reward]: { quantity: 1, totalUSDCents: 0 } }
          return storeApiClient.addItems(
            accountId,
            receipt,
            reward,
            undefined,
            undefined,
            'SUPERCELL_STORE_STAMP_CARD_REWARDS',
            undefined,
          )
        }),
      )
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_PUT_STAMP_FAILED, err)
    }
  }
}

function revokeRewards(storeApiClient: StoreApiClient) {
  return async (accountId: string, events: StampCardClaimEvent[]) => {
    if (events.length === 0) {
      return
    }

    try {
      // FIXME: see above
      await Promise.all(
        events.map((event) => {
          const originalPurchaseId = toOriginalSourceReference(event.eventSourceReference)
          const receipt = {
            purchaseId: event.eventSourceReference,
            originalPurchaseId: originalPurchaseId,
          }
          return storeApiClient.revokeItems(
            accountId,
            receipt,
            originalPurchaseId,
            'REFUND_PANDORA',
            'SUPERCELL_STORE_STAMP_CARD_REWARDS',
            undefined,
          )
        }),
      )
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_PUT_STAMP_FAILED, err)
    }
  }
}

function getEventsByReference(client: DynamoDBClient) {
  return async (accountId: string, eventSourceReference: string) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      KeyConditionExpression: '#PK = :PK and begins_with(#SK, :SK)',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: tablePartitionKey(accountId) },
        ':SK': { S: STAMP_CARD_EVENT_PREFIX },
        ':eventSourceReference': { S: eventSourceReference },
      },
      FilterExpression: 'begins_with(eventSourceReference, :eventSourceReference)',
    })

    try {
      const response = await client.send(command)

      if (!response.Items) {
        return []
      }

      const items = response.Items.map(dynamoDbItemToStampCardEvent)
      return stampCardEventSchema.array().parse(items).filter(isNonBalanceEvent)
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_GET_EVENT_FAILED, err)
    }
  }
}

function stampsToEvents(
  currentBalance: StampCardBalanceEvent[],
  stamps: Stamp[],
  eventSource: 'PURCHASE' | 'PANDORA' | 'BACKFILL',
  eventSourceReference: string,
  rewardIds: StampCardRewardIds,
): StampCardEvent[] {
  const timestamp = Date.now()
  return stamps.flatMap((stamp) => {
    const eventMatchingToStamp = findEventMatchingToStamp(currentBalance, stamp)
    const balance = (eventMatchingToStamp?.balance ?? STAMP_CARD_INITIAL_BALANCE) + stamp.amount
    const backfillBalance = eventMatchingToStamp?.backfilledStamps ?? 0
    const currentCardBackfillBalance = eventMatchingToStamp?.backfilledStampsInCurrentCard ?? 0
    const cardNumber = eventMatchingToStamp?.cardNumber ?? 1
    const isStampEvent = stamp.amount >= 0

    const stampOrRevokeEvent = {
      eventType: isStampEvent ? ('STAMP' as const) : ('REVOKE' as const),
      eventSource: eventSource,
      eventSourceReference: eventSourceReference,
      stampGame: stamp.game,
      stampType: stamp.type,
      stamps: stamp.amount,
      balance: balance,
      timestamp: timestamp,
    } as const

    const numberOfClaimEvents =
      isStampEvent && balance >= STAMP_CARD_CLAIM_COST ? Math.floor(balance / (STAMP_CARD_CLAIM_COST - 1)) : 0

    const claimAndFreeStampEvents = range(0, numberOfClaimEvents).flatMap((index) => {
      const nextBalanceBeforeFreeStamp =
        balance - // balance at the beginning
        STAMP_CARD_CLAIM_COST * (index + 1) + // minus the cost of the claim events in this loop so far
        STAMP_CARD_INITIAL_BALANCE * index // plus free stamps after each claim event in this loop, except this one

      if (nextBalanceBeforeFreeStamp < 0) {
        return []
      }

      return [
        {
          eventType: 'CLAIM' as const,
          eventSource: eventSource,
          eventSourceReference: `${eventSourceReference}#${index}`,
          stampGame: stamp.game,
          stampType: stamp.type,
          stamps: -STAMP_CARD_CLAIM_COST,
          balance: nextBalanceBeforeFreeStamp,
          reward: rewardIds[stamp.game],
          timestamp: timestamp + 2 * index + 1,
        },
        {
          eventType: 'STAMP' as const,
          eventSource: 'FREE_STAMP' as const,
          eventSourceReference: `${eventSourceReference}#${index}`,
          stampGame: stamp.game,
          stampType: stamp.type,
          stamps: STAMP_CARD_INITIAL_BALANCE,
          balance: nextBalanceBeforeFreeStamp + STAMP_CARD_INITIAL_BALANCE,
          timestamp: timestamp + 2 * index + 2,
        },
      ] as const
    })

    const currentCardBalance = last(claimAndFreeStampEvents)?.balance ?? balance
    const backfilledStamps = (eventSource === 'BACKFILL' ? stamp.amount : 0) + backfillBalance
    const backfilledStampsInCurrentCard =
      eventSource === 'BACKFILL' ? currentCardBalance : numberOfClaimEvents > 0 ? 0 : currentCardBackfillBalance

    const balanceEvent = {
      eventType: 'BALANCE' as const,
      stampGame: stamp.game,
      stampType: stamp.type,
      stampsRequiredToClaim: STAMP_CARD_CLAIM_COST,
      balance: currentCardBalance,
      backfilledStamps: backfilledStamps,
      backfilledStampsInCurrentCard: backfilledStampsInCurrentCard,
      cardNumber: cardNumber + numberOfClaimEvents,
      timestamp: timestamp + 2 * numberOfClaimEvents + 1,
    } as const

    return [stampOrRevokeEvent, ...claimAndFreeStampEvents, balanceEvent] as const
  })
}

function invertEvents(currentBalance: StampCardBalanceEvent[], events: StampCardNonBalanceEvent[]) {
  const timestamp = Date.now()
  return currentBalance.flatMap((balanceEvent, balanceIndex) => {
    const nonBalanceEvents = events.reduce<StampCardNonBalanceEvent[]>((invertedEvents, event, index) => {
      const matchingEvent = findEventMatchingToEvent([event], balanceEvent)

      if (!matchingEvent) {
        return invertedEvents
      }

      const previousBalance = last(invertedEvents)?.balance ?? balanceEvent.balance

      return invertedEvents.concat(
        Object.assign({}, matchingEvent, {
          eventSourceReference: `${matchingEvent.eventSourceReference}#REVOKE`,
          stamps: -matchingEvent.stamps,
          balance: previousBalance - matchingEvent.stamps,
          timestamp: timestamp + (balanceIndex + 1) * index,
        }),
      )
    }, [])

    const lastEvent = last(nonBalanceEvents) ?? balanceEvent
    const newBalanceEvent = {
      eventType: 'BALANCE',
      stampGame: balanceEvent.stampGame,
      stampType: balanceEvent.stampType,
      balance: lastEvent.balance,
      timestamp: lastEvent.timestamp + 1,
      stampsRequiredToClaim: balanceEvent.stampsRequiredToClaim,
      backfilledStamps: balanceEvent.backfilledStamps,
      backfilledStampsInCurrentCard: balanceEvent.backfilledStampsInCurrentCard,
      cardNumber: balanceEvent.cardNumber,
    } as const
    return [...nonBalanceEvents, newBalanceEvent]
  })
}

function toOriginalSourceReference(eventSourceReference: string) {
  return eventSourceReference.replace('#REVOKE', '')
}

function buildPutCommand(accountId: string, events: StampCardEvent[]) {
  const commands = events.map((event, index) => eventToBatchWriteRequestCommand(accountId, event, index))

  return new BatchWriteItemCommand({
    RequestItems: {
      [TABLE_NAME]: commands,
    },
  })
}

type BatchWriteRequestCommand =
  | {
      PutRequest: {
        Item: {
          PK: { S: string }
          SK: { S: string }
          eventType: { S: string }
          eventSource: { S: string }
          eventSourceReference: { S: string }
          stampGame: { S: string }
          stampType: { S: string }
          stamps: { N: string }
          balance: { N: string }
          timestamp: { N: string }
        }
      }
    }
  | {
      PutRequest: {
        Item: {
          PK: { S: string }
          SK: { S: string }
          eventType: { S: string }
          eventSource: { S: string }
          eventSourceReference: { S: string }
          stampGame: { S: string }
          stampType: { S: string }
          stamps: { N: string }
          balance: { N: string }
          reward: { S: string }
          timestamp: { N: string }
        }
      }
    }
  | {
      PutRequest: {
        Item: {
          PK: { S: string }
          SK: { S: string }
          eventType: { S: string }
          stampGame: { S: string }
          stampType: { S: string }
          stampsRequiredToClaim: { N: string }
          balance: { N: string }
          backfilledStamps: { N: string }
          backfilledStampsInCurrentCard: { N: string }
          cardNumber: { N: string }
          timestamp: { N: string }
        }
      }
    }
function eventToBatchWriteRequestCommand(
  accountId: string,
  event: StampCardEvent,
  index: number,
): BatchWriteRequestCommand {
  switch (event.eventType) {
    case 'STAMP':
    case 'REVOKE':
      return {
        PutRequest: {
          Item: {
            PK: { S: tablePartitionKey(accountId) },
            SK: { S: stampCardEventSortKey(event, index) },
            eventType: { S: event.eventType },
            eventSource: { S: event.eventSource },
            eventSourceReference: { S: event.eventSourceReference },
            stampGame: { S: event.stampGame },
            stampType: { S: event.stampType },
            stamps: { N: event.stamps.toString() },
            balance: { N: event.balance.toString() },
            timestamp: { N: event.timestamp.toString() },
          },
        },
      } as const
    case 'CLAIM':
      return {
        PutRequest: {
          Item: {
            PK: { S: tablePartitionKey(accountId) },
            SK: { S: stampCardEventSortKey(event, index) },
            eventType: { S: event.eventType },
            eventSource: { S: event.eventSource },
            eventSourceReference: { S: event.eventSourceReference },
            stampGame: { S: event.stampGame },
            stampType: { S: event.stampType },
            stamps: { N: event.stamps.toString() },
            balance: { N: event.balance.toString() },
            reward: { S: event.reward },
            timestamp: { N: event.timestamp.toString() },
          },
        },
      }
    case 'BALANCE':
      return {
        PutRequest: {
          Item: {
            PK: { S: tablePartitionKey(accountId) },
            SK: { S: stampCardBalanceSortKey(event) },
            eventType: { S: 'BALANCE' },
            stampGame: { S: event.stampGame },
            stampType: { S: event.stampType },
            stampsRequiredToClaim: { N: event.stampsRequiredToClaim.toString() },
            balance: { N: event.balance.toString() },
            backfilledStamps: { N: event.backfilledStamps.toString() },
            backfilledStampsInCurrentCard: { N: event.backfilledStampsInCurrentCard.toString() },
            cardNumber: { N: event.cardNumber.toString() },
            timestamp: { N: event.timestamp.toString() },
          },
        },
      } as const
    default:
      throw StoreError.withMessage(StoreErrorMessage.DB_STAMP_CARD_PUT_STAMP_FAILED, `Unknown event type: ${event}`)
  }
}

function buildSSE(
  accountId: string,
  event: StampCardNonBalanceEvent,
  updateReasonReferenceId: string,
  timestamp: number,
  consents?: string[],
): StoreStampCardUpdatedSCIDEvent {
  const updateReason =
    event.eventType === 'CLAIM'
      ? event.eventType
      : event.eventSource === 'PANDORA'
        ? 'CUSTOMER_SERVICE'
        : event.eventSource
  return {
    type: 'STORE_STAMP_CARD_UPDATED',
    timestamp,
    accountId,
    stampCardEventId: v4(),
    stampCardId: `${event.stampGame}#${event.stampType}`,
    stampCardUpdateReason: updateReason,
    stampCardUpdateReasonReferenceId: updateReasonReferenceId,
    stampCardUpdateAmount: event.stamps,
    stampCardNewBalance: event.balance,
    consents,
  }
}

function createInitialBalanceEvents(game: StampCardGame): StampCardEvent[] {
  const timestamp = Date.now()
  return [
    {
      eventType: 'STAMP',
      eventSource: 'FREE_STAMP',
      eventSourceReference: 'free-stamp-for-new-stamp-card',
      stampGame: game,
      stampType: 'SEASON_PASS',
      stamps: STAMP_CARD_INITIAL_BALANCE,
      balance: STAMP_CARD_INITIAL_BALANCE,
      timestamp: timestamp,
    },
    {
      eventType: 'BALANCE',
      stampGame: game,
      stampType: 'SEASON_PASS',
      stampsRequiredToClaim: STAMP_CARD_CLAIM_COST,
      balance: STAMP_CARD_INITIAL_BALANCE,
      backfilledStamps: 0,
      backfilledStampsInCurrentCard: 0,
      cardNumber: 1,
      timestamp: timestamp + 1,
    },
  ]
}

function isClaimEvent(event: StampCardEvent): event is StampCardClaimEvent {
  return event.eventType === 'CLAIM'
}

function isBalanceEvent(event: StampCardEvent): event is StampCardBalanceEvent {
  return event.eventType === 'BALANCE'
}

function isNonBalanceEvent(event: StampCardEvent): event is StampCardNonBalanceEvent {
  return event.eventType !== 'BALANCE'
}

function dynamoDbItemToStampCardEvent(item: Record<string, AttributeValue>) {
  return {
    eventType: item.eventType?.S,
    eventSource: item.eventSource?.S,
    eventSourceReference: item.eventSourceReference?.S,
    stampGame: item.stampGame?.S,
    stampType: item.stampType?.S,
    stampsRequiredToClaim: parseInt(item.stampsRequiredToClaim?.N ?? '0', 10),
    stamps: parseInt(item.stamps?.N ?? '0', 10),
    balance: parseInt(item.balance?.N ?? '0', 10),
    backfilledStamps: parseInt(item.backfilledStamps?.N ?? '0', 10),
    backfilledStampsInCurrentCard: parseInt(item.backfilledStampsInCurrentCard?.N ?? '0', 10),
    cardNumber: parseInt(item.cardNumber?.N ?? '1', 10),
    reward: item.reward?.S,
    timestamp: parseInt(item.timestamp?.N ?? '0', 10),
  }
}
