// map asset IDs to character names
export const hyperchargeIdToCharacter: Record<string, string> = {
  '23000613': 'Shelly',
  '23000614': 'Colt',
  '23000615': 'Spike',
  '23000616': 'Bull',
  '23000617': 'Jacky',
  '23000618': 'Pearl',
  '23000627': 'Charlie',
  '23000628': 'Rosa',
  '23000629': 'Maisie',
  '23000630': 'Jessie',
  '23000631': 'Lou',
  '23000632': 'Colette',
  '23000641': 'Mico',
  '23000650': 'Crow',
  '23000651': 'Leon',
  '23000652': 'Dynamike',
  '23000653': 'Fang',
  '23000654': 'Edgar',
  '23000679': 'Primo',
  '23000680': 'Buzz',
  '23000681': 'Sprout',
  '23000682': 'Belle',
  '23000683': 'Bibi',
  '23000684': 'Cordelius',
  // these below are missing character images
  '23000685': 'Sandy',
  '23000686': 'Nita',
  '23000687': 'Tick',
  '23000688': 'Brock',
  '23000689': 'Max',
  '23000690': 'Gene',
}

export const BrawlerItemType = 'Hero'

export const hyperchargeIds = Object.keys(hyperchargeIdToCharacter)

export const badAssetsBS = ['5000000', '5000019', '5000022', '5000023']

export const badStackableAssetsBS = ['5000000_0', '5000000_1', '5000000_2', '5000000_3', '5000000_4', '5000000_5']

export const stackableAssetsMap: Record<string, [number, number][]> = {
  '5000000': [
    [1, 30],
    [31, 80],
    [81, 170],
    [171, 360],
    [361, 950],
    [951, Number.MAX_SAFE_INTEGER],
  ],
  '5000001': [
    [1, 150],
    [151, 1000],
    [1001, 1500],
    [1501, Number.MAX_SAFE_INTEGER],
  ],
  '5000019': [
    [1, 9],
    [10, 100],
    [101, Number.MAX_SAFE_INTEGER],
  ],
  '5000023': [
    [1, 200],
    [201, 1500],
    [1501, Number.MAX_SAFE_INTEGER],
  ],
}
