import { PropsWithChildren, ReactNode } from 'react'

import { GameId } from '@common'
import cx from 'classnames'

import styles from './ItemsGrid.module.scss'

type GridProps = {
  children: ReactNode
}

type GridItemProps = PropsWithChildren<{
  gameId: GameId
}>

export const Grid = ({ children }: GridProps) => <div className={styles.Items}>{children}</div>

export const GridItem = ({ children, gameId }: GridItemProps) => (
  <div className={cx(styles.Item, styles[gameId])}>{children}</div>
)
