import { Profile } from '@common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { api } from '@/api'

export enum LOGIN_STATUS {
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGED_IN = 'LOGGED_IN',
  SSO_LOGIN_MIGHT_HAPPEN = 'SSO_MIGHT_HAPPEN',
}

export type UserState = {
  profile: Profile['profile'] | null
  inventory: Profile['inventory'] | null
  loginStatus: LOGIN_STATUS
  error: string | null
  helpshift: { name: string; userAuthToken: string } | null
} & Partial<{ token: string }>

const initialState: UserState = {
  profile: null,
  inventory: null,
  loginStatus: LOGIN_STATUS.SSO_LOGIN_MIGHT_HAPPEN,
  error: null,
  helpshift: null,
} as const

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginStatus: (state: UserState, action: PayloadAction<LOGIN_STATUS>) => {
      state.error = null
      state.loginStatus = action.payload
    },
    loginError: (state: UserState, action: PayloadAction<{ error: string }>) => {
      state.error = action.payload.error
    },
    resetUser: () => {
      return {
        ...initialState,
        loginStatus: LOGIN_STATUS.LOGGED_OUT,
      }
    },
    setToken: (state: UserState, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        delete state.token
      } else {
        state.token = action.payload
      }
    },
    login: (
      state: UserState,
      action: PayloadAction<{ ok: true; profile: Profile['profile']; inventory: Profile['inventory'] } | { ok: false }>,
    ) => {
      if (action.payload.ok) {
        state.loginStatus = LOGIN_STATUS.LOGGED_IN
        state.profile = action.payload.profile
        state.inventory = action.payload.inventory
      } else {
        state.loginStatus = LOGIN_STATUS.LOGGED_OUT
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.session.matchFulfilled, (state, { payload }) => {
      if (payload.ok) {
        state.loginStatus = LOGIN_STATUS.LOGGED_IN
        state.profile = payload.profile
        state.inventory = payload.inventory
        state.helpshift = payload.helpshift ?? null
      } else {
        state.loginStatus = LOGIN_STATUS.LOGGED_OUT
      }
    })
    builder.addMatcher(api.endpoints.session.matchRejected, (state) => {
      state.loginStatus = LOGIN_STATUS.LOGGED_OUT
    })
    builder.addMatcher(api.endpoints.logout.matchFulfilled, () => {
      return initialState
    })
    builder.addMatcher(
      api.endpoints.customerAddBillingAddress.matchFulfilled,
      (
        state,
        {
          meta: {
            arg: { originalArgs },
          },
        },
      ) => {
        if (state.profile === null) return
        state.profile = {
          ...state.profile,
          billingAddress: originalArgs,
        }
      },
    )
    builder.addMatcher(api.endpoints.customerRemoveBillingAddress.matchFulfilled, (state) => {
      if (state.profile === null) return
      state.profile = {
        ...state.profile,
        billingAddress: undefined,
      }
    })
  },
})

export const { setLoginStatus, resetUser, setToken, login } = userSlice.actions

export default userSlice.reducer
