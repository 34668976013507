export const StoreErrorMessage = {
  INVALID_REQUEST_BODY: ['Invalid request body', 400],
  INVALID_PURCHASE: ['Invalid purchase', 400],
  INVALID_PURCHASE_STATUS: ['Invalid purchase status', 400],
  INVALID_PURCHASE_STATUS_NOT_IN_DRAFT_STATE: ['Invalid purchase status, purchase is not in draft state.', 400],
  INVALID_PURCHASE_STATUS_NOT_IN_DRAFT_NOR_PENDING_STATE: [
    'Invalid purchase status, purchase is not in draft nor pending state.',
    400,
  ],
  INVALID_PURCHASE_DRAFT_EMPTY: ['Invalid purchase draft, empty', 400],
  INVALID_PURCHASE_DRAFT_CONTENT: ['Invalid purchase draft, content', 400],
  INVALID_PURCHASE_DRAFT_TOO_MANY_PRODUCTS: ['Invalid purchase draft, too many products', 400],
  INVALID_PURCHASE_DRAFT_PAYMENT_PROVIDER: ['Invalid payment provider on purchase', 400],
  PURCHASE_NOT_FOUND: ['Required purchase not found', 400],
  PRODUCT_NOT_FOUND: ['Product not found', 400],
  PRODUCT_PRICE_NOT_FOUND: ['Product price not found', 400],
  CUSTOMER_NOT_FOUND: ['Customer not found', 400],
  COUNTRY_NOT_FOUND: ['Country not found', 400],
  INVALID_URL_PARAMETER: ['Missing or invalid URL parameter', 400],
  CUSTOMER_INELIGIBLE: ['Customer is ineligible', 400],
  PURCHASE_DRAFT_EXPIRED: ['Purchase draft has expired', 400],
  PURCHASE_TRANSACTION_CONFLICT: ['Purchase draft has expired', 400],
  OFFER_EXPIRED: ['Offer expired', 400],
  SESSION_EXPIRED: ['Session expired', 401],
  // THIS IS ONLY FOR LOCAL ENVIRONMENTS
  // IN PRODUCTION CUSTOMERS ARE NOT DELETED FROM DATABASE
  // WHILE THEIR TOKEN IS VALID
  MISSING_CUSTOMER: ['Missing customer', 401],
  AUTHORIZATION_FAILED_CUSTOMER: ['Authorization of customer failed', 401],
  AUTHORIZATION_FAILED_ADYEN_REDIRECT: ['Authorization of adyen redirect request failed', 401],
  AUTHORIZATION_FAILED_ADYEN_WEBHOOK: ['Authorization of adyen webhook request failed', 401],
  AUTHORIZATION_FAILED_ADMIN: ['Authorization of admin failed', 401],

  AUTHORIZATION_FAILED_RAZER_CALLBACK: ['Authorization of integrity of razer callback request failed', 401],

  // Payment service provider webhook errors
  PSP_AUTHORIZATION_FAILED: ['Authorization of request failed', 401],
  PSP_INVALID_REQUEST_BODY: ['Invalid request body', 400],
  PSP_INVALID_WEBHOOK_TYPE: ['Invalid webhook type', 400],
  PSP_INVALID_REFUND: ['Invalid refund', 400],
  PSP_INVALID_PAYMENT: ['Invalid payment', 400],

  NOT_FOUND: ['Not found', 404],

  REFUND_FAILED: ['Refund failed', 500],
  MISSING_SCID_TOKEN: ['Missing authtoken from SCID login response', 500],
  // DB Errors
  DB_CUSTOMER_GET_FAILED: ['Failed to get customer from DB', 500],
  DB_CUSTOMER_CREATE_FAILED: ['Failed to create customer in DB', 500],
  DB_CUSTOMER_UPDATE_FAILED: ['Failed to save authToken of customer in DB', 500],
  DB_CUSTOMER_DELETE_FAILED: ['Failed to delete customer from DB', 500],
  DB_CUSTOMER_ADD_ADDRESS_FAILED: ['Failed to add billing address for customer in DB', 500],
  DB_CUSTOMER_REMOVE_ADDRESS_FAILED: ['Failed to remove billing address from customer in DB', 500],
  DB_NOTIFICATION_GET_FAILED: ['Failed to get notification from DB', 500],
  DB_NOTIFICATION_CREATE_FAILED: ['Failed to create notification in DB', 500],
  DB_NOTIFICATION_DELETE_FAILED: ['Failed to delete notification from DB', 500],
  DB_PURCHASE_GET_FAILED: ['Failed to get purchase from DB', 500],
  DB_PURCHASE_CREATE_FAILED: ['Failed to create purchase in DB', 500],
  DB_PURCHASE_UPDATE_FAILED: ['Failed to update purchase in DB', 500],
  DB_PURCHASE_SET_ITEM_RECEIVED_FAILED: ['Failed to set item received in DB', 500],
  DB_PURCHASE_SET_ITEM_REVOKED_FAILED: ['Failed to set item revoked in DB', 500],
  DB_PURCHASE_SET_REFUND_FAILED: ['Failed to set purchase refunded in DB', 500],
  DB_STAMP_CARD_GET_EVENTS_FAILED: ['Failed to get stamp card events', 500],
  DB_STAMP_CARD_GET_BALANCE_FAILED: ['Failed to get stamp card balance', 500],
  DB_STAMP_CARD_PUT_STAMP_FAILED: ['Failed to put stamp card stamp event', 500],
  DB_STAMP_CARD_PUT_CLAIM_FAILED: ['Failed to put stamp card claim event', 500],
  DB_STAMP_CARD_GET_EVENT_FAILED: ['Failed to get stamp card event', 500],
  DB_STAMP_CARD_NEGATIVE_BALANCE: ['Cannot set stamp card balance to a negative number', 400],
  DB_CART_GET_FAILED: ['Failed to get cart from DB', 500],
  DB_CART_UPDATE_FAILED: ['Failed to update cart in DB', 500],
  DB_CART_RESET_FAILED: ['Failed to reset cart in DB', 500],

  PURCHASE_PRODUCTS_ROWS_MISMATCH: ['Mismatch between purchase products and rows', 500],
  PURCHASE_PARSING_FAILED: ['Failed to parse purchase', 500],
  RECEIPT_PARSING_FAILED: ['Failed to parse receipt', 500],

  ITEMS_ALREADY_RECEIVED: ['Item already received', 500],
  ITEM_NOT_RECEIVED: ['Item not received', 500],
  ITEM_ALREADY_REVOKED: ['Item already revoked', 500],

  STATS_UPDATE_FAILED: ['Failed to update stats in DB', 500],

  EMAIL_SEND_FAILURE: ['Failed to send email', 500],

  CREATORS_API_ERROR: ['Failed to validate creator code', 502],

  SCID_BAD_REQUEST: ['Bad request', 400],
  SCID_UNAUTHORIZED: ['Unauthorized', 401],
  SCID_BAD_GATEWAY: ['Bad gateway', 502],
  SCID_UNKNOWN_ERROR: ['Unknown error', 503],
  SCID_GATEWAY_TIMEOUT: ['Gateway timeout', 504],
  SCID_TIMEOUT: ['Timeout', 504],
  APP_ACCOUNT_UNAVAILABLE: ['App account unavailable', 502],

  XSOLLA_INVALID_REQUEST: ['Bad request', 400],

  REFUND_ALREADY_TRIGGERED: ['Refund already triggered', 400],
  PURCHASE_DRAFT_PAYMENT_SESSION_CREATE_FAILED: ['Failed to create payment session', 500],

  BAD_REQUEST: ['Bad request', 400],
  UNAUTHORIZED: ['Unauthorized', 401],
  INTERNAL_SERVER_ERROR: ['Internal server error', 500],
} as const

type StoreErrorMessage = (typeof StoreErrorMessage)[keyof typeof StoreErrorMessage]

export class StoreError extends Error {
  public body: unknown
  static withMessage(param: StoreErrorMessage, thrownErr?: unknown): StoreError {
    const [message, code] = param
    return new StoreError(message, code, thrownErr)
  }
  private constructor(
    public message: StoreErrorMessage[0],
    public code: StoreErrorMessage[1],
    public thrownErr?: unknown,
  ) {
    super(message)
    this.name = 'StoreError'
    this.code = code
    this.thrownErr = thrownErr
  }
  withBody(body: unknown): StoreError {
    this.body = body
    return this
  }

  static toJSONStringifyableObject(error: unknown): string {
    try {
      return JSON.parse(
        JSON.stringify(error, (_, value: unknown) => {
          if (value instanceof Error) {
            const error: Record<string, unknown> = {}
            Object.getOwnPropertyNames(value).forEach((propName: string) => {
              error[propName] = (value as unknown as Record<string, unknown>)[propName]
            })
            return error
          }
          return value
        }),
      )
    } catch (err) {
      console.error(error, err)
      return error as string
    }
  }
}
