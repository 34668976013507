import { STORE_POPULAR_TAG, TAG_TRANSLATIONS_KEYS } from '@common'
import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { useLocale } from '@/features/locale/useLocale'

import { StoreTypography } from '../../../../typography/StoreTypography'

import styles from './Ribbons.module.scss'

type ColorVariant = 'red' | 'purple'
type SizeVariant = 'xs' | 'sm' | 'md' | 'lg'

export type Ribbon = {
  tag?: 'Popular' | 'Percentage' | undefined
  value?: string
  label?: string
  variant: ColorVariant
}

type Props = {
  ribbons: Ribbon[]
}

export function Ribbons({ ribbons }: Props) {
  const translatedRibbons = useTranslatedRibbonLabels(firstTwoRibbons(ribbons))

  return (
    <div className={styles.Ribbons}>
      {translatedRibbons.map((ribbon, index) => (
        <Ribbon key={index} {...ribbon} />
      ))}
    </div>
  )
}

function Ribbon({ variant, label, tag }: Ribbon) {
  const size = getSizeVariant(label?.length ?? 0, variant)
  const locale = useLocale()
  const tagStyle = `${tag ?? 'Unknown'}-${locale}`

  return (
    <div className={cx(styles.Ribbon, styles[variant], styles[size])}>
      <StoreTypography tag="p" textSize="sm" style="outlined" className={cx(styles[tagStyle], styles.Label)}>
        {label}
      </StoreTypography>
    </div>
  )
}

function firstTwoRibbons(ribbons: Ribbon[]): Ribbon[] {
  return ribbons.slice(0, 2)
}

function getSizeVariant(length: number, colorVariant: ColorVariant): SizeVariant {
  if (colorVariant === 'red') {
    return getRedSizeVariant(length)
  }
  return getPurpleSizeVariant(length)
}

function getRedSizeVariant(length: number): SizeVariant {
  if (length >= 19) return 'lg'
  if (length >= 11) return 'md'
  if (length >= 5) return 'sm'
  return 'xs'
}

function getPurpleSizeVariant(length: number): SizeVariant {
  if (length <= 5) return 'xs'
  if (length <= 10) return 'sm'
  if (length <= 15) return 'md'
  return 'lg'
}

const useTranslatedRibbonLabels = (ribbons: Ribbon[]): Ribbon[] => {
  const t = useTranslations()
  return ribbons.map((ribbon) => {
    if (ribbon.label === STORE_POPULAR_TAG) {
      return { ...ribbon, label: t(TAG_TRANSLATIONS_KEYS[STORE_POPULAR_TAG]) }
    }
    return ribbon
  })
}
