import { useMemo } from 'react'

import { ProductContent } from '@common'

import { hyperchargeIds } from '../constants'

export const brawlHyperChargeAsset = (id: string) => {
  return hyperchargeIds.includes(id)
}

export const isBrawlerOrSkin = (content: ProductContent) => {
  return content.product.itemType === 'Hero' || content.product.itemType === 'Skin'
}

export const isBrawlCharacter = (id: string) => id.startsWith('1600')

export const useBrawlCharacterImage = (id?: string) => {
  return id ? `/images/brawlstars/characters/${id}.png` : null
}

export const useAssetColor = (id: string) => {
  return useMemo(() => {
    if (id === '5000000') return 'green'
    if (id === '5000022') return 'pink'
    if (id === '5000023') return 'purple'
    if (id === '5000001') return 'yellow'
    if (id === '5000019') return 'blue'
    if (id === '16000080') return 'yellow' // Draco
    if (brawlHyperChargeAsset(id)) return 'character'

    return 'yellow'
  }, [id])
}
