import { useCallback } from 'react'

import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { Button } from '@/components/buttons/Button'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'

import { useModalContext } from '../../../../contexts/ModalContext'
import { useGameSlug } from '../../game-provider'
import { BonusView } from '../bonus/BonusesAndPoints'

import styles from './bonusTrackHeader.module.scss'

interface BonusTrackHeaderProps {
  onClick: (view: BonusView) => void
  selectedView: BonusView
}

export const BonusTrackHeader = ({ onClick, selectedView }: BonusTrackHeaderProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const { openModal } = useModalContext()
  const showInfoModal = useCallback(() => {
    openModal({ type: 'BONUS_INFO' })
  }, [openModal])

  const challengesTitle =
    gameSlug === 'clashroyale'
      ? t('bonus_track_tasks')
      : gameSlug === 'brawlstars'
        ? t('bonus_track_quests')
        : t('bonus_track_challenges')

  return (
    <div className={styles.bonusTrackHeaderContainer}>
      <div className={cx(styles.bonusTrackHeader, styles[gameSlug])}>
        <div className={cx(styles.info, styles[gameSlug])} onClick={() => showInfoModal()}>
          <StoreTypography tag="div" style="plain" textSize="xs" overrideFont="supercellText">
            i
          </StoreTypography>
        </div>
        <StoreTypography tag="h2" textSize="md" style="outlined" className={styles.title}>
          {t('bonus_track')}
        </StoreTypography>

        <div className={styles.buttonsContainer}>
          <Button
            additionalClasses={cx(
              styles.button,
              styles.gradientBorder,
              selectedView === 'bonuses' ? styles.selectedButton : styles.unselectedButton,
              styles[gameSlug],
            )}
            type="Primary"
            data-cy="button-bonuses"
            onClick={() => onClick('bonuses')}
          >
            {t('bonus_track_bonuses')}
          </Button>
          <Button
            additionalClasses={cx(
              styles.button,
              styles.gradientBorder,
              selectedView === 'points' ? styles.selectedButton : styles.unselectedButton,
              styles[gameSlug],
            )}
            type="Primary"
            data-cy="button-points"
            onClick={() => onClick('points')}
          >
            {challengesTitle}
          </Button>
        </div>
      </div>
    </div>
  )
}
