import React, { useEffect } from 'react'

import { supportedLanguages, GameId } from '@common'
import cx from 'classnames'
import { LazyMotion, AnimatePresence, m } from 'framer-motion'

import { useCartContext } from '@/contexts/CartContext'

import { CartContent } from './CartContent'
import { CartHeader } from './CartHeader'
import { useLocale } from '../../../locale/useLocale'

import styles from './Cart.module.scss'

type Props = {
  gameId: GameId
}

export function Cart(props: Props) {
  const { isOpen } = useCartContext()

  const features = () => import('@/animations/features').then((a) => a.default)

  return (
    <aside className={styles.Cart}>
      <LazyMotion features={features}>
        <AnimatePresence>{isOpen ? <AnimatedCartContent gameId={props.gameId} /> : null}</AnimatePresence>
      </LazyMotion>
    </aside>
  )
}

function AnimatedCartContent({ gameId }: Props) {
  const { closeCart, items, ineligibleItem } = useCartContext()
  const locale = useLocale()
  const isRTL = supportedLanguages[locale].rightToLeft

  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => document.body.classList.remove('overflow-hidden')
  }, [])

  return (
    <>
      <m.div
        className={cx(styles.CartDropdown)}
        data-cy="cart-dropdown"
        initial={{ x: isRTL ? -400 : 400 }}
        animate={{ x: 0 }}
        exit={{ x: isRTL ? -400 : 400 }}
        transition={{ duration: 0.2 }}
      >
        <CartHeader items={items} ineligibleItem={ineligibleItem} onClose={closeCart} />
        <CartContent game={gameId} items={items} isIneligible={Boolean(ineligibleItem)} />
      </m.div>
      <m.div
        key="cart-backdrop"
        className={styles.backdrop}
        onClick={closeCart}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
      />
    </>
  )
}
