import { PropsWithChildren } from 'react'

import { ProductV2 } from '@common'
import cx from 'classnames'

import colors from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/brawlstars/colors.module.scss'
import { useAssetColor } from '@/features/storefronts/brawlstars/bundle/helpers'

import styles from './Container.module.scss'

export type Props = PropsWithChildren<{
  product: ProductV2
  className?: string
}>

export function Container({ children, product, className }: Props) {
  if (product.contents.length === 1) {
    return (
      <SingleProductContainer product={product} className={className}>
        {children}
      </SingleProductContainer>
    )
  }

  return (
    <MultiProductContainer product={product} className={className}>
      {children}
    </MultiProductContainer>
  )
}

function SingleProductContainer({ product, className, children }: Props) {
  const colorScheme = useAssetColor(product.contents[0].product.id)

  return (
    <div
      className={cx(
        styles.Container,
        styles.SingleProductContainer,
        className,
        styles[product.game],
        colors[colorScheme],
      )}
    >
      {children}
    </div>
  )
}

function MultiProductContainer({ product, className, children }: Props) {
  return (
    <div className={cx(styles.Container, styles.MultiProductContainer, className, styles[product.game])}>
      {children}
    </div>
  )
}
