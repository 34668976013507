import { useTranslations } from 'next-intl'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusChallengeUnavailable.module.scss'

const UNTRANSLATED_title = 'Tasks unavailable'

export const BonusChallengeUnavailable = () => {
  const t = useTranslations()

  return (
    <div className={styles.bonusUnavailableContainer}>
      <StoreTypography tag="h2" textSize="lg" style="plain">
        {UNTRANSLATED_title}
      </StoreTypography>
      <StoreTypography tag="h3" textSize="body" style="plain" overrideFont="supercellText">
        {t('bonus_track_check_back')}
      </StoreTypography>
    </div>
  )
}
