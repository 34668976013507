import {
  ProductV2,
  STORE_SCENERY,
  STORE_SKIN,
  STORE_SPECIAL,
  LASER_TAG_PERCENT_DISCOUNT,
  LASER_TAG_PERCENT_EXTRA,
  StoreOfferProduct,
} from '@common'

export const isStoreSpecial = (product: ProductV2) => {
  return product.type === 'offer' && product.tags?.includes(STORE_SPECIAL)
}

export const isSeasonPass = (product: ProductV2) => {
  return !!product.contentCounts.SEASON_PASS
}

export const isNormalOffer = (product: ProductV2) => {
  return product.type === 'offer' && !isSeasonPass(product) && !isStoreSpecial(product) && !isCosmetic(product)
}

export const isCosmetic = (product: ProductV2) => {
  return product.type === 'offer' && (product.tags?.includes(STORE_SKIN) || product.tags?.includes(STORE_SCENERY))
}

export const isSkin = (product: ProductV2) => {
  return product.type === 'offer' && product.tags?.includes(STORE_SKIN)
}

export const isScenery = (product: ProductV2) => {
  return product.type === 'offer' && product.tags?.includes(STORE_SCENERY)
}

// sort by priority if available, else by price with highest first
export const sortByPriority = (a: ProductV2, b: ProductV2) => {
  if (a.priority !== b.priority) {
    const priorityA = a.priority === undefined ? 0 : a.priority
    const priorityB = b.priority === undefined ? 0 : b.priority
    return priorityB - priorityA
  }

  const priceA = Object.values(a.prices)[0]
  const priceB = Object.values(b.prices)[0]

  if (priceA === undefined || priceB === undefined) {
    return 0
  }

  return priceB - priceA
}

export const getBrawlSpecialOfferType = (product: StoreOfferProduct) => {
  const hasPercentExtra = product.tags?.some((tag) => tag.includes(LASER_TAG_PERCENT_EXTRA))
  const hasPercentDiscount = product.tags?.some((tag) => tag.includes(LASER_TAG_PERCENT_DISCOUNT))
  const specialOfferType = hasPercentExtra ? 'extra' : hasPercentDiscount ? 'off' : null
  return specialOfferType
}
