import { useEffect } from 'react'

import { StoreBonusStatusResponse } from '@common/models/bonus'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import CheckMark from '@/assets/images/storefronts/clashofclans/bonus-item-checkmark.png'
import ClashShield from '@/assets/images/storefronts/clashofclans/clash-shield.png'
import ReceiptPaper from '@/assets/images/storefronts/clashofclans/receipt-paper.png'
import ReceiptRibbon from '@/assets/images/storefronts/clashofclans/receipt-ribbon.png'
import { BonusPointsBS } from '@/features/storefronts/_storeBase/bonus/BonusPointsBS'
import { BonusChallenge } from '@/features/storefronts/_storeBase/bonusChallenge/BonusChallenge'
import { BONUS_TRACK_DIVIDER_BG, STORE_BG } from '@/features/storefronts/_storeBase/constants'
import { TimeRemaining } from '@/features/storefronts/_storeBase/timeRemaining/TimeRemaining'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { getTimeRemaining } from '@/utils/timer'

import { BonusPointsCR } from './BonusPointsCR'

import styles from './bonusPoints.module.scss'

interface BonusPointsProps {
  bonusStatus: StoreBonusStatusResponse
}

export const BonusPoints = ({ bonusStatus }: BonusPointsProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const missionsTimeLeft = bonusStatus?.missions?.endTimeMillis || 0
  const missions = bonusStatus?.missions?.missions

  useEffect(() => {
    const element = document.getElementById('bonusTrackWrapper')
    element?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  if (gameSlug === 'brawlstars') {
    return <BonusPointsBS bonusStatus={bonusStatus} />
  }

  if (gameSlug === 'clashroyale') {
    return <BonusPointsCR bonusStatus={bonusStatus} />
  }

  if (!missions) {
    return null
  }

  const remainingTime = getTimeRemaining(missionsTimeLeft)

  return (
    <div className={styles.contentContainer}>
      <div className={styles.backgroundContainer} style={{ backgroundImage: STORE_BG(gameSlug) }} />
      <div className={styles.dividerContainer}>
        <div className={styles.divider} style={{ backgroundImage: BONUS_TRACK_DIVIDER_BG(gameSlug) }} />
        <div className={styles.dividerImageContainer}>
          <div className={styles.dividerImageWrapper}>
            <Image src={ClashShield} alt={gameSlug} height={50} quality={100} />
          </div>
        </div>
      </div>
      <div className={styles.receiptContainer}>
        <div className={styles.receiptBackground}>
          <Image src={ReceiptPaper} alt={gameSlug} fill sizes="(max-width: 500px) 390px, 420px" />
        </div>
        <div className={styles.receiptContent}>
          <div className={styles.receiptCheckMarkContainer}>
            <div className={styles.receiptCheckMark}>
              <Image src={CheckMark} alt={gameSlug} width={90} height={90} />
            </div>
          </div>
          <div className={styles.receiptRibbonContainer}>
            <div className={styles.receiptRibbonBackground}>
              <Image src={ReceiptRibbon} alt={gameSlug} fill sizes="(max-width: 500px) 390px, 450px" />
            </div>
            <div className={styles.ribbonTexts}>
              <StoreTypography tag="span" textSize="body" style="outlined">
                {t('bonus_time_left')}
              </StoreTypography>
              <TimeRemaining timeLeftText={remainingTime} type="secondary" textStyle="outlined" />
            </div>
          </div>
          <div className={styles.descriptionContainer}>
            <span>{t('bonus_earn_weekly_challenge')}</span>
          </div>
          <div className={styles.challengesContainer}>
            {missions.map((mission) => (
              <BonusChallenge key={mission.missionId} mission={mission} />
            ))}
          </div>
          <div className={styles.infoContainer}>
            <span className={styles.infoCircle}>!</span>
            <span>{t('bonus_time_appear')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
