import React, { useEffect, useState } from 'react'

import { ProductV2, ShortGameId } from '@common'
import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { useLocale } from '@/features/locale/useLocale'
import { BuyButton } from '@/features/storefronts/_storeBase/BuyButton/BuyButton'
import { gtmProductView } from '@/utils/gtm'
import { getProductCurrencyType, isProductOffer, isSameProductType, isSeasonPass } from '@/utils/products'
import { resolveProductBackgroundVariant } from '@/utils/resolveProductBackgroundVariant'
import { resolveProductImage } from '@/utils/resolveProductImage'
import { useAppSelector } from '@/utils/useAppSelector'

import { ItemRow, ItemTitle, ItemDescription, ItemImages } from './ItemRow'
import ProductCard from './ProductCard'
import ProductDescription from './ProductDescription'
import { ProductHeader } from './ProductHeader'
import { useOfferCountdown } from './useOfferCountdown'
import { Clock } from '../../components/Animations/Clock'
import { useMarketContext } from '../../contexts/MarketContext'
import { useModalContext } from '../../contexts/ModalContext'
import { useMessageContext } from '../../localization/MessageContext'
import { useTranslatable } from '../../localization/useTranslatable'
import { snowplowTrackProductView } from '../../utils/snowplow'
import { getTimeLeftCopyKeys } from '../../utils/timer'
import { useGames } from '../common/useGames'
import { resolveRibbonKey } from '../common/useProducts'
import { OriginalPrice } from '../storefronts/_storeBase/productPage/OriginalPrice'

import styles from './Product.module.scss'

interface ProductProps {
  product: ProductV2
  isProductPage: boolean
  products?: ProductV2[]
}
/**
 * Order in "more from game – products"
 * 1. same product type offers
 * 2. other product type offers
 * 3. same product type
 * 4. rest
 */
const sortMoreProductsFromGame = (products: ProductV2[], product: ProductV2) => {
  const sameGameProducts = products.filter((p) => p.game === product.game && p.id !== product.id)

  const sortedProducts = sameGameProducts.sort((a, b) => {
    const aIsSameType = isSameProductType(a, product)
    const bIsSameType = isSameProductType(b, product)
    const aIsOffer = isProductOffer(a)
    const bIsOffer = isProductOffer(b)

    if (aIsSameType && !bIsSameType) {
      return -1
    } else if (!aIsSameType && bIsSameType) {
      return 1
    } else if (aIsOffer && !bIsOffer) {
      return -1
    } else if (!aIsOffer && bIsOffer) {
      return 1
    } else {
      const A = Object.values(a.prices)[0]
      const B = Object.values(b.prices)[0]
      if (A && B) {
        return B - A
      }
      return 0
    }
  })

  const sameTypeProducts = sortedProducts.filter((p) => isSameProductType(p, product)).slice(0, 3)
  const restOfProducts = sortedProducts
    .filter((p) => !isSameProductType(p, product))
    .slice(0, 3 - sameTypeProducts.length)

  return [...sameTypeProducts, ...restOfProducts]
}

export const Product: React.FC<ProductProps> = ({ product, isProductPage, products }) => {
  const market = useMarketContext('Product')
  const locale = useLocale()
  // Currency is not available if country is not supported
  const currencyCountry = market.currencyCountry
  const currency = market.currencyCode

  const { openModal } = useModalContext()
  const games = useGames()
  const t = useTranslations()
  const translatable = useTranslatable()
  const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
  const game = games.find((g) => g.slug === product.game)

  const ribbonData = resolveRibbonKey(product)
  const ribbonContent = ribbonData ? [t(ribbonData[0], ribbonData[1])] : []
  const { isTimedOffer, countdownExpired, timeLeftText } = useOfferCountdown({ product: product })

  useEffect(() => {
    gtmProductView(product)
  }, [locale, product, viewerCountry])

  useEffect(() => {
    if (currencyCountry && currency) {
      snowplowTrackProductView(product, locale, currencyCountry, currency)
    }
  }, [locale, product, currencyCountry, currency])

  useEffect(() => {
    if (isTimedOffer && countdownExpired) {
      openModal({ type: 'OFFER_EXPIRED', props: { expired: true } })
    }
  }, [isTimedOffer, countdownExpired, openModal])

  if (!game) {
    return <>Product not found</>
  }

  return (
    <div className={styles.Product}>
      <div
        className={cx(styles.ProductHeaderContainer, {
          [styles.InOverlayWithTimer]: isTimedOffer && isProductPage === false,
        })}
      >
        {isTimedOffer && timeLeftText.length > 0 && (
          <div className={styles.TimerContainer}>
            <Clock />
            <p>
              {t('hero_pb_ct')}
              &nbsp;
              {timeLeftText.map((time, idx) => {
                const copyKey = getTimeLeftCopyKeys('mobile', idx)
                if (copyKey) {
                  return <span key={idx}>{`${time}${t(copyKey)}`}&nbsp;</span>
                }
                return null
              })}
            </p>
          </div>
        )}
        <ProductHeader
          game={game}
          productImage={resolveProductImage(product)}
          alt={translatable(product.title)}
          size={'Large'}
          ribbonContent={ribbonContent}
          className={styles.ProductHeader}
          variant={resolveProductBackgroundVariant(product)}
        />
      </div>
      <div
        className={cx(styles.Content, {
          [styles.IsPopup]: isProductPage === false,
        })}
      >
        <div className={styles.MainContent}>
          <div className={styles.ProductContents}>
            <h1 className={styles.Title}>{translatable(product.title)}</h1>
            <ProductDescription product={product} tag="h2" className={styles.Description} align="left" card />
          </div>
          <div className={styles.Action}>
            <BuyButton
              disabled={isTimedOffer && countdownExpired}
              additionalClasses={styles.BuyButton}
              product={product}
              data-cy={`product-${product.id}`}
            />
            <OriginalPrice product={product} />
          </div>
        </div>
        {isSeasonPass(product) ? (
          <SeasonPassProductContents product={product} />
        ) : (
          <ProductExtraDescription product={product} shortSlug={game.shortSlug} />
        )}
      </div>
      <hr className={styles.Divider} />
      <div className={styles.MoreFromGame}>
        <h2>{t(`${game.shortSlug}_prod_related_prods`)}</h2>
        <div className={styles.Grid} data-cy="more-from-game-grid">
          {sortMoreProductsFromGame(products || [], product).map((product) => (
            <ProductCard
              type={'Normal'}
              game={game}
              key={product.id}
              product={product}
              openAs={isProductPage ? 'page' : 'overlay'}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const ProductExtraDescription = ({ product, shortSlug }: { product: ProductV2; shortSlug: ShortGameId }) => {
  const t = useTranslations()
  const translate = useTranslatable()
  const contentType = getProductCurrencyType(product)
  const offerDescription = product.type === 'offer' ? translate(product.description, '') : ''

  if (offerDescription !== '') {
    return <p className={styles.ExtraDescription}>{offerDescription}</p>
  }

  if (!contentType) {
    return null
  }
  switch (shortSlug) {
    case 'cr':
      break
    case 'hd':
      if (contentType === 'COIN') return <p className={styles.ExtraDescription}>{t('hd_coin_description_2')}</p>
      if (contentType === 'GEM') return <p className={styles.ExtraDescription}>{t('hd_gem_description_2')}</p>
      break
    case 'bs':
      if (contentType === 'GEM') return <p className={styles.ExtraDescription}>{t('bs_gem_description_2')}</p>
      break
    case 'coc':
      if (contentType === 'GEM') return <p className={styles.ExtraDescription}>{t('coc_gem_description_2')}</p>
      break
  }
  return null
}

const SeasonPassProductContents: React.FC<{ product: ProductV2 }> = ({ product }) => {
  const [productLoaded, setProductLoaded] = useState<undefined | ProductMessageKey>(undefined)
  const t = useTranslations(productLoaded ?? undefined)
  const locale = useLocale()
  const { loadProductMessage } = useMessageContext()
  useEffect(() => {
    const fn = async () => {
      const loadRes = await loadProductMessage(product, locale)
      if (loadRes) {
        setProductLoaded(loadRes)
      }
    }
    fn()
  }, [product, locale, loadProductMessage])
  if (!productLoaded) {
    return null
  }
  return (
    <>
      <p className={styles.ExtraDescription}>
        {t.rich('description', {
          TextBlock: (children) => {
            if (Array.isArray(children)) {
              return <span className={styles.TextBlock}>{children[0]}</span>
            }
          },
        })}
      </p>
      <div className={styles.ProductList}>
        {t.rich('contents', {
          Title: (children) => <ItemTitle>{children}</ItemTitle>,
          Description: (children) => <ItemDescription>{children}</ItemDescription>,
          Image: (children) => {
            if (Array.isArray(children)) {
              return <ItemImages>{children[0]}</ItemImages>
            }
          },
          Row: (children) => <ItemRow>{children}</ItemRow>,
          TextBlock: (children) => {
            if (Array.isArray(children)) {
              return <span className={styles.TextBlock}>{children[0]}</span>
            }
          },
        })}
      </div>
    </>
  )
}
