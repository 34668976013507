import { isGemPackOffer, isGemPackProduct, ProductV2 } from '@common'
import cx from 'classnames'
import { useLocale } from 'next-intl'

import { useFormattedAmount } from '@/features/storefronts/_storeBase/card/utils'
import { getStrikeThroughTag } from '@/features/storefronts/_storeBase/card/v2/shared'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'
import { isStoreSpecial } from '@/features/storefronts/productFilters'
import { useTranslatable } from '@/localization/useTranslatable'
import { isOffer } from '@/utils/products'

import styles from './BrawlStarsHeading.module.scss'

type Props = {
  product: ProductV2
}

export function BrawlStarsHeading({ product }: Props) {
  const translatable = useTranslatable()
  const locale = useLocale()
  const isMultiItem = product.contents.length > 1
  const formattedFirstAmount = useFormattedAmount(product.contents[0].amount)
  const productTitle = translatable(product.title, 'Offer')
  const isThisOffer = isOffer(product)
  const isGemOffer = isGemPackOffer(product)
  const isGemProduct = isGemPackProduct(product)
  const isSkinOrBrawler = isMultiItem ? false : isBrawlerOrSkin(product.contents[0])
  const isStoreSpecialOffer = isStoreSpecial(product)
  const contentProductTitle = translatable(product.contents[0].product.title)

  // for multi item offers & offers, we don't want to show the amount in the title
  let shownTitle = null
  const strikeThroughTag = getStrikeThroughTag(product)

  if (isThisOffer && !strikeThroughTag) {
    // single or multiple item offer that does not have a strike through tag
    shownTitle = productTitle
  } else {
    // cases where the product is not an offer
    // single item without offer
    if (!isMultiItem) {
      shownTitle = formattedFirstAmount ?? shownTitle
    } else {
      // multi item without offer
      shownTitle = productTitle
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.RowContainer}>
        <StoreTypography tag="span" style="plain" textSize="sm" className={styles.Strikethrough}>
          {strikeThroughTag}
        </StoreTypography>
        <StoreTypography tag="h3" style="outlined" textSize="md" className={cx(styles.Title, styles[locale])}>
          {shownTitle ?? productTitle}
        </StoreTypography>
      </div>
      {isSkinOrBrawler && !isStoreSpecialOffer && (
        <StoreTypography tag="div" style="outlined" textSize="body" className={styles.SubTitle}>
          {contentProductTitle}
        </StoreTypography>
      )}
      {isThisOffer &&
        !isStoreSpecialOffer &&
        !isGemProduct &&
        !strikeThroughTag &&
        !isSkinOrBrawler &&
        !isMultiItem &&
        !isGemOffer && ( // only show amount for offers that are not gem packs
          <StoreTypography tag="div" style="outlined" textSize="body" className={cx(styles.Amount, styles[locale])}>
            {formattedFirstAmount}
          </StoreTypography>
        )}
    </div>
  )
}
