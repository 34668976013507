/**
 * Simplified version from nextjs image loader for use in animated images
 * @param imagePath
 * @param width
 * @param quality
 * @returns
 */
export const nextImageLoader = (imagePath: string, width = 1080, quality = 75) => {
  return `/_next/image?url=${encodeURIComponent(imagePath)}&w=${width}&q=${quality}`
}
