import { useState, useEffect } from 'react'

import { ProductV2, isStoreOfferProduct } from '@common'

import { TimeLeft, calculateRemainingTime, getTimeRemaining } from '../../utils/timer'

const SHOW_COUNTDOWN_THRESHOLD_DAYS = 7

const resolveCountdownTo = (product: ProductV2) => {
  if (isStoreOfferProduct(product) && product.schedule) {
    return Date.now() < product.schedule.purchaseStart ? product.schedule.purchaseStart : product.schedule.purchaseEnd
  }

  return 0
}

type UseOfferCountdownProps = {
  product: ProductV2
  threshold?: number
}

// Add THRESHOLD as a parameter to the useOfferCountdown hook, mainly to be able to test the hook with different thresholds
export const useOfferCountdown = ({ product, threshold = SHOW_COUNTDOWN_THRESHOLD_DAYS }: UseOfferCountdownProps) => {
  const [isTimedOffer, setIsTimedOffer] = useState(false)
  const [countdownExpired, setCountdownExpired] = useState(false)
  const [timeLeftText, setTimeLeftText] = useState<TimeLeft>(getTimeRemaining(resolveCountdownTo(product)))

  useEffect(() => {
    const countdownTo = resolveCountdownTo(product)
    if (countdownTo === 0) {
      return
    }

    const initialCountdown = calculateRemainingTime(countdownTo)
    const days = initialCountdown[0]

    if (days >= threshold) {
      return
    }
    setIsTimedOffer(true)
    let interval: NodeJS.Timeout | null = null
    const isExpired = days < 0
    if (isExpired) {
      setCountdownExpired(true)
    } else {
      interval = setInterval(() => {
        setTimeLeftText(getTimeRemaining(countdownTo))
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [product, threshold])

  return {
    isTimedOffer,
    timeLeftText,
    countdownExpired,
  }
}
