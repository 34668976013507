import { BS_PROGRESS, COC_PROGRESS, CR_PROGRESS, HD_PROGRESS } from '../models/game'
import { SCIDApplication } from '../models/scid'

const PASS_ELIGIBILITY_MAP = {
  clashofclans: { progressIndex: COC_PROGRESS.TOWN_HALL, minLevel: 7 },
  hayday: { progressIndex: HD_PROGRESS.FARM_LEVEL, minLevel: 11 },
  brawlstars: { progressIndex: BS_PROGRESS.EXP_LEVEL, minLevel: 1 },
  clashroyale: { progressIndex: CR_PROGRESS.TROPHIES, minLevel: 1 },
  squadbusters: null,
} as const

export const isTooLowLevelForPass = (gameData: SCIDApplication): boolean => {
  const gamePassEligibility = PASS_ELIGIBILITY_MAP[gameData.application]

  if (!gamePassEligibility) {
    return false
  }

  const progress = gameData.account?.progress?.[gamePassEligibility.progressIndex]
  if (!progress || parseInt(progress, 10) < gamePassEligibility.minLevel) {
    return true
  }
  return false
}
