import React from 'react'

import { ProductV2, SupportedLanguage, columnTypes } from '@common'
import { enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking'
import {
  SnowplowEcommercePlugin,
  trackAddToCart,
  trackCheckoutStep,
  trackRemoveFromCart,
  trackTransaction,
} from '@snowplow/browser-plugin-snowplow-ecommerce'
import { WebVitalsPlugin } from '@snowplow/browser-plugin-web-vitals'
import {
  addGlobalContexts,
  clearGlobalContexts,
  enableActivityTracking,
  newTracker,
  trackPageView,
  trackSelfDescribingEvent,
  disableAnonymousTracking,
  enableAnonymousTracking,
  clearUserData,
} from '@snowplow/browser-tracker'
import Big from 'big.js'
import * as webVitals from 'web-vitals'

import { useLocale } from '@/features/locale/useLocale'
import { CartItem } from '@/utils/cart'

import { config } from '../../config'
import { useMarketContext } from '../contexts/MarketContext'

if (typeof window !== 'undefined') {
  window.webVitals = webVitals
}

let initialized = false
let hooked = false
let lastSeenUser: string | undefined = undefined
let lastSetUser: string | undefined = undefined
let lastIsOnetrustAnalyticsEnabled: boolean | undefined = undefined
let anonymousTracking: boolean | undefined = undefined
let lastSetTransactionId = '0'

const seenProductIds = new Set<string>()

function isEnabled() {
  return isSnowplowEnabled() && isOnetrustAnalyticsEnabled()
}

function isSnowplowEnabled() {
  return config.SNOWPLOW_ENABLED
}

function isOnetrustInitialized() {
  return document.cookie.split('; ').find((row) => row.startsWith('OptanonAlertBoxClosed'))
}

function isOnetrustAnalyticsEnabled() {
  try {
    const groupsEnabled = window.OnetrustActiveGroups?.split(',').filter((x) => x !== '')
    return groupsEnabled?.includes('C0002')
  } catch (e) {
    return false
  }
}

function addSnowplowUser() {
  if (!lastSetUser) return

  const req = {
    schema: 'iglu:com.supercell/user_identification/jsonschema/1-0-1',
    data: {
      user_id_type: 'scid',
      user_id: lastSetUser,
    },
  }
  addGlobalContexts([req])
}

function setSnowplowTracker(anonymous = true) {
  if (anonymous) {
    newTracker('sp', config.SNOWPLOW_COLLECTOR_URL, {
      appId: config.SNOWPLOW_APP_ID,
      cookieSameSite: 'Lax',
      postPath: config.SNOWPLOW_POST_PATH,
      plugins: [
        SnowplowEcommercePlugin(),
        WebVitalsPlugin({
          loadWebVitalsScript: false,
        }),
      ],
      anonymousTracking: {
        withServerAnonymisation: true,
        withSessionTracking: true,
      },
    })
  } else {
    newTracker('sp', config.SNOWPLOW_COLLECTOR_URL, {
      appId: config.SNOWPLOW_APP_ID,
      cookieSameSite: 'Lax',
      postPath: config.SNOWPLOW_POST_PATH,
      plugins: [
        SnowplowEcommercePlugin(),
        WebVitalsPlugin({
          loadWebVitalsScript: false,
        }),
      ],
    })
    snowplowSetUser(lastSetUser)
  }
  enableActivityTracking({
    minimumVisitLength: 5,
    heartbeatDelay: 10,
  })
  enableLinkClickTracking()
  snowplowPageView()
  initialized = true
}

function updateSnowplowTracker(anonymous = true) {
  if (anonymous) {
    snowplowClear() // Clearing SCID for anonymous tracking
    clearUserData()
    enableAnonymousTracking({
      options: {
        withServerAnonymisation: true,
        withSessionTracking: true,
      },
    })
  } else {
    snowplowClear()
    addSnowplowUser()
    disableAnonymousTracking()
  }
}

export function snowplowInit() {
  if (!window.OneTrust) {
    setTimeout(snowplowInit, 50)
    return
  }
  if (!hooked) {
    window.OneTrust.OnConsentChanged(() => {
      snowplowInit()
    })
    hooked = true
  }
  anonymousTracking = !isOnetrustAnalyticsEnabled()
  if (isSnowplowEnabled() && isOnetrustInitialized()) {
    if (!initialized) {
      setSnowplowTracker(anonymousTracking)
    } else {
      if (isOnetrustAnalyticsEnabled() != lastIsOnetrustAnalyticsEnabled) {
        updateSnowplowTracker(anonymousTracking)
        lastIsOnetrustAnalyticsEnabled = isOnetrustAnalyticsEnabled()
      }
    }
  }
}

export function snowplowSetUser(scid?: string) {
  lastSetUser = scid // to be used when snowplow will be initialized in a later stage
  if (isEnabled() && scid != lastSeenUser) {
    lastSeenUser = scid
    clearGlobalContexts()
    if (!scid) return

    const req = {
      schema: 'iglu:com.supercell/user_identification/jsonschema/1-0-1',
      data: {
        user_id_type: 'scid',
        user_id: scid,
      },
    }
    addGlobalContexts([req])
  }
}

export function snowplowClear() {
  clearGlobalContexts()
}

export function snowplowPageView() {
  trackPageView()
}

export interface SnowplowProduct {
  id: string
  price: number
  currency: string
  name: string
  category: string
  quantity: number
}

export function useSnowplowViewImpression<E extends HTMLElement>(
  product: ProductV2,
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
  componentId: string,
  componentName: string,
): React.RefObject<E> {
  const [isVisible, setIsVisible] = React.useState(false)
  const ref = React.useRef<E>(null)

  const checkItemVisibility = () => {
    if (ref.current && initialized) {
      const rect = ref.current.getBoundingClientRect()
      setIsVisible(rect.top >= 0 && rect.bottom < window.innerHeight)
    }
  }

  React.useEffect(() => {
    let observer: IntersectionObserver
    if (ref.current && currencyCountry && currencyCode) {
      observer = new IntersectionObserver(
        (entries) => {
          if (!initialized) {
            return
          }

          for (const entry of entries) {
            if ((entry.isIntersecting || isVisible) && !seenProductIds.has(product.id)) {
              const snowplowProduct = convertToSnowplowProduct(product, locale, currencyCountry, currencyCode, 1)
              const req = {
                event: {
                  schema: 'iglu:com.supercell/impression/jsonschema/1-0-1',
                  data: {
                    component_id: componentId,
                    component_name: componentName,
                  },
                },
                context: [
                  {
                    schema: 'iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0',
                    data: {
                      ...snowplowProduct,
                    },
                  },
                ],
              }
              trackSelfDescribingEvent(req)
              observer.disconnect()
              seenProductIds.add(product.id)
            }
          }
        },
        {
          threshold: 0.5,
        },
      )
      observer.observe(ref.current)
    }

    // If we can make the IntersectionObserver work correctly when the component is already visible,
    // we can remove the event listener.
    window.addEventListener('scroll', checkItemVisibility)

    return () => {
      observer?.disconnect()
      window.removeEventListener('scroll', checkItemVisibility)
    }
  }, [ref, product, locale, currencyCountry, currencyCode, componentId, componentName, isVisible])

  return ref
}

export function useSnowplowProductCardViewImpression<E extends HTMLElement>(
  product: ProductV2,
  componentId: string,
  componentName: string,
): React.RefObject<E> {
  const locale = useLocale()
  const market = useMarketContext('useSnowplowProductCardViewImpression')

  return useSnowplowViewImpression<E>(
    product,
    locale,
    market.currencyCountry,
    market.currencyCode,
    componentId,
    componentName,
  )
}

export function snowplowTrackProductView(
  product: ProductV2,
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
) {
  const req = {
    event: {
      schema: 'iglu:com.supercell/view_product/jsonschema/1-0-0',
      data: {},
    },
    context: [
      {
        schema: 'iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0',
        data: {
          ...convertToSnowplowProduct(product, locale, currencyCountry, currencyCode, 1),
        },
      },
    ],
  }
  trackSelfDescribingEvent(req)
}

export function snowplowAddToCart(
  products: CartItem[],
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
) {
  const req = convertToCartData(products, locale, currencyCountry, currencyCode)
  trackAddToCart(req)
}

export function snowplowRemoveFromCart(
  products: CartItem[],
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
) {
  const req = convertToCartData(products, locale, currencyCountry, currencyCode)
  trackRemoveFromCart(req)
}

export function snowplowTrackCheckout() {
  const req = {
    step: 1,
  }
  trackCheckoutStep(req)
}

export function snowplowCompletePurchase(
  transactionId: string,
  paymentMethodName: string,
  products: CartItem[],
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
) {
  const snowplowProducts = products.map((p) =>
    convertToSnowplowProduct(p.product, locale, currencyCountry, currencyCode, p.quantity),
  )
  const sum = snowplowProducts.reduce((sum, p) => sum.plus(Big(p.price).times(p.quantity)), Big(0))
  const quantity = products.map((p) => p.quantity).reduce((sum, q) => sum + q, 0)
  if (isOnetrustAnalyticsEnabled()) {
    lastSetTransactionId = transactionId
  } else {
    lastSetTransactionId = '0'
  }
  const req = {
    transaction_id: lastSetTransactionId,
    revenue: sum.round(2).toNumber(),
    payment_method: paymentMethodName,
    total_quantity: quantity,
    currency: currencyCode,
  }
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.supercell/transaction/jsonschema/1-0-0',
      data: {},
    },
    context: [
      {
        schema: 'iglu:com.snowplowanalytics.snowplow.ecommerce/transaction/jsonschema/1-0-0',
        data: req,
      },
      ...snowplowProducts.map((p) => ({
        schema: 'iglu:com.snowplowanalytics.snowplow.ecommerce/product/jsonschema/1-0-0',
        data: { ...p },
      })),
    ],
  })
  trackTransaction({ ...req, products: snowplowProducts })
}

export function snowplowTrackCampaign(campaignName: string) {
  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:com.supercell/campaign-event/jsonschema/1-0-0',
      data: { campaign_name: campaignName },
    },
  })
}

function convertToCartData(
  products: CartItem[],
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currency: columnTypes.CurrencyCode,
) {
  const snowplowProducts = products.map((p) =>
    convertToSnowplowProduct(p.product, locale, currencyCountry, currency, p.quantity),
  )
  const snowplowSum = snowplowProducts.reduce((sum, p) => sum.plus(Big(p.price).times(p.quantity)), Big(0))
  return { products: snowplowProducts, total_value: snowplowSum.round(2).toNumber(), currency }
}

function convertToSnowplowProduct(
  product: ProductV2,
  locale: SupportedLanguage,
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
  quantity: number,
): SnowplowProduct {
  const price = product.prices[currencyCountry]
  //const priceInMajorUnits = typeof price === 'number' ? convertToMajorUnits(price, currencyCode) : 0
  return {
    id: product.id,
    price: price ?? 0, // priceInMajorUnits,
    currency: currencyCode,
    name: product.title[locale] ?? 'N/A',
    category: product.game,
    quantity,
  }
}
