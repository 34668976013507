'use client'

import { memo, useEffect } from 'react'

import { StoreBonusStatusResponse, StoreRewardItem } from '@common/models/bonus'
import cx from 'classnames'
import { m } from 'framer-motion'
import Image, { StaticImageData } from 'next/image'
import { useTranslations } from 'next-intl'

import NotFound from '@/assets/images/bag.png'
import { useCartContext } from '@/contexts/CartContext'
import { setBonusTrackCompleted } from '@/features/common/commonSlice'
import { useAppDispatch } from '@/utils/useAppDispatch'

import { DrawerButton } from './DrawerButton'
import { useGameSlug } from '../../game-provider'
import { BonusImage } from '../bonus/BonusImage'
import {
  extractBonusValues,
  getDeliveredBonuses,
  getNextItem,
  getProgressPercentage,
  getRemainingPoints,
  getBonusTrackCompletionStatus,
} from '../bonus/bonusUtils'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusStatusFooter.module.scss'

const HIDE_BONUS_IF_NO_DATA = false

const BonusFooterItemImage = ({ src }: { src?: string | StaticImageData }) => {
  return (
    <div className={styles.deliveredImageContainer}>
      <Image
        className={styles.deliveredImage}
        src={src || NotFound}
        alt="bonuses-delivered"
        fill
        sizes="50px"
        placeholder="empty"
      />
    </div>
  )
}

const BonusFooterItemImages = ({ items }: { items: StoreRewardItem[] }) => {
  return (
    <>
      {items.map((item) => (
        <BonusFooterItemImage key={item.id} src={item.image} />
      ))}
    </>
  )
}

const BonusItemCount = ({ count }: { count: number }) => {
  return (
    <div className={styles.deliveredCountContainer}>
      <StoreTypography
        tag="div"
        textSize="xs"
        style="plain"
        overrideFont="supercellTextMedium"
        className={styles.deliveredCount}
      >
        {count}
      </StoreTypography>
    </div>
  )
}

interface BonusFooterProps {
  bonusStatus: StoreBonusStatusResponse
  onOpenDrawer: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const BonusStatusFooter = memo(({ bonusStatus, onOpenDrawer }: BonusFooterProps) => {
  const dispatch = useAppDispatch()
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const { items } = useCartContext()
  const { bonusItems } = extractBonusValues({ bonusStatus, items })

  const tentativeBonusesDeliveredOnCheckout = bonusStatus ? getDeliveredBonuses({ bonusStatus, items }) : []
  const progressPercentage = bonusStatus ? getProgressPercentage({ bonusStatus, items }) : 0
  const remainingPoints = bonusStatus ? getRemainingPoints({ bonusStatus, items }) : 0
  const nextItem = getNextItem({ bonusStatus, items })
  const bonusTrackCompletionStatus = getBonusTrackCompletionStatus({ bonusStatus, items })
  const isCompletedBonusTrack =
    bonusTrackCompletionStatus === 'EXHAUSTIVE' || bonusTrackCompletionStatus === 'TENTATIVE'
  const isExhaustivelyCompletedBonusTrack = bonusTrackCompletionStatus === 'EXHAUSTIVE'
  const isUnavailableBonusTrack = bonusTrackCompletionStatus === 'UNAVAILABLE'

  useEffect(() => {
    if (isExhaustivelyCompletedBonusTrack) {
      dispatch(setBonusTrackCompleted(true))
    }
  }, [isExhaustivelyCompletedBonusTrack, dispatch])

  const unclaimedItems = bonusItems.filter((item) => item.available && !item.claimed)
  const unclaimedBonuses = unclaimedItems.length > 0 && tentativeBonusesDeliveredOnCheckout.length === 0
  const bonusesDeliveredOnCheckout = [...unclaimedItems, ...tentativeBonusesDeliveredOnCheckout]

  if (HIDE_BONUS_IF_NO_DATA && !nextItem) {
    return null
  }

  const unavailableBonusTrack = t('bonus_track_unavailable')
  const unavailableBonusTrackDescription = t('bonus_track_check_back')
  const unclaimedBonusesText = t('bonus_unclaimed_bonuses')
  const remainingPointsText = t('bonus_number_of_points_to_next_store_bonus').replace('[n]', remainingPoints.toString())
  const bonusesDeliveredText = t('bonus_bonuses_delivered_on_checkout')

  const bonusesCompletedText = t('bonus_track_completed')
  const footerTitle = isCompletedBonusTrack
    ? bonusesCompletedText
    : isUnavailableBonusTrack
      ? unavailableBonusTrack
      : remainingPointsText

  return (
    <m.aside
      className={cx(styles.bonusStatusFooter, styles[gameSlug], { [styles.unclaimed]: unclaimedBonuses })}
      onClick={onOpenDrawer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isCompletedBonusTrack ? (
        <div className={styles.bonusImageContainer}>
          <BonusImage status="completed" progressPercentage={100} bonusStatusFooter />
        </div>
      ) : isUnavailableBonusTrack ? (
        <div className={styles.bonusImageContainer}>
          <BonusImage item={nextItem} status="next" progressPercentage={progressPercentage} bonusStatusFooter />
        </div>
      ) : (
        <div className={styles.bonusImageContainer}>
          <BonusImage item={nextItem} status="next" progressPercentage={progressPercentage} bonusStatusFooter />
        </div>
      )}

      <div className={styles.textContainer}>
        <StoreTypography
          tag="span"
          textSize="sm"
          style="plain"
          overrideFont="supercellTextMedium"
          lineHeight="normal"
          className={styles.remainingPoints}
        >
          {footerTitle}
        </StoreTypography>

        {!isCompletedBonusTrack && unclaimedBonuses && unclaimedItems.length <= 3 && (
          <div className={styles.deliveredContainer}>
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.deliveredText}
            >
              {unclaimedBonusesText}
            </StoreTypography>
            <BonusFooterItemImages items={unclaimedItems} />
          </div>
        )}

        {!isCompletedBonusTrack && unclaimedBonuses && unclaimedItems.length > 3 && (
          <div className={styles.deliveredContainer}>
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.deliveredText}
            >
              {unclaimedBonusesText}
            </StoreTypography>
            <BonusFooterItemImages items={[unclaimedItems[0]]} />
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.deliveredText}
            >
              +
            </StoreTypography>
            <BonusItemCount count={unclaimedItems.length - 1} />
          </div>
        )}

        {!isCompletedBonusTrack &&
          !unclaimedBonuses &&
          bonusesDeliveredOnCheckout.length > 0 &&
          bonusesDeliveredOnCheckout.length <= 3 && (
            <div className={styles.deliveredContainer}>
              <StoreTypography
                tag="div"
                textSize="xs"
                style="plain"
                overrideFont="supercellText"
                lineHeight="normal"
                className={styles.deliveredText}
              >
                {bonusesDeliveredText}
              </StoreTypography>
              <BonusFooterItemImages items={bonusesDeliveredOnCheckout} />
            </div>
          )}

        {!isCompletedBonusTrack && !unclaimedBonuses && bonusesDeliveredOnCheckout.length > 3 && (
          <div className={styles.deliveredContainer}>
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.deliveredText}
            >
              {bonusesDeliveredText}
            </StoreTypography>
            <BonusFooterItemImages items={[bonusesDeliveredOnCheckout[0]]} />
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.deliveredText}
            >
              +
            </StoreTypography>
            <BonusItemCount count={bonusesDeliveredOnCheckout.length - 1} />
          </div>
        )}

        {isUnavailableBonusTrack && (
          <div className={styles.deliveredContainer}>
            <StoreTypography
              tag="div"
              textSize="xs"
              style="plain"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.unavailableText}
            >
              {unavailableBonusTrackDescription}
            </StoreTypography>
            <BonusFooterItemImages items={bonusesDeliveredOnCheckout} />
          </div>
        )}
      </div>

      <div className={styles.closedButtonContainer}>
        <DrawerButton />
      </div>
    </m.aside>
  )
})

BonusStatusFooter.displayName = 'BonusStatusFooter'
