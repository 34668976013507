import { ProductContent } from '@common'
import cx from 'classnames'

import { useProductImage } from '@/features/storefronts/_storeBase/card/utils'
import { Flare } from '@/features/storefronts/_storeBase/flare/Flare'
import { ImageWithFallback } from '@/features/storefronts/_storeBase/imageWithFallback/ImageWithFallback'
import { AnimatedImageWithFallbackNoSSR } from '@/features/storefronts/_storeBase/productPage/ProductImage'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useAssetColor, brawlHyperChargeAsset, isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'
import { useTranslatable } from '@/localization/useTranslatable'

import { HyperchargeAsset } from '../../../../../BrawlCardProduct'

import styles from './BrawlItem.module.scss'
import colors from '../colors.module.scss'

type Props = {
  content: ProductContent
  isSingleItemOffer?: boolean
  isSubItem?: boolean // when item is shown inside another card item
}

export const ProductImage = ({ content, isSubItem }: Props) => {
  const translatable = useTranslatable()
  const title = translatable(content.product.title)
  const asset = useProductImage({
    game: 'brawlstars',
    productId: content.product.id,
    itemType: content.product.itemType,
    count: content.amount,
  })
  const isBrawlHyperChargeAsset = brawlHyperChargeAsset(content.product.id)

  if (isBrawlHyperChargeAsset) {
    return (
      <div className={styles.ProductImage}>
        <HyperchargeAsset title={title} assetId={content.product.id} />
      </div>
    )
  }

  if (asset.isAnimated && !isSubItem) {
    return (
      <div className={cx(styles.productImg, styles.AnimatedProductImage)}>
        <AnimatedImageWithFallbackNoSSR
          animationSrc={asset.animatedSrc}
          fallbackImage={asset.imageSrc}
          safariAnimationSrc={asset.safariAnimatedSrc}
        />
      </div>
    )
  }

  return (
    <div className={styles.ProductImage}>
      <ImageWithFallback productImage={asset.imageSrc} title={title} />
    </div>
  )
}

export function BrawlItem({ content, isSingleItemOffer, isSubItem }: Props) {
  let colorStyle = ''
  const colorScheme = useAssetColor(content.product.id)
  const translatable = useTranslatable()
  const isBrawlHyperChargeAsset = brawlHyperChargeAsset(content.product.id)
  const isSkinOrBrawler = isBrawlerOrSkin(content)

  let shownTitle = translatable(content.product.title)
  if (!isBrawlHyperChargeAsset && !isSkinOrBrawler) {
    shownTitle = content.amount.toString()
  }
  const cardStyle = isSingleItemOffer ? styles.SingleItemCard : styles.Card

  // for singleItemOffer colorScheme comes from container
  if (!isSingleItemOffer) {
    colorStyle = colors[colorScheme]
  }

  return (
    <div className={cx(cardStyle, colorStyle)}>
      {(isSubItem || (!isSingleItemOffer && !isSkinOrBrawler)) && <Flare />}
      <ProductImage content={content} isSubItem={isSubItem} />
      {!isSingleItemOffer && (
        <div className={styles.FooterWrapper}>
          <div className={styles.Footer}>
            <StoreTypography tag="h3" style="outlined" textSize="sm" className={styles.Title}>
              {shownTitle}
            </StoreTypography>
          </div>
        </div>
      )}
    </div>
  )
}
