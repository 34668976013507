import { createElement, useEffect } from 'react'

import { GameId } from '@common'
import cx from 'classnames'
import { AnimatePresence, LazyMotion } from 'framer-motion'
import Image from 'next/image'

import { useGameImages } from '@/components/games/images'
import { useModalContext } from '@/contexts/ModalContext'
import { StorefrontModal } from '@/features/storefronts/_storeBase/modal/StorefrontModal'
import { LOGIN_STATUS } from '@/features/user/userSlice'
import { useAppSelector } from '@/utils/useAppSelector'

import { Footer } from './Footer'

import styles from './OnboardingModal.module.scss'

export type OnboardingLoginModalProps = {
  type: 'ONBOARDING_LOGIN'
  props: Props
}

type Props = {
  gameId: GameId
  screen: () => JSX.Element
}

const features = () => import('@/animations/features').then((a) => a.default)

export const OnboardingLoginModal = ({ gameId, screen }: Props) => {
  const loginStatus = useAppSelector((state) => state.user.loginStatus)
  const { closeModal } = useModalContext()
  const { gameImage } = useGameImages(gameId)

  useEffect(() => {
    window.onpopstate = () => {
      closeModal()
    }
    return () => {
      window.onpopstate = null
    }
  }, [closeModal])

  const onSkip = () => {
    closeModal()
  }

  if (loginStatus === LOGIN_STATUS.SSO_LOGIN_MIGHT_HAPPEN) {
    return null
  }

  return (
    <LazyMotion features={features}>
      <StorefrontModal
        position="Middle"
        onClose={closeModal}
        closeOnClickOutside={false}
        className={styles.onboardingModal}
      >
        <div className={styles.OnboardingModalContainer}>
          <div className={cx(styles.Prompt, styles[gameId])}>
            <div className={cx(styles.Header, [styles[gameId]])}>
              <Image src={gameImage('logo')} alt={gameId} width={160} />
              <button className={cx(styles.CloseButton, styles.CloseModal, styles[gameId])} onClick={onSkip}>
                <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <g clipRule="evenodd" fill="#000" fillRule="evenodd">
                    <path d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0z" />
                    <path d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414z" />
                  </g>
                </svg>
              </button>
            </div>
            <div className={styles.Content}>
              <div className={styles.Screen}>
                <AnimatePresence mode="wait">{createElement(screen, { key: `screen-login` })}</AnimatePresence>
              </div>
            </div>
            <Footer loginStep={true} lastOnboardingScreen={false} onNextStep={closeModal} />
          </div>
        </div>
      </StorefrontModal>
    </LazyMotion>
  )
}
