import * as React from 'react'

import { Modal } from '../components/Modal'
import { useModalContext } from '../contexts/ModalContext'
import { OfferExpired } from '../features/offers/OfferExpired'

export type OfferExpiredModalProps = {
  type: 'OFFER_EXPIRED'
  props: Props
}

type Props = {
  expired?: boolean
}

export function OfferExpiredModal(props: Props) {
  const { closeModal } = useModalContext()
  return (
    <Modal position="Middle" data-cy="notify-modal" onClose={closeModal} closeOnClickOutside>
      <OfferExpired expired={props.expired} />
    </Modal>
  )
}
