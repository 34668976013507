import z from 'zod'

import * as columnTypes from './columnTypes'

function nullToDefault<T>(defaultValue: T) {
  return (value: T | null) => (value === null ? defaultValue : value)
}

export const country = {
  name: 'country',
  columns: {
    countryCode: 'country_code',
    countryCodeLong: 'country_code_long',
    name: 'name',
    currencyCode: 'currency_code',
    currencyCountry: 'currency_country',
    appStoreTerritory: 'app_store_territory',
    taxType: 'tax_type',
    enabled: 'enabled',
  },
  schema: z.object({
    countryCode: columnTypes.countryCodeSchema,
    countryCodeLong: z.string().length(3),
    name: z.string(),
    appStoreTerritory: z.string(),
    currencyCode: columnTypes.currencyCodeSchema.nullable().transform(nullToDefault(columnTypes.DEFAULT_CURRENCY_CODE)),
    currencyCountry: columnTypes.currencyCountrySchema
      .nullable()
      .transform(nullToDefault(columnTypes.DEFAULT_CURRENCY_COUNTRY)),
    taxType: columnTypes.taxTypeSchema.nullable().transform(nullToDefault(columnTypes.DEFAULT_TAX_TYPE)),
    enabled: z.boolean(),
  }),
}

export type Country = z.infer<typeof country.schema>

export const paymentGatewayCountry = {
  name: 'payment_gateway_country',
  columns: {
    paymentGatewayId: 'payment_gateway_id',
    countryCode: 'country_code',
  },
  schema: z.object({
    countryCode: columnTypes.countryCodeSchema,
    paymentGatewayId: columnTypes.paymentGatewaySchema,
  }),
}
export type PaymentGatewayCountry = z.infer<typeof paymentGatewayCountry.schema>

export const currency = {
  name: 'currency',
  columns: {
    currencyCode: 'currency_code',
    name: 'name',
    decimals: 'decimals',
  },
  schema: z.object({
    currencyCode: columnTypes.currencyCodeSchema,
    name: z.string(),
    decimals: z.number().int().min(0),
  }),
}
export type Currency = z.infer<typeof currency.schema>

export const currencyCountry = {
  name: 'currency_country',
  columns: {
    currencyCode: 'currency_code',
    countryCode: 'country_code',
  },
  schema: z.object({
    currencyCode: columnTypes.currencyCodeSchema,
    countryCode: columnTypes.countryCodeSchema,
  }),
}

export type CurrencyCountry = z.infer<typeof currencyCountry.schema>

export const paymentMethod = {
  name: 'payment_method',
  columns: {
    id: 'id',
    externalId: 'external_id',
    paymentGatewayId: 'payment_gateway_id',
    title: 'title',
    logo: 'logo',
    priority: 'priority',
    pinned: 'pinned',
    categories: 'categories',
    notes: 'notes',
    enabled: 'enabled',
  },
  schema: z.object({
    id: z.string(),
    externalId: z.string(),
    paymentGatewayId: columnTypes.paymentGatewaySchema,
    title: columnTypes.translationObjectSchema,
    logo: z.string().optional().nullable(),
    priority: z.number().int().min(0).max(100),
    pinned: z.boolean(),
    categories: z.array(z.string()),
    notes: z.string().optional(),
    enabled: z.boolean(),
  }),
}
export type PaymentMethod = z.infer<typeof paymentMethod.schema>

export const countryPaymentMethod = {
  name: 'country_payment_method',
  columns: {
    countryCode: 'country_code',
    paymentMethodId: 'payment_method_id',
    pinned: 'pinned',
    notes: 'notes',
    enabled: 'enabled',
  },
  schema: z.object({
    countryCode: columnTypes.countryCodeSchema,
    paymentMethodId: z.string(),
    pinned: z.boolean(),
    notes: z.string(),
    enabled: z.boolean(),
  }),
}
export type CountryPaymentMethod = z.infer<typeof countryPaymentMethod.schema>

export const currencyPaymentMethod = {
  name: 'currency_payment_method',
  columns: {
    currencyCode: 'currency_code',
    paymentMethodId: 'payment_method_id',
  },
  schema: z.object({
    currencyCode: columnTypes.currencyCodeSchema,
    paymentMethodId: z.string(),
  }),
}
export type CurrencyPaymentMethod = z.infer<typeof currencyPaymentMethod.schema>

// TODO: rename to paymentServiceGateway
export const paymentGateway = {
  name: 'payment_gateway',
  columns: {
    id: 'id',
    name: 'name',
    enabled: 'enabled',
  },
  schema: z.object({
    id: columnTypes.paymentGatewaySchema,
    name: z.string(),
    enabled: z.boolean(),
  }),
}
export type PaymentGateway = z.infer<typeof paymentGateway.schema>

export const paymentGatewayCurrency = {
  name: 'payment_gateway_currency',
  columns: {
    paymentGatewayId: 'payment_gateway_id',
    currencyCode: 'currency_code',
  },
  schema: z.object({
    paymentGatewayId: columnTypes.paymentGatewaySchema,
    currencyCode: columnTypes.currencyCodeSchema,
  }),
}
export type PaymentGatewayCurrency = z.infer<typeof paymentGatewayCurrency.schema>

export const price = {
  name: 'price',
  columns: {
    sku: 'sku',
    currencyCcode: 'currency_code',
    countryCode: 'country_code',
    price: 'price',
  },
  schema: z.object({
    sku: z.string(),
    currencyCode: columnTypes.currencyCodeSchema,
    countryCode: columnTypes.countryCodeSchema,
    price: z.number().int(),
  }),
}
export type Price = z.infer<typeof price.schema>

export const tax = {
  name: 'tax',
  columns: {
    countryCode: 'country_code',
    rate: 'rate',
    endDate: 'end_date',
  },
  schema: z.object({
    countryCode: columnTypes.countryCodeSchema,
    rate: z.coerce.number().nullable(),
    endDate: z.string().nullable(),
  }),
}
export type Tax = z.infer<typeof tax.schema>

export const announcement = {
  name: 'announcement',
  columns: {
    id: 'id',
    description: 'description',
    enabled: 'enabled',
  },
  schema: z.object({
    id: z.string(),
    description: columnTypes.translationObjectSchema,
    enabled: z.boolean(),
  }),
}
export type Announcement = z.infer<typeof announcement.schema>

export const announcementCountry = {
  name: 'announcement_country',
  columns: {
    announcementId: 'announcement_id',
    countryCode: 'country_code',
  },
  schema: z.object({
    announcementId: z.string(),
    countryCode: columnTypes.countryCodeSchema,
  }),
}
export type AnnouncementCountry = z.infer<typeof announcementCountry.schema>

export const auditLog = {
  name: 'audit_log',
  columns: {
    id: 'id',
    recordId: 'record_id',
    oldRecordId: 'old_record_id',
    op: 'op',
    performedBy: 'performed_by',
    queryName: 'query_name',
    tableOid: 'table_oid',
    tableSchema: 'table_schema',
    tableName: 'table_name',
    record: 'record',
    oldRecord: 'old_record',
    ts: 'ts',
    txId: 'tx_id',
  },
  schema: z.object({
    id: z.string().or(z.number()),
    recordId: z.string().uuid().nullable(),
    oldRecordId: z.string().uuid().nullable(),
    op: z.enum(['INSERT', 'UPDATE', 'DELETE', 'TRUNCATE']),
    performedBy: z.string().nullable(),
    queryName: z.string().nullable(),
    tableOid: z.string().or(z.number()),
    tableSchema: z.string(),
    tableName: z.string(),
    record: z.record(z.any()).nullable(),
    oldRecord: z.record(z.any()).nullable(),
    ts: z.coerce.date(),
    txId: z.string().or(z.number()).nullable(),
  }),
}

export type AuditLog = z.infer<typeof auditLog.schema>

export const maintenanceWindow = {
  name: 'maintenance_window',
  columns: {
    id: 'id',
    startTime: 'start_time',
    endTime: 'end_time',
    game: 'game',
  },
  schema: z.object({
    id: z.string().uuid(),
    game: z.string(),
    startTime: z.coerce.date(),
    endTime: z.coerce.date(),
  }),
}

export type MaintenanceWindow = z.infer<typeof maintenanceWindow.schema>

export const creatorGiveawayAccount = {
  name: 'creator_giveaway_account',
  columns: {
    id: 'id',
    notes: 'notes',
    enabled: 'enabled',
    updatedAt: 'updated_at',
    createdAt: 'created_at',
  },
  schema: z.object({
    id: z.string(),
    notes: z.string().nullable(),
    enabled: z.boolean(),
    updatedAt: z.coerce.date().nullable(),
    createdAt: z.coerce.date(),
  }),
}

export type CreatorGiveawayAccount = z.infer<typeof creatorGiveawayAccount.schema>

export const testAccount = {
  name: 'test_accounts',
  columns: {
    id: 'id',
    description: 'description',
    enabled: 'enabled',
  },
  schema: z.object({
    id: z.string(),
    description: z.string().nullable(),
    enabled: z.boolean(),
  }),
}
