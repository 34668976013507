import { GameId, ProductV2 } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import PassesInfoImage from '@/assets/images/storefronts/clashofclans/product-page-passes-info.webp'
import { useIsLoggedIn } from '@/features/common/useIsLoggedIn'
import { getStampCardTranslationKeys } from '@/localization/utils'
import { useAppSelector } from '@/utils/useAppSelector'
import { useBreakpoints } from '@/utils/useBreakpoints'

import { StampCard } from '../stampCard/StampCard'
import { getStampImage } from '../stampCard/utils'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './info.module.scss'

type Props = {
  product: ProductV2
}
export function PassesInfo({ product }: Props) {
  const t = useTranslations()
  const gameId = useAppSelector((state) => state.common.currentGame) as GameId
  const keys = getStampCardTranslationKeys(product.game)
  const stamp = getStampImage(gameId)
  const isLoggedIn = useIsLoggedIn()
  const from = useBreakpoints().from
  const isDesktop = from('tablet')

  if (!keys) {
    return null
  }

  const title = t(`stamps_info_${keys.product}_purchases`)
  const description = t(`stamps_info_${keys.product}_stamp_card`)
  const description2 = t('stamps_info_check_it_out')
  const info = t(`stamps_info`)
  const expiration = t('stamps_info_expiration')

  return (
    <div className={cx(styles.info, styles.passesInfo, styles[gameId])}>
      <div className={styles.infoContent}>
        <StoreTypography tag="h5" style="plain" textSize="body" overrideFont="supercellTextMedium" lineHeight="normal">
          {title}
        </StoreTypography>
        <div className={styles.passesInfoImage}>
          {isLoggedIn ? (
            <StampCard product={product} size="large" />
          ) : (
            <Image src={PassesInfoImage} alt="Passes page info" width={isDesktop ? 300 : 225} quality={100} />
          )}
        </div>
        <StoreTypography
          tag="p"
          textSize="body"
          style="plain"
          overrideFont="supercellText"
          lineHeight="normal"
        >{`${description} ${description2}`}</StoreTypography>

        {stamp && (
          <div className={styles.stampInfoImage}>
            <Image src={stamp} alt="stamp item" fill style={{ objectFit: 'contain' }} sizes="100px" />
          </div>
        )}
        <StoreTypography tag="p" textSize="body" style="plain" overrideFont="supercellText" lineHeight="normal">
          {info}
        </StoreTypography>
        <StoreTypography tag="p" textSize="body" style="plain" overrideFont="supercellText" lineHeight="normal">
          {expiration}
        </StoreTypography>
      </div>
    </div>
  )
}
