import { useEffect, useRef } from 'react'

import { StoreBonusStatusResponse } from '@common/models/bonus'
import cx from 'classnames'
import Image from 'next/image'
import { useLocale, useTranslations } from 'next-intl'

import { useGameImages } from '@/components/games/images'
import { useCartContext } from '@/contexts/CartContext'
import { BONUS_TRACK_DIVIDER_BG, ROPE_BG, STORE_BG, rtlLocales } from '@/features/storefronts/_storeBase/constants'
import { TimeRemaining } from '@/features/storefronts/_storeBase/timeRemaining/TimeRemaining'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { getTimeRemaining } from '@/utils/timer'

import { BonusCelebration } from './BonusCelebration'
import { BonusTrackItem } from './BonusTrackItem'
import { BonusTrackTentativeBonus } from './BonusTrackTentativeBonus'
import { BonusUnavailable } from './BonusUnavailable'
import {
  extractBonusValues,
  getBonusItemIndex,
  getDeliveredBonuses,
  getProgressPercentage,
  getBonusTrackCompletionStatus,
} from '../bonus/bonusUtils'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusTrack.module.scss'

interface BonusTrackProps {
  bonusStatus: StoreBonusStatusResponse
  animationComplete?: boolean
}

export const BonusTrack = ({ bonusStatus, animationComplete }: BonusTrackProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const { gameImage } = useGameImages(gameSlug)
  const locale = useLocale()
  const { items } = useCartContext()

  const { balance, bonusItems, totalBalance } = extractBonusValues({ bonusStatus, items })
  const bonusesDelivered = getDeliveredBonuses({ bonusStatus, items })
  const progressPercentage = getProgressPercentage({ bonusStatus, items })
  const remainingTime = getTimeRemaining(bonusStatus?.endTimeMillis || 0)

  const availableItems = bonusItems.filter((item) => item.available)
  const unavailableItems = bonusItems.filter((item) => !item.available)

  const unavailableItemsDelivered = unavailableItems.filter((item) => item.cost > totalBalance)
  const hasUnclaimedItems = availableItems.some((item) => !item.claimed)
  const bonusTrackCompletionStatus = getBonusTrackCompletionStatus({ bonusStatus, items })
  const isTentativelyCompletedBonusTrack = bonusTrackCompletionStatus === 'TENTATIVE'
  const isExhaustivelyCompletedBonusTrack = bonusTrackCompletionStatus === 'EXHAUSTIVE'
  const isUnavailableBonusTrack = bonusTrackCompletionStatus === 'UNAVAILABLE'

  const showRemainingTime = bonusStatus?.endTimeMillis && bonusStatus.endTimeMillis > 0
  const showTentativeBonus = bonusesDelivered.length > 0

  const availableBonusRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (animationComplete) {
      if (availableItems.length > 0 && hasUnclaimedItems) {
        const timeout = setTimeout(() => {
          if (availableBonusRef.current) {
            availableBonusRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        }, 100)

        return () => clearTimeout(timeout)
      } else {
        const timeout = setTimeout(() => {
          document.getElementById('bonusTrack')?.scrollIntoView({ behavior: 'smooth' })
        }, 100)

        return () => clearTimeout(timeout)
      }
    }
  }, [animationComplete, availableItems, hasUnclaimedItems])

  const scrollTop = () => {
    const element = document.getElementById('bonusTrackWrapper')
    element?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <div className={styles.track}>
      <div
        className={cx(styles.availableBonusContainer, styles[gameSlug])}
        style={{ backgroundImage: STORE_BG(gameSlug) }}
      >
        <div className={styles.rope} style={{ backgroundImage: ROPE_BG(gameSlug) }} />
        <div className={cx(styles.section, styles.availableBonuses)} data-cy="available-bonuses">
          {(isExhaustivelyCompletedBonusTrack ? bonusItems : availableItems)?.map((item) => (
            <BonusTrackItem
              key={item.id}
              balance={balance}
              index={getBonusItemIndex({ bonusStatus, item })}
              item={item}
              status={item.claimed ? 'claimed' : 'unclaimed'}
              progressPercentage={progressPercentage}
              ref={item.claimed ? null : availableBonusRef}
            />
          ))}
        </div>

        <div className={cx(styles.scrollTop, { [styles.noItems]: !hasUnclaimedItems })}>
          {hasUnclaimedItems && (
            <StoreTypography
              tag="div"
              style="plain"
              textSize="sm"
              overrideFont="supercellText"
              onClick={() => scrollTop()}
              className={styles.scrollText}
            >
              {t('bonus_unclaimed_bonuses')}
              <span className={styles.scrollIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                  <path d="M1 4.5L5 0.5L9 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            </StoreTypography>
          )}
        </div>
      </div>
      <div className={styles.dividerContainer} id="bonusTrack">
        <div className={styles.divider} style={{ backgroundImage: BONUS_TRACK_DIVIDER_BG(gameSlug) }} />
        <div className={styles.dividerImageContainer}>
          <div className={cx(styles.dividerImageWrapper, styles[gameSlug])}>
            <Image src={gameImage('badge')} alt={gameSlug} fill quality={100} sizes="100px" />
          </div>
        </div>
      </div>
      {!!showRemainingTime && (
        <div className={cx(styles.timeRibbonContainer, styles[gameSlug])}>
          {gameSlug !== 'brawlstars' && <div className={styles.timeRibbon} />}
          <div className={styles.timeTextContainer}>
            <TimeRemaining
              className={styles.timeText}
              timeLeftText={remainingTime}
              textSize={gameSlug === 'brawlstars' ? 'sm' : undefined}
              type={rtlLocales.includes(locale) ? 'primary' : 'secondary'}
              textStyle={rtlLocales.includes(locale) ? 'plain' : 'outlined'}
            />
          </div>
        </div>
      )}
      <div className={cx(styles.unavailableBonusContainer, styles[gameSlug])}>
        <div className={cx(styles.section, styles.unavailableBonuses)} data-cy="unavailable-bonuses">
          {showTentativeBonus && !isExhaustivelyCompletedBonusTrack ? (
            <>
              <div className={styles.tentativeBonusContainer}>
                <BonusTrackTentativeBonus bonusStatus={bonusStatus} />
                {!isTentativelyCompletedBonusTrack && <div className={cx(styles.dotsContainer, styles.colored)} />}
              </div>

              {unavailableItemsDelivered?.map((item, index) => (
                <div className={styles.unavailableItemContainer} key={item.id}>
                  <BonusTrackItem
                    balance={totalBalance}
                    index={getBonusItemIndex({ bonusStatus, item })}
                    item={item}
                    status={index === 0 ? 'next' : 'unavailable'}
                    progressPercentage={progressPercentage}
                  />
                  {index < unavailableItemsDelivered.length - 1 && <div className={styles.dotsContainer} />}
                </div>
              ))}
            </>
          ) : (
            <>
              {!isExhaustivelyCompletedBonusTrack && unavailableItems.length > 0 && (
                <div className={cx(styles.dotsContainer, styles.first, styles.colored)} />
              )}

              {!isExhaustivelyCompletedBonusTrack &&
                unavailableItems?.map((item, index) => (
                  <div className={styles.unavailableItemContainer} key={item.id}>
                    <BonusTrackItem
                      balance={balance}
                      index={getBonusItemIndex({ bonusStatus, item })}
                      item={item}
                      status={index === 0 ? 'next' : 'unavailable'}
                      progressPercentage={progressPercentage}
                    />
                    {index < unavailableItems.length - 1 && <div className={styles.dotsContainer} />}
                  </div>
                ))}
            </>
          )}
          {isExhaustivelyCompletedBonusTrack && <BonusCelebration />}
          {isUnavailableBonusTrack && <BonusUnavailable />}
        </div>
      </div>
    </div>
  )
}
