import {
  DynamoDBClient,
  BatchGetItemCommand,
  PutItemCommand,
  QueryCommand,
  TransactWriteItem,
} from '@aws-sdk/client-dynamodb'
import { marshall } from '@aws-sdk/util-dynamodb'

import { StoreError, StoreErrorMessage } from '../../utils/StoreError'
import { Receipt, receiptSchema, ReceiptStatus } from '../../models/receipt'
import { PURCHASE_STATUS, PurchaseId } from '../../models/purchase'

import * as dynamoDbConfig from './config'
import { MetricsClient } from '../cloudwatch/metrics'
import { isDefined } from '../../utils/isDefined'
import { logger } from '../../utils/logger'

const { TABLE_NAME, PARTITION_KEY, SORT_KEY, tablePartitionKey, GSIPK, GSISK, GSI_NAME } = dynamoDbConfig

const RECEIPT_PREFIX = 'RECEIPT'
const TYPE_RECEIPT = 'RECEIPT'

const receiptSortKeyPrefix = (purchaseId: PurchaseId) => `${RECEIPT_PREFIX}#${purchaseId}` as const

const receiptSortKey = (purchaseId: string, purchaseStatus: ReceiptStatus) =>
  `${receiptSortKeyPrefix(purchaseId)}#${purchaseStatus}` as const

const receiptGSISK = (timestamp: number) => `${RECEIPT_PREFIX}#${timestamp}` as const

type Dependencies = {
  metricsClient: MetricsClient
}
export function createReceiptClient(dynamoDbClient: DynamoDBClient, { metricsClient }: Dependencies) {
  return {
    createReceipt: metricsClient.withDurationMetrics('Receipt_CreateReceipt', createReceipt(dynamoDbClient)),
    getReceipt: metricsClient.withDurationMetrics('Receipt_GetReceipt', getReceipt(dynamoDbClient)),
    requireReceipt: metricsClient.withDurationMetrics('Receipt_RequireReceipt', requireReceipt(dynamoDbClient)),
    getReceipts: metricsClient.withDurationMetrics('Receipt_GetReceipts', getReceipts(dynamoDbClient)),
    getReceiptBatch: metricsClient.withDurationMetrics('Receipt_GetReceiptBatch', getReceiptBatch(dynamoDbClient)),
  }
}

function createReceipt(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, receipt: Receipt, overwrite = false): Promise<void> => {
    const command = buildCreateReceiptCommand(customerId, receipt, overwrite)
    await dynamoDbClient.send(command)
  }
}

function getReceipts(dynamoDbClient: DynamoDBClient) {
  return async (
    customerId: string,
    options: { exclusiveStartKey?: { purchaseId: string; timestamp: number }; limit: number },
  ): Promise<{ receipts: Receipt[]; lastEvaluatedKey?: { purchaseId: string; timestamp: number } }> => {
    const command = buildQueryUsersReceiptsCommand(customerId, options)
    const response = await dynamoDbClient.send(command)
    const items =
      response.Items?.map((item) => {
        try {
          const receiptJson = JSON.parse(item.data.S ?? '')
          return parseReceipt(fixAutoRefundReceipt(receiptJson))
        } catch (err) {
          return null
        }
      }) ?? []
    const receipts = filterDuplicateReceipts(items.filter(isDefined))
    const lastEvaluatedKey = response.LastEvaluatedKey
      ? {
          purchaseId: receipts.slice(-1)[0].purchaseId,
          timestamp: receipts.slice(-1)[0].timestamp,
        }
      : undefined
    return { receipts, lastEvaluatedKey }
  }
}

function getReceipt(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, purchaseId: string): Promise<Receipt | undefined> => {
    const command = buildQueryReceiptCommand(customerId, purchaseId)
    const response = await dynamoDbClient.send(command)
    const items = response.Items?.map((item) => parseReceipt(JSON.parse(item.data.S ?? ''))) ?? []
    const receipts = filterDuplicateReceipts(items).sort((a, b) => b.timestamp - a.timestamp)
    return receipts[0]
  }
}

function requireReceipt(dynamoDbClient: DynamoDBClient) {
  const _getReceipt = getReceipt(dynamoDbClient)
  return async (customerId: string, purchaseId: string): Promise<Receipt> => {
    const receipt = await _getReceipt(customerId, purchaseId)
    if (!receipt) {
      throw StoreError.withMessage(StoreErrorMessage.NOT_FOUND)
    }
    return receipt
  }
}

function getReceiptBatch(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, purchaseIds: string[]): Promise<Receipt[]> => {
    if (purchaseIds.length >= 100) {
      throw StoreError.withMessage(StoreErrorMessage.INTERNAL_SERVER_ERROR)
    }
    const command = batchGetReceiptsCommand(customerId, purchaseIds)
    const response = await dynamoDbClient.send(command)
    const items =
      response.Responses?.[TABLE_NAME]?.map((item) => {
        try {
          return parseReceipt(JSON.parse(item.data.S ?? ''))
        } catch (err) {
          return null
        }
      }) ?? []
    const receipts = filterDuplicateReceipts(items.filter(isDefined))
    return receipts
  }
}

// Utils

export function buildPutReceiptTransactWriteItem(receipt: Receipt, overwrite = false) {
  const partitionKey = tablePartitionKey(receipt.customerId)
  const sortKey = receiptSortKey(receipt.purchaseId, receipt.status)
  const item: TransactWriteItem = {
    Put: {
      TableName: TABLE_NAME,
      Item: marshall({
        data: JSON.stringify(receipt),
        [PARTITION_KEY]: partitionKey,
        [SORT_KEY]: sortKey,
        [GSIPK]: partitionKey,
        [GSISK]: receiptGSISK(receipt.timestamp),
        TYPE: TYPE_RECEIPT,
      }),
    },
  }
  if (!overwrite && item.Put) {
    item.Put.ConditionExpression = '#PK <> :PK AND #SK <> :SK'
    item.Put.ExpressionAttributeNames = {
      '#PK': PARTITION_KEY,
      '#SK': SORT_KEY,
    }
    item.Put.ExpressionAttributeValues = {
      ':PK': { S: partitionKey },
      ':SK': { S: sortKey },
    }
  }
  return item
}

function buildCreateReceiptCommand(customerId: string, receipt: Receipt, overwrite: boolean): PutItemCommand {
  const partitionKey = tablePartitionKey(customerId)
  const sortKey = receiptSortKey(receipt.purchaseId, receipt.status)
  const condition = overwrite
    ? {}
    : {
        ConditionExpression: '#PK <> :PK AND #SK <> :SK',
        ExpressionAttributeNames: {
          '#PK': PARTITION_KEY,
          '#SK': SORT_KEY,
        },
        ExpressionAttributeValues: {
          ':PK': { S: partitionKey },
          ':SK': { S: sortKey },
        },
      }
  return new PutItemCommand({
    TableName: TABLE_NAME,
    Item: marshall({
      data: JSON.stringify(receipt),
      [PARTITION_KEY]: partitionKey,
      [SORT_KEY]: sortKey,
      [GSIPK]: partitionKey,
      [GSISK]: receiptGSISK(receipt.timestamp),
      TYPE: TYPE_RECEIPT,
    }),
    ...condition,
  })
}

function buildQueryUsersReceiptsCommand(
  customerId: string,
  options: { exclusiveStartKey?: { purchaseId: string; timestamp: number }; limit: number },
): QueryCommand {
  const ExclusiveStartKey = options.exclusiveStartKey
    ? {
        /**
         * When viewing receipts in the UI, we want to show the most recent receipts first.
         * We also know that AUTHORISATION receipts have been created before REFUND receipts thus
         * we can start looking for next purchases from the AUTHROISATION receipt.
         * If the previous receipt was a REFUND we want to skip its AUTHORISATION receipt anyway.
         */
        PK: { S: tablePartitionKey(customerId) },
        SK: { S: receiptSortKey(options.exclusiveStartKey.purchaseId, PURCHASE_STATUS.AUTHORISED_WEBHOOK) },
        GSIPK: { S: tablePartitionKey(customerId) },
        GSISK: { S: receiptGSISK(options.exclusiveStartKey.timestamp) },
      }
    : undefined
  return new QueryCommand({
    TableName: TABLE_NAME,
    IndexName: GSI_NAME,
    KeyConditionExpression: '#GSIPK = :GSIPK AND begins_with(#GSISK, :GSISK_PREFIX)',
    ScanIndexForward: false,
    ExclusiveStartKey,
    Limit: options.limit,
    ExpressionAttributeNames: {
      '#GSIPK': GSIPK,
      '#GSISK': GSISK,
    },
    ExpressionAttributeValues: {
      ':GSIPK': { S: tablePartitionKey(customerId) },
      ':GSISK_PREFIX': { S: RECEIPT_PREFIX },
    },
  })
}

function buildQueryReceiptCommand(customerId: string, purchaseId: string): QueryCommand {
  const PK = tablePartitionKey(customerId)
  const sortKey = receiptSortKeyPrefix(purchaseId)
  return new QueryCommand({
    TableName: TABLE_NAME,
    KeyConditionExpression: '#PK = :PK and begins_with(#SK, :sortKey)',
    ExpressionAttributeNames: {
      '#PK': PARTITION_KEY,
      '#SK': SORT_KEY,
    },
    ExpressionAttributeValues: {
      ':PK': { S: PK },
      ':sortKey': { S: sortKey },
    },
  })
}

const batchGetReceiptsCommand = (customerId: string, purchaseIds: string[]): BatchGetItemCommand => {
  const PK = tablePartitionKey(customerId)
  const sortKeys = purchaseIds.map((purchaseId) => receiptSortKey(purchaseId, PURCHASE_STATUS.AUTHORISED_WEBHOOK))
  return new BatchGetItemCommand({
    RequestItems: {
      [TABLE_NAME]: {
        Keys: sortKeys.map((sortKey) => ({
          [PARTITION_KEY]: { S: PK },
          [SORT_KEY]: { S: sortKey },
        })),
      },
    },
  })
}

function fixAutoRefundReceipt(receipt: Record<string, unknown>) {
  if (receipt.status !== PURCHASE_STATUS.REFUND_WEBHOOK) {
    return receipt
  }
  if (receipt.checkoutTotal === 0) {
    return { receipt, checkoutTotal: receipt.total }
  }
  return receipt
}

const parseReceipt = (receipt: Record<string, unknown>): Receipt => {
  const result = receiptSchema.safeParse(receipt)
  if (!result.success) {
    logger.error('Failed to parse receipt', {
      id: receipt.purchaseId,
      customerId: receipt.customerId,
      status: receipt.status,
      paymentProvider: receipt.paymentGateway ?? receipt.paymentProvider,
      error: result.error,
    })
    throw StoreError.withMessage(StoreErrorMessage.RECEIPT_PARSING_FAILED)
  }
  return result.data
}

function filterDuplicateReceipts(items: Receipt[]): Receipt[] {
  const uniqueReceipts = items.reduce<{ [key: string]: Receipt }>((acc, cur) => {
    const previous = acc[cur.purchaseId]
    if (!previous) {
      acc[cur.purchaseId] = cur
    } else if (previous.status === PURCHASE_STATUS.AUTHORISED_WEBHOOK && cur.status !== previous.status) {
      acc[cur.purchaseId] = cur
    }
    return acc
  }, {})
  return Object.values(uniqueReceipts)
}
