import { ProductV2 } from '@common'
import cx from 'classnames'

import { useAppSelector } from '@/utils/useAppSelector'

import { CardRibbon } from './CardRibbon'

import styles from './productRibbon.module.scss'

interface Props {
  product: ProductV2
  unskew?: boolean
  position?: 's'
  className?: string
}

export const ProductRibbon = ({ product, position, unskew, className: additionalClasses }: Props) => {
  const bonusTrackCompleted = useAppSelector((state) => state.common.bonusTrackCompleted)

  if (bonusTrackCompleted) {
    return null
  }

  return (
    <div
      className={cx(
        styles.RibbonContainer,
        { [styles.unskew]: unskew, [styles['position-s']]: position === 's' },
        additionalClasses,
      )}
    >
      <CardRibbon product={product} />
    </div>
  )
}
