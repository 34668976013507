import { z } from 'zod'
import { MerchantOfRecord } from './checkout'
import { columnTypes } from './db'

const messageNotificationSchema = z.object({
  signature: z.string(),
  notification: z.string(),
})
export type MessageNotification = z.infer<typeof messageNotificationSchema>

const messageBaseSchema = z.object({
  notification: messageNotificationSchema,
  paymentGateway: MerchantOfRecord,
  pspReference: z.string(),
  // custom_data for Paddle
  environment: z.string(),
  customerId: z.string(),
  purchaseId: z.string(),
  creatorBoostCode: z.string().optional(),
  countryCode: columnTypes.countryCodeSchema,
  isUntouched: z.boolean().optional(),
})

const paymentMessageFieldsSchema = z.object({
  type: z.literal('payment'),
  paymentMethod: z.string(),
  payment: z.object({
    subtotal: z.number(),
    total: z.number(),
    tax: z.number(),
    taxRate: z.number(),
    currencyCode: columnTypes.currencyCodeSchema,
  }),
})

const failedPaymentMessageFieldsSchema = z
  .object({
    type: z.literal('failedPayment'),
    reason: z.string(),
  })
  .merge(messageBaseSchema)

const refundMessageFieldsSchema = z.object({
  type: z.literal('refund'),
  reason: z.union([z.literal('REFUND'), z.literal('CHARGEBACK')]),
})

export const paymentMessageSchema = messageBaseSchema.merge(paymentMessageFieldsSchema)
export type PaymentMessage = z.infer<typeof paymentMessageSchema>
export const refundMessageSchema = messageBaseSchema.merge(refundMessageFieldsSchema)
export type RefundMessage = z.infer<typeof refundMessageSchema>
export type FailedPaymentMessage = z.infer<typeof failedPaymentMessageFieldsSchema>

export const paymentOrRefundMessageSchema = z.union([
  paymentMessageSchema,
  refundMessageSchema,
  failedPaymentMessageFieldsSchema,
])
export type PaymentOrRefundMessage = z.infer<typeof paymentOrRefundMessageSchema>
