import pino from 'pino'

export const logger = pino({
  redact: [
    'context.req.cookies.SESSION_COOKIE',
    'event.payload.email',
    'eventBody.data.customer.email', // Fastspring webhook event
    'eventBody.data.customer.first', // Fastspring webhook event
    'eventBody.data.customer.last', // Fastspring webhook event
    'eventBody.data.email', // Paddle webhook event
    'eventBody.data.recipients', // Fastspring webhook event
    'input.receipts[*].email',
    'ip',
    'message.notification.notification',
    'output.action.authorisationToken',
    'output.action.paymentData',
    'output.action.token',
    'output.received.data.customer.email',
    'output.received.data.recipients',
    'purchase.customerIP',
    'purchase.email',
    'req.cookies.SESSION_COOKIE',
    'req.rawHeaders',
  ],
})
