import React, { useState } from 'react'

import { AnimatePresence, LazyMotion, m, Variants } from 'framer-motion'

import { nextImageLoader } from '@/utils/nextImageLoader'
import useInterval from '@/utils/useInterval'

import styles from './ItemRow.module.scss'

const BASE_DURATION = 0.3

const variants = (delay: number): Variants => {
  const duration = BASE_DURATION + delay
  return {
    initial: { opacity: 0, scale: 0.5, x: 0, position: 'absolute', zIndex: 0 },
    animate: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: { duration, delay: duration / 3, type: 'spring', stiffness: 110 },
      zIndex: 1,
    },
    exit: { opacity: 0, scale: 0.5, type: 'linear', transition: { duration }, position: 'absolute', zIndex: 0 },
  }
}

const AnimatedImages = (props: { images: string[] }) => {
  const [index, setIndex] = useState(0)
  const additionalDelay = parseFloat(props.images.slice(-1)?.[0]) || 0
  const images = props.images.slice(0, -1)
  useInterval(() => {
    if (images.length <= 1) return
    if (index === images.length - 1) {
      setIndex(0)
    } else {
      setIndex(index + +1)
    }
  }, 2500)
  return (
    <AnimatePresence initial={false}>
      <m.img
        key={index}
        variants={variants(additionalDelay)}
        initial="initial"
        animate="animate"
        exit="exit"
        src={nextImageLoader(images[index], 128, 100)}
        alt="item-image"
      />
    </AnimatePresence>
  )
}

const loadFeatures = () => import('../../animations/features').then((res) => res.default)

export const ItemRow: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <LazyMotion features={loadFeatures}>
      <div className={styles.ItemRow}>{children}</div>
    </LazyMotion>
  )
}

export const ItemImages: React.FC<React.PropsWithChildren> = ({ children }) => {
  if (typeof children !== 'string') return null
  const images = children.split(',')
  return (
    <div className={styles.ItemImage}>
      <AnimatedImages images={images} />
    </div>
  )
}

export const ItemTitle = ({ children }: { children: React.ReactNode }) => {
  return <p className={styles.Title}>{children}</p>
}

export const ItemDescription = ({ children }: { children: React.ReactNode }) => {
  return <p className={styles.Description}>{children}</p>
}
