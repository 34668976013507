import { z } from 'zod'

export const seasonPassSchema = z.literal('SEASON_PASS')
export const stampCardGameSchema = z.union([z.literal('clashofclans'), z.literal('clashroyale')])
const positiveIntSchema = z.number().int().gte(0)

const stampCardStampEventSchema = z.object({
  eventType: z.literal('STAMP'),
  eventSource: z.union([z.literal('PURCHASE'), z.literal('PANDORA'), z.literal('FREE_STAMP'), z.literal('BACKFILL')]),
  eventSourceReference: z.string(),
  stampGame: stampCardGameSchema,
  stampType: seasonPassSchema,
  stamps: z.number().int(),
  balance: z.number().int(),
  timestamp: positiveIntSchema,
})

const stampCardClaimEventSchema = z.object({
  eventType: z.literal('CLAIM'),
  eventSource: z.union([z.literal('PURCHASE'), z.literal('PANDORA'), z.literal('BACKFILL')]),
  eventSourceReference: z.string(),
  stampGame: stampCardGameSchema,
  stampType: seasonPassSchema,
  stamps: z.number().int(),
  balance: z.number().int(),
  reward: z.string(),
  timestamp: positiveIntSchema,
})

const stampCardRevokeEventSchema = z.object({
  eventType: z.literal('REVOKE'),
  eventSource: z.union([z.literal('PURCHASE'), z.literal('PANDORA'), z.literal('BACKFILL')]),
  eventSourceReference: z.string(),
  stampGame: stampCardGameSchema,
  stampType: seasonPassSchema,
  stamps: z.number().int(),
  balance: z.number().int(),
  timestamp: positiveIntSchema,
})

const stampCardBalanceEventSchema = z.object({
  eventType: z.literal('BALANCE'),
  stampGame: stampCardGameSchema,
  stampType: seasonPassSchema,
  stampsRequiredToClaim: z.number().int(),
  balance: z.number().int(),
  backfilledStamps: z.number().int().default(0),
  backfilledStampsInCurrentCard: z.number().int().default(0),
  cardNumber: z.number().int().default(1),
  timestamp: positiveIntSchema,
})

export const stampCardEventSchema = z.union([
  stampCardStampEventSchema,
  stampCardClaimEventSchema,
  stampCardRevokeEventSchema,
  stampCardBalanceEventSchema,
])
export const stampSchema = z.object({
  game: stampCardGameSchema,
  type: seasonPassSchema,
  amount: z.number().int(),
  timestamp: positiveIntSchema,
})
export const stampTypeSchema = stampSchema.pick({ game: true, type: true })

export type Stamp = z.infer<typeof stampSchema>
export type StampType = z.infer<typeof stampTypeSchema>
export type StampCardEvent = z.infer<typeof stampCardEventSchema>
export type StampCardStampEvent = z.infer<typeof stampCardStampEventSchema>
export type StampCardClaimEvent = z.infer<typeof stampCardClaimEventSchema>
export type StampCardRevokeEvent = z.infer<typeof stampCardRevokeEventSchema>
export type StampCardBalanceEvent = z.infer<typeof stampCardBalanceEventSchema>
export type StampCardNonBalanceEvent = Exclude<StampCardEvent, StampCardBalanceEvent>
