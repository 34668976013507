import { ProductV2 } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import { getHeroFallbackImage } from '@/features/storefronts/_storeBase/card/v2/shared/RegularHero'
import { brawlHyperChargeAsset, useBrawlCharacterImage } from '@/features/storefronts/brawlstars/bundle/helpers'
import { hyperchargeIdToCharacter } from '@/features/storefronts/brawlstars/constants'

import BrawlHyperchargeHeroBg from 'assets/images/storefronts/brawlstars/bundle-hypercharge.png'

import styles from './StoreSpecialHero.module.scss'

type Props = {
  product: ProductV2
  className?: string
}

export const StoreSpecialHero = ({ product, className }: Props) => {
  const heroImage = product.images.at(0) ?? getHeroFallbackImage(product.game)
  const characterIconId = product.contents.find((content) => brawlHyperChargeAsset(content.product.id))?.product.id
  const characterImage = useBrawlCharacterImage(characterIconId)

  if (!heroImage) {
    return null
  }

  const brawlHero = characterIconId ? BrawlHyperchargeHeroBg : product.images[0]
  const characterName = characterIconId && hyperchargeIdToCharacter[characterIconId]

  return (
    <div className={cx(styles.SpecialHeroContainer, className)}>
      <Image src={brawlHero} alt="Offer hero" fill quality={100} sizes="400px" priority />
      {characterImage && (
        <Image
          src={characterImage}
          alt={characterName ?? 'Brawl character'}
          fill
          style={{ objectFit: 'contain' }}
          sizes="200px"
          quality={100}
          className={styles.SpecialHero}
        />
      )}
    </div>
  )
}
