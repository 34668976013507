import { useRef, useState } from 'react'

import {
  FloatingArrow,
  arrow,
  useFloating,
  useHover,
  useInteractions,
  autoPlacement,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  Placement,
  safePolygon,
} from '@floating-ui/react'

import styles from './Tooltip.module.scss'

const GAP = 7

type Props = React.PropsWithChildren<{
  icon: JSX.Element
  show?: boolean
  position?: Placement
}>

export const Tooltip: React.FC<Props> = ({ show, icon, children, position }) => {
  const [showTooltip, setShowTooltip] = useState(show ?? false)
  const tooltipRef = useRef<SVGSVGElement>(null)

  const { refs, floatingStyles, context } = useFloating({
    open: showTooltip,
    onOpenChange: setShowTooltip,
    middleware: [
      autoPlacement({
        allowedPlacements: position ? [position] : undefined,
      }),
      offset(GAP),
      arrow({
        element: tooltipRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, { handleClose: safePolygon() })
  const click = useClick(context)
  const dismiss = useDismiss(context, {
    escapeKey: true,
    outsidePress: true,
    referencePress: true,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss])

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} data-cy="tooltip">
        <div className={styles.Icon}>{icon}</div>
      </div>
      {showTooltip && (
        <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps}>
          <FloatingArrow context={context} ref={tooltipRef} />
          <div className={styles.Content}>{children}</div>
        </div>
      )}
    </>
  )
}
