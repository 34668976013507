import cx from 'classnames'

import styles from './Success.module.scss'
export const SuccessIconAnimated = ({
  height,
  width,
  className,
}: {
  height: number
  width: number
  className?: string | string[]
}) => (
  <div className={cx(styles.Wrapper, className)}>
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <circle
          cx="12"
          cy="12"
          r="8"
          style={{ strokeDasharray: '50.3px, 50.3px', strokeDashoffset: '50.3px' }}
        ></circle>
        <path
          d="M8.5 11.4375C8.72856 12.1003 9.22793 14.6326 10.0046 13.8912C11.8333 12.4792 13.75 10.8125 15.4167 9.5625"
          strokeWidth="1.25"
          strokeLinecap="round"
          style={{ strokeDasharray: '15px, 15px' }}
        />
      </g>
    </svg>
  </div>
)
