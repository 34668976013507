import { createElement, ReactNode } from 'react'

import { GameId } from '@common'
import cx from 'classnames'

import { useLocale } from '@/features/locale/useLocale'
import { useAppSelector } from '@/utils/useAppSelector'

import styles from './storeTypography.module.scss'

type tagTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'

export type textSizes = 'xs' | 'sm' | 'body' | 'md' | 'lg' | 'xl'
export type textStyles = 'plain' | 'outlined'

type StoreTypographyProps = {
  children: ReactNode
  tag: tagTypes
  textSize: textSizes
  style: textStyles
  lineHeight?: 'normal' | 'tight'
  color?: 'grey' | 'green' | 'blue'
  className?: string
  overrideFont?: 'supercellText' | 'supercellTextBold' | 'supercellTextMedium' | 'supercellHeadline'
  onClick?: () => void
}

type StoreTypographyArgs = Pick<
  StoreTypographyProps,
  'textSize' | 'style' | 'lineHeight' | 'color' | 'className' | 'overrideFont'
> & { gameId: GameId; locale: string }

const specialCasedLocales = ['ar', 'fa', 'ja', 'ko', 'ru', 'tr', 'th', 'vi', 'zh-cn', 'zh-tw']

export function getTypographyStyles(args: StoreTypographyArgs) {
  const { textSize, style, lineHeight, color, className, overrideFont, locale, gameId } = args

  const font = overrideFont ? overrideFont : gameId === 'brawlstars' ? 'brawlstars' : 'clashofclans'

  const styleClasses = [
    styles.textBase,
    styles[`font-${font}`],
    styles[`size-${textSize}`],
    styles[style],
    styles[`line-height-${lineHeight}`],
    styles[color ?? ''],
    specialCasedLocales.includes(locale) ? styles[`locale-${locale}`] : '',
    className,
  ]

  return cx(...styleClasses)
}

const getGameFont = (gameId: GameId) => {
  switch (gameId) {
    case 'clashofclans':
      return 'clashofclans'
    case 'clashroyale':
      return 'clashofclans'
    case 'brawlstars':
      return 'brawlstars'
    case 'squadbusters':
      return 'squadbusters'
    default:
      return 'clashofclans'
  }
}

export const StoreTypography = ({
  children,
  tag,
  textSize,
  style,
  lineHeight = 'tight',
  color,
  className: additionalClasses,
  overrideFont,
  onClick,
  ...props
}: StoreTypographyProps) => {
  const gameId = useAppSelector((state) => state.common.currentGame) as GameId
  const locale = useLocale()

  const font = overrideFont ? overrideFont : getGameFont(gameId)

  const styleClasses = [
    styles.textBase,
    styles[tag],
    styles[`font-${font}`],
    styles[`size-${textSize}`],
    styles[style],
    styles[`line-height-${lineHeight}`],
    styles[color ?? ''],
    specialCasedLocales.includes(locale) ? styles[`locale-${locale}`] : '',
    additionalClasses,
  ]

  const textStyles = cx(...styleClasses)

  return createElement(tag, { className: textStyles, onClick, ...props }, children)
}
