import { GameId } from '@common'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useGameImages } from '@/components/games/images'
import { Cross } from '@/components/Icons'
import { useAppSelector } from '@/utils/useAppSelector'

import { useModalContext } from '../../../../contexts/ModalContext'
import { getShortSlugNotHD } from '../../../../fixtures/games'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusInfo.module.scss'

export const BonusInfo = () => {
  const t = useTranslations()
  const gameSlug = useAppSelector((state) => state.common.currentGame) as GameId
  const { gameImage } = useGameImages(gameSlug)
  const { closeModal } = useModalContext()
  const shortSlug = getShortSlugNotHD(gameSlug)

  return (
    <div className={styles.bonusInfo}>
      <div className={styles.closeOverlay} onClick={closeModal}>
        <Cross />
      </div>
      <div className={styles.bonusInfoHero}>
        <div className={styles.logoContainer}>
          <Image src={gameImage('logo')} alt="bonus info" fill style={{ objectFit: 'contain' }} />
        </div>
        <Image src={gameImage('heroMobile')} alt="bonus info" fill style={{ objectFit: 'cover' }} />
      </div>
      <div className={styles.bonusText}>
        <StoreTypography tag="p" style="plain" textSize="body" overrideFont="supercellText" lineHeight="normal">
          {t(`${shortSlug}_bonus_info_general`)}
        </StoreTypography>
        <StoreTypography tag="p" style="plain" textSize="body" overrideFont="supercellText" lineHeight="normal">
          {t('bonus_info_extra_points_missions')}
        </StoreTypography>
        <StoreTypography tag="p" style="plain" textSize="body" overrideFont="supercellText" lineHeight="normal">
          {t('bonus_info_earn_more')}
        </StoreTypography>
      </div>
    </div>
  )
}
