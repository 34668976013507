import { StoreBonusStatusResponse } from '@common/models/bonus'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import ChallengesKing from '@/assets/images/storefronts/clashroyale/challenges-king.png'
import ChallengesRibbon from '@/assets/images/storefronts/clashroyale/challenges-ribbon.png'
import { BONUS_TRACK_DIVIDER_BG, STORE_BG } from '@/features/storefronts/_storeBase/constants'
import { TimeRemaining } from '@/features/storefronts/_storeBase/timeRemaining/TimeRemaining'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { getTimeRemaining } from '@/utils/timer'
import { useAppSelector } from '@/utils/useAppSelector'

import { useGameImages } from '../../../../components/games/images'
import { BonusChallengeCR } from '../bonusChallenge/BonusChallengeCR'
import { BonusChallengeUnavailable } from '../bonusChallenge/BonusChallengeUnavailable'
import { transformMissionsData } from '../bonusChallenge/isCurrentSeason'

import styles from './bonusPointsCR.module.scss'

interface BonusPointsProps {
  bonusStatus: StoreBonusStatusResponse
}

//TODO: use timezone offset from browser as fallback?
const FALLBACK_TIMEZONE = 'UTC'

export const BonusPointsCR = ({ bonusStatus }: BonusPointsProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const profileTimezone = useAppSelector(
    (state) => state.user.profile?.applications?.find((app) => app.application === 'clashroyale')?.timezone,
  )
  const { gameImage } = useGameImages('clashroyale')

  const timezone = profileTimezone || FALLBACK_TIMEZONE
  const missionData = transformMissionsData(bonusStatus, timezone, new Date())
  const remainingTime = getTimeRemaining(missionData.currentCadenceEnd)

  return (
    <div className={styles.container}>
      <div className={styles.background} style={{ backgroundImage: STORE_BG(gameSlug) }} />
      <div className={styles.contentContainer}>
        <div className={styles.dividerContainer}>
          <div className={styles.divider} style={{ backgroundImage: BONUS_TRACK_DIVIDER_BG(gameSlug) }} />
          <div className={styles.dividerImageContainer}>
            <div className={styles.dividerImageWrapper}>
              <Image src={gameImage('badge')} alt={gameSlug} height={50} quality={100} />
            </div>
          </div>
        </div>
        <div className={styles.challengesContainerCard}>
          <div className={styles.receiptContent}>
            <div className={styles.receiptCheckMarkContainer}>
              <div className={styles.challengesHeaderImg}>
                <Image src={ChallengesKing} alt={gameSlug} fill={true} />
              </div>
            </div>
            <div className={styles.receiptRibbonContainer}>
              <Image src={ChallengesRibbon} alt={gameSlug} fill={true} />

              <div className={styles.ribbonTimeLabels}>
                <StoreTypography tag="span" textSize="body" style="outlined">
                  {t('bonus_time_left')}
                </StoreTypography>
                <TimeRemaining timeLeftText={remainingTime} type="secondary" textStyle="outlined" />
              </div>
            </div>
            {missionData.missions.length > 0 ? (
              <>
                <div className={styles.descriptionContainer}>
                  <span>{t('bonus_earn_daily_tasks')}</span>
                </div>
                <div className={styles.challengesContainer}>
                  {missionData.missions.map((mission, index) => (
                    <BonusChallengeCR key={index} mission={mission} />
                  ))}
                </div>
                <div className={styles.infoContainer}>
                  <span className={styles.infoCircle}>!</span>
                  <span>{t('bonus_time_appear')}</span>
                </div>
              </>
            ) : (
              <div className={styles.noCompletedChallenges}>
                <BonusChallengeUnavailable />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
