import { GameId, gameIds } from '@common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { HydrateAction, hydrateMatcher } from '@/hydrate'
import { RootState } from '@/store'

export interface ConfigState {
  features: {
    enableComingSoon: boolean
  }
  viewerCountry: string | null
  storefront: boolean
  inMaintenance: Record<GameId, boolean>
}

export interface FeatureFlagsConfig {
  mockCountry?: string
  enableComingSoon?: boolean
}

const initialState: ConfigState = {
  viewerCountry: null,
  features: {
    enableComingSoon: false,
  },
  storefront: false,
  inMaintenance: gameIds.reduce(
    (acc, gameId) => {
      acc[gameId] = false
      return acc
    },
    {} as Record<GameId, boolean>,
  ),
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setViewerCountry: (state: ConfigState, action: PayloadAction<string | null>) => {
      const viewerCountry = action.payload
      state.viewerCountry = viewerCountry ?? null
    },
    setFeatureFlags: (state: ConfigState, action: PayloadAction<FeatureFlagsConfig>) => {
      if (action.payload.enableComingSoon) {
        state.features.enableComingSoon = true
      }
    },
    setStorefront: (state: ConfigState, action: PayloadAction<boolean>) => {
      state.storefront = action.payload
    },
    setMaintenance: (state: ConfigState, action: PayloadAction<{ gameId: GameId; inMaintenance: boolean }>) => {
      state.inMaintenance[action.payload.gameId] = action.payload.inMaintenance
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher<HydrateAction<Partial<RootState>>>(hydrateMatcher, (state, action) => {
      const configState = action.payload?.config
      if (configState?.viewerCountry) {
        state.viewerCountry = configState.viewerCountry
      }

      return state
    })
  },
})

export const { setViewerCountry, setFeatureFlags, setStorefront, setMaintenance } = configSlice.actions

export default configSlice.reducer
