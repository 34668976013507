import { SupportedLanguage } from '@common'

import { formatAmount } from '../features/storefronts/_storeBase/card/utils'

export function formatNumbersInString(str: string, locale: SupportedLanguage, shouldTryFormatting: boolean) {
  if (!shouldTryFormatting) {
    return str
  }

  return str
    .split(/\s+/)
    .map((word) => {
      const maybeNumber = parseInt(word, 10)
      if (!isNaN(maybeNumber)) {
        return formatAmount(maybeNumber, locale)
      }
      return word
    })
    .join(' ')
}
