import crypto from 'crypto'
import { SCIDChronosOffer, SCIDChronosOfferProduct } from '../models/offer'
import {
  BillingPackages,
  Offer,
  Prices,
  ProductAvailability,
  ProductContent,
  ProductSchedule,
  ProductV2,
  SCIDProduct,
  StoreOfferProduct,
  BrawlStarsChainOffer,
} from '../models/product'
import { SCIDTranslatable, Translatable } from '../models/translatable'
import { extractApplication } from './extractApplication'
import { calculateBonusPointsForProduct } from './currencies'
import { SCIDMission, SCIDRewardItem } from '../services/scid/types'
import { StoreMissions, StoreRewardItem } from '../models/bonus'
import { PRODUCT_CONFIGURATION } from '../models/imported/productConfiguration'
import { isDefined } from './isDefined'
import { StoreError, StoreErrorMessage } from './StoreError'
import { GameId } from '../models/game'
import {
  STORE_CATEGORY_GEM,
  STORE_SPECIAL,
  STORE_BONUS_BANNER_VARIANT_RE,
  STORE_SCENERY,
  STORE_SKIN,
} from './chronosTags'

const getChronosCatalogOfferImage = (gameId: GameId) =>
  `https://store.supercell.com/images/${gameId}/product-icon-default.png`

export const isStoreOfferProduct = (product: ProductV2): product is StoreOfferProduct => {
  return product.type === 'offer'
}

const isSeasonPassProduct = (product?: ProductV2 | SCIDChronosOfferProduct | null) => {
  if (!product) {
    return false
  }

  const seasonPassContentCount = product.contentCounts.SEASON_PASS ?? 0

  return seasonPassContentCount > 0
}

export const hasReachedPurchaseQuota = (product: ProductV2) => {
  if (!isStoreOfferProduct(product)) {
    return false
  }

  if (product.quotaConsumed === undefined || product.quotaLimit === undefined) {
    return false
  }

  return product.quotaConsumed >= product.quotaLimit
}

// Used in frontend to get nice short unique paths for products
export function createShake256Hash(data: string, len: number) {
  return crypto.createHash('shake256', { outputLength: len }).update(data).digest('hex')
}

// Used in backend to create image hash for products
export function createMD5Hash(data: string) {
  return crypto.createHash('md5').update(data).digest('hex')
}

export const mapPurchaseRowsToProducts = (
  rows: { sku: string; quantity: number }[],
  productData: { [key: string]: ProductV2 },
) => {
  return rows.map((row) => {
    const product = productData[row.sku]
    if (!product) {
      throw StoreError.withMessage(StoreErrorMessage.PRODUCT_NOT_FOUND)
    }
    return {
      product,
      quantity: row.quantity,
    }
  })
}

const resolveAvailability = (availability?: boolean): ProductAvailability => {
  switch (availability) {
    case true: {
      return ProductAvailability.AVAILABLE
    }
    case false: {
      return ProductAvailability.UNAVAILABLE
    }
    case undefined: {
      return ProductAvailability.UNKNOWN
    }
  }
}

const createProductSlug = (game: string, title: string, hash: string) => {
  return `${game}-${title.replace(/[^\w]/gi, '-')}/${hash}`.toLowerCase()
}

const checkForSupportedLanguageKey = (lang: string) => {
  if (lang === 'jp') {
    return 'ja'
  }
  if (lang === 'se') {
    return 'sv'
  }
  if (lang === 'kr') {
    return 'ko'
  }
  if (lang === 'cn') {
    return 'zh-cn'
  }
  if (lang === 'ch') {
    return 'zh-cn'
  }
  if (lang === 'cht') {
    return 'zh-tw'
  }
  if (lang === 'cnt') {
    return 'zh-tw'
  }

  return lang
}
const translationsWithFallback = <T extends string>(
  base: SCIDTranslatable<T>,
  fallback?: SCIDTranslatable<T> | null,
): SCIDTranslatable<T> => {
  return Object.entries(fallback ?? {}).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: acc[key] || value, // Can't use ?? here because the value might be an empty string
    }
  }, base)
}

const lowercaseTranslatable = <T extends string>(t: SCIDTranslatable<T>): Translatable<T> =>
  Object.fromEntries(Object.entries(t).map(([key, value]) => [checkForSupportedLanguageKey(key.toLowerCase()), value]))

export const scidProductsToStoreProducts = (
  scidProducts: SCIDProduct[],
  stage: string,
  rewardsEnabled: boolean,
): ProductV2[] => {
  const products = (scidProducts || [])
    .map((product) => convertSCIDProductToStoreProduct(stage, rewardsEnabled, product, PRODUCT_CONFIGURATION))
    .filter(isDefined)

  return products
}

export const convertSCIDProductToStoreProduct = (
  stage: string,
  rewardsEnabled: boolean,
  product: SCIDProduct,
  productConfiguration: BillingPackages,
): ProductV2 | null => {
  // TODO: Fix
  const configuration = productConfiguration[product.billingPackageId || product.id]

  // Ensure configuration exists
  if (!configuration) {
    return null
  }

  // Check if product is enabled in non-production environments
  if (stage !== 'production' && configuration.isNonProduction === false) {
    return null
  }

  // Check if product is enabled in production environments
  if (stage === 'production' && !configuration.isProduction) {
    return null
  }

  return convertSCIDProductToStoreProductWithPrices(product, configuration.price, configuration.offer, rewardsEnabled)
}

const convertSCIDProductContentsToStoreProductContents = (
  contents?: SCIDChronosOfferProduct['contents'] | SCIDProduct['contents'],
): ProductContent[] => {
  if (!contents) {
    return []
  }

  return contents.map((content) => {
    // We want the global id to always be first in the colon seperated tuple
    const [id, itemType] = content.product.id.split(':').reverse()

    return {
      product: {
        id,
        title: content.product.title && lowercaseTranslatable(content.product.title),
        description: content.product.description && lowercaseTranslatable(content.product.description),
        type: content.product.type,
        itemType: itemType ?? null,
        tier: content.product.tier,
      },
      amount: content.amount ?? null,
    }
  })
}

export const convertSCIDProductToStoreProductWithPrices = (
  product: SCIDProduct,
  prices: Prices,
  offer: Offer | null,
  rewardsEnabled: boolean,
): ProductV2 | null => {
  // Product application is of format "application-env"
  const gameId = extractApplication(product.application)

  // Ensure that prices are found
  if (!prices || !gameId) {
    return null
  }

  if (offer?.expiresAt && Date.now() > offer?.expiresAt) {
    return null
  }

  const hash = createShake256Hash(product.id, 4)
  const title = lowercaseTranslatable(product.title)
  const slugTitle = title.en ?? product.id
  const priority = offer?.priority ?? product.priority ?? 0

  const bonus = rewardsEnabled ? calculateBonusPointsForProduct(product, prices) : undefined

  return {
    type: 'product' as const,
    id: product.id,
    title,
    prices,
    description: lowercaseTranslatable(product.description),
    game: gameId,
    images: product.images,
    contentCounts: product.contentCounts,
    contents: convertSCIDProductContentsToStoreProductContents(product.contents),
    availability: resolveAvailability(product.available),
    slug: createProductSlug(gameId, slugTitle, hash),
    hash: hash,
    priority,
    offerData: offer ?? null,
    bonus: bonus?.totalBonus,
    bonusData: bonus,
  }
}

export const getBonusPointBannerVariant = (product: ProductV2) => {
  if (!isStoreOfferProduct(product)) {
    return undefined
  }

  if (!product.tags || product.tags.length === 0) {
    return undefined
  }

  for (const tag of product.tags) {
    const match = tag.match(STORE_BONUS_BANNER_VARIANT_RE)
    if (match) {
      return match[1]
    }
  }

  return undefined
}

export const isGemPackOffer = (product: ProductV2) => {
  if (isStoreOfferProduct(product)) {
    return product.tags !== undefined && product.tags.includes(STORE_CATEGORY_GEM)
  }

  return false
}

export function isSquadCoinProduct(product: ProductV2) {
  return product.game === 'squadbusters' && product.contentCounts.GEM !== undefined
}

export const isGemPackProduct = (product: SCIDChronosOfferProduct | ProductV2) => {
  const contentCountEntries = Object.entries(product.contentCounts).map((entry) => ({
    type: entry[0],
    amount: entry[1],
  }))
  const nonGemEntries = contentCountEntries.filter((entry) => entry.type !== 'GEM')
  const hasGems = product.contentCounts.GEM !== undefined && product.contentCounts.GEM > 0
  const hasNothingElse = nonGemEntries.every((entry) => entry.amount === undefined || entry.amount === 0)
  return hasGems && hasNothingElse
}

export const isBlingProduct = (product: ProductV2) =>
  product.contents.length === 1 && product.contents[0].product.id === '5000023'

export const isCreditProduct = (product: ProductV2) =>
  product.contents.length === 1 && product.contents[0].product.id === '5000019'

const getChronosOfferImages = (product: SCIDChronosOfferProduct, gameId: GameId) => {
  const images = product.images
  const tags = product.tags

  const isStoreSpecial = tags?.includes(STORE_SPECIAL)
  const isGemPack = tags?.includes(STORE_CATEGORY_GEM)
  const isSeasonPass = isSeasonPassProduct(product)
  const isCosmetic = tags.includes(STORE_SKIN) || tags.includes(STORE_SCENERY)

  if (!isStoreSpecial && !isGemPack && !isSeasonPass && !isCosmetic) {
    //
    return [getChronosCatalogOfferImage(gameId)]
  }

  return images
}

const getBrawlChainOfferInfoFromTag = (tags: string[]) =>
  tags.reduce<BrawlStarsChainOffer | null>((parsed, unparsed) => {
    if (parsed) {
      return parsed
    }

    const match = unparsed.match(/^laser:chain:(.*):(\d*)$/)
    const chain = match?.[1]
    const indexStr = match?.[2]
    const index = indexStr ? parseInt(indexStr, 10) : undefined

    if (!chain || !index || isNaN(index)) {
      return null
    }

    return {
      chain,
      index,
    }
  }, null)

const getBrawlPriorityFromTag = (tags: string[]) => {
  const orderNumberTag = tags.find((tag) => tag.startsWith('laser:orderNumber:'))

  return Number(orderNumberTag?.split(':')?.[2]) || 0
}

export const convertSCIDChronosOfferProductToStoreProductWithPrices = (
  chronosOffer: SCIDChronosOffer,
  product: SCIDChronosOfferProduct,
  prices: Prices,
  offer: Offer | null,
  schedule: ProductSchedule | null,
  includeBonus: boolean,
  rewardsEnabled: boolean,
): ProductV2 | null => {
  // Product application is of format "application-env"
  const gameId = extractApplication(product.application)

  // Ensure that prices are found
  if (!prices || !gameId) {
    return null
  }

  const bonusData = includeBonus && rewardsEnabled ? calculateBonusPointsForProduct(product, prices) : undefined

  return {
    type: 'offer' as const,
    id: product.id,
    title: lowercaseTranslatable(translationsWithFallback(product.title, chronosOffer.title)),
    prices,
    description: lowercaseTranslatable(translationsWithFallback(product.description, chronosOffer.description)),
    game: gameId,
    images: getChronosOfferImages(product, gameId),
    contentCounts: product.contentCounts,
    availability: resolveAvailability(product.available),
    slug: product.slug,
    hash: createShake256Hash(product.id, 4),
    priority: Math.max(getBrawlPriorityFromTag(product.tags), offer?.priority ?? product.priority ?? 0),
    offerData: offer ?? null,
    schedule,
    tags: product.tags,
    contents: convertSCIDProductContentsToStoreProductContents(product?.contents),
    quotaConsumed: product.quotaConsumed,
    ...(product.quotaLimit === 0 ? {} : { quotaLimit: product.quotaLimit }),
    bonus: bonusData?.totalBonus,
    bonusData,
    brawlStarsChainOffer: gameId === 'brawlstars' ? getBrawlChainOfferInfoFromTag(product.tags) : null,
  }
}

export function scidRewardItemToStoreBonusItem(item: SCIDRewardItem): StoreRewardItem {
  return {
    id: item.id,
    title: lowercaseTranslatable(item.title),
    description: lowercaseTranslatable(item.description),
    gameId: extractApplication(item.application),
    image: item.image,
    cost: item.cost,
    available: item.available,
    claimed: item.claimed,
    seasonId: item.seasonId,
  }
}

export function scidMissionsToStoreMissions(missions: SCIDMission[]): StoreMissions | null {
  if (missions.length === 0) {
    return null
  }
  const endTimeMillis = missions[0].seasonEndTime
  const storeMissions = missions.map((mission) => {
    return {
      missionId: mission.missionId,
      current: mission.current,
      target: mission.target,
      rewardPoints: mission.rewardPoints,
    }
  })
  return {
    endTimeMillis,
    missions: storeMissions,
  }
}
