import { ProductV2 } from '@common/models/product'
import cx from 'classnames'

import { getBonusVariant, hasOutline, TagVariant } from '@/features/storefronts/_storeBase/card/v2/shared/Tags/common'

import { PointsTag } from './components/PointsTag'

import styles from './Tags.module.scss'

type Props = {
  product: ProductV2
  variant?: TagVariant
}

export const Tags = ({ product, variant }: Props) => {
  const { bonusData, game } = product

  if (!bonusData) {
    return null
  }

  const outline = hasOutline(game)
  const bonusVariant = getBonusVariant(product)
  // const idBonusVariant = getIdRewardVariant(product.game)

  return (
    <div className={cx(styles.TagsContainer, styles[game])}>
      {/*<PointsTag*/}
      {/*  points={100}*/}
      {/*  originalPoints={50}*/}
      {/*  tagIcon={'IdReward'}*/}
      {/*  variant={idBonusVariant}*/}
      {/*  postfix={'pts'}*/}
      {/*  skew={true}*/}
      {/*/>*/}
      <PointsTag
        originalPoints={bonusData.baseBonus}
        points={bonusData.totalBonus}
        variant={variant ?? bonusVariant}
        outline={outline}
      />
    </div>
  )
}
