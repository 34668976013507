import { ProductV2 } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import { getHeroFallbackImage } from '@/features/storefronts/_storeBase/card/v2/shared/RegularHero'

import styles from './RegularHero.module.scss'

type Props = {
  product: ProductV2
  className?: string
}

export const RegularHero = ({ product, className }: Props) => {
  const heroImage = product.images.at(0) ?? getHeroFallbackImage(product.game)

  if (!heroImage) {
    return null
  }

  return (
    <div className={cx(styles.RegularHero, styles[product.game], className)}>
      <Image src={heroImage} alt="Test Hero" width={343} height={184} />
    </div>
  )
}
