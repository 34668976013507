import z from 'zod'

export const languageSchema = z.union([
  z.literal('de'),
  z.literal('fi'),
  z.literal('fr'),
  z.literal('it'),
  z.literal('en'),
  z.literal('es'),
  z.literal('nl'),
  z.literal('pt'),
  z.literal('ja'),
  z.literal('zh-cn'),
  z.literal('zh-tw'),
  z.literal('da'),
  z.literal('ko'),
  z.literal('ms'),
  z.literal('no'),
  z.literal('pl'),
  z.literal('ru'),
  z.literal('sv'),
  z.literal('th'),
  z.literal('tr'),
  z.literal('vi'),
  z.literal('id'),
  z.literal('ar'),
  z.literal('fa'),
  z.literal('he'),
])
export const translationObjectSchema = z.record(languageSchema, z.string())
export const taxTypeSchema = z.union([z.literal('VAT'), z.literal('SALES_TAX')])
export const countryCodeSchema = z.string().length(2).brand<'CountryCode'>()
export const currencyCodeSchema = z.string().length(3).brand<'CurrencyCode'>()
export const currencyCountrySchema = z.string().length(6).brand<'CurrencyCountry'>()
export const paymentGatewaySchema = z.union([
  z.literal('ADYEN'),
  z.literal('XSOLLA'),
  z.literal('FASTSPRING'),
  z.literal('PADDLE'),
  z.literal('NEON'),
  z.literal('RAZER_GOLD'),
])

export type Language = z.infer<typeof languageSchema>
export type TranslationObject = z.infer<typeof translationObjectSchema>
export type TaxType = z.infer<typeof taxTypeSchema>
export type CountryCode = z.infer<typeof countryCodeSchema>
export type CurrencyCode = z.infer<typeof currencyCodeSchema>
export type CurrencyCountry = z.infer<typeof currencyCountrySchema>
export type PaymentGateway = z.infer<typeof paymentGatewaySchema>

export const DEFAULT_CURRENCY_CODE = currencyCodeSchema.parse('USD')
export const DEFAULT_COUNTRY_CODE = countryCodeSchema.parse('US')
export const DEFAULT_CURRENCY_COUNTRY = currencyCountrySchema.parse(`${DEFAULT_CURRENCY_CODE}-${DEFAULT_COUNTRY_CODE}`)
export const DEFAULT_TAX_TYPE = taxTypeSchema.parse('VAT')
