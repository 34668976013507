import { StoreRewardItem } from '@common/models/bonus'
import cx from 'classnames'
import { getImageProps } from 'next/image'
import { useTranslations } from 'next-intl'

import BrawlFlare from '@/assets/images/storefronts/brawlstars/rays.png'
import { Button } from '@/components/buttons/Button'
import { useModalContext } from '@/contexts/ModalContext'
import { StorefrontModal } from '@/features/storefronts/_storeBase/modal/StorefrontModal'
import { setUnclaimedBonusesCookie } from '@/utils/cookies'

import { CloseBonusTrackIcon } from '../../bonusStatusFooter/CloseBonusTrackIcon'
import { getBackgroundImage } from '../../imageWithFallback/imageUtils'
import { StoreTypography } from '../../typography/StoreTypography'
import { useBonus } from '../BonusContextProvider'
import { BonusItemImage } from '../shared/BonusItemImage'

import styles from './unclaimedBonusModal.module.scss'

export type UnclaimedBonusModalProps = {
  type: 'UNCLAIMED_BONUS'
  props: Props
}

type Props = {
  unclaimedItems: StoreRewardItem[]
}

export const UnclaimedBonusModal = ({ unclaimedItems }: Props) => {
  const t = useTranslations()
  const { closeModal } = useModalContext()
  const { setIsOpen } = useBonus()

  const unclaimedBonusesText =
    unclaimedItems.length > 1 ? t('n_unclaimed_bonuses', { n: unclaimedItems.length }) : t('one_unclaimed_bonus')

  const {
    props: { srcSet },
  } = getImageProps({ alt: '', width: 400, height: 400, src: BrawlFlare })
  const backgroundImage = getBackgroundImage(srcSet)

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault()
    setUnclaimedBonusesCookie()
    closeModal()
  }

  const onClick = (e: React.MouseEvent) => {
    handleClose(e)
    setIsOpen(true)
  }

  return (
    <StorefrontModal
      position="Middle"
      data-cy="notify-modal"
      onClose={() => {
        setUnclaimedBonusesCookie()
        closeModal()
      }}
      closeOnClickOutside
      className={styles.unclaimedBonusModal}
    >
      <div className={styles.unclaimedBonusContainer}>
        <div className={styles.closeBtn} onClick={(e) => handleClose(e)}>
          <CloseBonusTrackIcon />
        </div>

        <div className={styles.flareWrapper}>
          <div className={styles.flareContainer} style={{ backgroundImage: backgroundImage }} />
        </div>

        <div className={styles.unclaimedBonusContent}>
          <StoreTypography
            tag="h3"
            textSize="lg"
            overrideFont="supercellHeadline"
            style="plain"
            lineHeight="normal"
            className={cx(styles.unclaimedText, { [styles.single]: unclaimedItems.length === 1 })}
          >
            {unclaimedBonusesText}
          </StoreTypography>

          <div className={styles.itemsWrapper}>
            {unclaimedItems.slice(0, 3).map((item) => (
              <div className={styles.unclaimedItem} key={item.id}>
                <BonusItemImage item={item} status="unclaimed" />
              </div>
            ))}
          </div>

          <Button type="Primary" onClick={onClick} data-type="bonus-track-btn" additionalClasses={styles.bonusTrackBtn}>
            <StoreTypography
              tag="span"
              style="plain"
              textSize="sm"
              overrideFont="supercellHeadline"
              className={styles.btnText}
            >
              {t('view_bonus_track')}
            </StoreTypography>
          </Button>
        </div>
      </div>
    </StorefrontModal>
  )
}
