import * as React from 'react'

import { calculatePricing, formatPrice, GameId } from '@common'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { LinkButton } from '@/components/buttons/Button'
import { ArrowRight } from '@/components/Icons'
import { useMarketContext } from '@/contexts/MarketContext'
import { CreatorCode } from '@/features/checkout/CreatorCode'
import { useLocale } from '@/features/locale/useLocale'
import { CartItem } from '@/utils/cart'

import { CartProducts } from './CartProducts'
import { EmptyCart } from './EmptyCart'
import { HelpShift } from './HelpShift'
import { snowplowTrackCheckout } from '../../../../utils/snowplow'

import styles from './CartContent.module.scss'

type Props = {
  game: GameId
  items: CartItem[]
  isIneligible: boolean
}

export function CartContent({ game, items, isIneligible }: Props) {
  const [loadingCheckout, setLoadingCheckout] = React.useState(false)
  const [loadingCreatorCode, setIsLoadingCreatorCode] = React.useState(false)

  const router = useRouter()
  const resetLoadingCheckout = React.useCallback(() => setLoadingCheckout(false), [setLoadingCheckout])
  React.useEffect(() => {
    router.events.on('routeChangeComplete', resetLoadingCheckout)
    return () => router.events.off('routeChangeComplete', resetLoadingCheckout)
  }, [router, resetLoadingCheckout])

  const t = useTranslations()
  const locale = useLocale()
  const market = useMarketContext('CartContent')

  const isEmpty = items.length === 0

  if (isEmpty) {
    return <EmptyCart game={game} />
  }

  const total = calculatePricing(
    items.map(({ product: { id: sku, prices, game }, quantity }) => ({ sku, prices, quantity, game })),
    market.currencyCountry,
    market.currencyCode,
    { type: market.taxType ?? 'NONE', percentage: market.taxRate ?? 0 },
  ).total

  return (
    <div className={styles.CartContent}>
      <div className={styles.CartListContainer}>
        <div className={styles.CartList} data-cy="cart-list">
          <CartProducts items={items} className={styles.ProductRow} game={game} />
        </div>
      </div>
      <div className={styles.CartFooter}>
        <div className={styles.CheckoutArea}>
          <div className={styles.Totals}>
            <p className={styles.CartTotalTitle}>
              {market.taxType === 'SALES_TAX' ? t('checkout_cart_sub') : t('summary_total')}
            </p>
            <p data-cy="cart-total" className={styles.CartTotalAmount}>
              {total ? formatPrice(total, market, locale) : '-'}
            </p>
          </div>
          <LinkButton
            disabled={isIneligible || loadingCheckout || loadingCreatorCode}
            onClick={() => {
              snowplowTrackCheckout()
              setLoadingCheckout(true)
            }}
            additionalClasses={styles.CheckoutButton}
            data-cy="cart-button-checkout"
            href={`/${game}/checkout`}
            loading={!isIneligible && loadingCheckout}
          >
            <div className={styles.CheckoutButtonContent}>
              <ArrowRight /> {t('checkout_checkout')}
            </div>
          </LinkButton>
        </div>
        <div className={styles.BottomContainer}>
          <CreatorCode gameId={game} setIsLoading={setIsLoadingCreatorCode} />
          <HelpShift />
        </div>
      </div>
    </div>
  )
}
