import { useState, useEffect } from 'react'

export function usePreviousWithDelayedReset<T>(value: T, resetTimeout: number) {
  const [current, setCurrent] = useState(value)
  const [previous, setPrevious] = useState(value)

  if (value !== current) {
    setPrevious(current)
    setCurrent(value)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPrevious(value)
    }, resetTimeout)

    return () => clearTimeout(timeout)
  }, [value, setPrevious, resetTimeout])

  return previous
}
