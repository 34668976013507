import cx from 'classnames'

import { CloseBonusTrackIcon } from './CloseBonusTrackIcon'
import { OpenBonusTrackIcon } from './OpenBonusTrackIcon'

import styles from './drawerButton.module.scss'

interface DrawerButtonProps {
  isOpen?: boolean
  onClick?: React.MouseEventHandler
}

export const DrawerButton = ({ isOpen, onClick }: DrawerButtonProps) => {
  return (
    <button className={cx(styles.drawerBtn, { [styles.open]: isOpen })} onClick={(e) => onClick?.(e)}>
      {isOpen ? <CloseBonusTrackIcon /> : <OpenBonusTrackIcon />}
    </button>
  )
}
