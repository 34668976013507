import * as React from 'react'

import { joins } from '@common'

type MarketContextValue = joins.Market

const MarketContext = React.createContext<MarketContextValue | null>(null)

export function useMarketContext(caller: string) {
  const context = React.useContext(MarketContext)
  if (!context) {
    throw new Error(`${caller}: useMarketContext must be used within a marketProvider`)
  }
  return context
}

type Props = React.PropsWithChildren<{
  market: joins.Market
}>
export function MarketContextProvider({ market, children }: Props) {
  return <MarketContext.Provider value={market}>{children}</MarketContext.Provider>
}
