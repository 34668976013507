import type { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import { GetItemCommand, PutItemCommand } from '@aws-sdk/client-dynamodb'

import * as dynamoDbConfig from './config'
import { StoreError, StoreErrorMessage } from '../../utils/StoreError'

import { MetricsClient } from '../cloudwatch/metrics'
import { GameId } from '../../models/game'
import { ddbCartSchema, DDBCartItem } from '../../models/cart'
import { createCart } from '../../utils/cart'

const { TABLE_NAME, tablePartitionKey, PARTITION_KEY, SORT_KEY } = dynamoDbConfig

const CART_PREFIX = 'CART' as const
const cartSortKey = (gameId: GameId) => `${CART_PREFIX}#${gameId}` as const

export function createCartClient(dynamoDbClient: DynamoDBClient, metricsClient: MetricsClient) {
  return {
    getCart: metricsClient.withDurationMetrics('Cart_GetCart', getCart(dynamoDbClient)),
    updateCart: metricsClient.withDurationMetrics('Cart_UpdateCart', updateCart(dynamoDbClient)),
  }
}

function getCart(dynamoDbClient: DynamoDBClient) {
  return async (accountId: string | undefined, gameId: GameId) => {
    if (!accountId) {
      return createCart(gameId, [])
    }

    const command = new GetItemCommand({
      TableName: TABLE_NAME,
      Key: {
        [PARTITION_KEY]: { S: tablePartitionKey(accountId) },
        [SORT_KEY]: { S: cartSortKey(gameId) },
      },
    })

    try {
      const response = await dynamoDbClient.send(command)
      const item = response.Item

      if (!item) {
        return createCart(gameId, [])
      }

      return ddbCartSchema.parse({
        gameId: item.gameId?.S,
        items: JSON.parse(item.items?.S ?? ''),
      })
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_CART_GET_FAILED, err)
    }
  }
}

function updateCart(dynamoDbClient: DynamoDBClient) {
  return async (accountId: string, gameId: GameId, items: DDBCartItem[]) => {
    const storableItems = items.filter((item) => item.quantity > 0)
    const command = new PutItemCommand({
      TableName: TABLE_NAME,
      Item: {
        [PARTITION_KEY]: { S: tablePartitionKey(accountId) },
        [SORT_KEY]: { S: cartSortKey(gameId) },
        gameId: { S: gameId },
        items: { S: JSON.stringify(storableItems) },
        timestamp: { N: Date.now().toString() },
      },
    })

    try {
      await dynamoDbClient.send(command)
      return createCart(gameId, storableItems)
    } catch (err) {
      throw StoreError.withMessage(StoreErrorMessage.DB_CART_UPDATE_FAILED, err)
    }
  }
}
