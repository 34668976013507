import cx from 'classnames'

import { StoreTypography } from '../../../../../../typography/StoreTypography'

import styles from './SquadBustersQuantity.module.scss'

type Props = {
  quantity: string
  size: 'sm' | 'lg'
}

export function SquadBustersQuantity({ quantity, size }: Props) {
  return (
    <div className={styles.Container}>
      <div className={cx(styles.Pill, styles[size])}>
        <StoreTypography textSize="body" tag="span" style="plain" className={styles.Text}>
          x{quantity}
        </StoreTypography>
      </div>
    </div>
  )
}
