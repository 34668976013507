import { range } from '@common'
import { StoreMission } from '@common/models/bonus'
import Image, { StaticImageData } from 'next/image'
import { useTranslations } from 'next-intl'

import CheckMarkEmpty from '@/assets/images/storefronts/clashofclans/bonus-item-checkmark-empty.png'
import CheckMark from '@/assets/images/storefronts/clashofclans/bonus-item-checkmark.png'
import CompleteHomeVillage from '@/assets/images/storefronts/clashofclans/challenge-complete-home-village.png'
import DestroyTownhalls from '@/assets/images/storefronts/clashofclans/challenge-destroy-townhalls.png'
import LogIn from '@/assets/images/storefronts/clashofclans/challenge-log-in.png'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusChallenge.module.scss'

interface BonusChallengeProps {
  mission: StoreMission
}

type Missions = {
  [key: string]: {
    image: StaticImageData
    title: string
  }
}

export const BonusChallenge = ({ mission }: BonusChallengeProps) => {
  const t = useTranslations()

  const supportedMissions: Missions = {
    daily_logins: {
      image: LogIn,
      title: t('magic_mission_title_daily_logins', { target: mission.target }),
    },
    destroy_townhalls: {
      image: DestroyTownhalls,
      title: t('magic_mission_title_destroy_townhalls', { target: mission.target }),
    },
    star_bonus_delivered: {
      image: CompleteHomeVillage,
      title: t('magic_mission_title_star_bonus_delivered'),
    },
  }

  if (!mission) {
    return null
  }

  const missionData = supportedMissions[mission?.missionId]

  if (!missionData) {
    return null
  }

  const title = missionData.title
  const pointsText = t('bonus_number_of_points').replace('[n]', mission.rewardPoints.toString())

  return (
    <div className={styles.challengeContainer}>
      <div className={styles.challengeImage}>
        <Image src={missionData.image} alt="challenge-image" width={60} height={60} />
      </div>
      <div className={styles.challengeItemsContainer}>
        <div className={styles.challengeTitle}>
          <StoreTypography tag="span" textSize="body" style="outlined">
            {title}
          </StoreTypography>
        </div>
        <div className={styles.challengeStampsContainer}>
          <div className={styles.challengeStamps}>
            {range(0, mission.target).map((index) => (
              <Image
                key={index}
                src={index < mission.current ? CheckMark : CheckMarkEmpty}
                alt="challenge-stamp"
                width={24}
                height={24}
              />
            ))}
          </div>
          <div className={styles.challengePoints}>{pointsText}</div>
        </div>
      </div>
    </div>
  )
}
