import cx from 'classnames'
import NextImage from 'next/image'
import { ImageProps } from 'next/legacy/image'

import Image from '@/components/DynamicImage'
import { useAppSelector } from '@/utils/useAppSelector'

import ProfileIcon from 'assets/images/icons/icon-profile-image-lg.svg'
import ProfileIconOutline from 'assets/images/icons/icon-profile.svg'
import SCIDIcon from 'assets/images/icons/icon-SCID.svg'

import styles from './UserAvatarImage.module.scss'

export type UserAvatarImageProps = {
  onClick?: () => void
  className?: string | string[]
  showOutline?: boolean
  showInitial?: boolean
  showSCIDIcon?: boolean
} & Omit<ImageProps, 'src'>

export const UserAvatarImage: React.FC<UserAvatarImageProps> = ({
  onClick,
  showOutline = false,
  showInitial = false,
  showSCIDIcon = false,
  className,
  ...rest
}) => {
  const avatarSrc = useAppSelector((state) => state.user.profile?.profile?.image?.url)
  const userName = useAppSelector((state) => state.user.profile?.profile?.name)
  const userInitial = userName?.[0]
  const fallbackSrc = showOutline ? ProfileIconOutline : ProfileIcon
  const src = avatarSrc ? avatarSrc : fallbackSrc
  return (
    <div
      className={cx(styles.UserAvatarImage, className, { [styles.ShowInitial]: showInitial && userInitial })}
      onClick={onClick}
      data-cy="user-avatar-image"
      data-after-content={userInitial}
    >
      <Image className={styles.userImage} {...rest} alt="avatar" src={src} fallback={fallbackSrc} />
      {showSCIDIcon && <NextImage className={styles.SCIDicon} width={10} alt="SCID icon" src={SCIDIcon} />}
    </div>
  )
}
