import React, { Fragment, memo, useEffect, useState } from 'react'

import { ProductV2, ShortGameId, GameId, StoreOfferProduct, isGemPackOffer, isGemPackProduct } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { useBonusStatusQuery } from '@/api'
import BSGemIcon from '@/assets/images/icons/bs_icon_gem.png'
import CocGemIcon from '@/assets/images/icons/coc_icon_gem.png'
import CrGemIcon from '@/assets/images/icons/cr_icon_gem.png'
import HdGemIcon from '@/assets/images/icons/hd_icon_gem.png'
import { useModalContext } from '@/contexts/ModalContext'
import { useGames } from '@/features/common/useGames'
import { useLocale } from '@/features/locale/useLocale'
import { useOfferCountdown } from '@/features/products/useOfferCountdown'
import { isStoreSpecial, isNormalOffer, isCosmetic } from '@/features/storefronts//productFilters'
import { isNewVisualsEnabled } from '@/features/storefronts/_storeBase/card/v2/shared'
import { STORE_BG, PASSES_BG } from '@/features/storefronts/_storeBase/constants'
import { OfferValueBadge } from '@/features/storefronts/_storeBase/offerValueBadge/OfferValueBadge'
import { PdpProductHero } from '@/features/storefronts/_storeBase/productPage/v2/productHero'
import { PdpHeroFooter } from '@/features/storefronts/_storeBase/productPage/v2/shared/productHeroFooter'
import { TimeRemaining } from '@/features/storefronts/_storeBase/timeRemaining/TimeRemaining'
import { LOGIN_STATUS } from '@/features/user/userSlice'
import { getShortSlugNotHD } from '@/fixtures/games'
import { useMessageContext } from '@/localization/MessageContext'
import { useTranslatable } from '@/localization/useTranslatable'
import { gtmProductView } from '@/utils/gtm'
import { getProductCurrencyType, isSeasonPass } from '@/utils/products'
import { snowplowTrackProductView } from '@/utils/snowplow'
import { TimeLeft } from '@/utils/timer'
import { useAppSelector } from '@/utils/useAppSelector'

import { productType } from './constants'
import { ItemRow, ItemTitle, ItemDescription, ItemImages } from './ItemRow'
import { OriginalPrice } from './OriginalPrice'
import { PassesInfo } from './PassesInfo'
import { ProductInfo } from './ProductInfo'
import {
  SpecialOfferHero,
  NormalOfferHero,
  SingularOfferHero,
  SingleProductHero,
  GemPackOfferHero,
  CosmeticsHero,
  BrawlerHero,
} from './ProductPageHeader'
import { useMarketContext } from '../../../../contexts/MarketContext'
import { brawlHyperChargeAsset, isBrawlerOrSkin } from '../../brawlstars/bundle/helpers'
import { BonusContainer } from '../bonus/BonusContainer'
import { BuyButton } from '../BuyButton/BuyButton'
import { BrawlCardProduct } from '../card/BrawlCardProduct'
import { CardProduct } from '../card/CardProduct'
import { useFormattedAmount } from '../card/utils'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './gameStoreProduct.module.scss'

export const resolveGemIcon = (gameId: GameId) => {
  switch (gameId) {
    case 'hayday':
      return HdGemIcon
    case 'clashroyale':
      return CrGemIcon
    case 'clashofclans':
      return CocGemIcon
    case 'brawlstars':
      return BSGemIcon
    default:
      return ''
  }
}

interface ProductProps {
  product: ProductV2
  isProductPage: boolean
  closeModal?: () => void
  enableNewVisuals?: GameId[]
}

const OldProductHero = ({
  isOffer,
  product,
  gameSlug,
  children,
}: {
  isOffer: boolean
  product: ProductV2
  gameSlug: GameId
  children: React.ReactNode
}) => {
  return (
    <div className={styles.productHero}>
      {isOffer && <OfferValueBadge offerProduct={product as StoreOfferProduct} game={gameSlug} />}
      {children}
    </div>
  )
}

const TITLE_FALLBACK = 'title'

export const GameStoreProduct: React.FC<ProductProps> = memo(
  ({ product, isProductPage, closeModal, enableNewVisuals }) => {
    const t = useTranslations()
    const { openModal } = useModalContext()
    const router = useRouter()
    const market = useMarketContext('GameStoreProduct')
    const loginStatus = useAppSelector((state) => state.user.loginStatus)
    const gameId = useAppSelector((state) => state.common.currentGame) as GameId
    const locale = useLocale()

    const currencyCountry = market.currencyCountry
    const currency = market.currencyCode

    const games = useGames()
    const translatable = useTranslatable()
    const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
    const game = games.find((g) => g.slug === product.game)
    const shortSlug = getShortSlugNotHD(game?.slug)
    const newVisualsEnabled = isNewVisualsEnabled(product.game, enableNewVisuals)

    const { isTimedOffer, countdownExpired, timeLeftText } = useOfferCountdown({ product: product, threshold: 90 })

    const isOffer = isStoreSpecial(product) || isNormalOffer(product)
    const isStampCard = product.stampCard
    const useLightBuyButton = gameId === 'clashroyale'

    const { data: bonusStatus } = useBonusStatusQuery(gameId, {
      skip: loginStatus !== LOGIN_STATUS.LOGGED_IN,
    })

    const gemCount = useFormattedAmount(product.contentCounts.GEM ?? 0)

    useEffect(() => {
      gtmProductView(product)
    }, [locale, product, viewerCountry])

    useEffect(() => {
      if (currencyCountry && currency) {
        snowplowTrackProductView(product, locale, currencyCountry, currency)
      }
    }, [locale, product, currencyCountry, currency])

    useEffect(() => {
      if (isTimedOffer && countdownExpired) {
        openModal({ type: 'OFFER_EXPIRED', props: { expired: true } })
      }
    }, [isTimedOffer, countdownExpired, openModal])

    if (!game) {
      router.push('/404')
      return null
    }

    const gameSlug = game.slug

    const royale = gameSlug === 'clashroyale'
    const royaleSpecialOffer = isStoreSpecial(product) && royale
    const royalePass = isSeasonPass(product) && royale
    const royaleNormalOffer = isNormalOffer(product) && royale
    const royaleGemPack = isGemPackOffer(product) && royale

    const brawl = gameSlug === 'brawlstars'
    const brawlSpecialOffer = isStoreSpecial(product) && brawl
    const brawlPass = isSeasonPass(product) && brawl
    const brawlNormalOffer = isNormalOffer(product) && brawl
    const brawlGemPack = isGemPackProduct(product) && brawl

    const productTitle = translatable(product.title, TITLE_FALLBACK)
    const firstItemTitle = translatable(product.contents[0].product.title, TITLE_FALLBACK)
    const title = productTitle === TITLE_FALLBACK ? firstItemTitle : productTitle

    const productTypeClass = () => {
      if (brawlSpecialOffer || royaleSpecialOffer) return productType.storeSpecial
      if (brawlPass || royalePass) return productType.pass
      if (brawlNormalOffer || royaleNormalOffer) return productType.normalOffer
      if (brawlGemPack || royaleGemPack) return productType.gemPack
    }
    const singularOffer = !isStoreSpecial(product) && product.contents.length === 1
    const hideDescription = singularOffer && isOffer
    const isBrawler =
      brawl && product.contents.some((c) => c.product.itemType === 'Hero' || c.product.itemType === 'Skin')

    const getProductHero = () => {
      if (isOffer) {
        if (isBrawler) {
          return <BrawlerHero product={product} />
        } else if (isStoreSpecial(product)) {
          return <SpecialOfferHero alt={translatable(product.title)} product={product} game={gameSlug} />
        } else if (singularOffer) {
          return <SingularOfferHero alt={translatable(product.title)} product={product} game={gameSlug} />
        } else {
          return <NormalOfferHero alt={translatable(product.title)} product={product} game={gameSlug} />
        }
      }

      if (isGemPackOffer(product)) {
        return <GemPackOfferHero alt={translatable(product.title)} product={product} game={gameSlug} />
      }

      if (isCosmetic(product)) {
        return <CosmeticsHero alt={translatable(product.title)} product={product} game={gameSlug} />
      }

      return <SingleProductHero product={product} alt={translatable(product.title)} game={gameSlug} />
    }

    return (
      <div className={cx(styles.ProductContainer, styles[gameSlug], { [styles.ProductPage]: isProductPage })}>
        <div
          className={cx(styles.Product, styles[gameSlug], styles[productTypeClass() ?? ''], {
            [styles.IsPopup]: isProductPage === false,
          })}
          style={{
            ...((brawlSpecialOffer || brawlNormalOffer || brawlGemPack) && { backgroundImage: STORE_BG(gameSlug) }),
            ...(brawlPass && { backgroundImage: PASSES_BG(gameSlug) }),
          }}
        >
          {newVisualsEnabled ? (
            <PdpProductHero product={product} />
          ) : (
            <div className={styles.ProductHeaderContainer}>
              <StoreTypography tag="h1" style="outlined" textSize="lg" className={styles.Title}>
                {title}
              </StoreTypography>
              <OldProductHero product={product} gameSlug={gameSlug} isOffer={isOffer}>
                {getProductHero()}
              </OldProductHero>
            </div>
          )}

          <div
            className={cx(styles.Content, {
              [styles.IsPopup]: isProductPage === false,
            })}
          >
            {newVisualsEnabled ? (
              <PdpHeroFooter product={product} />
            ) : (
              <GameStoreProductFooter
                product={product}
                isTimedOffer={isTimedOffer}
                timeLeftText={timeLeftText}
                useLightBuyButton={useLightBuyButton}
                brawl={brawl}
                royaleSpecialOffer={royaleSpecialOffer}
                gemCount={gemCount}
                countdownExpired={countdownExpired}
                closeModal={closeModal}
              />
            )}
            {!hideDescription && (
              <div className={styles.descriptionWrapper}>
                {isStampCard && <PassesInfo product={product} />}

                {isSeasonPass(product) ? (
                  <SeasonPassProductContents product={product} gameSlug={gameSlug} />
                ) : (
                  <ProductExtraDescription product={product} shortSlug={game.shortSlug} />
                )}

                {isStoreSpecial(product) && (
                  <StoreTypography
                    tag="p"
                    textSize="body"
                    style="plain"
                    overrideFont="supercellText"
                    lineHeight="normal"
                    className={styles.offerDescription}
                  >
                    {t(`${shortSlug}_specials_high_value`)}
                  </StoreTypography>
                )}
              </div>
            )}
            {product.type === 'offer' && !isSeasonPass(product) && !isGemPackOffer(product) && !singularOffer && (
              <OfferContent product={product} game={gameSlug} />
            )}
          </div>

          <ProductInfo gameSlug={gameSlug} productType={productTypeClass()} />
        </div>
        {isProductPage && bonusStatus && <BonusContainer bonusStatus={bonusStatus} />}
      </div>
    )
  },
)

const GameStoreProductFooter = ({
  product,
  isTimedOffer,
  timeLeftText,
  useLightBuyButton,
  brawl,
  royaleSpecialOffer,
  gemCount,
  countdownExpired,
  closeModal,
}: {
  product: ProductV2
  isTimedOffer: boolean
  timeLeftText: TimeLeft
  useLightBuyButton: boolean
  brawl: boolean
  royaleSpecialOffer?: boolean
  gemCount: string | null
  countdownExpired: boolean
  closeModal?: () => void
}) => {
  return (
    <div className={styles.offerDetailsWrapper}>
      {isTimedOffer && timeLeftText.length > 0 && (
        <div className={styles.TimerContainer}>
          <TimeRemaining
            timeLeftText={timeLeftText}
            className={cx({ [styles.whiteText]: brawl || royaleSpecialOffer })}
          />
        </div>
      )}

      {!isTimedOffer && (isGemPackOffer(product) || isGemPackProduct(product)) && (
        <div className={styles.TimerContainer}>
          <Image src={resolveGemIcon(product.game)} alt="gem" height={32} width={32} sizes="50px" />
          <StoreTypography tag="span" textSize="body" style="outlined">
            {gemCount}
          </StoreTypography>
        </div>
      )}

      <div className={styles.Action}>
        <BuyButton
          disabled={isTimedOffer && countdownExpired}
          variant={useLightBuyButton ? 'Light' : 'Dark'}
          product={product}
          data-cy={`product-${product.id}`}
          onAddToCart={closeModal}
        />
        <OriginalPrice product={product} />
      </div>
    </div>
  )
}

GameStoreProduct.displayName = 'GameStoreProduct'

const OfferContent = ({ product, game }: { product: ProductV2; game: GameId }) => {
  const t = useTranslations()
  const translatable = useTranslatable()
  const storeSpecial = isStoreSpecial(product)
  const brawl = game === 'brawlstars'

  const singleProduct = product.contents.length === 1

  return (
    <div className={styles.offerContentContainer}>
      <div className={styles.subtitle}>{t('offers_included')}</div>
      <div className={styles.productsContainer}>
        {product.contents.map((content, i) => (
          <Fragment key={i}>
            {brawl ? (
              <BrawlCardProduct
                size={singleProduct ? 'size-product-page-large' : 'size-product-page'}
                game={game}
                content={content}
                title={translatable(content.product.title, 'title')}
                productPage={true}
                isHypercharge={brawlHyperChargeAsset(content.product.id)}
                isBrawlerOrSkin={isBrawlerOrSkin(content)}
              />
            ) : (
              <CardProduct
                size="size-product-page"
                game={game}
                content={content}
                title={translatable(content.product.title, 'title')}
                productPage={true}
                storeSpecial={storeSpecial}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

const ProductExtraDescription = ({ product, shortSlug }: { product: ProductV2; shortSlug: ShortGameId }) => {
  const t = useTranslations()
  const translate = useTranslatable()
  const contentType = getProductCurrencyType(product)
  const offerDescription = product.type === 'offer' ? translate(product.description, '') : ''

  const escapedNewLine = offerDescription.replace(/\\n/g, '\n').replace(/\\q/g, '"')
  const escapedOfferDescr = escapedNewLine
    .split('\n')
    .filter(Boolean)
    .map((str, index) => (
      <StoreTypography
        tag="p"
        style="plain"
        textSize="body"
        overrideFont="supercellText"
        lineHeight="normal"
        key={index}
      >
        {str}
      </StoreTypography>
    ))
  if (offerDescription !== '') {
    return <div className={styles.extraDescription}>{escapedOfferDescr}</div>
  }

  if (!contentType) {
    return null
  }
  switch (shortSlug) {
    case 'cr':
      break
    case 'hd':
      if (contentType === 'COIN')
        return (
          <StoreTypography
            tag="p"
            style="plain"
            textSize="body"
            overrideFont="supercellText"
            lineHeight="normal"
            className={styles.extraDescription}
          >
            {t('hd_coin_description_2')}
          </StoreTypography>
        )
      if (contentType === 'GEM')
        return (
          <StoreTypography
            tag="p"
            style="plain"
            textSize="body"
            overrideFont="supercellText"
            lineHeight="normal"
            className={styles.extraDescription}
          >
            {t('hd_gem_description_2')}
          </StoreTypography>
        )
      break
    case 'bs':
      if (contentType === 'GEM')
        return (
          <StoreTypography
            tag="p"
            style="plain"
            textSize="body"
            overrideFont="supercellText"
            lineHeight="normal"
            className={styles.extraDescription}
          >
            {t('bs_gem_description_2')}
          </StoreTypography>
        )
      break
    case 'coc':
      break
  }
  return null
}

const SeasonPassProductContents: React.FC<{ product: ProductV2; gameSlug: GameId }> = ({ product, gameSlug }) => {
  const [productLoaded, setProductLoaded] = useState<undefined | ProductMessageKey>(undefined)
  const t = useTranslations(productLoaded ?? undefined)
  const locale = useLocale()
  const { loadProductMessage } = useMessageContext()
  useEffect(() => {
    const fn = async () => {
      const loadRes = await loadProductMessage(product, locale)
      if (loadRes) {
        setProductLoaded(loadRes)
      }
    }
    fn()
  }, [product, locale, loadProductMessage])
  if (!productLoaded) {
    return null
  }
  return (
    <div className={styles.seasonPassProductContents}>
      <StoreTypography
        tag="h3"
        style="plain"
        textSize="body"
        overrideFont="supercellTextMedium"
        lineHeight="normal"
        className={styles.title}
      >
        {t('title')}
      </StoreTypography>
      <StoreTypography tag="p" style="plain" textSize="body" overrideFont="supercellText" lineHeight="normal">
        {t.rich('description', {
          TextBlock: (children) => {
            if (Array.isArray(children)) {
              return <span className={styles.TextBlock}>{children[0]}</span>
            }
          },
        })}
      </StoreTypography>
      <div className={styles.ProductList}>
        {t.rich('contents', {
          Title: (children) => <ItemTitle gameSlug={gameSlug}>{children}</ItemTitle>,
          Description: (children) => <ItemDescription gameSlug={gameSlug}>{children}</ItemDescription>,
          Image: (children) => {
            if (Array.isArray(children)) {
              return <ItemImages>{children[0]}</ItemImages>
            }
          },
          Row: (children) => <ItemRow gameSlug={gameSlug}>{children}</ItemRow>,
          TextBlock: (children) => {
            if (Array.isArray(children)) {
              return <span className={styles.TextBlock}>{children[0]}</span>
            }
          },
        })}
      </div>
    </div>
  )
}
