import { GameId } from '@common'

import { CartItem, cartItemsToDdbCartItems } from '@/utils/cart'

export async function saveCart(gameId: GameId | undefined, cartItems: CartItem[]) {
  if (!gameId) {
    return Promise.reject(new Error('Trying to save cart with no gameId'))
  }

  return fetch(`/api/v3/${gameId}/cart`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ items: cartItemsToDdbCartItems(cartItems) }),
  })
}

export async function upsertCreatorCode(gameId: GameId, creatorCode: string) {
  const response = await fetch(`/api/v3/${gameId}/creator-code`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ creatorCode }),
  })

  const json = await response.json()
  return {
    isValid: Boolean(json.isValid),
    code: json.code,
  }
}
