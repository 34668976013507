import Big from 'big.js'
import { SupportedLanguage, Currency, supportedLanguages } from '../models/countries'
import type { Prices, ProductBonus, ProductV2, SCIDProduct } from '../models/product'
import { allCurrencies } from '../models/countries'
import { GameId } from '../models/game'
import { STORE_BONUS_POINT_OVERRIDE } from './chronosTags'
import { SCIDChronosOfferProduct } from '../models/offer'
import { columnTypes } from '../models/db'
import { DEFAULT_CURRENCY_COUNTRY } from '../models/db/columnTypes'

function decimalPointsForCurrency(currency: columnTypes.CurrencyCode) {
  if (currency in allCurrencies) {
    return allCurrencies[currency as Currency].decimals
  }
  throw new Error(`Unknown currency ${currency}`)
}

export function convertToMinorUnits(amount: number, currency: columnTypes.CurrencyCode): number {
  const decimals = decimalPointsForCurrency(currency)
  return new Big(amount).times(Math.pow(10, decimals)).round(0).toNumber()
}

export function convertToMajorUnits(amount: number, currency: columnTypes.CurrencyCode): number {
  const decimals = decimalPointsForCurrency(currency)
  return new Big(amount).div(Math.pow(10, decimals)).round(decimals).toNumber()
}

type RowLike = { sku: string; prices: ProductV2['prices']; game: ProductV2['game']; quantity: number }
type TaxConfig = { type: 'SALES_TAX'; percentage: number } | { type: 'VAT'; percentage: number } | { type: 'NONE' }

function resolveRowPriceNoTax<T extends RowLike>(
  { prices, sku, quantity }: T,
  currencyCountry: columnTypes.CurrencyCountry,
) {
  const price = prices[currencyCountry]
  if (!price) {
    throw new Error(`No price for ${currencyCountry}`)
  }
  const total = Big(price).times(quantity)
  return { sku, subtotal: total, total, taxAmount: Big(0), quantity }
}

function resolveRowPriceVAT<T extends RowLike>(
  { prices, sku, quantity }: T,
  currencyCountry: columnTypes.CurrencyCountry,
  taxPercentage: number,
) {
  const price = prices[currencyCountry]
  if (!price) {
    throw new Error(`No price for ${currencyCountry}`)
  }
  const total = Big(price).times(quantity)
  const subtotal = total.div(Big(1).add(taxPercentage)).round()
  const taxAmount = total.minus(subtotal)
  return { sku, subtotal, total, taxAmount, quantity }
}

function resolveRowPriceSalesTax<T extends RowLike>(
  { prices, sku, quantity }: T,
  currencyCountry: columnTypes.CurrencyCountry,
  taxPercentage: number,
) {
  const price = prices[currencyCountry]
  if (!price) {
    throw new Error(`No price for ${currencyCountry}`)
  }
  const subtotal = Big(price).times(quantity)
  const taxAmount = subtotal.times(taxPercentage).round()
  const total = subtotal.add(taxAmount)
  return { sku, subtotal, total, taxAmount, quantity }
}

function resolveRowPrice<T extends RowLike>(row: T, currencyCountry: columnTypes.CurrencyCountry, tax: TaxConfig) {
  const price = row.prices[currencyCountry]
  if (!price) {
    throw new Error(`No price for SKU ${row.sku}, currency country ${currencyCountry}`)
  }
  switch (tax.type) {
    case 'NONE':
      return resolveRowPriceNoTax(row, currencyCountry)
    case 'VAT':
      return resolveRowPriceVAT(row, currencyCountry, tax.percentage)
    case 'SALES_TAX':
      return resolveRowPriceSalesTax(row, currencyCountry, tax.percentage)
  }
}

export function calculatePricing<T extends RowLike = RowLike>(
  inputRows: T[],
  currencyCountry: columnTypes.CurrencyCountry,
  currencyCode: columnTypes.CurrencyCode,
  tax: TaxConfig,
) {
  let total = Big(0)
  let totalUSD = Big(0)
  let subtotal = Big(0)
  let subtotalUSD = Big(0)
  const rows: {
    sku: string
    game: GameId
    quantity: number
    subtotal: number
    subtotalUSD: number
    total: number
    totalUSD: number
  }[] = []
  for (const row of inputRows) {
    const price = resolveRowPrice(row, currencyCountry, tax)
    const priceUSD = resolveRowPrice(row, columnTypes.DEFAULT_CURRENCY_COUNTRY, { type: 'NONE' })
    total = total.add(price.total)
    totalUSD = totalUSD.add(priceUSD.total)
    subtotal = subtotal.add(price.subtotal)
    subtotalUSD = subtotalUSD.add(priceUSD.subtotal)
    rows.push({
      sku: row.sku,
      quantity: row.quantity,
      game: row.game,
      subtotal: price.subtotal.toNumber(),
      subtotalUSD: priceUSD.subtotal.toNumber(),
      total: price.total.toNumber(),
      totalUSD: priceUSD.total.toNumber(),
    })
  }
  return {
    total: total.toNumber(),
    totalUSD: totalUSD.toNumber(),
    subtotal: subtotal.toNumber(),
    subtotalUSD: subtotalUSD.toNumber(),
    taxAmount: total.minus(subtotal).toNumber(),
    taxAmountUSD: totalUSD.minus(subtotalUSD).toNumber(),
    currency: currencyCode,
    rows,
    version: 'v1',
  }
}

export const convertPercentageToDecimals = (percentage: number) => {
  return Big(percentage).div(100).round(4).toNumber()
}

export const convertDecimalToPercentage = (decimal: number) => {
  return Big(decimal).times(100).round(2).toNumber()
}

// Adds sales tax to price and rounds it to nearest minor unit
export function addSalesTaxToPrice(price: number, taxPercentage: number) {
  return Big(price).times(Big(1).add(taxPercentage)).round().toNumber()
}

export function substractTaxFromPrice(price: number, taxAmount: number) {
  return Big(price).sub(taxAmount).round(2).toNumber()
}

const INDONESIAN_CURRENCY = 'IDR' as const
const SKIP_FRACTION_CURRENCY: readonly string[] = [INDONESIAN_CURRENCY]

function formatMajorUnitPriceText(
  price: number | null | undefined,
  language: SupportedLanguage,
  currency: columnTypes.CurrencyCode | null,
) {
  if (!price || !currency) return ''

  // TODO: remove the numberingSystem when it's merged to TS lib, included in 5.5.0-dev
  // https://github.com/microsoft/TypeScript/issues/52072
  const formatOptions: Intl.NumberFormatOptions & { numberingSystem: string } = {
    style: 'currency',
    currency,
    numberingSystem: 'latn',
  }
  if (SKIP_FRACTION_CURRENCY.includes(currency)) {
    // For some browsers _BOTH_ of are required
    formatOptions.maximumFractionDigits = 0
    formatOptions.minimumFractionDigits = 0
  }
  return new Intl.NumberFormat(supportedLanguages[language].locale, formatOptions).format(price)
}

// START new pricing based on market
type MarketLike = {
  currencyCode: columnTypes.CurrencyCode
  currencyCountry: columnTypes.CurrencyCountry
  currencyDecimals: number
}
export function formatPrice(price: number, market: MarketLike, language: SupportedLanguage, skipConvert = false) {
  const { currencyCode, currencyDecimals } = market

  return toIntlNumberFormat(skipConvert ? price : toMajorUnits(price, currencyDecimals), language, currencyCode)
}

type ProductPricesLike = { prices: ProductV2['prices'] }
export function formatProductPrice<P extends ProductPricesLike, M extends MarketLike>(
  product: P,
  market: M,
  language: SupportedLanguage,
  quantity = 1,
) {
  const { currencyCode, currencyCountry, currencyDecimals } = market

  const price = product.prices[currencyCountry]

  if (!price) {
    return null
  }

  return toIntlNumberFormat(toMajorUnits(price * quantity, currencyDecimals), language, currencyCode)
}

export function toMajorUnits(amount: number, decimals: number) {
  return new Big(amount).div(Math.pow(10, decimals)).round(decimals).toNumber()
}

function toIntlNumberFormat(price: number, language: SupportedLanguage, currency: columnTypes.CurrencyCode) {
  const formatOptions: Intl.NumberFormatOptions & { numberingSystem: string } = {
    style: 'currency',
    currency,
    numberingSystem: 'latn',
  }

  if (SKIP_FRACTION_CURRENCY.includes(currency)) {
    formatOptions.maximumFractionDigits = 0
    formatOptions.minimumFractionDigits = 0
  }

  return new Intl.NumberFormat(supportedLanguages[language].locale, formatOptions).format(price)
}
// END new pricing based on market

export function formatPriceText(
  price: number | null | undefined,
  language: SupportedLanguage,
  currency: columnTypes.CurrencyCode | null | undefined,
  skipConvert = false,
) {
  if (!price || !currency) return ''
  const amount = skipConvert ? price : convertToMajorUnits(price, currency)
  return formatMajorUnitPriceText(amount, language, currency)
}

export function calculateBonusPointsForProduct(
  product: SCIDProduct | SCIDChronosOfferProduct,
  prices: Prices,
): ProductBonus {
  const totalUSDCents = prices[DEFAULT_CURRENCY_COUNTRY] ?? 0
  const baseBonus = Big(totalUSDCents).div(2).round(0, 3)
  const bonusOverride = product?.tags?.reduce<Big | undefined>((bonus, tag) => {
    if (bonus) {
      return bonus
    }

    const points = STORE_BONUS_POINT_OVERRIDE.exec(tag)?.[1]
    if (points) {
      return Big(parseInt(points, 10))
    }
  }, undefined)

  const bonusAddModifier = bonusOverride?.sub(baseBonus)

  if (bonusAddModifier && bonusOverride && bonusAddModifier.gt(0)) {
    return {
      baseBonus: baseBonus.toNumber(),
      totalBonus: bonusOverride.toNumber(),
      modifier: {
        type: 'add',
        value: bonusAddModifier?.toNumber(),
      },
    }
  }

  return {
    baseBonus: baseBonus.toNumber(),
    totalBonus: baseBonus.toNumber(),
  }
}

export function calculateBonusPointsForPurchase<T extends { bonus?: number }>(
  rows: { product: T; quantity: number }[],
) {
  return rows
    .reduce((acc, row) => {
      return acc.add(Big(row.product.bonus ?? 0).times(row.quantity))
    }, Big(0))
    .toNumber()
}
