import { isGameId, SupportedLanguage, supportedLanguages, supportedLanguagesByGame } from '@common'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { DropdownSelect } from '@/components/Dropdown'
import { Globe } from '@/components/Icons'
import { useLocale } from '@/features/locale/useLocale'
import { setLocaleCookie } from '@/utils/cookies'

import { config } from '../../../config'

import styles from './LanguageSelector.module.scss'

const { STAGE } = config

type LanguageSelectorProps = {
  id: string
  isMobileNav?: boolean
  direction?: 'Up' | 'Down'
  storefront?: boolean
}

export const LanguageSelector = ({
  id,
  isMobileNav = false,
  direction = 'Up',
  storefront = false,
}: LanguageSelectorProps) => {
  const router = useRouter()
  const locale = useLocale()
  const gameId = router.query.gameSlug as string | undefined

  const options = Object.entries(supportedLanguages)
    .filter(([key]) => {
      if (gameId && isGameId(gameId)) {
        return supportedLanguagesByGame[gameId].includes(key as SupportedLanguage)
      }
      return true
    })
    .filter(([, value]) => (STAGE === 'production' ? value.inProduction : true))
    .map(([key, value]) => ({ value: key, label: value.native }))
    .sort((a, b) => a.label.localeCompare(b.label, 'en'))

  const selectedOption = options.find((option) => option.value === router.locale)
  return (
    <DropdownSelect
      id={id}
      className={cx(styles.LanguageSelector, { [styles.MobileNav]: isMobileNav }, { [styles.storefront]: storefront })}
      options={options}
      value={locale}
      formatValue={(val) => val?.toUpperCase()}
      onChange={(lang) => {
        setLocaleCookie(lang as SupportedLanguage)
        router.push(router.asPath, '', { locale: lang })
      }}
      direction={direction}
    >
      <Globe className={styles.GlobeIcon} height={22} width={22} />
      <p className={styles.LabelText}>{selectedOption?.label}</p>
    </DropdownSelect>
  )
}
