import React, { useEffect } from 'react'

import {
  UnclaimedBonusModalProps,
  UnclaimedBonusModal,
} from '@/features/storefronts/_storeBase/storeBonusV2/unclaimedBonusModal/UnclaimedBonusModal'
import {
  OnboardingLoginModal,
  OnboardingLoginModalProps,
} from '@/features/storefronts/landing/Onboarding/components/OnboardingLoginModal'
import {
  OnboardingModal,
  OnboardingModalProps,
} from '@/features/storefronts/landing/Onboarding/components/OnboardingModal'
import { BonusModal, BonusModalProps } from '@/modals/BonusModal'
import { FeatureFlagsModal, FeatureFlagsModalProps } from '@/modals/FeatureFlagsModal'
import { LoginModal, LoginModalProps } from '@/modals/LoginModal'
import { OfferExpiredModal, OfferExpiredModalProps } from '@/modals/OfferExpiredModal'
import { ProductModal, ProductModalProps } from '@/modals/ProductModal'
import { isMac } from '@/utils/safariDeviceType'
import { useAppSelector } from '@/utils/useAppSelector'
import { useBreakpoints } from '@/utils/useBreakpoints'

export type OverlayProps = {
  overlay:
    | BonusModalProps
    | FeatureFlagsModalProps
    | LoginModalProps
    | OfferExpiredModalProps
    | ProductModalProps
    | OnboardingModalProps
    | OnboardingLoginModalProps
    | UnclaimedBonusModalProps
    | null
}

export function Overlay({ overlay }: OverlayProps) {
  const gameStoresEnabled = useAppSelector((state) => state.config.storefront)

  const from = useBreakpoints().from
  const isDesktop = from('laptop-small')

  /* Hide body scroll when modal is open */
  useEffect(() => {
    if (overlay && gameStoresEnabled) {
      if (overlay !== null) {
        document.body.style.overflow = 'hidden'

        // Default behavior on macOS is to display overlay scrollbars when scrolling,
        // so adding the padding shifts the layout on macOS unncessary

        // add padding to desktop body to prevent content from shifting when body scroll is hidden, non-macOS desktops only
        overlay.type === 'PRODUCT' && isDesktop && !isMac() ? (document.body.style.paddingRight = '15px') : null
      }

      // bonus info is always on top of an another overlay - no need to reset body scroll after closing it
      if (overlay.type === 'BONUS_INFO') {
        return
      }

      return () => {
        document.body.style.overflowY = 'auto'
        document.body.style.paddingRight = '0'
      }
    }
  }, [overlay, gameStoresEnabled, isDesktop])

  if (!overlay) {
    return null
  }

  switch (overlay.type) {
    case 'BONUS_INFO':
      return <BonusModal />
    case 'FEATURE_FLAGS':
      return <FeatureFlagsModal />
    case 'LOGIN':
      return <LoginModal {...overlay.props} />
    case 'OFFER_EXPIRED':
      return <OfferExpiredModal {...overlay.props} />
    case 'PRODUCT':
      return <ProductModal {...overlay.props} />
    case 'ONBOARDING':
      return <OnboardingModal {...overlay.props} />
    case 'ONBOARDING_LOGIN':
      return <OnboardingLoginModal {...overlay.props} />
    case 'UNCLAIMED_BONUS':
      return <UnclaimedBonusModal {...overlay.props} />
    default:
      return null
  }
}
