import { gameIdSchema, StageSchema } from '@common'
import { enabledSchema, timedEnableSchema } from '@common/utils/config'
import { z } from 'zod'

const configJSONSchema = z.object({
  API_URL: z.string(),
  ADYEN_CLIENT_KEY: z.string(),
  TEST_CARDS: z.boolean(),
  GOOGLE_TAG_MANAGER_ID: z.string(),
  TRACKING_ENABLED: z.boolean(),
  COOKIE_CONSENT_ENABLED: z.boolean(),
  ADYEN_ENVIRONMENT: z.literal('test').or(z.literal('live')),
  APP_URL: z.string(),
  SENTRY_ENABLED: z.boolean(),
  SENTRY_DSN: z.string(),
  WF_EVENT_SITE_URL: z.string(),
  PADDLE_CLIENT_TOKEN: z.string(),
  PADDLE_SAVED_PAYMENT_METHOD_MAGIC_PRICE_ID: z.string(),
  FASTSPRING_CLIENT_TOKEN: z.string(),
  FASTSPRING_STOREFRONT_URL: z.string(),
  FASTSPRING_API_URL: z.string(),
  STAGE: StageSchema,
  SNOWPLOW_ENABLED: z.boolean(),
  SNOWPLOW_APP_ID: z.string(),
  SNOWPLOW_COLLECTOR_URL: z.string(),
  SNOWPLOW_POST_PATH: z.string(),
  OAUTH_LOGIN_URL: z.string(),
  OAUTH_CLIENT_ID: z.string(),
  OAUTH_REDIRECT_URI: z.string(),
  SCID_SSO_COOKIE_NAME: z.string(),
  HELPSHIFT_PLATFORM_ID: z.string().optional(),
  HELPSHIFT_DOMAIN: z.string().optional(),
  ENABLED_NEW_STOREFRONTS: z.string().array(),
  USE_LOCAL_AWS: z.boolean(),
  OFFERS_ENABLED: z.boolean(),
  REWARDS_ENABLED: enabledSchema.default('disabled'),
  STAMP_CARD_ENABLED: z.boolean(),
  SCID_STORE_API_URL: z.string(),
  SCID_REWARDS_API_URL: z.string(),
  DB_HOST: z.string(),
  DB_PORT: z.number(),
  DB_DATABASE: z.string(),
  STORE_API_URL: z.string(),
  CREATORS_API_URL: z.string(),
  ADYEN_MERCHANT_ACCOUNT: z.string(),
  ADYEN_UNIQUE_LIVE_URL_PREFIX: z.string(),
  PADDLE_API_URL: z.string(),
  RAZER_ENVIRONMENT: z.union([z.literal('TEST'), z.literal('LIVE'), z.literal('DISABLED')]),
  RAZER_LOCAL_CALLBACK_URL: z.string().optional(),
  SQUAD_DISABLED_COUNTRIES: z.array(z.string()),
  NEW2024_VISUALS_ENABLED: z.array(gameIdSchema).optional(),
  CREATOR_GIVEAWAY_ENABLED: z.boolean().default(false),
})

const configSchema = configJSONSchema.extend({
  BUILD_ID: z.string(),
  GIT_HASH: z.string().default('local'),
})

let configJSON
if (process.env.NEXT_PUBLIC_STAGE === 'production') {
  configJSON = require('./production.json')
} else if (process.env.NEXT_PUBLIC_STAGE === 'staging') {
  configJSON = require('./staging.json')
} else if (process.env.NEXT_PUBLIC_STAGE === 'integration') {
  configJSON = require('./integration.json')
} else if (process.env.NEXT_PUBLIC_STAGE === 'local-dev') {
  configJSON = require('./local-dev.json')
} else if (process.env.NEXT_PUBLIC_STAGE === 'test') {
  configJSON = require('./test.json')
  if (process.env.OLD_ENVIRONMENT === 'true') {
    configJSON.ENABLED_NEW_STOREFRONTS = []
  }
} else {
  configJSON = require('./development.json')
}

const parsedConfig = configSchema.parse({
  ...configJSON,
  BUILD_ID: process.env.NEXT_PUBLIC_BUILD_ID,
  GIT_HASH: process.env.NEXT_PUBLIC_GIT_HASH,
})

export type Config = z.infer<typeof configSchema>

export const config = {
  get: (key: keyof Config) => {
    const value = parsedConfig[key]
    const timedEnable = timedEnableSchema.safeParse(value)
    if (timedEnable.success) {
      switch (timedEnable.data.rule) {
        case 'enabled-after':
          return Date.now() >= timedEnable.data.date.getTime()
      }
    }
    return value
  },
  ...parsedConfig,
}

const base64EncodedValueSchema = z.string().transform((val) => Buffer.from(val, 'base64').toString('utf8'))

const secretSchema = {
  ADMIN_DB_READ_PASSWORD: z.string(),
  OAUTH_CLIENT_SECRET: z.string(),
  OAUTH_STORE_SECRET: z.string(),
  SCID_ACCOUNT_STORE_API_SECRET: z.string(),
  CREATORS_API_SECRET: z.string(),

  AUTH_STORE_SECRET_ADYEN: z.string(),
  ADYEN_API_KEY: z.string(),
  FASTSPRING_PK: base64EncodedValueSchema,
  FASTSPRING_USERNAME: z.string(),
  FASTSPRING_PASSWORD: z.string(),
  PADDLE_API_KEY: z.string(),

  RAZER_APP_CODE: z.string(),
  RAZER_SECRET_KEY: z.string(),
}

export type Secret = keyof typeof secretSchema

type SecretSchema = typeof secretSchema

type SecretSchemaType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof SecretSchema]: SecretSchema[K] extends z.ZodType<any, any, infer T> ? T : never
}

export const requireSecret = <K extends Secret>(secret: K): SecretSchemaType[K] => {
  try {
    return secretSchema[secret].parse(process.env[secret]) as SecretSchemaType[K]
  } catch (e) {
    throw new Error(`Secret ${secret} not available: ${e}`)
  }
}

export const isEnabled = (key: 'REWARDS_ENABLED', isTestAccount = false) => {
  const value = config.get(key)
  if (isTestAccount && (value === 'enabled_qa' || value === 'enabled')) {
    return true
  }
  return value === 'enabled'
}
