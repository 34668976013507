import { memo, useMemo } from 'react'

import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import ClockIconBrawl from '@/assets/images/storefronts/brawlstars/icon-clock.png'
import ClockIconCoC from '@/assets/images/storefronts/clashofclans/icon-clock.png'
import ClockIconRoyale from '@/assets/images/storefronts/clashroyale/icon-clock.png'
import { TimeLeft, getTimeLeftCopyKeys } from '@/utils/timer'
import { useAppSelector } from '@/utils/useAppSelector'

import { StoreTypography, textSizes, textStyles } from '../typography/StoreTypography'

import styles from './timeRemaining.module.scss'

type TimeRemainingProps = {
  timeLeftText: TimeLeft
  type?: 'primary' | 'secondary'
  textStyle?: textStyles
  textSize?: textSizes
  className?: string
}

export const TimeRemaining = memo(
  ({
    timeLeftText,
    type = 'primary',
    textSize = 'body',
    textStyle = 'plain',
    className: additionalClasses,
  }: TimeRemainingProps) => {
    const t = useTranslations()
    const gameSlug = useAppSelector((state) => state.common.currentGame) as GameId

    // slice the leading zero from the time units
    timeLeftText = timeLeftText.map((unit) => (unit[0] === '0' && unit.length > 1 ? unit.slice(1) : unit)) as TimeLeft

    const displayTimeRemaining = useMemo(() => {
      return (timeLeft: TimeLeft) => {
        const [days, hours, minutes, seconds] = timeLeft.map((tl, idx) => {
          const timeLeftKey = getTimeLeftCopyKeys('mobile', idx)
          return `${tl}${timeLeftKey ? t(timeLeftKey) : ''}`
        })

        // use timeLeft instead of mapped items, as they contain the timeLeftKey
        if (timeLeft[0] !== '0') {
          return `${days} ${hours}`
        }

        if (timeLeft[1] !== '0') {
          return `${hours} ${minutes}`
        }

        if (timeLeft[2] !== '0') {
          return `${minutes} ${seconds}`
        }

        return seconds
      }
    }, [t])

    return (
      <>
        {displayTimeRemaining.length > 0 ? (
          <div className={cx(styles.timeRemaining, styles[type], styles[gameSlug])}>
            <Image
              className={styles.clockImage}
              src={
                gameSlug === 'brawlstars' ? ClockIconBrawl : gameSlug === 'clashroyale' ? ClockIconRoyale : ClockIconCoC
              }
              alt="clock icon"
              width={24}
              height={24}
            />
            <StoreTypography
              tag="div"
              textSize={textSize}
              style={textStyle}
              className={cx(styles.time, additionalClasses)}
            >
              {displayTimeRemaining(timeLeftText)}
            </StoreTypography>
          </div>
        ) : (
          <div className={styles.timeSkeleton} />
        )}
      </>
    )
  },
)

TimeRemaining.displayName = 'TimeRemaining'
