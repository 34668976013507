import type { PaymentResponse } from '@adyen/api-library/lib/src/typings/checkout/paymentResponse'
import type { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse'
import type { PaymentDetailsRequest } from '@adyen/api-library/lib/src/typings/checkout/paymentDetailsRequest'
import type { PaymentRequest } from '@adyen/api-library/lib/src/typings/checkout/paymentRequest'
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types'
import type { columnTypes } from '..'
import { z } from 'zod'

export type PurchaseDetailsBody = {
  purchaseId: string
  details: Record<string, unknown>
  paymentData: string
}

type PurchasesCheckoutSessionBaseResponse = { paymentGateway: PaymentGateway }
export type PurchasesCheckoutMerchantOfRecordSessionResponse = PurchasesCheckoutSessionBaseResponse & { token: string }
export type PurchasesCheckoutAdyenSessionResponse = PurchasesCheckoutSessionBaseResponse &
  CreateCheckoutSessionResponse & {
    countryCode: columnTypes.CountryCode
  }
export type PurchasesCheckoutSessionResponse =
  | PurchasesCheckoutMerchantOfRecordSessionResponse
  | PurchasesCheckoutAdyenSessionResponse

export type PurchasesCheckoutProcessRequest = {
  purchaseId: string
  paymentMethodName: string
  data: { paymentMethod: PaymentRequest['paymentMethod']; additionalData: PaymentRequest['additionalData'] } & Record<
    string,
    unknown
  >
}

export type PurchasesCheckoutProcessResponse = PaymentResponse

export type PurchaseDetailsRequest = Pick<PaymentDetailsRequest, 'details' | 'paymentData'> & { purchaseId: string }
export type PurchaseDetailsResponse = {
  purchaseId: string
  resultCode: PaymentResponse['resultCode']
  action: PaymentAction | null
  outputDetails: {
    [name: string]: string
  } | null
}

export const PaymentGatewayPSP = z.literal('PSP')
export const PaymentGatewayMOR = z.literal('MOR')
export const PaymentGatewayKind = z.union([PaymentGatewayPSP, PaymentGatewayMOR])

export type PaymentGatewayPSP = z.infer<typeof PaymentGatewayPSP>
export type PaymentGatewayMOR = z.infer<typeof PaymentGatewayMOR>
export type PaymentGatewayKind = z.infer<typeof PaymentGatewayKind>

export const PSPAdyen = z.literal('ADYEN')
export const PaymentServiceProvider = PSPAdyen

export type PSPAdyen = z.infer<typeof PSPAdyen>
export type PaymentServiceProvider = z.infer<typeof PaymentServiceProvider>
export const isPSP = (paymentGateway: PaymentGateway): paymentGateway is PaymentServiceProvider =>
  PaymentServiceProvider.safeParse(paymentGateway).success

export const MORXsolla = z.literal('XSOLLA')
export const MORPaddle = z.literal('PADDLE')
export const MORFastSpring = z.literal('FASTSPRING')
export const MORNeon = z.literal('NEON')
export const MORRazer = z.literal('RAZER_GOLD')
export const MerchantOfRecord = z.union([MORXsolla, MORPaddle, MORFastSpring, MORNeon, MORRazer])
export const isMOR = (paymentGateway: PaymentGateway): paymentGateway is MerchantOfRecord =>
  MerchantOfRecord.safeParse(paymentGateway).success

export type MORXsolla = z.infer<typeof MORXsolla>
export type MORPaddle = z.infer<typeof MORPaddle>
export type MORFastSpring = z.infer<typeof MORFastSpring>
export type MORNeon = z.infer<typeof MORNeon>
export type MORRazer = z.infer<typeof MORRazer>
export type MerchantOfRecord = z.infer<typeof MerchantOfRecord>

export const PaymentGateway = z.union([PSPAdyen, MORRazer, MORXsolla, MORPaddle, MORFastSpring, MORNeon])
export type PaymentGateway = z.infer<typeof PaymentGateway>

export type SalesTax = 'SALES_TAX'
export type VAT = 'VAT'
export type TaxType = SalesTax | VAT

export enum RazerPaymentChannel {
  RAZER_GOLD_WALLET = 1,
  RAZER_GOLD_PIN = 3,
}

export const razerPaymentChannelEnumToName = (paymentChannel: RazerPaymentChannel): string => {
  switch (paymentChannel) {
    case RazerPaymentChannel.RAZER_GOLD_WALLET:
      return 'Razer Gold Wallet'
    case RazerPaymentChannel.RAZER_GOLD_PIN:
      return 'Razer Gold PIN'
    default:
      throw 'Invalid Razer Gold payment channel'
  }
}

export const RazerPaymentChannelIdentifier = z.union([z.literal('RazerGoldWallet'), z.literal('RazerGoldPIN')])
export type RazerPaymentChannelIdentifier = z.infer<typeof RazerPaymentChannelIdentifier>

export const razerPaymentChannelToIdentifier = (paymentChannel: RazerPaymentChannel): RazerPaymentChannelIdentifier => {
  switch (paymentChannel) {
    case RazerPaymentChannel.RAZER_GOLD_WALLET:
      return 'RazerGoldWallet'
    case RazerPaymentChannel.RAZER_GOLD_PIN:
      return 'RazerGoldPIN'
    default:
      throw 'Invalid Razer Gold payment channel'
  }
}
