import { memo } from 'react'

import { GameId, ProductContent } from '@common'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import BgFlare from '@/assets/images/storefronts/clashofclans/shop_item_bg_flare.png'
import { useLocale } from '@/features/locale/useLocale'

import { CardBase } from './CardBase'
import { useFormattedAmount, useProductImage, useSmallerFont } from './utils'
import { clashResources } from '../../clashofclans/contants'
import { isCrCardProduct } from '../../clashroyale/bundle/helpers'
import { ImageWithFallback } from '../imageWithFallback/ImageWithFallback'
import { StoreTypography } from '../typography/StoreTypography'

import base from './cardBase.module.scss'
import styles from './cardProduct.module.scss'

const AnimatedImageWithFallbackNoSSR = dynamic<{
  animationSrc: string
  fallbackImage: string
  safariAnimationSrc?: string | undefined
}>(
  () =>
    import('../../_storeBase/imageWithFallback/AnimatedImageWithFallback').then((mod) => mod.AnimatedImageWithFallback),
  { ssr: false },
)

type CardProps = {
  size: 'size-bundle-xs' | 'size-bundle-sm' | 'size-bundle-lg' | 'size-product-md' | 'size-product-page'
  offerType?: 'bundle' | 'offer'
  game: GameId
  content: ProductContent
  title: string
  productPage?: boolean
  offerContent?: boolean
  hiddenTitle?: boolean
  storeSpecial?: boolean
  cocSingleItem?: boolean
  cosmeticOfferProductImage?: string
}

export const CardProduct = memo(
  ({
    size,
    offerType,
    game,
    content,
    title,
    productPage = false,
    offerContent = false,
    hiddenTitle,
    storeSpecial = false,
    cocSingleItem = false,
    cosmeticOfferProductImage,
  }: CardProps) => {
    const locale = useLocale()

    // get the product image from public folder with the product id
    const { imageSrc, isAnimated, animatedSrc, safariAnimatedSrc } = useProductImage({
      game: game,
      productId: content.product.id,
      itemType: content.product.itemType,
    })

    const productImg = cosmeticOfferProductImage ?? imageSrc

    // format the amount to have spaces between the thousands and millions etc with spaces
    const formattedAmount = useFormattedAmount(content.amount)

    const smallerFont = useSmallerFont({ contentAmount: content.amount, size: size })
    const isCrBundle = offerType === 'bundle' && game === 'clashroyale'

    const isBundleLg = size === 'size-bundle-lg'
    const bundleLgLargerFont = isBundleLg && content.amount < Math.pow(10, 6)

    const displayClashXmultiplier = game === 'clashofclans' && !clashResources.includes(content.product.id)
    const displayRoyaleXmultiplier = game === 'clashroyale' && isCrCardProduct(content.product.id)

    // Always hide "Medal event hard currency" title (assetId 3000018), it is for internal use only
    const hideMedalEventTitle = content.product.id === '3000018'

    return (
      <CardBase
        className={cx(base[game], base.product, styles.cardProduct, styles[game], styles[size], {
          [base.productPage]: productPage,
          [base.storeSpecial]: storeSpecial,
          [base.cocSingleItem]: cocSingleItem,
        })}
        offerContent={offerContent}
      >
        {game !== 'clashroyale' && !hiddenTitle && !hideMedalEventTitle && (
          <StoreTypography
            tag="h3"
            style="outlined"
            textSize={isBundleLg ? 'body' : 'sm'}
            className={cx(styles.title, styles[`locale-${locale}`])}
          >
            {title}
          </StoreTypography>
        )}
        {game !== 'clashroyale' && (
          <div className={styles.bgFlare}>
            <Image src={BgFlare} unoptimized alt="product flare" fill style={{ objectFit: 'contain' }} sizes="200px" />
          </div>
        )}
        <div
          className={cx(styles.productImg, {
            [styles['crBundle']]: isCrBundle,
            [styles['animated']]: isAnimated,
            [styles.fullSize]: cocSingleItem,
          })}
        >
          {isAnimated ? (
            <AnimatedImageWithFallbackNoSSR
              animationSrc={animatedSrc}
              fallbackImage={productImg}
              safariAnimationSrc={safariAnimatedSrc}
            />
          ) : (
            <ImageWithFallback productImage={productImg} title={title} />
          )}
        </div>
        <StoreTypography
          tag="div"
          textSize={smallerFont ? 'xs' : bundleLgLargerFont ? 'md' : 'body'}
          style="outlined"
          className={cx(styles.amount, styles[game], { [styles.bundle]: offerType === 'bundle' })}
        >
          {displayRoyaleXmultiplier && 'x'}
          {formattedAmount}
          {displayClashXmultiplier && 'x'}
        </StoreTypography>
      </CardBase>
    )
  },
)

CardProduct.displayName = 'CardProduct'
