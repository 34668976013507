import { memo } from 'react'

import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import SingleStar from '@/assets/images/storefronts/clashofclans/single_small_star.png'
import StarCoin from '@/assets/images/storefronts/clashofclans/star_coin.png'
import { useLocale } from '@/features/locale/useLocale'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './valueIndicator.module.scss'

interface Props {
  value: number
  variant?: 'onboarding' | 'bundle'
}

export const ClashValueIndicator = memo(({ value, variant }: Props) => {
  const t = useTranslations()
  const locale = useLocale()

  const isOnboarding = variant === 'onboarding'
  const isBundle = variant === 'bundle'

  return (
    <div className={cx(styles.clashValueIndicator, { [styles.onboarding]: isOnboarding })}>
      <div className={styles.multiplierWrapper}>
        <div className={styles.icons}>
          <Image src={StarCoin} alt="" width={isOnboarding ? 60 : 18} className={styles.starCoin} />
          <Image src={SingleStar} alt="" width={isOnboarding ? 32 : 10} />
        </div>
        <StoreTypography tag="div" textSize="lg" style="outlined" lineHeight="tight" className={styles.valueNumber}>
          {value}
          <StoreTypography tag="span" textSize="sm" style="outlined" lineHeight="tight">
            x
          </StoreTypography>
        </StoreTypography>
      </div>
      <div className={styles.textWrapper}>
        <StoreTypography
          tag="div"
          textSize="sm"
          style="outlined"
          lineHeight="tight"
          className={cx(styles.valueText, styles[locale], { [styles.bundle]: isBundle })}
        >
          {t('value').toLowerCase()}
        </StoreTypography>
      </div>
    </div>
  )
})

ClashValueIndicator.displayName = 'ClashValueIndicator'
