import React from 'react'

import { isStoreOfferProduct, ProductV2 } from '@common'

import { getRibbonsFromTags } from '@/features/storefronts/_storeBase/card/v2/OfferCard'
import { Ribbon } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Ribbons'
import { BrawlPdpProductHero } from '@/features/storefronts/_storeBase/productPage/v2/games/brawlstars/PdpProductHero'
import { SquadPdpProductHero } from '@/features/storefronts/_storeBase/productPage/v2/games/squadbusters/PdpProductHero'
import { PdpProductHeroHeader } from '@/features/storefronts/_storeBase/productPage/v2/ProductHeroHeader'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useTranslatable } from '@/localization/useTranslatable'

import styles from './productHero.module.scss'

interface Props {
  product: ProductV2
  ribbons?: Ribbon[]
}
function GameSpecificHero({ product, ribbons }: Props) {
  switch (product.game) {
    case 'brawlstars':
      return <BrawlPdpProductHero product={product} ribbons={ribbons || []} className={styles.HeroContainer} />
    case 'squadbusters':
      return <SquadPdpProductHero product={product} ribbons={ribbons || []} className={styles.HeroContainer} />
    case 'clashofclans':
      // Not implemented
      return <div />
    case 'clashroyale':
      // Not implemented
      return <div />
    case 'hayday':
      // Not implemented
      return <div />
    default:
      throw new Error('Unsupported game')
  }
}
export const PdpProductHero = ({ product }: Props) => {
  const translatable = useTranslatable()
  const ribbons = isStoreOfferProduct(product) ? getRibbonsFromTags(product) : []
  return (
    <div className={styles.ProductHeroContainer}>
      <div className={styles.ProductHeroHeader}>
        <StoreTypography tag="h1" style="outlined" textSize="lg" className={styles.Title}>
          {translatable(product.title)}
        </StoreTypography>
        <PdpProductHeroHeader product={product} />
      </div>
      <div className={styles.ProductHero}>
        <GameSpecificHero product={product} ribbons={ribbons} />
      </div>
    </div>
  )
}
