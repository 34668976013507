import { z } from 'zod'
import { GameId, isGameId } from '../models/game'

export const timedEnableSchema = z.object({
  rule: z.literal('enabled-after'),
  date: z.date(),
})

export const enabledSchema = z.union([z.literal('disabled'), z.literal('enabled'), z.literal('enabled_qa')])

export const perGameEnabledSchema = z.string().transform((val) =>
  val.split(',').reduce<Record<GameId, z.infer<typeof enabledSchema>>>(
    (acc, gameStatus) => {
      const [gameId, isEnabled] = gameStatus.split('|')
      if (!isGameId(gameId)) {
        throw new Error(`Invalid game id: ${gameId}`)
      }
      acc[gameId] = enabledSchema.parse(isEnabled)
      return acc
    },
    {
      clashofclans: 'disabled',
      clashroyale: 'disabled',
      brawlstars: 'disabled',
      hayday: 'disabled',
      squadbusters: 'disabled',
    } as const,
  ),
)

export type PerGameEnabled = z.infer<typeof perGameEnabledSchema>

const enabledAfterRegex = () => /^enabled-after (\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d)$/g
export const enabledAfterSchema = z
  .string()
  .regex(enabledAfterRegex(), 'Invalid enabled-after pattern')
  .transform((val, ctx) => {
    const match = [...val.matchAll(enabledAfterRegex())]
    if (match?.[0]?.[1] === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid datetime in enabled-after',
      })
      return z.NEVER
    }
    // Add suffix Z so that the string is interpreted as UTC
    const asUTCDateTime = `${match[0][1]}Z`
    const date = new Date(asUTCDateTime)

    if (!z.coerce.date().safeParse(date).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid datetime in enabled-after',
      })
      return z.NEVER
    }

    return {
      rule: 'enabled-after',
      date,
    }
  })
