import React from 'react'

import cx from 'classnames'

import styles from './IconButton.module.scss'

type IconButtonProps = React.PropsWithChildren<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  'data-cy'?: string
  'data-after-content'?: number | string
}>

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  { onClick, className, children, 'data-cy': dataCy, 'data-after-content': dataAfterContent },
  ref,
) {
  return (
    <button
      data-cy={dataCy}
      data-after-content={dataAfterContent}
      onClick={(e) => onClick?.(e)}
      className={cx(styles.IconButton, className)}
      ref={ref}
    >
      {children}
    </button>
  )
})
