import * as React from 'react'

import { Overlay, OverlayProps } from '../features/overlay/Overlay'

type Modal = Extract<OverlayProps['overlay'], { type: string }>

type ModalContextValue = {
  openModal: (modal: Modal) => void
  closeModal: () => void
  modalType: Modal['type'] | null
}

const ModalContext = React.createContext<ModalContextValue | null>(null)

export function useModalContext() {
  const context = React.useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

export function ModalContextProvider({ children }: { children: React.ReactNode }) {
  const [modal, setModal] = React.useState<Modal | null>(null)

  const openModal = React.useCallback((modal: Modal) => setModal(modal), [setModal])
  const closeModal = React.useCallback(() => setModal(null), [setModal])
  const modalType = modal?.type || null

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modalType }}>
      {children}
      <Overlay overlay={modal} />
    </ModalContext.Provider>
  )
}
