import { GameId, ProductV2, isStoreOfferProduct, STORE_SPECIAL } from '@common'

export type TagStyle = 'outline' | 'no-outline'
export type TagVariant = 'Light' | 'Dark' | 'Purple' | 'Golden' | 'Bonus' | 'BonusDark'
export type TagIcon = 'Points' | 'IdReward' | 'Mission'

export const hasOutline = (gameId: GameId): boolean => {
  switch (gameId) {
    case 'brawlstars':
    case 'squadbusters':
      return true
    default:
      return false
  }
}

export const getBonusVariant = (product: ProductV2): TagVariant => {
  const { game: gameId } = product
  const isSingleItemProduct = product.contents.length === 1
  const isStoreSpecial = isStoreOfferProduct(product) && product.tags?.includes(STORE_SPECIAL)

  if (isSingleItemProduct && !isStoreSpecial) {
    return 'Dark'
  }

  switch (gameId) {
    case 'brawlstars':
    case 'squadbusters':
    case 'clashroyale':
      return 'Light'
    default:
      return 'Dark'
  }
}

export const getIdRewardVariant = (gameId: GameId): TagVariant => {
  switch (gameId) {
    case 'brawlstars':
    case 'squadbusters':
    case 'clashroyale':
      return 'Purple'
    default:
      return 'Golden'
  }
}
