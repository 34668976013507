import { supportedLanguages } from '@common'
import Image from 'next/legacy/image'
import { useTranslations } from 'next-intl'

import HelpIcon from '@/assets/images/icons/help.svg'
import { Tooltip } from '@/components/Tooltip'
import { useLocale } from '@/features/locale/useLocale'

import styles from './CreatorBoostHelp.module.scss'

export const CreatorBoostHelp: React.FC = () => {
  const locale = useLocale()
  const t = useTranslations()
  const position = supportedLanguages[locale].rightToLeft ? 'right' : 'left'

  return (
    <div className={styles.CreatorBoostHelp}>
      <Tooltip
        position={position}
        icon={<Image src={HelpIcon} width={20} height={20} objectFit="contain" alt="creatorboost-help" />}
      >
        {t('summary_creator_desc')}
      </Tooltip>
    </div>
  )
}
