import * as React from 'react'

import { useTranslations } from 'next-intl'

import { SupportIcon } from '@/components/Icons'

import styles from './HelpShift.module.scss'

export function HelpShift() {
  const t = useTranslations()
  return (
    <div className={styles.Container} onClick={() => window.Helpshift?.('open')}>
      <div className={styles.HelpShiftIcon}>
        <SupportIcon height={40} width={40} fill="transparent" />
      </div>
      <div className={styles.Content}>{t('help_helpshift')}</div>
    </div>
  )
}
