import * as React from 'react'

export function useApi<T extends (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>>>(
  apiFn: T,
  onError: (error: unknown) => Awaited<ReturnType<T>>,
) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const fn = React.useCallback(
    async (...args: Parameters<T>) => {
      setIsLoading(true)
      try {
        const response = await apiFn(...args)
        setIsLoading(false)
        return response
      } catch (error) {
        setIsError(true)
        setIsLoading(false)
        return onError(error)
      }
    },
    [apiFn, setIsLoading, onError],
  )
  return [fn, { isLoading, isError }] as const
}
