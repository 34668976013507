import { StoreBonusStatusResponse, StoreRewardItem } from '@common/models/bonus'

import { CartItem } from '@/utils/cart'

interface BonusUtils {
  bonusStatus: StoreBonusStatusResponse
  items: CartItem[]
}

interface BonusItemIndexUtils {
  bonusStatus: StoreBonusStatusResponse
  item?: StoreRewardItem
}

export const calculateProgressPercentage = (value: number, lowerLimit: number, upperLimit: number) => {
  if (value < lowerLimit) return 0
  if (value > upperLimit) return 100

  const total = Math.abs(upperLimit - lowerLimit)
  const progress = Math.abs(value - lowerLimit)
  return total !== 0 ? (progress * 100) / total : 0
}

export const extractBonusValues = ({ bonusStatus, items }: BonusUtils) => {
  const balance = bonusStatus?.balance || 0
  const bonus = getBonusFromCartItems(items)
  const totalBalance = balance + bonus
  const bonusItems = bonusStatus?.items || []
  return { balance, bonus, totalBalance, bonusItems }
}

function getBonusFromCartItems(items: CartItem[]) {
  return items.reduce((bonus, item) => bonus + (item.product.bonus ?? 0) * item.quantity, 0)
}

export const getDeliveredBonuses = ({ bonusStatus, items }: BonusUtils) => {
  const { balance, bonus, totalBalance, bonusItems } = extractBonusValues({ bonusStatus, items })
  return bonus > 0 ? bonusItems.filter((item) => item.cost > balance && item.cost <= totalBalance && !item.claimed) : []
}

export const getNextItem = ({ bonusStatus, items }: BonusUtils) => {
  const { totalBalance, bonusItems } = extractBonusValues({ bonusStatus, items })
  return bonusItems.find((item) => item.cost > totalBalance)
}

export const getRemainingPoints = ({ bonusStatus, items }: BonusUtils) => {
  const { totalBalance } = extractBonusValues({ bonusStatus, items })
  const nextItem = getNextItem({ bonusStatus, items })
  return nextItem?.cost ? nextItem.cost - totalBalance : 0
}

export const getProgressPercentage = ({ bonusStatus, items }: BonusUtils) => {
  const { totalBalance, bonusItems } = extractBonusValues({ bonusStatus, items })
  const previousItem = [...bonusItems].reverse().find((item) => item.cost <= totalBalance)
  const nextItem = getNextItem({ bonusStatus, items })
  return calculateProgressPercentage(totalBalance, previousItem?.cost || 0, nextItem?.cost || 0)
}

export const getBonusItemIndex = ({ bonusStatus, item: bonusItem }: BonusItemIndexUtils) => {
  if (bonusItem?.seasonId !== undefined && bonusStatus?.seasonId !== bonusItem?.seasonId) {
    return -1
  }
  const currentSeasonItems = bonusStatus?.items?.filter(
    (item) => item.seasonId === bonusStatus?.seasonId || item.seasonId === undefined,
  )
  const index = currentSeasonItems?.findIndex((item) => item.id === bonusItem?.id)
  return index !== undefined && index >= 0 ? index + 1 : -1
}

export const getBonusTrackCompletionStatus = ({
  bonusStatus,
  items,
}: BonusUtils): 'UNAVAILABLE' | 'NOT_COMPLETE' | 'TENTATIVE' | 'EXHAUSTIVE' => {
  const tentativeBonuses = getDeliveredBonuses({ bonusStatus, items })
  const { bonusItems, balance } = extractBonusValues({ bonusStatus, items })
  const bonusTrackTotal = bonusItems.at(-1)?.cost ?? 0
  const isExhaustive =
    (bonusTrackTotal <= balance && bonusItems.filter((item) => !item.available).length === 0) ||
    bonusItems.every((item) => item.claimed)

  if (bonusStatus === null || bonusItems.length === 0) {
    return 'UNAVAILABLE'
  }

  if (isExhaustive) {
    return 'EXHAUSTIVE'
  }

  if (bonusItems.filter((item) => !item.available).length <= tentativeBonuses.length) {
    return 'TENTATIVE'
  }

  return 'NOT_COMPLETE'
}
