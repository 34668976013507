import { useState, useCallback, CSSProperties } from 'react'

import { ProductV2, formatProductPrice, hasReachedPurchaseQuota } from '@common'
import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { ButtonProps, Button } from '@/components/buttons/Button'
import { Lock, CartButtonIcon } from '@/components/Icons'
import { FailureIconAnimated } from '@/components/Icons/Failure'
import { SuccessIconAnimated } from '@/components/Icons/Success'
import { useCartContext } from '@/contexts/CartContext'
import { useMarketContext } from '@/contexts/MarketContext'
import { useModalContext } from '@/contexts/ModalContext'
import { useLocale } from '@/features/locale/useLocale'
import { LOGIN_STATUS } from '@/features/user/userSlice'
import { useAppSelector } from '@/utils/useAppSelector'

import styles from './BuyButton.module.scss'

type Variant = 'Light' | 'LightBordered' | 'Dark' | 'DarkBordered'

type Props = Omit<ButtonProps, 'onClick' | 'content'> & {
  product: ProductV2
  onAddToCart?: () => void
  textScaling?: TextScale
  variant?: Variant
  style?: CSSProperties
}

export function BuyButton({ variant = 'Dark', ...props }: Props) {
  const { product, onAddToCart, textScaling, ...rest } = props

  const { addItem, quotaForItem } = useCartContext()
  const { openModal } = useModalContext()
  const market = useMarketContext('BuyButton')

  const locale = useLocale()
  const t = useTranslations()
  const isLoggedIn = useAppSelector((state) => state.user.loginStatus === LOGIN_STATUS.LOGGED_IN)
  const [isAdding, setIsAdding] = useState(false)

  const purchaseQuotaReached = hasReachedPurchaseQuota(product)
  const content = formatProductPrice(product, market, locale)

  const onClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (!isLoggedIn) {
        openModal({ type: 'LOGIN' })
      } else {
        const targetElement = e.currentTarget
        targetElement.style.width = `${targetElement.offsetWidth}px`
        setIsAdding(true)
        setTimeout(() => {
          setIsAdding(false)
          targetElement.style.width = 'auto'
        }, 2000)
        addItem(product)
        onAddToCart?.()
      }
    },
    [addItem, onAddToCart, product, isLoggedIn, openModal],
  )

  if (purchaseQuotaReached) {
    return (
      <Button
        {...rest}
        data-type="buy"
        additionalClasses={cx(styles.StoreFrontBuyButton, styles.Owned)}
        disabled={purchaseQuotaReached}
      >
        {t('gen_btn_purchased')}
      </Button>
    )
  }

  return (
    <Button
      {...rest}
      onClick={onClick}
      data-type="buy"
      additionalClasses={cx(styles.StoreFrontBuyButton, styles[variant])}
      disabled={quotaForItem(product).available === 0}
    >
      <ButtonContent
        isAdding={isAdding}
        addedSuccesfully={true}
        variant={variant}
        content={content}
        isLocked={!content}
        textScaling={textScaling}
      />
    </Button>
  )
}

function shouldUseDarkIcon(variant: Variant) {
  return ['Light', 'LightBordered'].includes(variant)
}

type TextScale = 'Small' | 'XSmall' | 'XXSmall'

const ButtonContent: React.FC<{
  isAdding: boolean
  addedSuccesfully: boolean
  variant: Variant
  content: React.ReactNode
  isLocked?: boolean
  textScaling?: TextScale
}> = ({ isAdding, addedSuccesfully, variant, content, isLocked, textScaling }) => {
  if (isLocked) {
    return <Lock className={cx(styles.Lock, { [styles.DarkLock]: shouldUseDarkIcon(variant) })} />
  }

  if (isAdding) {
    return addedSuccesfully ? (
      <SuccessIconAnimated
        height={28}
        width={28}
        className={cx(styles.Icon, { [styles.DarkIcon]: shouldUseDarkIcon(variant) })}
      />
    ) : (
      <FailureIconAnimated
        height={28}
        width={28}
        className={cx(styles.Icon, { [styles.DarkIcon]: shouldUseDarkIcon(variant) })}
      />
    )
  }

  return (
    <div className={cx(styles.ContentWrapper, textScaling && styles[textScaling])}>
      <CartButtonIcon
        className={cx(
          styles.Icon,
          styles.WithContent,
          { [styles.DarkIcon]: shouldUseDarkIcon(variant) },
          textScaling && styles[textScaling],
        )}
      />
      {content}
    </div>
  )
}
