import * as React from 'react'

import { StoreOfferProduct } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import { Flare } from '@/features/storefronts/_storeBase/flare/Flare'
import { isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'

import { BrawlStarsHeading } from './components/BrawlStarsHeading'
import { BrawlStarsOfferCardContent } from './components/BrawlStarsOfferCardContent'
import { Countdown, Footer, ValueBadge } from '../../../shared'
import { Container } from '../../shared/Container'
import { Ribbon, Ribbons } from '../../shared/Ribbons'

import BrawlerBackground from 'assets/images/components/BrawlerCard/brawlercard-hero-bg.png'

import styles from './BrawlStarsOfferCard.module.scss'

type Props = {
  product: StoreOfferProduct
  offerCardType?: 'default' | 'special'
  ribbons: Ribbon[]
}

export function BrawlStarsOfferCard({ product, ribbons, offerCardType }: Props) {
  const isMultiItemOffer = product.contents.length > 1
  const isSpecialOffer = offerCardType === 'special'
  const isSingleBrawlerOrSkin = !isMultiItemOffer && isBrawlerOrSkin(product.contents[0])

  return (
    <Container
      product={product}
      className={cx(styles.Container, {
        [styles.MultiItemContainer]: isMultiItemOffer,
        [styles.SingleItemContainer]: !isMultiItemOffer,
        [styles.SpecialOfferContainer]: isSpecialOffer,
      })}
    >
      {!isSpecialOffer && !isMultiItemOffer && !isSingleBrawlerOrSkin && <Flare />}
      {!isSpecialOffer && isSingleBrawlerOrSkin && (
        <Image className={styles.Background} src={BrawlerBackground} alt="" fill />
      )}
      <Countdown product={product as StoreOfferProduct} />
      {ribbons.length === 0 ? <ValueBadge product={product} /> : <Ribbons ribbons={ribbons} />}
      <BrawlStarsHeading product={product} />
      <BrawlStarsOfferCardContent product={product} offerCardType={offerCardType} />
      <Footer product={product} className={styles.Footer} />
    </Container>
  )
}
