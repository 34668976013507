import * as React from 'react'

import { applicationEnvToGameId, GameId, isStoreOfferProduct, ProductV2 } from '@common'
import { useRouter } from 'next/router'

import { Cart } from '@/features/storefronts/_storeBase/cart/Cart'
import { saveCart } from '@/nextApi'
import { CartItem } from '@/utils/cart'
import { isSeasonPass } from '@/utils/products'
import { useAppSelector } from '@/utils/useAppSelector'

import { Market } from '../../../common/src/models/db/joins'
import { useLocale } from '../features/locale/useLocale'
import { snowplowAddToCart, snowplowRemoveFromCart } from '../utils/snowplow'

export const CART_MAX_PRODUCT_QUANTITY = 30
export const CART_MAX_SINGLE_PRODUCT_QUANTITY = 10
export const CART_MAX_SEASON_PASS_PRODUCT_QUANTITY = 8

export const QUERY_INELIGIBLE_ID = 'ineligibleItem'
export const QUERY_INELIGIBLE_REASON = 'ineligibleReason'

type CartContextValue = {
  isOpen: boolean
  openCart: () => void
  closeCart: () => void
  addItem: (item: CartItem['product']) => void
  updateItem: (item: CartItem['product'], quantity: CartItem['quantity']) => void
  items: CartItem[]
  ineligibleItem?: { product: ProductV2; reason?: string }
  quotaForItem: (product: ProductV2) => { quota: number; available: number }
}

const CartContext = React.createContext<CartContextValue>({
  isOpen: false,
  openCart: () => undefined,
  closeCart: () => undefined,
  addItem: () => undefined,
  updateItem: () => undefined,
  items: [],
  ineligibleItem: undefined,
  quotaForItem: () => ({ quota: CART_MAX_SINGLE_PRODUCT_QUANTITY, available: CART_MAX_SINGLE_PRODUCT_QUANTITY }),
})

export function useCartContext() {
  const context = React.useContext(CartContext)
  if (!context) {
    throw new Error('useCartContext must be used within a CartContextProvider')
  }
  return context
}

type CartProviderProps = React.PropsWithChildren<{
  market: Market
  gameId?: GameId
  items?: CartItem[]
  ineligibleFor?: {
    id: string
    reason: string
  }
}>

export function CartContextProvider(props: CartProviderProps) {
  const [ineligibleFor, setIneligibleFor] = useIneligibility(props)
  const [isOpen, setIsOpen] = React.useState(false)
  const [_items, setItems] = React.useState<CartItem[] | undefined>(props.items)
  // props.items is undefined on checkout page. If the user refreshes the page
  // and goes back to the game page, the cart will be empty. This ensures that
  // the server-returned items are used if available.
  const items = React.useMemo(() => _items ?? props.items ?? [], [_items, props.items])
  const locale = useLocale()

  const inventory = useAppSelector((state) => state.user.inventory)
  const passesInInventory = React.useMemo(() => {
    if (!props.gameId || !inventory) {
      return 0
    }
    const nonEventPassesInInveotry = inventory.items
      .filter((item) => item.applications.some((application) => applicationEnvToGameId(application) === props.gameId))
      .filter((item) => item.type === 'SEASON_PASS')
      .filter((item) => item.tier === 'BASIC' || item.tier === 'PREMIUM')
    return nonEventPassesInInveotry.length
  }, [inventory, props.gameId])

  const openCart = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const closeCart = React.useCallback(() => setIsOpen(false), [setIsOpen])

  const updateItem = React.useCallback(
    (product: CartItem['product'], quantity: CartItem['quantity']) => {
      const newQuantity = cartQuantity(items) + quantity
      if (newQuantity > CART_MAX_PRODUCT_QUANTITY) {
        return
      }

      setIneligibleFor(undefined)

      setItems((previousItems) => {
        const { hadItemInCart, updatedItems } = updateCartItems(previousItems ?? [], product, quantity)

        quantity > 0
          ? snowplowAddToCart(updatedItems, locale, props.market?.currencyCountry, props.market?.currencyCode)
          : snowplowRemoveFromCart(updatedItems, locale, props.market?.currencyCountry, props.market?.currencyCode)

        saveCart(props.gameId, updatedItems)
          .then((res) => (res.ok ? undefined : Promise.reject(res)))
          .catch((error) => {
            console.error('Failed to save cart', error)
            setItems(previousItems)
          })

        if (!hadItemInCart) {
          openCart()
        }

        return updatedItems
      })
    },
    [
      setItems,
      openCart,
      props.gameId,
      setIneligibleFor,
      items,
      locale,
      props.market?.currencyCountry,
      props.market?.currencyCode,
    ],
  )

  const addItem = React.useCallback((product: CartItem['product']) => updateItem(product, 1), [updateItem])

  const quotaForItem = React.useCallback(
    (product: ProductV2) => {
      if (cartQuantity(items) >= CART_MAX_PRODUCT_QUANTITY) {
        return {
          quota: 0,
          available: 0,
        }
      }

      const cartItem = items.find((item) => item.product.id === product.id)
      const cartItemQuantity = cartItem?.quantity ?? 0

      if (!isStoreOfferProduct(product) || !product.quotaLimit) {
        const maxQuantity = isSeasonPass(product)
          ? Math.max(CART_MAX_SEASON_PASS_PRODUCT_QUANTITY - passesInInventory, 0)
          : CART_MAX_SINGLE_PRODUCT_QUANTITY
        return {
          quota: maxQuantity,
          available: maxQuantity - cartItemQuantity,
        }
      }

      const quotaConsumed = product.quotaConsumed ?? 0
      const maxQuota = Math.min(product.quotaLimit, 10)
      return {
        quota: maxQuota - quotaConsumed,
        available: maxQuota - quotaConsumed - cartItemQuantity,
      }
    },
    [items, passesInInventory],
  )

  const ineligibleForItem = items.find((item) => item.product.id === ineligibleFor?.id)
  const ineligibleItem = ineligibleForItem
    ? { product: ineligibleForItem.product, reason: props.ineligibleFor?.reason }
    : undefined

  return (
    <CartContext.Provider
      value={{
        isOpen,
        openCart,
        closeCart,
        addItem,
        updateItem,
        items: withIneligibility(items, ineligibleFor?.id),
        quotaForItem,
        ineligibleItem,
      }}
    >
      {props.children}
      {props.gameId ? <Cart gameId={props.gameId} /> : null}
    </CartContext.Provider>
  )
}

function updateCartItems(previousItems: CartItem[], product: CartItem['product'], quantity: CartItem['quantity']) {
  const hasItemInCart = previousItems.some((previousItem) => previousItem.product.id === product.id)

  if (hasItemInCart) {
    const newItems = previousItems
      .map((previousItem) =>
        previousItem.product.id === product.id
          ? Object.assign({}, previousItem, { quantity: previousItem.quantity + quantity })
          : previousItem,
      )
      .filter((item) => item.quantity > 0)

    return { hadItemInCart: hasItemInCart, updatedItems: newItems }
  }

  const newItems = [...previousItems, { product, quantity }]
  return { hadItemInCart: hasItemInCart, updatedItems: newItems }
}

function withIneligibility(items?: CartItem[], ineligibleItemId?: string): CartItem[] {
  if (!items) {
    return []
  }

  if (!ineligibleItemId) {
    return items
  }

  return items.map((item) => ({
    ...item,
    ineligible: item.product.id === ineligibleItemId,
  }))
}

function cartQuantity(items: CartItem[]) {
  return items.reduce((total, item) => total + item.quantity, 0)
}

// This is a workaround, next.js only supports redirects on the server side
// Would be easier if we could just return a different page without the redirect
function useIneligibility(props: CartProviderProps) {
  const router = useRouter()
  const [ineligibleFor, setIneligibleFor] = React.useState<CartProviderProps['ineligibleFor']>(props.ineligibleFor)

  const setIneligibleForHandler = React.useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.has(QUERY_INELIGIBLE_ID) && queryParams.has(QUERY_INELIGIBLE_REASON)) {
      setIneligibleFor({
        id: queryParams.get(QUERY_INELIGIBLE_ID) ?? '',
        reason: queryParams.get(QUERY_INELIGIBLE_REASON) ?? '',
      })
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [setIneligibleFor])

  React.useEffect(() => {
    router.events.on('routeChangeComplete', setIneligibleForHandler)
    return () => {
      router.events.off('routeChangeComplete', setIneligibleForHandler)
    }
  }, [setIneligibleForHandler, router])

  return [ineligibleFor, setIneligibleFor] as const
}
