import { useCallback, useEffect } from 'react'

import { enabledIn } from '@common'
import { captureMessage } from '@sentry/nextjs'

import { config } from '../../config'

const { STAGE } = config

function isImage(et: EventTarget | null): et is HTMLImageElement {
  return et !== null && et instanceof HTMLImageElement && et.tagName === 'IMG'
}

export function captureImageError(src: string) {
  const sentryEnabled = enabledIn(STAGE, ['integration', 'staging', 'production'])
  if (sentryEnabled) {
    captureMessage(`[BROKEN IMAGE]`, {
      tags: {
        missingImage: src,
      },
      extra: { src },
    })
  } else {
    console.log(`Failed to load image: ${src}`)
  }
}

export const useCaptureBrokenImages = () => {
  const handleError = useCallback((event: ErrorEvent) => {
    const target = event.target
    if (isImage(target)) {
      captureImageError(target.src)
    }
  }, [])

  useEffect(() => {
    const targetElement = document.body
    targetElement.addEventListener('error', handleError, true)
    return () => {
      targetElement.removeEventListener('error', handleError, true)
    }
  }, [handleError])
}
