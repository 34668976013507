import React from 'react'

import { isStoreOfferProduct, ProductV2 } from '@common'

import { useOfferCountdown } from '@/features/products/useOfferCountdown'
import { BuyButton } from '@/features/storefronts/_storeBase/BuyButton/BuyButton'
import { Tags } from '@/features/storefronts/_storeBase/card/v2/shared'
import { TagVariant } from '@/features/storefronts/_storeBase/card/v2/shared/Tags/common'

import styles from './productHeroFooter.module.scss'

interface Props {
  product: ProductV2
}

function getButtonVariant(product: ProductV2) {
  /** OfferCard variants. */
  switch (product.game) {
    case 'clashofclans':
    case 'clashroyale':
      return 'Dark'
    case 'squadbusters':
    case 'brawlstars':
      return 'LightBordered'
    default:
      return 'Light'
  }
}

function getTagVariant(product: ProductV2): TagVariant {
  switch (product.game) {
    case 'clashofclans':
    case 'clashroyale':
      return 'Dark'
    case 'squadbusters':
    case 'brawlstars':
      return 'Light'
    default:
      return 'Light'
  }
}

export const PdpHeroFooter = ({ product }: Props) => {
  const { isTimedOffer, countdownExpired } = useOfferCountdown({ product: product })
  const offerNotStarted =
    isStoreOfferProduct(product) && product.schedule !== null && product.schedule.purchaseStart > Date.now()
  const disabled = (isTimedOffer && countdownExpired) || offerNotStarted
  return (
    <div className={styles.FooterContainer}>
      <div className={styles.Footer}>
        <Tags product={product} variant={getTagVariant(product)} />
        <BuyButton
          style={{ height: '48px' }}
          variant={getButtonVariant(product)}
          disabled={disabled}
          product={product}
          data-cy={`bundle-${product.id}`}
        />
      </div>
    </div>
  )
}
