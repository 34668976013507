import { StoreBonusStatusResponse } from '@common/models/bonus'

const ONE_DAY_MILLIS = 24 * 60 * 60 * 1000

export function determineCurrentTaskTimeSpan(endTimeMillis?: number) {
  if (!endTimeMillis) {
    return {
      start: 0,
      end: 2147483648000,
    }
  }
  return {
    start: endTimeMillis - ONE_DAY_MILLIS,
    end: endTimeMillis,
  }
}

export function isWithinCurrentTaskTimeSpan(datetimeMillis: number, season?: { start: number; end: number }) {
  if (!season) {
    return false
  }
  return season.start <= datetimeMillis && datetimeMillis <= season.end
}

type MissionsData = Pick<NonNullable<StoreBonusStatusResponse>, 'missions' | 'completedMissions'>

type MissionRenderable = {
  currentCadenceEnd: number
  missions: Array<{
    missionId: string
    isCompleted: boolean
    title: string
    rewardPoints: number
    isToday: boolean
  }>
}

export function nextMidnightMillis(now: Date, timezone: string) {
  const timezoneDate = new Date(now.toLocaleString('en-US', { timeZone: timezone }))

  const midnight = new Date(timezoneDate)
  midnight.setHours(24, 0, 0, 0)

  const diff = midnight.getTime() - timezoneDate.getTime()

  return now.getTime() + diff
}

const ONE_DAY = 24 * 60 * 60 * 1000

export function isTodays(now: Date, timeStamp: number, timezone: string) {
  const prevMidnight = nextMidnightMillis(now, timezone) - ONE_DAY
  const nextMidnight = nextMidnightMillis(now, timezone)
  return prevMidnight < timeStamp && timeStamp < nextMidnight
}

export function transformMissionsData(
  missionsData: MissionsData | undefined | null,
  timezone: string,
  now: Date,
  locale?: string,
): MissionRenderable {
  const nextMidnight = nextMidnightMillis(now, timezone)
  if (!(missionsData?.missions && missionsData?.completedMissions)) {
    return {
      currentCadenceEnd: nextMidnight,
      missions: [],
    }
  }

  //TODO: refactor: shorter function
  const firstMission = missionsData.missions?.missions.at(0)
  const isTodaysMissionCompleted = !!missionsData.completedMissions.find((mission) =>
    isTodays(now, mission.timestamp, timezone),
  )
  const currentTask = firstMission
    ? [
        {
          missionId: firstMission.missionId,
          isCompleted: isTodaysMissionCompleted,
          title: 'Today',
          rewardPoints: firstMission.rewardPoints,
          isToday: true,
        },
      ]
    : []
  const formatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    timeZone: timezone,
  })
  const sorted = missionsData.completedMissions
    ? missionsData.completedMissions
        .filter((mission) => {
          return !isTodays(now, mission.timestamp, timezone)
        })
        .sort((a, b) => b.timestamp - a.timestamp)
    : []

  const completedTasks =
    sorted.map((task) => ({
      missionId: task.missionId,
      isCompleted: true,
      title: formatter.format(task.timestamp),
      rewardPoints: task.rewardPoints,
      isToday: false,
    })) || []
  return {
    currentCadenceEnd: nextMidnight,
    missions: [...currentTask, ...completedTasks],
  }
}
