import { z } from 'zod'
import { gameIdSchema } from './game'

// Currently only game slugs are supported content slugs
const HeroContent = z.object({
  src: z.string().nullable(),
  imagePosition: z.object({
    x: z.number(),
    y: z.number(),
  }),
})

const HeroModule = z.object({
  mobile: HeroContent,
  desktop: HeroContent,
})

export const ContentModule = z.object({
  type: z.literal('hero'),
  props: HeroModule,
})

export const StoreContent = z.object({
  slug: gameIdSchema,
  modules: ContentModule.array(),
})

export type StoreContent = z.infer<typeof StoreContent>
export type HeroContent = z.infer<typeof HeroContent>
export type HeroModuleTarget = keyof z.infer<typeof HeroModule>
export type HeroModuleProps = z.infer<typeof HeroModule>
