import { isClient } from './utils/isClient'
import 'intersection-observer'

if (isClient) {
  const supportsContainerQueries = 'container' in document.documentElement.style
  if (!supportsContainerQueries) {
    // eslint-disable-next-line
    // @ts-ignore
    import('container-query-polyfill')
  }
}
