import React from 'react'

import { ProductV2, StoreOfferProduct } from '@common'

import { SquadBustersOfferCardContent } from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/squadbusters/components/SquadBustersOfferCardContent'
import { SquadPattern } from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/squadbusters/components/SquadPattern'
import { Container } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Container'
import { Ribbon, Ribbons } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Ribbons'
import { Countdown, ValueBadge } from '@/features/storefronts/_storeBase/card/v2/shared'

import styles from './PdpProductHero.module.scss'

interface Props {
  product: ProductV2
  ribbons?: Ribbon[]
  className?: string
}

export const SquadPdpProductHero = ({ product, ribbons }: Props) => {
  return (
    <div>
      <Container product={product} className={styles.ContainerStyle}>
        <SquadPattern />
        <Countdown product={product as StoreOfferProduct} />
        {ribbons?.length === 0 ? (
          <ValueBadge product={product as StoreOfferProduct} />
        ) : (
          <Ribbons ribbons={ribbons ?? []} />
        )}
        <SquadBustersOfferCardContent product={product} className={styles.PdpCardContent} showQuantity={false} />
      </Container>
    </div>
  )
}
