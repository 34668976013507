import { SupportedLanguage } from '@common'
import { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies'

import { FeatureFlagsConfig } from '@/features/locale/configSlice'

export const NEXT_LOCALE = 'NEXT_LOCALE'
export const VIEWER_COUNTRY = 'VIEWER_COUNTRY'
export const STORE_FEATURES = 'STORE_FEATURES'
export const OAUTH_LOGIN_STATE = 'OAUTH_LOGIN_STATE'
export const SESSION_COOKIE = 'SESSION_COOKIE'
export const CREATOR_BOOST_COOKIE = 'CREATOR_BOOST'
export const ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED'
export const UNCLAIMED_BONUSES = 'UNCLAIMED_BONUSES'

export const setLocaleCookie = (locale: SupportedLanguage) => {
  document.cookie = `${NEXT_LOCALE}=${locale}; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/`
}

export const setOnboardingCompletedCookie = () => {
  document.cookie = `${ONBOARDING_COMPLETED}=1; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/; SameSite=Strict`
}

export const hasCompletedOnboarding = () => {
  return getCookie(ONBOARDING_COMPLETED) !== null
}

export const setUnclaimedBonusesCookie = () => {
  // unclaimed bonuses cookie to expire in 24hours
  document.cookie = `${UNCLAIMED_BONUSES}=1; max-age=86400;path=/; SameSite=Strict`
}

export const hasUnclaimedBonusesCookie = () => {
  return getCookie(UNCLAIMED_BONUSES) !== null
}

export const setStoreFeaturesCookies = (featureFlags: FeatureFlagsConfig) => {
  document.cookie = `${STORE_FEATURES}=${encodeURIComponent(
    JSON.stringify(featureFlags),
  )}; Path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
}

export const getCreatorBoostCookie = () => getCookie(CREATOR_BOOST_COOKIE)

export const getCookie = (name: string, cookie?: string) => {
  if (typeof document !== 'undefined' || cookie) {
    const data = (cookie ? cookie : document.cookie)
      .split(';')
      .map((pair) => pair.trim().split('='))
      .find(([key]) => key === name)
    if (!data) {
      return null
    }
    return data[1]
  }
  return null
}

export const deleteCookie = (cookie: string) => {
  document.cookie = `${cookie}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const parseJsonCookie = <T>(cookie: string): T | undefined => {
  const value = getCookie(cookie)
  if (value) {
    return JSON.parse(decodeURIComponent(value)) as T
  }
}

export const cookieOptions: Partial<ResponseCookie> = {
  path: '/',
  maxAge: 31536000,
  sameSite: 'strict',
  secure: true,
} as const
