import { useEffect } from 'react'

import { useModalContext } from '../../contexts/ModalContext'
import { getCookie, VIEWER_COUNTRY } from '../../utils/cookies'
import { useAppDispatch } from '../../utils/useAppDispatch'
import { useAppSelector } from '../../utils/useAppSelector'
import { setViewerCountry } from '../locale/configSlice'

export function useResolveViewerCountry() {
  const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
  const { openModal } = useModalContext()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!viewerCountry) {
      const viewerCountryFromCookie = getCookie(VIEWER_COUNTRY)
      if (viewerCountryFromCookie) {
        dispatch(setViewerCountry(viewerCountryFromCookie))
      }
      return
    }
  }, [viewerCountry, dispatch, openModal])
}
