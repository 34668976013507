import { GameId } from '@common'

import CoCBonusItem from '@/assets/images/storefronts/clashofclans/book-of-everything.png'
import CocFreeStamp from '@/assets/images/storefronts/clashofclans/stamp-free.png'
import CRBonusItem from '@/assets/images/storefronts/clashroyale/bonus-item.png'
import CrFreeStamp from '@/assets/images/storefronts/clashroyale/stamp-free.png'

export function getStampImage(game: GameId) {
  switch (game) {
    case 'clashofclans':
      return CocFreeStamp
    case 'clashroyale':
      return CrFreeStamp
    default:
      return null
  }
}

export function getBonusItemImage(game: GameId) {
  switch (game) {
    case 'clashofclans':
      return CoCBonusItem
    case 'clashroyale':
      return CRBonusItem
    default:
      return null
  }
}
