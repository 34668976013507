import { useRef } from 'react'

import { supportedLanguages } from '@common'
import cx from 'classnames'

import { useLocale } from '@/features/locale/useLocale'
import useOnClickOutside from '@/utils/useOnClickOutside'

import styles from './storefrontModal.module.scss'

const MODAL_ID = 'modal' as const

export const scrollModal = (x: number, y: number) => {
  document?.getElementById(MODAL_ID)?.scrollTo(x, y)
}

type ModalProps = React.PropsWithChildren<{
  position: 'Top' | 'Middle'
  onClose: () => void
  ['data-cy']?: string
  closeOnClickOutside?: boolean
  className?: string | string[]
  contentClassName?: string | string[]
}>

export const StorefrontModal: React.FC<ModalProps> = ({
  onClose,
  closeOnClickOutside,
  'data-cy': dataCy,
  position,
  children,
  className: additionalClasses,
  contentClassName,
}) => {
  const locale = useLocale()
  const contentRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(contentRef, (event) => {
    event.stopImmediatePropagation()
    if (closeOnClickOutside) {
      onClose()
    }
  })
  return (
    <div
      id={MODAL_ID}
      className={cx(styles.Modal, styles[position], additionalClasses)}
      data-cy={dataCy}
      dir={supportedLanguages[locale].rightToLeft ? 'rtl' : 'ltr'}
    >
      <div className={cx(styles.ModalContent, contentClassName)} ref={contentRef}>
        {children}
      </div>
    </div>
  )
}
