import axios, { AxiosError, AxiosInstance } from 'axios'

import { apiErrorMessage, buildBaseHeaders, buildRequestHeaders, handleApiErrors, SCIDRequestOptions } from './util'
import { EmptyResponse, SCIDRewardsEventResponse, SCIDRewardsStatusResponse } from './types'
import { GameId, gameIdSchema } from '../../models/game'
import { MetricsClient } from '../cloudwatch/metrics'
import { z } from 'zod'

type RewardsApiConfig = {
  baseUrl: string
  token: string
  gitHash: string
}
type RewardsApiDependencies = {
  metricsClient: MetricsClient
}

const RewardsGameParam = gameIdSchema.or(z.literal('scid-rewards'))
type RewardsGameParam = z.infer<typeof RewardsGameParam>

export type RewardsApiClient = ReturnType<typeof createRewardsApiClient>

/**
 * Rewards API is built to be idempotent, so we can safely retry requests
 */
export function createRewardsApiClient(config: RewardsApiConfig, { metricsClient }: RewardsApiDependencies) {
  const client = axios.create({
    baseURL: config.baseUrl,
    timeout: 3000, // keep it low to avoid waiting in the UI if the Rewards API is slow
    headers: buildBaseHeaders(config.token, config.gitHash),
  })

  return {
    getStatus: metricsClient.withDurationMetrics('RewardsAPI_GetStatus', getStatus(client)),
    getEvents: metricsClient.withDurationMetrics('RewardsAPI_GetEvents', getEvents(client)),
    addPoints: metricsClient.withDurationMetrics('RewardsAPI_AddPointsFromPurchase', addPointsFromPurchase(client)),
    addPandoraPoints: metricsClient.withDurationMetrics(
      'RewardsAPI_AddPointsFromPandora',
      addPointsFromPandora(client),
    ),
    revokePoints: metricsClient.withDurationMetrics('RewardsAPI_RevokePoints', revokePoints(client)),
    claimReward: metricsClient.withDurationMetrics('RewardsAPI_ClaimReward', claimReward(client)),
  }
}

function claimReward(client: AxiosInstance) {
  return async (accountId: string, gameId: GameId, offerId: string, options?: SCIDRequestOptions) => {
    const response = await client.post(
      `/store/v1/${gameId}/claim/${accountId}`,
      { offer_id: offerId },
      buildRequestHeaders('application/json', options),
    )
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'claimReward'))
  }
}

function getStatus(client: AxiosInstance) {
  return async (
    accountId: string,
    gameId: RewardsGameParam,
    options: SCIDRequestOptions | undefined,
  ): Promise<SCIDRewardsStatusResponse> => {
    const response = await client
      .get<SCIDRewardsStatusResponse>(
        `/store/v1/${gameId}/status/${accountId}`,
        buildRequestHeaders('application/json', options),
      )
      .catch((err): { data: { ok: false; error: string } } => {
        if (err?.code === AxiosError.ECONNABORTED) {
          // is axios error
          return { data: { ok: false, error: 'timeout' } }
        }
        throw err
      })
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'getStatus'))
  }
}

function getEvents(client: AxiosInstance) {
  return async (accountId: string, gameId: RewardsGameParam, options: SCIDRequestOptions | undefined) => {
    const response = await client
      .get<SCIDRewardsEventResponse>(
        `/store/v1/${gameId}/events/${accountId}`,
        buildRequestHeaders('application/json', options),
      )
      .catch((err) => {
        throw err
      })
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'getEvents'))
  }
}

function addPointsFromPurchase(client: AxiosInstance) {
  return async (
    accountId: string,
    gameId: GameId,
    data: { points: number; timestamp: number; id: string },
    options?: SCIDRequestOptions,
  ) => {
    const response = await client.post<EmptyResponse>(
      `/store/v1/${gameId}/add/${accountId}`,
      { ...data, type: 'purchase' as const },
      buildRequestHeaders('application/json', options),
    )
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'addRewardPoints'))
  }
}

function addPointsFromPandora(client: AxiosInstance) {
  return async (
    accountId: string,
    gameId: GameId,
    data: { points: number; timestamp: number; id: string },
    options?: SCIDRequestOptions,
  ) => {
    const response = await client.post<EmptyResponse>(
      `/store/v1/${gameId}/add/${accountId}`,
      { ...data, type: 'pandora' as const },
      buildRequestHeaders('application/json', options),
    )
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'addRewardPoints'))
  }
}

function revokePoints(client: AxiosInstance) {
  return async (
    accountId: string,
    purchaseId: string,
    purchaseProcessedTimestamp: number,
    options?: SCIDRequestOptions,
  ) => {
    const response = await client.post<EmptyResponse>(
      `/store/v1/${accountId}/revoke/${purchaseId}`,
      { type: 'revoke' as const, purchaseProcessedTimestamp },
      buildRequestHeaders('application/json', options),
    )
    return handleApiErrors(response.data, apiErrorMessage('RewardsApiClient', 'revokeRewardPoints'))
  }
}
