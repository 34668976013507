import { clamp } from '@common'
import { StoreMission } from '@common/models/bonus'
import cx from 'classnames'
import Image, { StaticImageData } from 'next/image'
import { useTranslations } from 'next-intl'

import BrawlPass from '@/assets/images/storefronts/brawlstars/mission-brawl-pass.png'
import CheckMark from '@/assets/images/storefronts/brawlstars/mission-check-mark.svg'
import DailyLogins from '@/assets/images/storefronts/brawlstars/mission-daily-logins.png'
import WinBattles from '@/assets/images/storefronts/brawlstars/mission-win-battles.png'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusMissionBS.module.scss'

interface BonusMissionProps {
  mission: StoreMission
}

type Missions = {
  [key: string]: {
    image: StaticImageData
    title: string
    type: 'normal' | 'binary'
  }
}

export const BonusMissionBS = ({ mission }: BonusMissionProps) => {
  const t = useTranslations()

  if (!mission) {
    return null
  }

  const { current, rewardPoints, target } = mission

  const supportedMissions: Missions = {
    daily_logins: {
      image: DailyLogins,
      title: t('laser_mission_title_daily_logins', { target }),
      type: 'normal',
    },
    complete_brawl_pass: {
      image: BrawlPass,
      title: t('laser_mission_complete_brawl_pass'),
      type: 'binary',
    },
    win_matches: {
      image: WinBattles,
      title: t('laser_mission_win_matches', { target }),
      type: 'normal',
    },
  }

  const missionData = supportedMissions[mission?.missionId]

  if (!missionData) {
    return null
  }

  const { title, type, image } = missionData
  const isBinary = type === 'binary'
  const isComplete = current >= target
  const subtitle = isComplete
    ? t('mission_received_points', { count: rewardPoints })
    : t('bonus_number_of_points').replace('[n]', rewardPoints.toString())
  const progressText = `${clamp(current, 0, target)}/${target}`
  const progressPercentage = target ? (100 * current) / target : 0

  return (
    <div className={cx(styles.container, isComplete && styles.complete)}>
      <div className={styles.topSection}>
        <div className={styles.imageContainer}>
          <Image className={styles.image} src={image} alt="logo" width={70} height={70} />
        </div>
        <div className={styles.texts}>
          <StoreTypography className={styles.title} tag="span" textSize="body" style="outlined">
            {title}
          </StoreTypography>
          <div className={styles.subtitle}>
            {isComplete && <Image src={CheckMark} alt="checkmark" width={17} height={17} />}
            <StoreTypography tag="span" textSize="body" style="plain" overrideFont="supercellTextMedium">
              {subtitle}
            </StoreTypography>
          </div>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <div className={styles.leftSection}>
          <div className={styles.background} />
          <div className={styles.progressContainer}>
            {isBinary && !isComplete && (
              <StoreTypography
                className={styles.incomplete}
                tag="span"
                textSize="sm"
                style="plain"
                overrideFont="supercellTextBold"
              >
                {t('bonus_track_incomplete')}
              </StoreTypography>
            )}
            {(!isBinary || (isBinary && isComplete)) && (
              <div className={styles.progressBar} style={{ width: `${progressPercentage}%` }}></div>
            )}
          </div>
        </div>

        {!isBinary && (
          <div className={styles.rightSection}>
            <StoreTypography tag="span" textSize="body" style="outlined">
              {progressText}
            </StoreTypography>
          </div>
        )}
      </div>
    </div>
  )
}
