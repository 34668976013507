import { GameId, supportedLanguages } from '@common'

export const GAME_THEMES = {
  clashofclans: '#d8c4ad',
  clashroyale: '#0f377d',
  brawlstars: '#000',
  hayday: '#f9f9f9',
  squadbusters: '#bada55', // TODO: fix
}

export const gameNames = {
  clashofclans: 'Clash of Clans',
  clashroyale: 'Clash Royale',
  brawlstars: 'Brawl Stars',
  hayday: 'Hay Day',
  squadbusters: 'Squad Busters',
}

export const STORE_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/special-offers-bg.png')`
export const PASSES_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/passes-bg.png')`
export const PASS_CARD_HERO_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/pass-card-product-bg.png')`
export const REWARD_ITEM_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/bonus-item-claimed-bg.png')`
export const ROPE_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/bonus-track-rope-bg.png')`
export const BONUS_TRACK_DIVIDER_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/bonus-track-divider-bg.png')`
export const PASS_BG = (gameSlug: GameId) => `url('/images/storefronts/${gameSlug}/passcard-bg.png')`
export const OFFER_BG = (gameSlug: GameId) => `url('/images/${gameSlug}/green-offer-bg.png')`

export const rtlLocales = Object.values(supportedLanguages)
  .filter((lang) => lang.rightToLeft === true)
  .map((lang) => lang.locale.split('-')[0])
