export function partition<T>(predicate: (item: T) => boolean, items: T[]) {
  const truthy: T[] = []
  const falsy: T[] = []

  items.forEach((item) => {
    if (predicate(item)) {
      truthy.push(item)
    } else {
      falsy.push(item)
    }
  })

  return [truthy, falsy] as const
}
