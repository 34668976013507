export enum INELIGIBLE_REASON {
  APP_ACCOUNT_BANNED = 'APP_ACCOUNT_BANNED',
  APP_ACCOUNT_PROGRESS_TOO_LOW = 'APP_ACCOUNT_PROGRESS_TOO_LOW',
  APP_ACCOUNT_COUNTRY_NOT_SUPPORTED = 'APP_ACCOUNT_COUNTRY_NOT_SUPPORTED',
  APP_ACCOUNT_UNAVAILABLE = 'APP_ACCOUNT_UNAVAILABLE',
  APP_ACCOUNT_INELIGIBLE = 'APP_ACCOUNT_INELIGIBLE',
  APP_ACCOUNT_NOT_CONNECTED = 'APP_ACCOUNT_NOT_CONNECTED',
  APP_ACCOUNT_INVENTORY_FULL = 'APP_ACCOUNT_INVENTORY_FULL',
  OTHER = 'OTHER',
}
