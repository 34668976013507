import type { ProductV2 } from '@common'

export const resolveProductImage = (product: ProductV2): string => {
  const imageUrl = product.images[0] ?? ''
  const relativeUrl = imageUrl.replace('https://store.supercell.com', '')
  return relativeUrl
}

export const isProductImageEmpty = (product?: ProductV2): boolean => {
  return product !== undefined && product.images.length > 0 && product.images[0] === ''
}
