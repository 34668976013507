import { z } from 'zod'
import { gameIdSchema } from './game'

export const ddbCartItemSchema = z.object({
  sku: z.string(),
  quantity: z.number(),
})

export const ddbCartSchema = z.object({
  gameId: gameIdSchema,
  items: ddbCartItemSchema.array(),
})

export type DDBCartItem = z.infer<typeof ddbCartItemSchema>
