'use client'

import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

export type MenuContextType = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const initialMenuContext: MenuContextType = {
  isOpen: false,
  setIsOpen: () => '',
}

export const MenuContext = createContext<MenuContextType>(initialMenuContext)

export const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(initialMenuContext.isOpen)

  return <MenuContext.Provider value={{ isOpen, setIsOpen }}>{children}</MenuContext.Provider>
}

export const useMenu = () => useContext(MenuContext)
