import { useState, useEffect, memo } from 'react'

import Image, { StaticImageData } from 'next/image'

import AssetMissingNonprod from '@/assets/images/asset-missing-nonprod.png'
import NotFound from '@/assets/images/bag.png'
import { captureImageError } from '@/utils/useCaptureBrokenImages'

import { config } from '../../../../../config'

const STAGE = config.STAGE

type ImageWithFallbackProps = {
  productImage: string | StaticImageData
  title: string
  className?: string
}

export const ImageWithFallback = memo(({ productImage, title, className }: ImageWithFallbackProps) => {
  const [src, setSrc] = useState<string | StaticImageData>(productImage)
  const isProd = STAGE === 'production'

  useEffect(() => {
    setSrc(productImage)
  }, [productImage])

  const handleNotFound = () => {
    if (typeof productImage === 'string') {
      captureImageError(productImage)
    }
    if (!isProd) {
      setSrc(AssetMissingNonprod)
    } else {
      setSrc(NotFound)
    }
  }

  return (
    <Image
      src={src}
      onErrorCapture={() => handleNotFound()}
      unoptimized
      alt={title}
      fill
      style={{
        objectFit: 'contain',
      }}
      sizes="400px"
      className={className}
    />
  )
})

ImageWithFallback.displayName = 'ImageWithFallback'
