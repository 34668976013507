import { useState, useEffect, useRef } from 'react'

import Image, { StaticImageData } from 'next/image'

import AssetMissingNonprod from '@/assets/images/asset-missing-nonprod.png'
import NotFound from '@/assets/images/bag.png'

import { config } from '../../../../../config'
import { safariDeviceType } from '../../../../utils/safariDeviceType'

import styles from './animatedImageWithFallback.module.scss'

const STAGE = config.STAGE

export const AnimatedImageWithFallback = ({
  animationSrc,
  fallbackImage,
  safariAnimationSrc,
}: {
  animationSrc: string
  fallbackImage: string
  safariAnimationSrc?: string | undefined
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [fallbackSrc, setFallbackSrc] = useState<string | StaticImageData>(fallbackImage)
  const [error, setError] = useState(false)
  const isProd = STAGE === 'production'
  const useSafariAsset = safariDeviceType() === 'desktop' || safariDeviceType() === 'handheld'

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    /** MediaErrors for the video tag are not handled properly in some cases, this ensures we handle it gracefully.  */
    if (videoRef.current.error) {
      setError(true)
    } else {
      videoRef?.current?.play()
    }
  }, [])

  const handleNotFound = () => {
    if (!isProd) {
      setFallbackSrc(AssetMissingNonprod)
    } else {
      setFallbackSrc(NotFound)
    }
    setError(true)
  }

  const image = (
    <Image
      alt=""
      src={fallbackSrc}
      onErrorCapture={() => handleNotFound()}
      fill
      style={{
        objectFit: 'contain',
      }}
      sizes="200px"
    />
  )

  if (useSafariAsset && safariAnimationSrc && !error) {
    if (safariAnimationSrc.endsWith('.mov')) {
      return (
        <video
          ref={videoRef}
          className={styles.video}
          playsInline
          muted
          autoPlay
          loop
          preload="none"
          src={safariAnimationSrc}
          poster={fallbackImage}
          onErrorCapture={() => handleNotFound()}
        >
          <source src={safariAnimationSrc} />
        </video>
      )
    } else {
      return (
        <Image
          alt=""
          src={safariAnimationSrc}
          onErrorCapture={() => handleNotFound()}
          style={{
            objectFit: 'contain',
            width: '150%',
          }}
          width="200"
          height="200"
        />
      )
    }
  }

  if (!error) {
    return (
      <video
        ref={videoRef}
        className={styles.video}
        playsInline
        muted
        autoPlay
        loop
        preload="none"
        poster={fallbackImage}
        onError={handleNotFound}
      >
        <source src={animationSrc} />
      </video>
    )
  }

  return image
}
