import { useCallback } from 'react'

import type { SupportedLanguage } from '@common'
import type { Translatable } from '@common/models/translatable'

import { useLocale } from '@/features/locale/useLocale'

const translate = <T extends string>(
  locale: SupportedLanguage,
  obj?: Translatable<T>,
  fallback = 'missing localization',
) => {
  const value = obj?.[locale] ?? obj?.en
  if (value || typeof value === 'string') {
    return value
  }

  return fallback
}

export const useTranslatable = () => {
  const locale = useLocale()
  const t = useCallback(
    (obj: Partial<Record<SupportedLanguage, string>>, fallback?: string) => translate(locale, obj, fallback),
    [locale],
  )
  return t
}
