import React from 'react'

import { ProductV2 } from '@common'

import { BrawlPdpProductHeroHeader } from '@/features/storefronts/_storeBase/productPage/v2/games/brawlstars/PdpProductHeroHeader'

interface Props {
  product: ProductV2
}

export const PdpProductHeroHeader = ({ product }: Props) => {
  switch (product.game) {
    case 'brawlstars':
      return <BrawlPdpProductHeroHeader product={product} />
    default:
      // not implemented
      return null
  }
}
