import * as React from 'react'

import { GameId, ProductV2 } from '@common'

import { Modal } from '../components/Modal'
import { useModalContext } from '../contexts/ModalContext'
import { ProductOverlay } from '../features/overlay/ProductOverlay'
import { ProductOverlayV2 } from '../features/overlay/ProductOverlayV2'
import { StorefrontModal } from '../features/storefronts/_storeBase/modal/StorefrontModal'
import { useAppSelector } from '../utils/useAppSelector'
import { useBreakpoints } from '../utils/useBreakpoints'

import styles from './Modal.module.scss'

export type ProductModalProps = {
  type: 'PRODUCT'
  props: Props
}

type Props = {
  product: ProductV2
  products?: ProductV2[]
  enableNewVisuals?: GameId[]
}

export function ProductModal(props: Props) {
  const gameStoresEnabled = useAppSelector((state) => state.config.storefront)
  const { closeModal } = useModalContext()
  const { from } = useBreakpoints()

  const onClose = React.useCallback(() => {
    const url = `/${props.product.game}`
    history.replaceState(null, '', url)
    closeModal()
  }, [props.product, closeModal])

  React.useEffect(() => {
    const url = gameStoresEnabled
      ? `/${props.product.game}/product/${props.product.slug}`
      : `/product/${props.product.slug}`
    history.pushState(null, '', url)
    window.onpopstate = () => {
      onClose()
    }
    return () => {
      window.onpopstate = null
    }
  }, [props.product, onClose, gameStoresEnabled])

  const handleClose = () => {
    if (from('laptop-small')) {
      onClose()
    }
  }

  if (gameStoresEnabled) {
    return (
      <StorefrontModal position="Top" data-cy="product-modal" onClose={handleClose} closeOnClickOutside>
        <ProductOverlayV2 product={props.product} closeModal={onClose} enableNewVisuals={props.enableNewVisuals} />
      </StorefrontModal>
    )
  }

  return (
    <Modal
      contentClassName={styles.ModalContent}
      position="Top"
      data-cy="product-modal"
      onClose={handleClose}
      closeOnClickOutside
    >
      <ProductOverlay product={props.product} products={props.products} closeModal={onClose} />
    </Modal>
  )
}
