import { isSupportedLanguage } from './countries'
import { z } from 'zod'
import { GameId, gameIdSchema } from './game'
import {
  PaymentGatewayKind,
  TaxType,
  PaymentGatewayMOR,
  PaymentGateway,
  PaymentGatewayPSP,
  PSPAdyen,
  MORRazer,
  MORXsolla,
  RazerPaymentChannelIdentifier,
  MORPaddle,
  MORFastSpring,
} from './checkout'
import { ProductV2, bonusSchema } from './product'
import type { PaymentResponse, CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/models'
import { EligibilityStatus } from './scid'
import type { DistributiveOmit } from '../utils/distributiveOmit'
import { MORPurchaseSCIDReceipt, PSPPurchaseSCIDReceipt } from './scidReceipt'
import { columnTypes } from './db'
import { PaddleStoredPaymentMethod } from './profile'

export enum PURCHASE_STATUS {
  AUTHORISED_WEBHOOK = 'AUTHORISATION',
  REFUND_WEBHOOK = 'REFUND',
  CHARGEBACK_WEBHOOK = 'CHARGEBACK',
  FAILED_CUSTOM = 'FAILED',
  STORE_DRAFT = 'STORE_DRAFT',
  STORE_PENDING = 'STORE_PENDING',
}

export type ReceiptIdentifier = 'id' | 'pspReference'
export interface PurchaseResponse {
  country: columnTypes.CountryCode
  creatorBoostCode: string | undefined
  customerId: string
  // currency in which the actual payment is made
  currency: columnTypes.CurrencyCode
  game: GameId
  id: string
  email: string
  initializedTimestamp: number
  issuerCountry: string | undefined
  paymentMethod: string | undefined
  paymentMethodName: string | undefined
  paymentGatewayKind: PaymentGatewayKind
  paymentGateway: PaymentGateway
  processedTimestamp: number | undefined
  productId: string
  productPrice: number
  productTitle: string
  pspReference: string | undefined
  quantity: number
  status: string
  taxType: TaxType | undefined
  taxAmount: number | undefined
  taxPercentage: number | undefined
  priceBeforeTax: number | undefined
  total: number | undefined
  totalUSD: number | undefined
  receiptIdentifier: ReceiptIdentifier

  // original values that are used to initialize payments
  // Depends on the paymentProvider
  // For xsolla total, and currency might change due to currency changing while in payment
  // for adyen total and currency are the same as what user initialised the payment with
  checkoutTotal: number
  checkoutCurrency: columnTypes.CurrencyCode
}

const VAT = z.literal('VAT')
const SalesTax = z.literal('SALES_TAX')
export const TaxTypeSchema = VAT.or(SalesTax)

const PriceNumberSchema = z.number().int().gte(0)

const CheckoutPriceSchema = z.object({
  total: PriceNumberSchema,
  currency: columnTypes.currencyCodeSchema,
  currencyCountry: columnTypes.currencyCountrySchema,
})

const PaymentPriceSchema = z.object({
  subtotal: PriceNumberSchema,
  taxAmount: PriceNumberSchema,
  total: PriceNumberSchema,
  currency: columnTypes.currencyCodeSchema,
  taxPercentage: z.number(),
  taxType: TaxTypeSchema,
})

const AnalyticsPriceSchema = z.object({
  total: PriceNumberSchema,
  currency: z.literal('USD'),
  rows: z.array(z.object({ sku: z.string(), total: PriceNumberSchema })).default([]),
})
const PSPPurchasePricingSchema = z.object({
  paymentGatewayKind: PaymentGatewayPSP,
  paymentGateway: PSPAdyen,
  checkout: CheckoutPriceSchema,
  payment: PaymentPriceSchema,
  analytics: AnalyticsPriceSchema,
})

const MORPurchasePricingBaseSchema = z.object({
  paymentGatewayKind: PaymentGatewayMOR,
  paymentGateway: MORXsolla.or(MORRazer).or(MORPaddle).or(MORFastSpring),
  checkout: CheckoutPriceSchema,
})

const MORPricingPreTaxSchema = MORPurchasePricingBaseSchema.extend({
  // Some old purchases have analytics fields in pending purchases -> map it to undefined
  analytics: z.undefined().or(AnalyticsPriceSchema.transform(() => undefined)),
  payment: z
    .undefined()
    .or(z.object({ total: PriceNumberSchema, currency: columnTypes.currencyCodeSchema.or(z.undefined()) })),
})

const MORPricingAfterTaxSchema = z
  .object({
    analytics: AnalyticsPriceSchema,
    payment: PaymentPriceSchema,
  })
  .merge(MORPurchasePricingBaseSchema)

const PurchaseRowSchema = z.object({
  sku: z.string(),
  quantity: z.number().int().gte(1),
})
export type PurchaseRow = z.infer<typeof PurchaseRowSchema>

export type AdminPurchase = z.infer<typeof AdminPurchaseSchema>

const isPurchaseStatus = (s: string): s is PURCHASE_STATUS =>
  [
    PURCHASE_STATUS.STORE_DRAFT,
    PURCHASE_STATUS.STORE_PENDING,
    PURCHASE_STATUS.AUTHORISED_WEBHOOK,
    PURCHASE_STATUS.REFUND_WEBHOOK,
    PURCHASE_STATUS.CHARGEBACK_WEBHOOK,
    PURCHASE_STATUS.FAILED_CUSTOM,
  ]
    .map((status) => status.toString())
    .includes(s)

const PurchaseStatusSchema = z.string().refine(isPurchaseStatus)

export const purchaseIdSchema = z.string()
export type PurchaseId = z.infer<typeof purchaseIdSchema>

const PurchaseBaseSchema = z.object({
  id: purchaseIdSchema,
  customerId: z.string(),
  customerIP: z.string(),
  status: PurchaseStatusSchema,
  rows: z.array(PurchaseRowSchema),
  email: z.string(),
  language: z.string().refine(isSupportedLanguage),
  // Creator Boost
  creatorBoost: z
    .object({
      value: z.string(),
      scid: z.string(),
    })
    .or(z.null()),
  // Discount Codes
  discountCodes: z.array(z.object({ value: z.string(), id: z.string() })),

  country: columnTypes.countryCodeSchema,
  bonus: z.number().int().optional(),
  bonusData: bonusSchema.optional(),
  createdAt: z.number().int().positive(),
  updatedAt: z.number().int().positive(),
  migratedAt: z.number().positive().optional(), // TODO: should this be optional
  paymentProvider: z.string().optional(),
  gameId: gameIdSchema.optional(),
  consents: z.array(z.string()).optional(),

  version: z.literal('v0'),
})

const PurchaseDraftCommonFields = z.object({
  status: z.literal(PURCHASE_STATUS.STORE_DRAFT),
  issuerCountry: z.string().optional(),
  paymentMethod: z.string().optional(),
  paymentMethodName: z.string().optional(),
  sessionContentHash: z.string().optional(),
  gameId: gameIdSchema.optional(),
})

const PSPPurchaseBaseSchema = PurchaseBaseSchema.merge(PSPPurchasePricingSchema).extend({
  paymentData: z.string().optional(),
})
export const PSPPurchaseDraftSchema = PSPPurchaseBaseSchema.merge(PurchaseDraftCommonFields)
const MORPurchaseDraftSchema = PurchaseBaseSchema.merge(PurchaseDraftCommonFields).merge(MORPricingPreTaxSchema)

export const PurchaseDraftSchema = z.union([PSPPurchaseDraftSchema, MORPurchaseDraftSchema])

export const PSPPurchasePendingSchema = PSPPurchaseBaseSchema.extend({
  status: z.literal(PURCHASE_STATUS.STORE_PENDING),
  issuerCountry: z.string().optional(),
  paymentMethod: z.string().optional(),
  paymentMethodName: z.string().optional(),
  sessionContentHash: z.string().optional(),
}).merge(PSPPurchasePricingSchema)

const MORPurchasePendingSchema = PurchaseBaseSchema.extend({
  status: z.literal(PURCHASE_STATUS.STORE_PENDING),
  paymentMethodName: z.string().optional(),
}).merge(MORPricingPreTaxSchema)

const MORPurchasePendingWithTaxSchema = PurchaseBaseSchema.extend({
  status: z.literal(PURCHASE_STATUS.STORE_PENDING),
  paymentMethodName: z.string().optional(),
}).merge(MORPricingAfterTaxSchema)

const PurchaseCompletedCommonFields = z.object({
  issuerCountry: z.string().optional(),
  paymentMethod: z.string(),
  paymentMethodName: z.string().optional(),
  pspReference: z.string(),
  processedTimestamp: z.number(),
  itemsReceived: z.boolean(),
})

const PurchaseAuthorisedCommonFields = PurchaseCompletedCommonFields.extend({
  status: z.literal(PURCHASE_STATUS.AUTHORISED_WEBHOOK),
  refundTriggered: z.boolean().optional(),
  skipItemRevoke: z.boolean().optional(),
})

export const PSPPurchaseAuthorisedSchema =
  PurchaseBaseSchema.merge(PurchaseAuthorisedCommonFields).merge(PSPPurchasePricingSchema)
export const MORPurchaseAuthorisedSchema =
  PurchaseBaseSchema.merge(PurchaseAuthorisedCommonFields).merge(MORPricingAfterTaxSchema)

export const PSPPurchaseFailedSchema = PurchaseBaseSchema.extend({
  status: z.literal(PURCHASE_STATUS.FAILED_CUSTOM),
  issuerCountry: z.string().optional(),
  paymentMethod: z.string().optional(),
  paymentMethodName: z.string().optional(),
  pspReference: z.string().optional(),
  processedTimestamp: z.number(),
  refundTriggered: z.boolean().optional(),
  skipItemRevoke: z.boolean().optional(),
}).merge(PSPPurchasePricingSchema)

const MORPurchaseFailedCommonFields = z.object({
  status: z.literal(PURCHASE_STATUS.FAILED_CUSTOM),
  issuerCountry: z.string().optional(),
  paymentMethod: z.string().optional(),
  paymentMethodName: z.string().optional(),
  pspReference: z.string().optional(),
  processedTimestamp: z.number(),
  refundTriggered: z.boolean().optional(),
  skipItemRevoke: z.boolean().optional(),
})

const MORFailedPreTax = PurchaseBaseSchema.merge(MORPricingPreTaxSchema).merge(MORPurchaseFailedCommonFields)
const MORFailedAfterTax = PurchaseBaseSchema.merge(MORPricingAfterTaxSchema).merge(MORPurchaseFailedCommonFields)

const OptionalGameId = { gameId: gameIdSchema.optional() }

const MORFailedAfterTaxAdmin = MORFailedAfterTax.extend(OptionalGameId)
const MORFailedPreTaxAdmin = MORFailedPreTax.extend(OptionalGameId)
const MORFailedAdminSchema = MORFailedAfterTaxAdmin.or(MORFailedPreTaxAdmin)

const MORPurchaseFailedSchema = MORFailedAfterTax.or(MORFailedPreTax)

export type PSPPurchaseFailed = z.infer<typeof PSPPurchaseFailedSchema>

export const PurchaseAuthorisedSchema = PSPPurchaseAuthorisedSchema.or(MORPurchaseAuthorisedSchema)

const PurchaseRefundedCommonFields = PurchaseCompletedCommonFields.extend({
  status: z.literal(PURCHASE_STATUS.REFUND_WEBHOOK),
  itemsRevoked: z.boolean(),
  refundReason: z.string().optional(),
})

const PurchaseChargebackedCommonFields = PurchaseCompletedCommonFields.extend({
  status: z.literal(PURCHASE_STATUS.CHARGEBACK_WEBHOOK),
  itemsRevoked: z.boolean(),
})

export const PSPPurchaseRefundedSchema =
  PurchaseBaseSchema.merge(PurchaseRefundedCommonFields).merge(PSPPurchasePricingSchema)
export const PSPPurchaseChargebackedSchema = PurchaseBaseSchema.merge(PurchaseChargebackedCommonFields).merge(
  PSPPurchasePricingSchema,
)

export const MORPurchaseRefundedSchema =
  PurchaseBaseSchema.merge(PurchaseRefundedCommonFields).merge(MORPricingAfterTaxSchema)
export const MORPurchaseChargebackedSchema = PurchaseBaseSchema.merge(PurchaseChargebackedCommonFields).merge(
  MORPricingAfterTaxSchema,
)

// Would be faster with discriminated unions, but it does not seem to be supported for this?
export const PurchaseSchema = z.union([
  PSPPurchaseDraftSchema,
  PSPPurchasePendingSchema,
  PSPPurchaseFailedSchema,
  PSPPurchaseAuthorisedSchema,
  PSPPurchaseRefundedSchema,
  PSPPurchaseChargebackedSchema,
  MORPurchaseDraftSchema,
  MORPurchasePendingSchema,
  MORPurchaseAuthorisedSchema,
  MORPurchaseFailedSchema,
  MORPurchaseRefundedSchema,
  MORPurchaseChargebackedSchema,
  MORPurchasePendingWithTaxSchema,
])

export const AdminPurchaseSchema = z.union([
  PSPPurchaseDraftSchema.extend(OptionalGameId),
  PSPPurchasePendingSchema.extend(OptionalGameId),
  PSPPurchaseFailedSchema.extend(OptionalGameId),
  PSPPurchaseAuthorisedSchema.extend(OptionalGameId),
  PSPPurchaseRefundedSchema.extend(OptionalGameId),
  PSPPurchaseChargebackedSchema.extend(OptionalGameId),
  MORPurchaseDraftSchema.extend(OptionalGameId),
  MORPurchasePendingSchema.extend(OptionalGameId),
  MORPurchaseAuthorisedSchema.extend(OptionalGameId),
  MORFailedAdminSchema,
  MORPurchaseRefundedSchema.extend(OptionalGameId),
  MORPurchaseChargebackedSchema.extend(OptionalGameId),
  MORPurchasePendingWithTaxSchema.extend(OptionalGameId),
])

const RefundedPurchaseSchema = MORPurchaseRefundedSchema.or(PSPPurchaseRefundedSchema)
const CharegebackedPurchaseSchema = MORPurchaseChargebackedSchema.or(PSPPurchaseChargebackedSchema)
export const PurchasePendingSchema = MORPurchasePendingSchema.or(PSPPurchasePendingSchema)
export type PurchaseDraft = z.infer<typeof PurchaseDraftSchema>
export type PurchasePending = z.infer<typeof PurchasePendingSchema>
export type PurchasePSPDraft = z.infer<typeof PSPPurchaseDraftSchema>
export type PurchasePSPPending = z.infer<typeof PSPPurchasePendingSchema>
export type PurchasePSPAuthorised = z.infer<typeof PSPPurchaseAuthorisedSchema>
export type PurchasePSPChargeback = z.infer<typeof PSPPurchaseChargebackedSchema>
export type PurchasePSPRefund = z.infer<typeof PSPPurchaseRefundedSchema>
export type PurchasePSPFailed = z.infer<typeof PSPPurchaseFailedSchema>

export type PurchaseMORDraft = z.infer<typeof MORPurchaseDraftSchema>
export type PurchaseMORPending = z.infer<typeof MORPurchasePendingSchema>
export type PurchaseMORFailed = z.infer<typeof MORPurchaseFailedSchema>
export type PurchaseMORAuthorised = z.infer<typeof MORPurchaseAuthorisedSchema>
export type PurchaseMORRefund = z.infer<typeof MORPurchaseRefundedSchema>
export type PurchaseMORChargebacked = z.infer<typeof MORPurchaseChargebackedSchema>
export type PurchaseMORPendingWithTax = z.infer<typeof MORPurchasePendingWithTaxSchema>
export type PurchaseAuthorised = z.infer<typeof PurchaseAuthorisedSchema>

export type MORPricingPreTax = z.infer<typeof MORPricingPreTaxSchema>
export type PSPPricing = z.infer<typeof PSPPurchasePricingSchema>

type PSPPurchase =
  | PurchasePSPDraft
  | PurchasePSPPending
  | PurchasePSPAuthorised
  | PurchasePSPChargeback
  | PurchasePSPRefund
  | PurchasePSPFailed

type MORPurchase =
  | PurchaseMORDraft
  | PurchaseMORPending
  | PurchaseMORFailed
  | PurchaseMORAuthorised
  | PurchaseMORRefund
  | PurchaseMORChargebacked
  | PurchaseMORPendingWithTax

export type Purchase = PSPPurchase | MORPurchase

export type RefundedPurchase = z.infer<typeof RefundedPurchaseSchema>
export type ChargebackPurchase = z.infer<typeof CharegebackedPurchaseSchema>

type PurchaseRowWithProducts = { quantity: number; product: ProductV2 }
type PurchaseRowWithProductsAndEligibilities = PurchaseRowWithProducts & { eligibility: EligibilityStatus }

export type PurchaseDraftWithProducts<T extends PurchaseDraft = PurchaseDraft> = DistributiveOmit<
  T,
  'rows' | 'creatorBoost'
> & {
  rows: PurchaseRowWithProductsAndEligibilities[]
  creatorBoost: null | string
}

export type PSPPurchaseDraftWithProducts = PurchaseDraftWithProducts<PurchasePSPDraft>
export type MORPurchaseDraftWithProducts = PurchaseDraftWithProducts<PurchaseMORDraft>

export type PurchaseGetDraftResponse = PurchaseDraftWithProducts | null

export const CheckoutSessionInputSchema = z.object({
  id: z.string(),
})

export const CheckoutRazerSessionInputSchema = z.object({
  id: z.string(),
  paymentChannel: RazerPaymentChannelIdentifier,
})

export type CheckoutSessionRequest = z.infer<typeof CheckoutSessionInputSchema>
export type CheckoutRazerSessionRequest = z.infer<typeof CheckoutRazerSessionInputSchema>

type BaseChekoutSession<T, P extends PaymentGateway> = {
  session: T
  draft: PurchaseDraft
  paymentGateway: P
}

export type CheckoutAdyenSession = BaseChekoutSession<
  {
    action: 'token'
    payload: CreateCheckoutSessionResponse & {
      countryCode: columnTypes.CountryCode
    }
  },
  PSPAdyen
>

type CheckoutPSPSession = CheckoutAdyenSession

export type CheckoutFastSpringSession = BaseChekoutSession<
  {
    action: 'token'
    payload: {
      secureData: string
      secureKey: string
    }
    email: string
  },
  MORFastSpring
>

export type CheckoutPaddleSession = BaseChekoutSession<
  {
    action: 'token'
    payload: {
      transactionId: string
    }
    email: string
    savedPaymentMethods: PaddleStoredPaymentMethod[]
  },
  MORPaddle
>

export type CheckoutRazerSession = BaseChekoutSession<
  {
    action: 'redirect'
    payload: string
    email: string
  },
  MORRazer
>

type CheckoutMORSession = CheckoutFastSpringSession | CheckoutPaddleSession | CheckoutRazerSession
export type CheckoutSession = CheckoutPSPSession | CheckoutMORSession

export const CheckoutPSPPaymentRequestSchema = z.object({
  id: z.string(),
  paymentMethodName: z.string(),
  data: z.any(),
})

export type CheckoutPSPPaymentRequest = z.infer<typeof CheckoutPSPPaymentRequestSchema>
export type CheckoutPSPPaymentResponse = {
  id: string
  paymentResponse: PaymentResponse
}

export const CheckoutPSPPaymentDetailsRequestSchema = z.object({
  details: z.object({}).passthrough(),
  paymentData: z.string().optional(),
  paymentMethodName: z.string(),
  id: z.string(),
})

export type CheckoutPSPPaymentDetailsRequest = z.infer<typeof CheckoutPSPPaymentDetailsRequestSchema>
export type CheckoutPSPPaymentDetailsResponse = {
  id: string
  resultCode: PaymentResponse['resultCode']
  action: PaymentResponse['action']
}

export type PurchasesGetResponse = Omit<Purchase, 'rows' | 'creatorBoost'> & {
  rows: PurchaseRowWithProducts[]
  creatorBoost: string
  events: Array<{ eventType: string; timestamp: number }>
  isRewardsDeliveryReady: boolean
}

export type PurchasesGetPendingReponse = Omit<PurchasePending, 'creatorBoost'> & {
  creatorBoost: string
}

export type PandoraPurchaseResponse = {
  timestamp: string // ISO Timestamp
  scid: string
  status: PURCHASE_STATUS
  transactionId: string // Store purchase id
  source: 'store'
  totalLocal: {
    currency: columnTypes.CurrencyCode
    amount: number // Major units
  }
  totalUSD: number
  rows: {
    [sku: string]: {
      game: GameId | ''
      quantity: number
      title?: string
      totalUSD: number // major units multiplied by quantity
    }
  }
  paymentMethod?: string
  paymentProvider?: PaymentGateway
  pspReference?: string
  refundTriggered?: boolean
  refundSource?: string
  isDelivered: boolean
  creatorBoostCode?: string
}

export const PURCHASE_DRAFT_MAX_SKU_COUNT = 30
export const MAX_ROW_SKU_COUNT = 10

export type REFUND_REASON =
  | 'CHARGEBACK'
  | 'REFUND_ADYEN'
  | 'REFUND_PANDORA'
  | 'REFUND_XSOLLA'
  | 'REFUND_PADDLE'
  | 'REFUND_RAZER_GOLD'
  | 'REFUND_FASTSPRING'
  | 'REFUND_NEON'

export const isRefundReason = (s: string): s is REFUND_REASON =>
  [
    'CHARGEBACK',
    'REFUND_ADYEN',
    'REFUND_PANDORA',
    'REFUND_XSOLLA',
    'REFUND_RAZER_GOLD',
    'REFUND_PADDLE',
    'REFUND_FASTSPRING',
    'REFUND_NEON',
  ].includes(s)

export type SCIDReceipt = MORPurchaseSCIDReceipt | PSPPurchaseSCIDReceipt
