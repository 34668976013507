import React from 'react'

import { ProductV2 } from '@common'

import logger from '../utils/logger'
import { getProductKey } from '../utils/products'

export interface ProductCopyJSON {
  title: string
  description: string
  contents: string
}

export const loadProductCopy = async (product: ProductV2, locale: string): Promise<ProductCopyJSON | null> => {
  const productPath = getProductKey(product)

  try {
    const copy: ProductCopyJSON = (await import(`./products/${productPath}/${locale}.json`)).default
    return {
      title: copy.title,
      description: copy.description,
      contents: copy.contents,
    }
  } catch (err) {
    logger.debug(err)
    return null
  }
}

const MessageContext: React.Context<
  Partial<{
    messages: IntlMessages
    loadProductMessage: (product: ProductV2, locale: string) => Promise<false | ProductMessageKey>
  }>
> = React.createContext({})

export function MessageContextProvider({ messages, children }: React.PropsWithChildren<{ messages: IntlMessages }>) {
  const [messagesState, setMessagesState] = React.useState(messages)

  React.useEffect(() => {
    if (messages) {
      setMessagesState((previousMessages) => ({ ...previousMessages, ...messages }))
    }
  }, [messages])

  const loadProductMessage = React.useCallback(
    async (product: ProductV2, locale: string): Promise<false | ProductMessageKey> => {
      try {
        const productCopy = await loadProductCopy(product, locale)
        if (productCopy) {
          setMessagesState((prev) => ({ ...prev, [product.slug]: productCopy }))
          return product.slug as ProductMessageKey
        }
        return false
      } catch (err) {
        logger.debug(err)
        return false
      }
    },
    [setMessagesState],
  )

  return (
    <MessageContext.Provider value={{ messages: messagesState, loadProductMessage }}>
      {children}
    </MessageContext.Provider>
  )
}

export const useMessageContext = () => {
  const { loadProductMessage, messages } = React.useContext(MessageContext)
  if (!loadProductMessage || !messages) {
    throw new Error('useMessageContext must be used within a MessageContextProvider')
  }
  const res = React.useMemo(() => ({ loadProductMessage, messages }), [loadProductMessage, messages])
  return res
}
