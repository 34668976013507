import { useEffect } from 'react'

import logger from '../utils/logger'

const MockTracking = () => {
  useEffect(() => {
    if (typeof window !== undefined) {
      let items: { event: string | undefined; [key: string]: unknown }[] = []
      const push = (event: { event: string | undefined; [key: string]: unknown }) => {
        items.push(event)
        logger.debug({ msg: 'dataLayer.push', event: event })
      }
      const flush = () => {
        items = []
      }
      window.dataLayer = {
        items,
        push,
        flush,
      }
    }
    return () => {
      window.dataLayer = undefined
    }
  }, [])
  return null
}

export default MockTracking
