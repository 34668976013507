import React, { MouseEventHandler } from 'react'

import cx from 'classnames'

import styles from './Card.module.scss'

type ShadowSize = 'Small' | 'Medium' | 'Large'

type Props = React.PropsWithChildren<{
  className?: string | string[]
  shadowSize?: ShadowSize
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}>

export const Card: React.FC<Props> = ({
  className: additionalClasses,
  shadowSize = 'Large',
  children,
  href,
  onClick,
}) => {
  return (
    <a href={href} onClick={onClick} className={cx(styles.Card, additionalClasses, styles[shadowSize])}>
      {children}
    </a>
  )
}
