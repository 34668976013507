import { useCallback } from 'react'

import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { ButtonProps, Button } from '@/components/buttons/Button'
import { useClaimBonus } from '@/features/common/useClaimBonus'

import { StoreRewardItem } from '../../../../../../common/src/models/bonus'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './claimButton.module.scss'

export const ClaimButton: React.FC<Omit<ButtonProps, 'onClick'> & { item: StoreRewardItem }> = ({
  item,
  type,
  ...props
}) => {
  const t = useTranslations()
  // TODO: implement error state
  const { claimBonus, isLoading } = useClaimBonus()

  const onClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      await claimBonus(item)
    },
    [item, claimBonus],
  )
  return (
    <Button
      {...props}
      type={type}
      onClick={onClick}
      data-type="claim"
      additionalClasses={cx(styles.claimButton, styles.gradientBorder)}
      loading={isLoading}
      disabled={isLoading}
    >
      <StoreTypography
        tag="span"
        style="plain"
        textSize="body"
        overrideFont="supercellTextMedium"
        className={styles.btnText}
      >
        {t('bonus_claim')}
      </StoreTypography>
    </Button>
  )
}
