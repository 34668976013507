import { z } from 'zod'
import { RazerPaymentChannel } from './checkout'
import { columnTypes } from './db'

export const RazerMetadata = z.object({
  applicationCode: z.string(),
  version: z.literal('v1'),
  hashType: z.literal('hmac-sha256'),
})

export type RazerMetadata = z.infer<typeof RazerMetadata>

export type RazerSignature = Record<'signature', string>

export type RazerPaymentPayoutRequest = {
  referenceId: string
  channelId: RazerPaymentChannel
  amount?: number
  currencyCode?: columnTypes.CurrencyCode
  description: string
  customerId: string
  returnUrl: string
  callbackUrl: string
  maxAcceptedCurrencyCode?: number
  maxAcceptedAmount?: number
}

export const RazerPaymentPayoutRequestResponse = z.object({
  paymentId: z.string(),
  referenceId: z.string(),
  paymentUrl: z.string().url(),
})

export const RazerPaymentStatus = z.nativeEnum({
  Success: '00',
  Incomplete: '01',
  Expired: '02',
  Cancelled: '03',
  Failure: '99',
  InvalidOTP: '93',
  ExceededDailyLimit: '94',
  ExceededMonthlyLimit: '95',
  UserBlocked: '96',
  InvalidAccount: '97',
  InsufficientFunds: '98',
} as const)

export const RazerPaymentPayoutResponse = z.object({
  paymentId: z.string(),
  referenceId: z.string(),
  paymentStatusCode: RazerPaymentStatus,
  paymentStatusDate: z.string().datetime(),
})

export const RazerPayoutPaymentResult = z
  .object({
    referenceId: z.string(),
    amount: z.preprocess(Number, z.number().positive()),
    currencyCode: columnTypes.currencyCodeSchema,
    paymentId: z.string(),
    paymentStatusCode: RazerPaymentStatus,
    paymentStatusDate: z.string().datetime(),
    customerId: z.string(),
    channelId: z.preprocess(Number, z.union([z.literal(1), z.literal(3)])),
    signature: z.string(),
  })
  .merge(RazerMetadata)

export type RazerPaymentPayoutRequestResponse = z.infer<typeof RazerPaymentPayoutRequestResponse>
export type RazerPaymentPayoutResponse = z.infer<typeof RazerPaymentPayoutResponse>
export type RazerPaymemtStatus = z.infer<typeof RazerPaymentStatus>
export type RazerPayoutPaymentResult = z.infer<typeof RazerPayoutPaymentResult>
export type RazerPurchaseSCIDReceipt = {
  signature: string
  notification: string
}
