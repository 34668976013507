import { ReactNode } from 'react'

import Link from 'next/link'
import { RichTranslationValues } from 'next-intl'

export const NoWrap = (children: ReactNode) => {
  return <span style={{ whiteSpace: 'nowrap' }}>{children}</span>
}

export const globalFormats: RichTranslationValues = {
  HelpShift: (children) => {
    if (children) {
      return (
        <a href="#" onClick={() => window.Helpshift?.('open')}>
          {children}
        </a>
      )
    }
  },
  NewTab: (children) => {
    if (children) {
      const [copy, url] = children?.toString().split('#')
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {copy}
        </a>
      )
    }
  },
  SameTab: (children) => {
    if (children) {
      const [copy, path, anchor] = children?.toString().split('#')
      return (
        <Link legacyBehavior href={`/${path}#${anchor}`}>
          {copy}
        </Link>
      )
    }
  },
  NoWrap,
  Mail: (children) => {
    return <a href={`mailto:${children}`}>{children}</a>
  },
  Text: (children) => <span>{children}</span>,
  Bold: (children) => <b>{children}</b>,
}
