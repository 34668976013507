import React, { useCallback } from 'react'

import {
  GameId,
  isStoreOfferProduct,
  ProductV2,
  STORE_PERCENT_TAG_RE,
  STORE_POPULAR_TAG,
  StoreOfferProduct,
} from '@common'

import { scrollModal } from '@/components/Modal'
import { useModalContext } from '@/contexts/ModalContext'
import { useLocale } from '@/features/locale/useLocale'
import { useSnowplowProductCardViewImpression } from '@/utils/snowplow'

import { BrawlStarsOfferCard } from './games/brawlstars/BrawlStarsOfferCard'
import { ClashOfClansOfferCard } from './games/clashofclans'
import { ClashRoyaleOfferCard } from './games/clashroyale'
import { HayDayOfferCard } from './games/hayday'
import { SquadBustersOfferCard } from './games/squadbusters/SquadBustersCard'
import { Ribbon } from './shared/Ribbons'

import styles from './OfferCard.module.scss'

export type OfferCardType = 'default' | 'special'

type Props = {
  product: ProductV2 | StoreOfferProduct
  offerCardType?: OfferCardType
  enableNewVisuals?: GameId[]
}

export const OfferCard = ({ product, offerCardType = 'default', enableNewVisuals = [] }: Props) => {
  const { openModal } = useModalContext()
  const locale = useLocale()

  const href = `/${locale}/product/${product.slug}`

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (event.ctrlKey || event.metaKey) {
        return
      }
      event.preventDefault()
      openModal({ type: 'PRODUCT', props: { product, enableNewVisuals } })
      scrollModal(0, 0)
    },
    [product, openModal, enableNewVisuals],
  )
  const snowplowViewImpressionRef = useSnowplowProductCardViewImpression<HTMLDivElement>(
    product,
    'OfferCard',
    'OfferCard',
  )

  const ribbons = isStoreOfferProduct(product) ? getRibbonsFromTags(product) : []

  return (
    <div ref={snowplowViewImpressionRef}>
      <a className={styles.ContainerLink} href={href} onClick={handleClick}>
        <GameSpecificOfferCard product={product} ribbons={ribbons} offerCardType={offerCardType} />
      </a>
    </div>
  )
}

type GameSpecificOfferCardProps = {
  product: ProductV2 | StoreOfferProduct
  offerCardType?: OfferCardType
  ribbons: Ribbon[]
}
function GameSpecificOfferCard({ product, ribbons, offerCardType = 'default' }: GameSpecificOfferCardProps) {
  switch (product.game) {
    case 'brawlstars':
      return (
        <BrawlStarsOfferCard product={product as StoreOfferProduct} ribbons={ribbons} offerCardType={offerCardType} />
      )
    case 'squadbusters':
      return <SquadBustersOfferCard product={product as StoreOfferProduct} ribbons={ribbons} />
    case 'clashofclans':
      // Not implemented
      return <ClashOfClansOfferCard />
    case 'clashroyale':
      // Not implemented
      return <ClashRoyaleOfferCard />
    case 'hayday':
      // Not implemented
      return <HayDayOfferCard />
    default:
      throw new Error('Unsupported game')
  }
}

export const getRibbonsFromTags = ({ tags }: StoreOfferProduct): Ribbon[] => {
  if (!tags || tags.length === 0) {
    return []
  }

  const shownTags: Ribbon[] = []
  const isPopular = tags.includes(STORE_POPULAR_TAG)

  if (isPopular) {
    shownTags.push({ tag: 'Popular', label: STORE_POPULAR_TAG, variant: 'red' })
  }

  for (const tag of tags) {
    const matchPercent = tag.match(STORE_PERCENT_TAG_RE)
    if (matchPercent) {
      shownTags.push({ tag: 'Percentage', label: `+${matchPercent[1]}%`, variant: 'purple' })
    }
  }
  return shownTags
}
