import { useMemo, useEffect } from 'react'

import { configureStore, combineReducers, ThunkAction, Action, Middleware, isRejectedWithValue } from '@reduxjs/toolkit'

import { api } from './api'
import commonReducer from './features/common/commonSlice'
import configReducer, { FeatureFlagsConfig, setFeatureFlags } from './features/locale/configSlice'
import userReducer from './features/user/userSlice'
import { HYDRATE } from './hydrate'
import { parseJsonCookie, STORE_FEATURES } from './utils/cookies'
import { isClient } from './utils/isClient'
import isRunningPlaywright from './utils/isRunningPlaywright'
import logger from './utils/logger'
import { config as appConfig } from '../config'

const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  user: userReducer,
  config: configReducer,
  common: commonReducer,
})

const requestMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const payload = action.payload as { status: number; data: { status: string } }
    if (
      payload?.status === 503 &&
      payload?.data?.status === 'maintenance' &&
      isClient &&
      window.location.pathname.indexOf('maintenance') === -1
    ) {
      logger.debug('server is unavailable, redirecting user to maintenance')
      window.location.href = '/maintenance'
    }
  }
  return next(action)
}

function createStore(preloadedState?: PreloadedState) {
  return configureStore({
    reducer: reducer,
    preloadedState,
    devTools: appConfig.STAGE !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        // https://redux-toolkit.js.org/api/createListenerMiddleware
        .concat(api.middleware)
        .concat(requestMiddleware),
  })
}

export const initializeStore = (initialState?: PreloadedState) => {
  const initializedStore = createStore(initialState)
  // Check if running in Playwright and set store available in window object
  if (isRunningPlaywright) {
    window.store = initializedStore
    window.testingSupport = {
      enableComingSoon() {
        initializedStore.dispatch(setFeatureFlags({ enableComingSoon: true }))
      },
    }
  }

  return initializedStore
}

export function useInitStore(initialState?: PreloadedState) {
  // Never recreate store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => initializeStore(initialState), [])

  // HYDRATE on pageload and when page props change
  useEffect(() => {
    store.dispatch({ type: HYDRATE, payload: initialState })
  }, [store, initialState])

  // Handle feature flags
  const featureFlags = parseJsonCookie<FeatureFlagsConfig>(STORE_FEATURES)
  useEffect(() => {
    if (featureFlags) {
      store.dispatch(setFeatureFlags(featureFlags))
    }
  }, [featureFlags, store])

  return store
}

export type Store = ReturnType<typeof createStore>
export type RootState = ReturnType<typeof reducer>
export type PreloadedState = Partial<RootState>
export type AppDispatch = Store['dispatch']
export type AppThunk<T = void> = ThunkAction<T, RootState, unknown, Action>
