import styles from './Clock.module.scss'

export const Clock = () => {
  return (
    <div className={styles.Clock}>
      <div className={styles.Hour}></div>
      <div className={styles.Minute}></div>
    </div>
  )
}
