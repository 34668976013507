export const CloseBonusTrackIcon = () => {
  return (
    <svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" width={20} height={18}>
      <path
        d="M2 1.43262L17.9984 16.9994"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 16.5664L17.9984 0.9996" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
