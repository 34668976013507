import React, { CSSProperties, MouseEventHandler } from 'react'

import { captureMessage } from '@sentry/react'
import cx from 'classnames'
import Link from 'next/link'

import { Loader } from '@/components/Loader'
import logger from '@/utils/logger'

import { config } from '../../../config'
import { COLOR_BLACK, COLOR_WHITE } from '../../utils/styles'

import styles from './Button.module.scss'

type ButtonType = 'Primary' | 'Secondary' | 'Tertiary' | 'OutlinePrimary' | 'OutlineSecondary' | 'Soft'

export type ButtonProps<T extends MouseEventHandler<HTMLButtonElement> = MouseEventHandler<HTMLButtonElement>> =
  React.PropsWithChildren<{
    additionalClasses?: string | string[]
    content?: string
    disabled?: boolean
    loading?: boolean
    'data-cy'?: string
    'data-type'?: string
    type?: ButtonType
    onClick?: T
    size?: string
    style?: CSSProperties
  }>

export type LinkButtonProps<T extends MouseEventHandler<HTMLButtonElement> = MouseEventHandler<HTMLButtonElement>> =
  ButtonProps<T> & {
    href: string
    target?: '_blank'
  }

export type SubmitButtonProps = {
  additionalClasses?: string | string[]
  content?: string
  'data-cy'?: string
  'data-type'?: string
  type?: ButtonType
}
function strokeColor(type: ButtonProps['type']) {
  switch (type) {
    case 'Secondary':
    case 'OutlineSecondary':
      return COLOR_BLACK
    default:
      return COLOR_WHITE
  }
}

export const Button: React.FC<ButtonProps> = ({
  children,
  additionalClasses,
  type = 'Primary',
  content,
  disabled,
  loading,
  'data-cy': dataCy,
  onClick,
  'data-type': dataType,
  style,
}) => {
  if (React.Children.count(children) > 1) {
    logger.debug('Button component should not have more than one child', children)
    captureMessage('Button component should not have more than one child')
    if (config.STAGE !== 'production') {
      throw new Error('Button component should not have more than one child')
    }
  }
  return (
    <button
      disabled={disabled}
      className={cx(styles.Button, styles[type], additionalClasses)}
      onClick={onClick}
      data-cy={dataCy}
      data-type={dataType}
      style={style}
    >
      {loading ? <Loader dataCy={`${dataCy}-loader`} strokeColor={strokeColor(type)} /> : children ? children : content}
    </button>
  )
}

export const LinkButton: React.FC<LinkButtonProps> = ({ href, target, ...rest }) => {
  return (
    <Link href={href} className={styles.Link} target={target}>
      <Button {...rest} />
    </Link>
  )
}

export const SubmitButton: React.FC<{
  value: string
  type: ButtonProps['type']
  additionalClasses: ButtonProps['additionalClasses']
  'data-cy'?: string
  'data-type'?: string
}> = ({ value, type = 'Primary', additionalClasses, 'data-cy': dataCy, 'data-type': dataType }) => {
  return (
    <input
      type="submit"
      className={cx(styles.Button, styles[type], additionalClasses)}
      data-cy={dataCy}
      value={value}
      data-type={dataType}
    />
  )
}
