import { memo } from 'react'

import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import Star from '@/assets/images/storefronts/brawlstars/star.png'
import StarOnboarding from '@/assets/images/storefronts/brawlstars/star_onboarding.png'
import { useLocale } from '@/features/locale/useLocale'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './valueIndicator.module.scss'

interface Props {
  value: number
  variant?: 'catalogOffer' | 'onboarding'
  className?: string
}

export const BrawlValueIndicator = memo(({ value, variant, className }: Props) => {
  const t = useTranslations()
  const locale = useLocale()

  const starImg = variant === 'onboarding' ? StarOnboarding : Star

  return (
    <div
      className={cx(
        styles.brawlValueIndicator,
        {
          [styles.onboarding]: variant === 'onboarding',
          [styles.unskew]: variant === 'catalogOffer',
        },
        className,
      )}
    >
      <div className={styles.valueStar}>
        <Image
          src={starImg}
          alt="star coin"
          fill
          style={{ objectFit: 'contain' }}
          sizes="300px"
          className={styles.star}
        />
      </div>
      <div className={styles.textWrapper}>
        <StoreTypography tag="div" textSize="lg" style="outlined" lineHeight="tight" className={styles.valueNumber}>
          {value}
          <StoreTypography tag="span" textSize="body" style="outlined" lineHeight="tight">
            x
          </StoreTypography>
        </StoreTypography>
        <StoreTypography
          tag="div"
          textSize="sm"
          style="outlined"
          lineHeight="tight"
          className={cx(styles.valueText, styles[locale])}
        >
          {t('value').toLowerCase()}
        </StoreTypography>
      </div>
    </div>
  )
})

BrawlValueIndicator.displayName = 'BrawlValueIndicator'
