export const getTimeLeftCopyKeys = (size: 'desktop' | 'mobile', idx: number) => {
  switch (idx) {
    case 0: {
      return size === 'desktop' ? ('hero_pb_time_d' as const) : ('hero_pb_time_m_d' as const)
    }

    case 1: {
      return size === 'desktop' ? ('hero_pb_time_h' as const) : ('hero_pb_time_m_h' as const)
    }

    case 2: {
      return size === 'desktop' ? ('hero_pb_time_m' as const) : ('hero_pb_time_m_m' as const)
    }

    case 3: {
      return size === 'desktop' ? ('hero_pb_time_s' as const) : ('hero_pb_time_m_s' as const)
    }
  }
}

export type TimeLeft = [string, string, string, string]

export const getTimeRemaining = (countdownTo: number) => {
  const timeLeft = calculateRemainingTime(countdownTo)
  return timeLeft.map((n) => (n <= 0 ? addLeadingZeroToNumber(0) : addLeadingZeroToNumber(n))) as TimeLeft
}

const addLeadingZeroToNumber = (number: number) => `0${number.toString()}`.slice(-2)

export const calculateRemainingTime = (endTime: number) => {
  const _second = 1000
  const _minute = _second * 60
  const _hour = _minute * 60
  const _day = _hour * 24
  const distance = endTime - new Date().getTime()
  const days = Math.floor(distance / _day)
  const hours = Math.floor((distance % _day) / _hour)
  const minutes = Math.floor((distance % _hour) / _minute)
  const seconds = Math.floor((distance % _minute) / _second)

  return [days, hours, minutes, seconds]
}
