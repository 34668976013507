import {
  BS_PROGRESS,
  COC_PROGRESS,
  convertToMajorUnits,
  CR_PROGRESS,
  ProductV2,
  Profile,
  HD_PROGRESS,
  DEFAULT_LANGUAGE,
  isDefined,
  columnTypes,
} from '@common'

import { CartItem } from '@/utils/cart'

export enum GTMCheckoutStep {
  Summary = 1,
  Login = 2,
  Payment = 3,
  BillingCountry = 4,
}

const dataLayer = <T extends { [key: string]: unknown }>(event: string | undefined, content: T) => {
  window.dataLayer?.push({
    event,
    ...content,
  })
}

export const gtmPageLoad = (url: string, language: string) => {
  dataLayer('pageLoad', { pagePath: url.split('?')[0], pageTitle: document.title, language })
}

export const gtmProductView = (product: ProductV2) => {
  const price = product.prices[columnTypes.DEFAULT_CURRENCY_COUNTRY]
  const title = product.title[DEFAULT_LANGUAGE]
  if (!price || !title) {
    return
  }

  dataLayer(undefined, { ecommerce: null })
  dataLayer('productView', {
    ecommerce: {
      detail: {
        products: [
          {
            game: product.game,
            name: product.title[DEFAULT_LANGUAGE],
            id: product.id,
            category: Object.keys(product.contentCounts)[0] ?? 'OTHER',
            price: convertToMajorUnits(price, columnTypes.DEFAULT_CURRENCY_CODE),
            quantity: 1,
          },
        ],
      },
    },
  })
}

export const gtmAddToCart = (product: ProductV2) => {
  const price = product.prices[columnTypes.DEFAULT_CURRENCY_COUNTRY]
  const title = product.title[DEFAULT_LANGUAGE]
  if (!price || !title) {
    return
  }
  const priceInMajorUnits = convertToMajorUnits(price, columnTypes.DEFAULT_CURRENCY_CODE)
  dataLayer(undefined, { ecommerce: null })
  dataLayer('addToCart', {
    ecommerce: {
      currency: columnTypes.DEFAULT_CURRENCY_CODE,
      value: priceInMajorUnits,
      items: [
        {
          game: product.game,
          name: product.title[DEFAULT_LANGUAGE],
          id: product.id,
          category: Object.keys(product.contentCounts)[0] ?? 'OTHER',
          price: priceInMajorUnits,
          quantity: 1,
        },
      ],
    },
  })
}

export const gtmRemoveFromCart = (product: ProductV2) => {
  const price = product.prices[columnTypes.DEFAULT_CURRENCY_COUNTRY]
  const title = product.title[DEFAULT_LANGUAGE]
  if (!price || !title) {
    return
  }

  const priceInMajorUnits = convertToMajorUnits(price, columnTypes.DEFAULT_CURRENCY_CODE)
  dataLayer(undefined, { ecommerce: null })
  dataLayer('removeFromCart', {
    ecommerce: {
      currency: columnTypes.DEFAULT_CURRENCY_CODE,
      value: priceInMajorUnits,
      items: [
        {
          game: product.game,
          name: product.title[DEFAULT_LANGUAGE],
          id: product.id,
          category: Object.keys(product.contentCounts)[0] ?? 'OTHER',
          price: priceInMajorUnits,
          quantity: 1,
        },
      ],
    },
  })
}

export const gtmBeginCheckout = (rows: CartItem[], country?: string) => {
  const items = rows
    .map(({ product, quantity }) => {
      const price = product.prices[columnTypes.DEFAULT_CURRENCY_COUNTRY]
      const title = product.title[DEFAULT_LANGUAGE]
      if (!price || !title) {
        return undefined
      }
      return {
        game: product.game,
        name: title,
        id: product.id,
        category: Object.keys(product.contentCounts)[0] ?? 'OTHER',
        price: convertToMajorUnits(price, columnTypes.DEFAULT_CURRENCY_CODE),
        quantity,
      }
    })
    .filter(isDefined)
  const value = items.reduce((acc, item) => acc + item.price * item.quantity, 0)
  dataLayer(undefined, { ecommerce: null })
  dataLayer('beginCheckout', {
    country,
    ecommerce: {
      currency: columnTypes.DEFAULT_CURRENCY_CODE,
      value,
      items,
    },
  })
}

export const gtmEnforceLogin = () => {
  dataLayer('enforceLogin', { pageTitle: document.title })
}

export const gtmInitViewerCountry = (country: string) => {
  dataLayer('initViewerCountry', { viewerCountry: country })
}

export const gtmLoginEvent = (user: Profile['profile']) => {
  const clashOfClansAccount = user.applications?.find((a) => a.application === 'clashofclans')?.account
  const haydayAccount = user.applications?.find((a) => a.application === 'hayday')?.account
  const brawlStarsAccount = user.applications?.find((a) => a.application === 'brawlstars')?.account
  const clashRoayleAccount = user.applications?.find((a) => a.application === 'clashroyale')?.account
  dataLayer('login', {
    clashOfClansThLevel: clashOfClansAccount?.progress?.[COC_PROGRESS.TOWN_HALL],
    clashOfClansTrophies: clashOfClansAccount?.progress?.[COC_PROGRESS.TROPHIES],
    haydayFarmLevel: haydayAccount?.progress?.[HD_PROGRESS.FARM_LEVEL],
    brawlStarsExpLevel: brawlStarsAccount?.progress?.[BS_PROGRESS.EXP_LEVEL],
    brawlStarsTrophies: brawlStarsAccount?.progress?.[BS_PROGRESS.TROPHIES],
    clashRoyaleArenaName: clashRoayleAccount?.progress?.[CR_PROGRESS.ARENA_NAME],
    clashRoyaleTrophies: clashRoayleAccount?.progress?.[CR_PROGRESS.TROPHIES],
  })
}

export const gtmPaymentError = (errorType: string) => {
  dataLayer(undefined, { ecommerce: null })
  dataLayer('purchaseError', { errorType })
}
