import { useState, createElement, useEffect } from 'react'

import { GameId } from '@common'
import cx from 'classnames'
import { AnimatePresence, LazyMotion } from 'framer-motion'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useGameImages } from '@/components/games/images'
import { useModalContext } from '@/contexts/ModalContext'
import { StorefrontModal } from '@/features/storefronts/_storeBase/modal/StorefrontModal'
import { setOnboardingCompletedCookie, hasCompletedOnboarding } from '@/utils/cookies'

import { Footer } from './Footer'

import styles from './OnboardingModal.module.scss'

export type OnboardingModalProps = {
  type: 'ONBOARDING'
  props: Props
}

type Props = {
  gameId: GameId
  screens: (() => JSX.Element)[]
}

const features = () => import('@/animations/features').then((a) => a.default)

function getInitialStep(onboardingCompleted: boolean) {
  return onboardingCompleted ? null : 0
}

export const OnboardingModal = ({ gameId, screens }: Props) => {
  const t = useTranslations()
  const { closeModal } = useModalContext()
  const [step, setStep] = useState<number | null>(null)
  const { gameImage } = useGameImages(gameId)

  const lastOnboardingScreen = screens.length - 1

  useEffect(() => {
    const onboardingCompleted = hasCompletedOnboarding()
    const initialStep = getInitialStep(onboardingCompleted)
    setStep(initialStep)
  }, [screens.length])

  useEffect(() => {
    window.onpopstate = () => {
      closeModal()
    }
    return () => {
      window.onpopstate = null
    }
  }, [closeModal])

  const onOnboardingComplete = () => {
    setOnboardingCompletedCookie()
    setStep(null)
    closeModal()
  }

  const onNextStep = (nextStep: number) => {
    if (step === lastOnboardingScreen) {
      onOnboardingComplete()
    } else {
      setStep(nextStep)
    }
  }

  const onSkip = () => {
    onOnboardingComplete()
  }

  if (step === null) {
    return null
  }

  return (
    <LazyMotion features={features}>
      <StorefrontModal
        position="Middle"
        onClose={closeModal}
        closeOnClickOutside={false}
        className={styles.onboardingModal}
      >
        <div className={styles.OnboardingModalContainer}>
          <div className={cx(styles.Prompt, styles[gameId])}>
            <div className={cx(styles.Header, [styles[gameId]])}>
              <Image src={gameImage('logo')} alt={gameId} width={160} />
              <button className={cx(styles.CloseButton, styles[gameId])} onClick={onSkip}>
                {t('onboarding_skip')}
              </button>
            </div>
            <div className={cx(styles.Content, styles[gameId])}>
              <div className={styles.Screen}>
                <AnimatePresence mode="wait">{createElement(screens[step], { key: `screen-${step}` })}</AnimatePresence>
              </div>
            </div>
            <Footer
              loginStep={false}
              lastOnboardingScreen={step === lastOnboardingScreen}
              onNextStep={() => onNextStep(step + 1)}
            />
          </div>
        </div>
      </StorefrontModal>
    </LazyMotion>
  )
}
