import { GameId, SupportedLanguage } from '@common'

import { useLocale } from '@/features/locale/useLocale'

import BrawlStarsAppIcon from 'assets/images/brawlstars/app-icon-brawlstars.png'
import BrawlStarsBg from 'assets/images/brawlstars/background.png'
import BrawlStarsBgBasic from 'assets/images/brawlstars/background_bp_basic.png'
import BrawlStarsBgPremium from 'assets/images/brawlstars/background_bp_plus.png'
import BrawlStarsBadgeOutline from 'assets/images/brawlstars/badge-outline.svg'
import BrawlStarsBadge from 'assets/images/brawlstars/badge.png'
import BrawlStarsGameBadge from 'assets/images/brawlstars/game-badge.png'
import BrawlStarsHeaderLogo_JA from 'assets/images/brawlstars/header-logo-ja.png'
import BrawlStarsHeroDesktop_JA from 'assets/images/brawlstars/hero-desktop-ja.png'
import BrawlStarsHeroDesktop from 'assets/images/brawlstars/hero-desktop.png'
import BrawlStarsHeroMobile from 'assets/images/brawlstars/hero-mobile.png'
import BrawlStarsLogo_JA from 'assets/images/brawlstars/logo-ja.png'
import BrawlStarsLogo from 'assets/images/brawlstars/logo.png'
import BrawlStarsMissingImageLarge from 'assets/images/brawlstars/missing-image-large.png'
import BrawlStarsMissingImageSmall from 'assets/images/brawlstars/missing-image-small.png'
import BrawlStarsPass from 'assets/images/brawlstars/pass.png'
import BrawlStarsPassBasic from 'assets/images/brawlstars/pass_basic.png'
import BrawlStarsPassPremium from 'assets/images/brawlstars/pass_premium.png'
import ClashOfClansAppIcon from 'assets/images/clashofclans/app-icon-clashofclans.png'
import ClashOfClansBg from 'assets/images/clashofclans/background.png'
import ClashOfClansBadgeOutline from 'assets/images/clashofclans/badge-outline.svg'
import ClashOfClansBadge from 'assets/images/clashofclans/badge.png'
import ClashOfClasnsEventPass from 'assets/images/clashofclans/event-pass.png'
import ClashOfClansGameBadge from 'assets/images/clashofclans/game-badge.png'
import ClashOfClansHeroDesktop_JA from 'assets/images/clashofclans/hero-desktop-ja.png'
import ClashOfClansHeroDesktop from 'assets/images/clashofclans/hero-desktop.png'
import ClashOfClansHeroMobile from 'assets/images/clashofclans/hero-mobile.png'
import ClashOfClansLogo_JA from 'assets/images/clashofclans/logo-ja.png'
import ClashOfClansLogo from 'assets/images/clashofclans/logo.png'
import ClashOfClansMissingImageLarge from 'assets/images/clashofclans/missing-image-large.png'
import ClashOfClansMissingImageSmall from 'assets/images/clashofclans/missing-image-small.png'
import ClashOfClansPass from 'assets/images/clashofclans/pass.png'
import ClashRoyaleAppIcon from 'assets/images/clashroyale/app-icon-clashroyale.png'
import ClashRoyaleBg from 'assets/images/clashroyale/background.png'
import ClashRoyaleBadgeOutline from 'assets/images/clashroyale/badge-outline.svg'
import ClashRoyaleBadge from 'assets/images/clashroyale/badge.png'
import ClashRoyaleGameBadge from 'assets/images/clashroyale/game-badge.png'
import ClashRoyaleHeroDesktopJA from 'assets/images/clashroyale/hero-desktop-ja.png'
import ClashRoyaleHeroDesktop from 'assets/images/clashroyale/hero-desktop.png'
import ClashRoyaleHeroMobile from 'assets/images/clashroyale/hero-mobile.png'
import ClashRoyaleLogo_JA from 'assets/images/clashroyale/logo-ja.png'
import ClashRoyaleLogo from 'assets/images/clashroyale/logo.png'
import ClashRoyaleMissingImageLarge from 'assets/images/clashroyale/missing-image-large.png'
import ClashRoyaleMissingImageSmall from 'assets/images/clashroyale/missing-image-small.png'
import ClashRoyalePassPremium from 'assets/images/clashroyale/pass-prem.png'
import ClashRoyalePass from 'assets/images/clashroyale/pass.png'
import HayDayAppIcon from 'assets/images/hayday/app-icon-hayday.png'
import HayDayBg from 'assets/images/hayday/background.png'
import HayDayBadge_JA from 'assets/images/hayday/badge-ja.png'
import HayDayBadgeOutline from 'assets/images/hayday/badge-outline.svg'
import HayDayBadge from 'assets/images/hayday/badge.png'
import HayDayGameBadge from 'assets/images/hayday/game-badge.png'
import HayDayHeroDesktop_JA from 'assets/images/hayday/hero-desktop-ja.png'
import HayDayHeroDesktop from 'assets/images/hayday/hero-desktop.png'
import HayDayHeroMobile from 'assets/images/hayday/hero-mobile.png'
import HayDayLogo_JA from 'assets/images/hayday/logo-ja.png'
import HayDayLogo from 'assets/images/hayday/logo.png'
import HayDayPass from 'assets/images/hayday/pass.png'
import SquadBustersAppIcon from 'assets/images/squadbusters/app-icon-squadbusters.png'
import SquadBustersBg from 'assets/images/squadbusters/background.png'
//import SquadBustersBadgeOutline from 'assets/images/squadbusters/badge-outline.svg'
import SquadBustersBadge from 'assets/images/squadbusters/badge.png'
import SquadBustersGameBadge from 'assets/images/squadbusters/game-badge.png'
import SquadBustersHeroDesktop_JA from 'assets/images/squadbusters/hero-desktop-ja.png'
import SquadBustersHeroDesktop from 'assets/images/squadbusters/hero-desktop.png'
import SquadBustersHeroMobile from 'assets/images/squadbusters/hero-mobile.png'
import SquadBustersLogo_JA from 'assets/images/squadbusters/logo-ja.png'
import SquadBustersLogo from 'assets/images/squadbusters/logo.png'
//import SquadBustersMissingImageLarge from 'assets/images/squadbusters/missing-image-large.png'
//import SquadBustersMissingImageSmall from 'assets/images/squadbusters/missing-image-small.png'
//import SquadBustersPassPremium from 'assets/images/squadbusters/pass-prem.png'
//import SquadBustersPass from 'assets/images/squadbusters/pass.png'
import ClashOfClansHeaderBg from 'assets/images/storefronts/clashofclans/header-bg.png'

type LocalizedVariants = Partial<Record<SupportedLanguage, typeof HayDayBg>> &
  Record<'default', typeof HayDayBg> &
  Partial<Record<'passbasic' | 'passpremium', typeof HayDayBg>>

type Images = Record<GameId, ImageVariants>

interface ImageVariants {
  passProductImageDefault: LocalizedVariants
  passProductImageBasic: LocalizedVariants
  passProductImagePremium: LocalizedVariants
  passProductImageEvent: LocalizedVariants
  logo: LocalizedVariants
  badge: LocalizedVariants
  gameBadge: LocalizedVariants
  badgeOutline: LocalizedVariants
  heroMobile: LocalizedVariants
  heroDesktop: LocalizedVariants
  background: LocalizedVariants
  appIcon: LocalizedVariants
  headerBg: LocalizedVariants
  headerLogo: LocalizedVariants
  missingProductImageSmall: LocalizedVariants
  missingProductImageLarge: LocalizedVariants
}

const images: Images = {
  clashofclans: {
    passProductImageDefault: {
      default: ClashOfClansPass,
    },
    passProductImageBasic: {
      default: ClashOfClansPass,
    },
    passProductImagePremium: {
      default: ClashOfClansPass,
    },
    passProductImageEvent: {
      default: ClashOfClasnsEventPass,
    },
    logo: {
      ja: ClashOfClansLogo_JA,
      default: ClashOfClansLogo,
    },
    badge: {
      default: ClashOfClansBadge,
    },
    gameBadge: {
      default: ClashOfClansGameBadge,
    },
    badgeOutline: {
      default: ClashOfClansBadgeOutline,
    },
    heroMobile: {
      ja: ClashOfClansHeroMobile,
      default: ClashOfClansHeroMobile,
    },
    heroDesktop: {
      ja: ClashOfClansHeroDesktop_JA,
      default: ClashOfClansHeroDesktop,
    },
    background: {
      default: ClashOfClansBg,
    },
    appIcon: {
      default: ClashOfClansAppIcon,
    },
    headerBg: {
      default: ClashOfClansHeaderBg,
    },
    headerLogo: {
      ja: ClashOfClansLogo_JA,
      default: ClashOfClansLogo,
    },
    missingProductImageSmall: {
      default: ClashOfClansMissingImageSmall,
    },
    missingProductImageLarge: {
      default: ClashOfClansMissingImageLarge,
    },
  },
  hayday: {
    passProductImageDefault: {
      default: HayDayPass,
    },
    passProductImageBasic: {
      default: HayDayPass,
    },
    passProductImagePremium: {
      default: HayDayPass,
    },
    passProductImageEvent: {
      default: HayDayPass,
    },
    logo: {
      default: HayDayLogo,
      ja: HayDayLogo_JA,
    },
    badge: {
      default: HayDayBadge,
      ja: HayDayBadge_JA,
    },
    gameBadge: {
      default: HayDayGameBadge,
    },
    badgeOutline: {
      default: HayDayBadgeOutline,
    },
    heroMobile: {
      default: HayDayHeroMobile,
    },
    heroDesktop: {
      default: HayDayHeroDesktop,
      ja: HayDayHeroDesktop_JA,
    },
    background: {
      default: HayDayBg,
    },
    appIcon: {
      default: HayDayAppIcon,
    },
    headerBg: {
      default: HayDayBg,
    },
    headerLogo: {
      default: HayDayLogo,
      ja: HayDayLogo_JA,
    },
    missingProductImageSmall: {
      default: HayDayBadge,
    },
    missingProductImageLarge: {
      default: HayDayBadge,
    },
  },
  brawlstars: {
    passProductImageDefault: {
      default: BrawlStarsPass,
    },
    passProductImageBasic: {
      default: BrawlStarsPassBasic,
    },
    passProductImagePremium: {
      default: BrawlStarsPassPremium,
    },
    passProductImageEvent: {
      default: BrawlStarsPass,
    },
    logo: {
      default: BrawlStarsLogo,
      ja: BrawlStarsLogo_JA,
    },
    badge: {
      default: BrawlStarsBadge,
    },
    gameBadge: {
      default: BrawlStarsGameBadge,
    },
    badgeOutline: {
      default: BrawlStarsBadgeOutline,
    },
    heroMobile: {
      default: BrawlStarsHeroMobile,
    },
    heroDesktop: {
      default: BrawlStarsHeroDesktop,
      ja: BrawlStarsHeroDesktop_JA,
    },
    background: {
      default: BrawlStarsBg,
      passpremium: BrawlStarsBgPremium,
      passbasic: BrawlStarsBgBasic,
    },
    appIcon: {
      default: BrawlStarsAppIcon,
    },
    headerBg: {
      default: BrawlStarsBg,
    },
    headerLogo: {
      default: BrawlStarsLogo,
      ja: BrawlStarsHeaderLogo_JA,
    },
    missingProductImageSmall: {
      default: BrawlStarsMissingImageSmall,
    },
    missingProductImageLarge: {
      default: BrawlStarsMissingImageLarge,
    },
  },
  clashroyale: {
    passProductImageDefault: {
      default: ClashRoyalePass,
    },
    passProductImageBasic: {
      default: ClashRoyalePass,
    },
    passProductImagePremium: {
      default: ClashRoyalePassPremium,
    },
    passProductImageEvent: {
      default: ClashRoyalePass,
    },
    logo: {
      default: ClashRoyaleLogo,
      ja: ClashRoyaleLogo_JA,
    },
    badge: {
      default: ClashRoyaleBadge,
    },
    gameBadge: {
      default: ClashRoyaleGameBadge,
    },
    badgeOutline: {
      default: ClashRoyaleBadgeOutline,
    },
    heroMobile: {
      default: ClashRoyaleHeroMobile,
    },
    heroDesktop: {
      default: ClashRoyaleHeroDesktop,
      ja: ClashRoyaleHeroDesktopJA,
    },
    background: {
      default: ClashRoyaleBg,
    },
    appIcon: {
      default: ClashRoyaleAppIcon,
    },
    headerBg: {
      default: ClashRoyaleBg,
    },
    headerLogo: {
      default: ClashRoyaleLogo,
      ja: ClashRoyaleLogo_JA,
    },
    missingProductImageSmall: {
      default: ClashRoyaleMissingImageSmall,
    },
    missingProductImageLarge: {
      default: ClashRoyaleMissingImageLarge,
    },
  },
  squadbusters: {
    passProductImageDefault: {
      default: BrawlStarsMissingImageSmall,
    },
    passProductImageBasic: {
      default: BrawlStarsMissingImageSmall,
    },
    passProductImagePremium: {
      default: BrawlStarsMissingImageSmall,
    },
    passProductImageEvent: {
      default: BrawlStarsMissingImageSmall,
    },
    logo: {
      default: SquadBustersLogo,
      ja: SquadBustersLogo_JA,
    },
    badge: {
      default: SquadBustersBadge,
    },
    gameBadge: {
      default: SquadBustersGameBadge,
    },
    badgeOutline: {
      default: SquadBustersBadge,
    },
    heroMobile: {
      default: SquadBustersHeroMobile,
    },
    heroDesktop: {
      default: SquadBustersHeroDesktop,
      ja: SquadBustersHeroDesktop_JA,
    },
    background: {
      default: SquadBustersBg,
    },
    appIcon: {
      default: SquadBustersAppIcon,
    },
    headerBg: {
      default: SquadBustersBg,
    },
    headerLogo: {
      default: SquadBustersLogo,
      ja: SquadBustersLogo_JA,
    },
    missingProductImageSmall: {
      default: BrawlStarsMissingImageSmall,
    },
    missingProductImageLarge: {
      default: BrawlStarsMissingImageLarge,
    },
  },
} as const

const getGameImage =
  (variant: SupportedLanguage | 'passbasic' | 'passpremium', game: GameId) => (image: keyof ImageVariants) => {
    return images[game][image][variant] ?? images[game][image]['default']
  }

export const useGameImages = (game: GameId, variant?: 'passbasic' | 'passpremium') => {
  const locale = useLocale()

  return { gameImage: getGameImage(variant ?? locale, game) }
}
