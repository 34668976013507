import React from 'react'

import cx from 'classnames'

import styles from './StripedContainer.module.scss'

type Props = React.PropsWithChildren<{
  containerClass?: string
  stripClass?: string
  ref?: React.Ref<HTMLDivElement>
  error?: boolean
}>

// eslint-disable-next-line react/display-name
export const StripedContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ containerClass, stripClass, error, children }: Props, ref) => (
    <div className={cx(styles.Container, { [styles.Error]: error }, containerClass)} ref={ref}>
      <span className={cx(styles.StripWrapper)}>
        <span className={cx(styles.Strip, stripClass)} />
      </span>
      {children}
    </div>
  ),
)
