import { DDBCartItem, isDefined, ProductV2 } from '@common'

export type CartItem = {
  product: ProductV2
  quantity: number
  ineligible?: boolean
}

export function cartItemQuantity(items: CartItem[]) {
  return items.reduce((acc, item) => acc + item.quantity, 0)
}

export function cartItemsToDdbCartItems(cartItems: CartItem[]): DDBCartItem[] {
  return cartItems.map(cartItemToDdbCartItem)
}

function cartItemToDdbCartItem(cartItem: CartItem): DDBCartItem {
  return {
    sku: cartItem.product.id,
    quantity: cartItem.quantity,
  }
}

export function ddbCartItemsToCartItems(ddbCartItems: DDBCartItem[], products: ProductV2[]): CartItem[] {
  return ddbCartItems.map((ddbCartItem) => ddbCartItemToCartItem(ddbCartItem, products)).filter(isDefined)
}

function ddbCartItemToCartItem(ddbCartItem: DDBCartItem, products: ProductV2[]): CartItem | null {
  const product = products.find((product) => product.id === ddbCartItem.sku)

  if (!product) {
    return null
  }

  return {
    product,
    quantity: ddbCartItem.quantity,
  }
}
