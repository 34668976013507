import { z, literal } from 'zod'

export const StageSchema = z
  .union([
    literal('local-dev'),
    literal('test'),
    literal('development'),
    literal('integration'),
    literal('staging'),
    literal('production'),
  ])
  .default('development')

export type Stage = z.infer<typeof StageSchema>

export const enabledIn = (stage: Stage, environments: Stage[]) => environments.includes(stage)
