import { useMemo } from 'react'

export type Callback<T> = (args: T) => void | Promise<void>

function debounce<T>(fn: Callback<T>, ms: number) {
  let timer: NodeJS.Timeout | null = null
  return function debouncedFn(args: T) {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => fn(args), ms)
  }
}

export function useDebouncedFn<T>(fn: Callback<T>, ms: number) {
  return useMemo(() => debounce(fn, ms), [fn, ms])
}
