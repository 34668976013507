import { ProductV2 } from '@common'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import { useProductImage } from '@/features/storefronts/_storeBase/card/utils'

import BrawlerBackground from 'assets/images/components/BrawlerCard/brawlercard-hero-bg.png'

import styles from './BrawlerHero.module.scss'

type Props = {
  product: ProductV2
  className?: string
}

const AnimatedImageWithFallbackNoSSR = dynamic<{
  animationSrc: string
  fallbackImage: string
  safariAnimationSrc?: string | undefined
  className?: string
}>(
  () =>
    import('@/features/storefronts/_storeBase/imageWithFallback/AnimatedImageWithFallback').then(
      (mod) => mod.AnimatedImageWithFallback,
    ),
  { ssr: false },
)

export const BrawlerHero = ({ product, className }: Props) => {
  const item = product.contents.find((c) => c.product.itemType === 'Hero' || c.product.itemType === 'Skin')

  if (!item) {
    throw new Error('Invalid itemType')
  }

  const { imageSrc, animatedSrc, safariAnimatedSrc } = useProductImage({
    game: 'brawlstars',
    productId: item.product.id,
    itemType: item.product.itemType,
    count: item.amount,
  })

  return (
    <div className={cx(styles.BrawlerHero, className)} dir="ltr">
      <Image className={styles.Background} src={BrawlerBackground} alt="" fill />
      <div className={styles.Character}>
        <AnimatedImageWithFallbackNoSSR
          animationSrc={animatedSrc}
          fallbackImage={imageSrc}
          safariAnimationSrc={safariAnimatedSrc}
        />
      </div>
    </div>
  )
}
