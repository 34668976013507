import cx from 'classnames'

import styles from './Arrow.module.scss'

export type Direction = 'Up' | 'Down' | 'Left' | 'Right'

interface ArrowProps {
  direction: Direction
  className?: string | string[]
}

export const Arrow: React.FC<ArrowProps> = ({ direction, className: additionalClasses }) => {
  return (
    <span className={cx(styles.Arrow, styles[direction], additionalClasses)}>
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_247_10475)">
          <path
            d="M5.99999 11.1433C5.78067 11.1433 5.56124 11.0596 5.3941 10.8922L0.251238 5.7493C-0.0835833 5.41448 -0.0835833 4.87207 0.251238 4.53725C0.58606 4.20243 1.12847 4.20243 1.46329 4.53725L5.99999 9.07542L10.5375 4.53792C10.8723 4.2031 11.4147 4.2031 11.7495 4.53792C12.0844 4.87274 12.0844 5.41515 11.7495 5.74997L6.60669 10.8928C6.43927 11.0602 6.21963 11.1433 5.99999 11.1433Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_247_10475">
            <rect width="12" height="13.7143" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}
