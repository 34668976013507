import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import BrawlProductInfoImg from '@/assets/images/storefronts/brawlstars/product-page-info.png'
import ClashProductInfoImg from '@/assets/images/storefronts/clashofclans/product-page-info.png'
import ClashRoyaleProductInfoImg from '@/assets/images/storefronts/clashroyale/product-page-info.png'
import { getShortSlugNotHD } from '@/fixtures/games'

import { ProductTypes } from './constants'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './info.module.scss'

type ProductInfoProps = {
  gameSlug: GameId
  productType?: ProductTypes
}

export const ProductInfo = ({ gameSlug, productType }: ProductInfoProps) => {
  const t = useTranslations()
  const shortSlug = getShortSlugNotHD(gameSlug)
  const body1 = t(`${shortSlug}_products_info_purchases`)
  const body2 = t('products_info_challenges')

  const ProductInfoImg = (gameSlug: GameId) => {
    switch (gameSlug) {
      case 'clashofclans':
        return ClashProductInfoImg
      case 'clashroyale':
        return ClashRoyaleProductInfoImg
      case 'brawlstars':
        return BrawlProductInfoImg
      default:
        return ClashProductInfoImg
    }
  }

  return (
    <div className={cx(styles.info, styles[gameSlug], styles[productType ?? ''])}>
      <div className={styles.infoContent}>
        <StoreTypography tag="h5" style="plain" textSize="body" overrideFont="supercellTextMedium">
          {t('products_info_points_and_bonuses')}
        </StoreTypography>
        <div className={styles.infoImage}>
          <Image src={ProductInfoImg(gameSlug)} alt="Products info" quality={100} fill sizes="150px" />
        </div>
        <StoreTypography tag="p" textSize="body" style="plain" overrideFont="supercellText" lineHeight="normal">
          {body1}
        </StoreTypography>
        <StoreTypography tag="p" textSize="body" style="plain" overrideFont="supercellText" lineHeight="normal">
          {body2}
        </StoreTypography>
      </div>
    </div>
  )
}

ProductInfo.displayName = 'ProductInfo'
