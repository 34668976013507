import { supportedLanguages } from '@common'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { useLocale } from '@/features/locale/useLocale'

import { config } from '../../config'

const { APP_URL } = config

function getOpenGraphImage(gameId: string | undefined) {
  switch (gameId) {
    case 'clashofclans':
      return 'og-clashofclans.png'
    case 'clashroyale':
      return 'og-clashroyale.png'
    case 'brawlstars':
      return 'og-brawlstars.png'
    default:
      return 'shareimage.png'
  }
}

export const DefaultMetaTags: React.FC = () => {
  const locale = useLocale()
  const t = useTranslations()
  const router = useRouter()
  const gameId = router.query.gameSlug as string | undefined

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1, maximum-scale=1" />
      <link rel="icon" href={`${APP_URL}/favicon.ico`} />
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${APP_URL}/apple-touch-icon-57x57.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${APP_URL}/apple-touch-icon-114x114.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${APP_URL}/apple-touch-icon-72x72.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${APP_URL}/apple-touch-icon-144x144.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href={`${APP_URL}/apple-touch-icon-60x60.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href={`${APP_URL}/apple-touch-icon-120x120.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href={`${APP_URL}/apple-touch-icon-76x76.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href={`${APP_URL}/apple-touch-icon-152x152.png`} />
      <link rel="apple-touch-icon" href={`${APP_URL}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" href={`${APP_URL}/favicon-196x196.png`} sizes="196x196" />
      <link rel="icon" type="image/png" href={`${APP_URL}/favicon-96x96.png`} sizes="96x96" />
      <link rel="icon" type="image/png" href={`${APP_URL}/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${APP_URL}/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`${APP_URL}/favicon-128.png`} sizes="128x128" />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content={`${APP_URL}/mstile-144x144.png`} />
      <meta name="msapplication-square70x70logo" content={`${APP_URL}/mstile-70x70.png`} />
      <meta name="msapplication-square150x150logo" content={`${APP_URL}/mstile-150x150.png`} />
      <meta name="msapplication-wide310x150logo" content={`${APP_URL}/mstile-310x150.png`} />
      <meta name="msapplication-square310x310logo" content={`${APP_URL}/mstile-310x310.png`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:image" content={`${APP_URL}/images/${getOpenGraphImage(gameId)}`} />
      <meta property="og:site_name" content={t('sitename')} />
      <meta property="og:locale" content={supportedLanguages[locale].locale} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={APP_URL} />
      <meta name="twitter:image" content={`${APP_URL}/images/${getOpenGraphImage(gameId)}`} />

      <meta name="robots" content="index,follow" />
    </Head>
  )
}

interface PageMetaTagProps {
  title: string
  description: string
  theme?: string
}

export const PageMetaTags: React.FC<PageMetaTagProps> = ({ title, description, theme = '#fff' }) => {
  return (
    <Head>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="theme-color" content={theme} />
    </Head>
  )
}
