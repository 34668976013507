import { useMemo } from 'react'

import { games, GAME_STATUS } from '@/fixtures/games'
import { sortByStatusAndPriority } from '@/utils/sortGames'
import { useAppSelector } from '@/utils/useAppSelector'

export const useGames = () => {
  const enableComingSoon = useAppSelector((state) => state.config.features.enableComingSoon)
  const allGames = useMemo(() => {
    const enabledOrComingSoonGames = sortByStatusAndPriority(games, [GAME_STATUS.ENABLED, GAME_STATUS.COMING_SOON])
    if (enableComingSoon) {
      return enabledOrComingSoonGames.map((g) => ({ ...g, status: GAME_STATUS.ENABLED }))
    }
    return enabledOrComingSoonGames
  }, [enableComingSoon])

  return allGames
}
