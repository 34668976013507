import React from 'react'

import { ProductV2 } from '@common'

import { RoundButton } from '@/components/buttons/RoundButton'
import { ExitIcon } from '@/components/Icons/ExitIcon'

import { Product } from '../products/Product'

import styles from './ProductOverlay.module.scss'

type Props = {
  product: ProductV2
  products?: ProductV2[]
  closeModal: () => void
}

export function ProductOverlay({ product, products, closeModal }: Props) {
  return (
    <div className={styles.Content}>
      <RoundButton additionalClasses={styles.CloseButton} onClick={closeModal} data-cy="product-modal-close-button">
        <ExitIcon />
      </RoundButton>

      <Product product={product} products={products} isProductPage={false} />
    </div>
  )
}
