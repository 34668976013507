import { RefObject, useEffect } from 'react'

import { supportedLanguages } from '@common'

import { useLocale } from '../features/locale/useLocale'

type AnyEvent = MouseEvent | TouchEvent | KeyboardEvent

const scrollbarWidth = 15

const clickedOnScrollbar = (event: AnyEvent, rtl: boolean) => {
  if (!(event instanceof MouseEvent)) {
    return false
  }
  if (rtl) {
    return event.clientX <= scrollbarWidth
  }
  return document.documentElement.clientWidth - event.clientX <= scrollbarWidth
}

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
): void {
  const locale = useLocale()

  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current

      if (clickedOnScrollbar(event, supportedLanguages[locale].rightToLeft)) {
        return
      }

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handler(event)
      }
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`keydown`, handleEsc)
    }

    // Reload only if ref or handler changes
  }, [ref, handler, locale])
}

export default useOnClickOutside
