import React from 'react'

import { ProductV2, StoreOfferProduct } from '@common'
import cx from 'classnames'

import { BrawlStarsHero } from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/brawlstars/components/BrawlStarsHero'
import { BrawlStarsOfferCardContent } from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/brawlstars/components/BrawlStarsOfferCardContent'
import { Container } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Container'
import { Ribbon, Ribbons } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Ribbons'
import { Countdown, ValueBadge } from '@/features/storefronts/_storeBase/card/v2/shared'
import { Flare } from '@/features/storefronts/_storeBase/flare/Flare'
import { isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'
import { BrawlPassCard } from '@/features/storefronts/brawlstars/passCard/v2/BrawlPassCardItem'
import { isSeasonPass, isStoreSpecial } from '@/features/storefronts/productFilters'

import styles from './PdpProductHero.module.scss'

interface Props {
  product: ProductV2
  ribbons: Ribbon[]
  className?: string
}

const SelectRenderedProductHero = (product: ProductV2) => {
  const isMultiItemOffer = product.contents.length > 1
  const isPass = isSeasonPass(product)
  const isSpecialOffer = isStoreSpecial(product)
  const isSingleSkinOrBrawler = !isMultiItemOffer && isBrawlerOrSkin(product.contents[0])

  if (isPass) {
    return <BrawlPassCard product={product} />
  }
  if (isMultiItemOffer || isSpecialOffer) {
    return <BrawlStarsHero product={product} className={styles.MultiItemHero} />
  }
  if (isSingleSkinOrBrawler) {
    return <BrawlStarsHero product={product} className={styles.BrawlerHero} />
  }
  return <BrawlStarsOfferCardContent product={product} />
}

export const BrawlPdpProductHero = ({ product, ribbons, className }: Props) => {
  const isMultiItemOffer = product.contents.length > 1
  return (
    <Container
      product={product}
      className={cx(styles.ContainerStyle, className, {
        [styles.MultiItemContainer]: isMultiItemOffer,
        [styles.SingleItemContainer]: !isMultiItemOffer,
      })}
    >
      <Countdown product={product as StoreOfferProduct} />
      {ribbons.length === 0 ? <ValueBadge product={product as StoreOfferProduct} /> : <Ribbons ribbons={ribbons} />}
      <Flare game={'brawlstars'} />
      {SelectRenderedProductHero(product)}
    </Container>
  )
}
