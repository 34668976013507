import { useMemo } from 'react'

import { INELIGIBLE_REASON, isTooLowLevelForPass } from '@common'
import { m } from 'framer-motion'
import { useTranslations } from 'next-intl'

import { Alert } from '@/components/Icons'
import { useTranslatable } from '@/localization/useTranslatable'
import { CartItem } from '@/utils/cart'
import { useAppSelector } from '@/utils/useAppSelector'

import styles from './EligibilityCopy.module.scss'

type Props = {
  ineligibleItem: {
    product: CartItem['product']
    reason?: string
  }
}
export function EligibilityCopy({ ineligibleItem }: Props) {
  const { product, reason } = ineligibleItem
  const translatable = useTranslatable()
  const t = useTranslations()
  const gameData = useAppSelector((state) =>
    state.user.profile?.applications?.find((app) => app.application === product?.game),
  )
  const isPassProduct = Boolean(product?.contentCounts.SEASON_PASS)

  const copy = useMemo(() => {
    if (!product) return

    if (reason === 'NOT_BOUND' || !gameData?.account) {
      return {
        title: t(`${INELIGIBLE_REASON.APP_ACCOUNT_NOT_CONNECTED}_tit`),
        subtitle: t(`${INELIGIBLE_REASON.APP_ACCOUNT_NOT_CONNECTED}_txt`),
        body: t.rich(`${INELIGIBLE_REASON.APP_ACCOUNT_NOT_CONNECTED}_txt_2`),
      }
    }

    if (isPassProduct && gameData && isTooLowLevelForPass(gameData)) {
      return {
        title: t(`${INELIGIBLE_REASON.APP_ACCOUNT_PROGRESS_TOO_LOW}_tit`),
        subtitle: t(`${INELIGIBLE_REASON.APP_ACCOUNT_PROGRESS_TOO_LOW}_txt`),
        body: t.rich(`${INELIGIBLE_REASON.APP_ACCOUNT_PROGRESS_TOO_LOW}_txt_2`),
      }
    }

    if (reason === 'NOT_ELIGIBLE' || !gameData?.account) {
      return {
        title: t(`${INELIGIBLE_REASON.APP_ACCOUNT_INELIGIBLE}_tit`),
        subtitle: t.rich(`${INELIGIBLE_REASON.APP_ACCOUNT_INELIGIBLE}_txt`),
      }
    }

    if (reason === 'QUOTA_EXCEEDED') {
      return {
        title: t(`${INELIGIBLE_REASON.APP_ACCOUNT_INVENTORY_FULL}_tit`),
        subtitle: t(`${INELIGIBLE_REASON.APP_ACCOUNT_INVENTORY_FULL}_txt`, { product: translatable(product.title) }),
        body: t.rich(`${INELIGIBLE_REASON.APP_ACCOUNT_INVENTORY_FULL}_txt_2`, { product: translatable(product.title) }),
      }
    }

    if (reason) {
      return {
        title: t(`${INELIGIBLE_REASON.APP_ACCOUNT_UNAVAILABLE}_tit`),
        subtitle: t(`${INELIGIBLE_REASON.APP_ACCOUNT_UNAVAILABLE}_txt`, { product: translatable(product.title) }),
        body: t.rich(`${INELIGIBLE_REASON.APP_ACCOUNT_UNAVAILABLE}_txt_2`, { product: translatable(product.title) }),
      }
    }

    return {
      title: t(`${INELIGIBLE_REASON.OTHER}_tit`),
      subtitle: t(`${INELIGIBLE_REASON.OTHER}_txt`),
      body: t.rich(`${INELIGIBLE_REASON.OTHER}_txt_2`),
    }
  }, [reason, gameData, isPassProduct, product, t, translatable])

  if (!copy) {
    return null
  }

  return (
    <m.div
      initial={{ opacity: 0, y: '-50%', height: 0 }}
      animate={{
        opacity: 1,
        y: 0,
        height: 'auto',
        transition: {
          height: {
            duration: 0.3,
          },
          opacity: {
            duration: 0.25,
          },
          y: {
            duration: 0.25,
          },
        },
      }}
      exit={{ opacity: 0, y: '-50%', height: 0, transition: { duration: 0.2 } }}
      className={styles.Container}
      data-cy="eligibility-copy"
    >
      <div className={styles.Content}>
        <Alert type="error" dataCy="alert-icon" />
        <h3>{copy.title}</h3>
        <p>{copy.subtitle}</p>
      </div>
    </m.div>
  )
}
