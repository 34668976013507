import { supportedLanguages } from '@common'
import cx from 'classnames'

import { useLocale } from '../../features/locale/useLocale'

import styles from './PillCounter.module.scss'

export interface PillCounterProps {
  count: number
  'data-cy'?: string
  increment: () => void
  decrement: () => void
  disabled?: boolean
  max?: number
  min?: number
  error?: boolean
  isActive?: boolean
  onOpenCb?: () => void
}

export const PillCounter: React.FC<PillCounterProps> = ({
  count,
  increment,
  decrement,
  'data-cy': dataCy,
  disabled,
  min,
  max,
  error,
}) => {
  const locale = useLocale()
  const formattedItemCount = new Intl.NumberFormat(supportedLanguages[locale].locale).format(count)
  return (
    <div
      className={cx(styles.PillCounter, { [styles.Error]: error })}
      data-cy={dataCy}
      aria-roledescription="button"
      role="button"
    >
      <button
        data-cy="pill-decrement"
        className={cx(styles.Decrement, { [styles.Disabled]: count === min })}
        onClick={decrement}
        disabled={count === min || disabled}
      >
        -
      </button>
      <span className={styles.Count} data-cy="pill-value">
        {formattedItemCount}
      </span>
      <button
        data-cy="pill-increment"
        className={cx(styles.Increment, { [styles.Disabled]: max !== undefined && count >= max })}
        onClick={increment}
        disabled={count === max || disabled}
      >
        +
      </button>
    </div>
  )
}
