import z from 'zod'
import allCurrencies from './all_currencies.json'
import supportedLanguagesConfig from './supportedLanguages.json'
import { columnTypes } from './db'
import { GameId } from './game'
import { paymentGatewaySchema } from './db/columnTypes'

export { default as allCurrencies } from './all_currencies.json'

export type Currency = keyof typeof allCurrencies

export type SupportedLanguage = keyof typeof supportedLanguages

export const supportedLanguages = supportedLanguagesConfig
export const LANGUAGES = Object.keys(supportedLanguages) as SupportedLanguage[]
export const DEFAULT_LANGUAGE = 'en' as const
export const isSupportedLanguage = (language: string | undefined): language is SupportedLanguage =>
  LANGUAGES.includes(language as SupportedLanguage)
export const getLocale = (ctx: { locale?: string }) => (isSupportedLanguage(ctx.locale) ? ctx.locale : DEFAULT_LANGUAGE)

const LOCALES_TO_LANGUAGES = Object.fromEntries(
  Object.entries(supportedLanguages).map(([language, { locale }]) => [locale, language]),
) as Record<string, SupportedLanguage>
export const localeToLanguage = (locale: string) => LOCALES_TO_LANGUAGES[locale] ?? DEFAULT_LANGUAGE

// Belarus, China (PRC), Cuba, Iran, Russia, Vietnam
export const BLACKLISTED_COUNTRIES = columnTypes.countryCodeSchema.array().parse(['BY', 'CN', 'CU', 'IR', 'RU', 'VN'])
export const isBlacklistedCountry = (countryCode: columnTypes.CountryCode) =>
  BLACKLISTED_COUNTRIES.includes(countryCode)
const BLACKLISTED_CURRENCIES = columnTypes.currencyCodeSchema.array().parse(['BYN', 'CNY', 'CUP', 'IRR', 'RUB', 'VND'])
export const isBlacklistedCurrency = (currency: columnTypes.CurrencyCode) => BLACKLISTED_CURRENCIES.includes(currency)

export const isCurrency = (currency: string): currency is Currency => currency in allCurrencies

// TODO Modify this to require tax always from VAT country
export const countryConfigurationSchema = z.object({
  countryCode: columnTypes.countryCodeSchema,
  currencyCode: columnTypes.currencyCodeSchema,
  appStoreTerritory: z.string(),
  enabled: z.boolean(),
  taxType: z.union([z.literal('VAT'), z.literal('SALES_TAX')]),
  paymentGateway: paymentGatewaySchema,
  tax: z
    .string()
    .transform((tax) => parseFloat(tax))
    .or(z.number())
    .optional()
    .nullable(),
  currencyCountry: columnTypes.currencyCountrySchema,
})

export type CountryConfiguration = z.infer<typeof countryConfigurationSchema>

export const supportedLanguagesByGame: Record<GameId, SupportedLanguage[]> = {
  clashofclans: [
    'ar',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'nl',
    'pt',
    'ru',
    'th',
    'tr',
    'tr',
    'vi',
    'zh-cn',
    'zh-tw',
    'fa',
    'no',
  ],
  brawlstars: [
    'ar',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'nl',
    'pt',
    'ru',
    'th',
    'tr',
    'tr',
    'vi',
    'zh-cn',
    'zh-tw',
    'he',
    'pl',
  ],
  clashroyale: [
    'ar',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'id',
    'it',
    'ja',
    'ko',
    'ms',
    'nl',
    'pt',
    'ru',
    'th',
    'tr',
    'tr',
    'vi',
    'zh-cn',
    'zh-tw',
    'fa',
    'no',
  ],
  squadbusters: [
    'en',
    'de',
    'es',
    'it',
    'nl',
    'tr',
    'ja',
    'ko',
    'ru',
    'ar',
    'pt',
    'zh-tw',
    'zh-cn',
    'id',
    'th',
    'fi',
    'pl',
    'fr',
  ],
  hayday: [
    'en',
    'fr',
    'it',
    'de',
    'es',
    'pt',
    'ja',
    'ko',
    'ru',
    'ar',
    'fi',
    'id',
    'nl',
    'th',
    'tr',
    'zh-cn',
    'zh-tw',
    'vi',
    'ms',
    'no',
    'da',
    'sv',
  ],
}
