import React from 'react'

import { isBlingProduct, isCreditProduct, isGemPackProduct, ProductV2 } from '@common'
import Image from 'next/image'

import BSBlingIcon from '@/assets/images/icons/bs_icon_bling.png'
import BSCreditIcon from '@/assets/images/icons/bs_icon_credit.png'
import BSGemIcon from '@/assets/images/icons/bs_icon_gem.png'
import { useFormattedAmount } from '@/features/storefronts/_storeBase/card/utils'
import { getStrikeThroughTag } from '@/features/storefronts/_storeBase/card/v2/shared'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'
import { isStoreSpecial } from '@/features/storefronts/productFilters'
import { useTranslatable } from '@/localization/useTranslatable'

import styles from './PdpProductHeroHeader.module.scss'

interface Props {
  product: ProductV2
}

export const BrawlPdpProductHeroHeader = ({ product }: Props) => {
  const translatable = useTranslatable()
  const gemCount = useFormattedAmount(product.contentCounts.GEM ?? 0)
  const itemCount = useFormattedAmount(product.contentCounts.ITEM ?? 0)
  const isSpecialOffer = isStoreSpecial(product)
  const isGemPack = isGemPackProduct(product)
  const strikeThroughTag = getStrikeThroughTag(product)
  const isBrawler = isBrawlerOrSkin(product.contents[0])
  const isCredits = isCreditProduct(product)
  const isBling = isBlingProduct(product)

  if (!isSpecialOffer && isBrawler) {
    const shownTitle = translatable(product.contents[0].product.title)
    return (
      <div className={styles.PdpHeroHeaderContainer}>
        <StoreTypography tag="h2" textSize="body" style="outlined">
          {shownTitle}
        </StoreTypography>
      </div>
    )
  }
  if (!isSpecialOffer && isCredits) {
    return (
      <div className={styles.PdpHeroHeaderContainer}>
        <Image src={BSCreditIcon} alt="credit" height={32} width={32} sizes="50px" />
        <div className={styles.HeaderText}>
          <StoreTypography tag="h2" textSize="body" style="outlined">
            {itemCount}
          </StoreTypography>
        </div>
      </div>
    )
  }
  if (!isSpecialOffer && isBling) {
    return (
      <div className={styles.PdpHeroHeaderContainer}>
        <Image src={BSBlingIcon} alt="bling" height={32} width={32} sizes="50px" />
        <div className={styles.HeaderText}>
          <StoreTypography tag="h2" textSize="body" style="outlined">
            {itemCount}
          </StoreTypography>
        </div>
      </div>
    )
  }
  if (!isSpecialOffer && isGemPack) {
    return (
      <div className={styles.PdpHeroHeaderContainer}>
        <Image src={BSGemIcon} alt="gem" height={32} width={32} sizes="50px" />
        <div className={styles.HeaderText}>
          {strikeThroughTag && (
            <StoreTypography
              tag="h2"
              style="plain"
              textSize="body"
              className={styles.Strikethrough}
              overrideFont={'supercellText'}
            >
              {strikeThroughTag}
            </StoreTypography>
          )}
          <StoreTypography tag="h2" textSize="body" style="outlined">
            {gemCount}
          </StoreTypography>
        </div>
      </div>
    )
  }
  return null
}
