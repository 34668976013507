import cx from 'classnames'

import styles from './circleProgress.module.scss'

type CircleProgressProps = {
  progressPercentage: number
  loading?: boolean
  bonusStatusFooter?: boolean
}

export const CircleProgress = ({ progressPercentage, loading = true, bonusStatusFooter }: CircleProgressProps) => {
  const RADIUS = 45
  const CIRCLE_POSITION = 50
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS
  const PROGRESS = CIRCUMFERENCE * ((100 - progressPercentage) / 100)
  const PROGRESS_STROKE = bonusStatusFooter ? 4 : 5

  return (
    <div className={cx(styles.container, { [styles.bonusStatusFooter]: bonusStatusFooter })}>
      <svg width="100%" viewBox="0 0 100 100" className={cx(loading && styles.animate)}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#EAAE15" />
            <stop offset="90%" stopColor="#FEE5A0" />
            <stop offset="100%" stopColor="#FEE5A0" />
          </linearGradient>
          <linearGradient id="loading-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#FDBF2C" />
            <stop offset="50%" stopColor="#FEE5A0" />
            <stop offset="100%" stopColor="#EFEFEF" />
          </linearGradient>
        </defs>
        <circle
          r={RADIUS}
          cx={CIRCLE_POSITION}
          cy={CIRCLE_POSITION}
          fill="none"
          stroke="transparent"
          strokeWidth="6px"
          transform="rotate(-90 50 50)"
        />
        <circle
          r={RADIUS}
          cx={CIRCLE_POSITION}
          cy={CIRCLE_POSITION}
          fill="none"
          stroke={loading ? 'url(#loading-gradient)' : '#eab119'}
          strokeWidth={PROGRESS_STROKE}
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={loading ? CIRCUMFERENCE * 0.25 : PROGRESS}
          transform="rotate(-90 50 50)"
        />
      </svg>
    </div>
  )
}
