import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'

import useOnClickOutside from '@/utils/useOnClickOutside'

import { Arrow } from './Icons/Arrow'

import styles from './Dropdown.module.scss'

type Option<T extends string> = {
  value: T
  label: string
  onClick?: () => void
}

interface DropdownSelectProps<T extends string> {
  className?: string
  value: T
  options: Option<T>[]
  id: string
  icon?: React.ReactElement | JSX.Element
  formatValue?: (value: Option<T>['value']) => string
  onChange?: (value: Option<T>['value']) => void
  direction?: 'Up' | 'Down'
}

export function DropdownSelect<T extends string>({
  options,
  value,
  id,
  children,
  direction = 'Down',
  ...rest
}: React.PropsWithChildren<DropdownSelectProps<T>>) {
  const [selectedItem, setSelectedItem] = useState<T>(value)
  const rootRef = useRef(null)
  const [menuOpen, setMenu] = useState<boolean>(false)

  useOnClickOutside(rootRef, () => setMenu(false))

  const toggleMenu = () => {
    setMenu(!menuOpen)
  }

  const onSelect = (item: T) => {
    toggleMenu()
    setSelectedItem(item)
    rest.onChange?.(item)
  }

  useEffect(() => {
    if (value !== selectedItem) {
      setSelectedItem(value)
    }
  }, [value, selectedItem])

  return (
    <div className={cx(styles.Dropdown, styles[direction], rest.className)} ref={rootRef}>
      <div className={styles.SelectMobile}>
        <label htmlFor={id}>
          {children}
          <Arrow direction="Down" className={styles.Arrow} />
          <select
            id={id}
            value={selectedItem}
            className={styles.Select}
            onChange={(e) => onSelect(e.currentTarget.value as T)}
          >
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className={styles.SelectDesktop} data-cy="language-desktop-selector">
        <button className={styles.Main} onClick={toggleMenu}>
          {children}
          <Arrow direction={menuOpen ? 'Up' : 'Down'} className={styles.Arrow} />
        </button>
        <DropdownMenu visible={menuOpen} className={styles.Options}>
          {options.map((option, index) => (
            <m.div
              className={styles.Option}
              key={option.value}
              onClick={() => onSelect(option.value)}
              custom={index}
              animate="visible"
              variants={variants}
            >
              <p
                className={cx(styles.DropdownLabel, {
                  [styles.Active]: option.value === selectedItem,
                })}
              >
                {option.label}
              </p>
            </m.div>
          ))}
        </DropdownMenu>
      </div>
    </div>
  )
}

type DropdownMenuProps = React.PropsWithChildren<{
  className?: string
  visible: boolean
}>

const variants = {
  hidden: { maxHeight: 0 },
  show: {
    maxHeight: '350px',
    transition: { type: 'linear', duration: 0.3 },
  },
  visible: (custom: number) => ({
    opacity: 1,
    transition: { delay: 0.05 + custom * 0.02, type: 'linear' },
  }),
}
const features = () => import('../animations/features').then((a) => a.default)

function DropdownMenu(props: DropdownMenuProps) {
  const { children, visible, className } = props
  return (
    <LazyMotion features={features}>
      <AnimatePresence>
        {visible && (
          <m.div key="menu" initial={'hidden'} animate={'show'} variants={variants} className={className}>
            <div className={styles.DropdownContainer}>{children}</div>
          </m.div>
        )}
      </AnimatePresence>
    </LazyMotion>
  )
}
