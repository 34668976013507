import cx from 'classnames'

import styles from './Loader.module.scss'

interface LoaderProps {
  strokeColor: string
  className?: string
  dataCy?: string
}

export const Loader: React.FC<LoaderProps> = ({ strokeColor, className, dataCy }) => {
  return (
    <svg
      data-cy={dataCy}
      className={cx(styles.Loader, className)}
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={styles.Circle} stroke={strokeColor} strokeWidth="8" cx="19" cy="19" r="14" />
    </svg>
  )
}
