'use client'

import { createContext, useContext, useMemo } from 'react'

import { GameId } from '@common'

type GameContext = GameId

export const GameContext = createContext<GameContext>({} as GameContext)

type GameProviderProps = {
  gameSlug: GameContext
  children: React.ReactNode
}

export const GameProvider = ({ gameSlug, children }: GameProviderProps) => {
  const value = useMemo(() => gameSlug, [gameSlug])

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>
}

export const useGameSlug = () => useContext(GameContext)
