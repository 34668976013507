import { memo } from 'react'

import { GameId, ProductContent } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import { CardBase } from '@/features/storefronts/_storeBase/card/CardBase'
import base from '@/features/storefronts/_storeBase/card/cardBase.module.scss'
import { ImageWithFallback } from '@/features/storefronts/_storeBase/imageWithFallback/ImageWithFallback'

import { useFormattedAmount, useProductImage, useSmallerFont } from './utils'
import { useAssetColor } from '../../brawlstars/bundle/helpers'
import { Flare } from '../flare/Flare'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './cardProduct.module.scss'

type CardProps = {
  size:
    | 'size-bundle-xs'
    | 'size-bundle-sm'
    | 'size-bundle-lg'
    | 'size-product-md'
    | 'size-product-page'
    | 'size-product-page-large'
  game: GameId
  content: ProductContent
  title: string
  productPage?: boolean
  offerContent?: boolean
  singleProduct?: boolean
  isHypercharge?: boolean
  isBrawlerOrSkin?: boolean
}

export const BrawlCardProduct = memo(
  ({
    size,
    game,
    content,
    title,
    productPage = false,
    offerContent = false,
    singleProduct = false,
    isHypercharge = false,
    isBrawlerOrSkin = false,
  }: CardProps) => {
    // get the product image from public folder with the product id
    const { imageSrc } = useProductImage({ game: game, productId: content.product.id, count: content.amount })

    // format the amount to have spaces between the thousands and millions etc with spaces
    const formattedAmount = useFormattedAmount(content.amount)

    const smallerFont = useSmallerFont({ contentAmount: content.amount, size: size })

    const cardColor = useAssetColor(content.product.id)

    return (
      <CardBase
        className={cx(
          styles.cardProduct,
          styles[game],
          styles[size],
          styles[cardColor],
          {
            [base.productPage]: productPage,
          },
          { [styles.singleProduct]: singleProduct },
        )}
        offerContent={offerContent}
      >
        <Flare />
        <div className={cx(styles.productImg, { [styles.hypercharge]: isHypercharge })}>
          {isHypercharge ? (
            <HyperchargeAsset title={title} assetId={content.product.id} />
          ) : (
            <ImageWithFallback productImage={imageSrc} title={title} />
          )}
        </div>
        {!isBrawlerOrSkin && !isHypercharge && formattedAmount && (
          <StoreTypography
            tag="div"
            style="outlined"
            textSize={smallerFont ? 'xs' : 'body'}
            className={cx(styles.amount, styles[game])}
          >
            {formattedAmount}
          </StoreTypography>
        )}
        {(isBrawlerOrSkin || isHypercharge) && (
          <StoreTypography
            tag="h3"
            style="outlined"
            textSize={size === 'size-bundle-sm' ? 'xs' : 'sm'}
            className={styles.hyperchargeTitle}
          >
            {title}
          </StoreTypography>
        )}
      </CardBase>
    )
  },
)

BrawlCardProduct.displayName = 'BrawlCardProduct'

export const HyperchargeAsset = ({ assetId, title }: { assetId: string; title: string }) => {
  const productImage = `/images/brawlstars/characters/icons/${assetId}.png`
  return (
    <div className={styles.hyperchargeContainer}>
      <Image
        src={productImage}
        alt={title}
        fill
        style={{ objectFit: 'contain' }}
        sizes="200px"
        className={styles.icon}
      />
    </div>
  )
}
