import { GameId } from '../models/game'
import { ItemTier, ProductV2 } from '../models/product'
import { PurchaseRow } from '../models/purchase'
import { StampCardBalanceEvent, StampCardEvent, StampType } from '../models/stampCard'
import { StoreError, StoreErrorMessage } from './StoreError'

export const STAMP_CARD_GAMES = ['clashofclans', 'clashroyale'] as const
export const STAMP_CARD_INITIAL_BALANCE = 1 as const
export const STAMP_CARD_CLAIM_COST = 6 as const
export type StampCardGame = (typeof STAMP_CARD_GAMES)[number]
export type StampCardRewardIds = Record<(typeof STAMP_CARD_GAMES)[number], string>

export const ELIGIBLE_PASSES = ['com.supercell.store.magic.goldpass', 'com.supercell.scroll.scid.seasonpass.premium']

export function countStamps(products: ProductV2[], rows: PurchaseRow[]) {
  const timestamp = Date.now()
  const clashOfClansProducts = products.filter((product) => product.game === 'clashofclans')
  const clashroyaleProducts = products.filter((product) => product.game === 'clashroyale')

  // Brawl Stars and Hay Day don't support stamp card yet
  // Clash of Clans has only basic season passes, and event passes don't count towards the stamp card at the moment
  const passes = [
    {
      type: 'SEASON_PASS',
      game: 'clashofclans',
      amount: countProductsStamps(clashOfClansProducts, rows, 'BASIC'),
      timestamp,
    },
    {
      type: 'SEASON_PASS',
      game: 'clashroyale',
      amount: countProductsStamps(clashroyaleProducts, rows, 'PREMIUM'),
      timestamp,
    },
  ] as const

  return passes.filter((pass) => pass.amount > 0)
}

function countProductsStamps(products: ProductV2[], purchaseRows: PurchaseRow[], tier: ItemTier) {
  return products.reduce((a, product) => a + countProductStamps(product, purchaseRows, tier), 0)
}

function countProductStamps(product: ProductV2, purchaseRows: PurchaseRow[], tier: ItemTier) {
  const matchingRow = purchaseRows.find((purchaseRow) => purchaseRow.sku === product.id)

  if (!matchingRow) {
    throw StoreError.withMessage(
      StoreErrorMessage.PURCHASE_PRODUCTS_ROWS_MISMATCH,
      `Missing purchase for product ${product.id}`,
    )
  }

  return product.contents
    .filter((content) => content.product.tier === tier)
    .reduce((a, b) => a + b.amount * matchingRow.quantity, 0)
}

export function createInitialStampCardBalance(gameIds: readonly StampCardGame[]): StampCardBalanceEvent[] {
  const timestamp = Date.now()
  return gameIds.map((game) => ({
    eventType: 'BALANCE' as const,
    stampGame: game,
    stampType: 'SEASON_PASS',
    stampsRequiredToClaim: STAMP_CARD_CLAIM_COST,
    balance: STAMP_CARD_INITIAL_BALANCE,
    backfilledStamps: 0,
    backfilledStampsInCurrentCard: 0,
    cardNumber: 1,
    timestamp,
  }))
}

export function findEventMatchingToEvent<T extends StampCardEvent>(events: T[], balance: StampCardBalanceEvent) {
  return events.find((event) => event.stampGame === balance.stampGame && event.stampType === balance.stampType)
}

export function findEventMatchingToStamp<T extends StampCardEvent>(events: T[], stamp: StampType) {
  return events.find((event) => event.stampGame === stamp.game && event.stampType === stamp.type)
}

export function withStampCard(product: ProductV2, events: StampCardBalanceEvent[]) {
  const matchingBalanceEvent = getBalance(product, events)

  if (!matchingBalanceEvent) {
    return product
  }
  return {
    ...product,
    stampCard: {
      balance: matchingBalanceEvent.balance,
      backfilledStamps:
        'backfilledStampsInCurrentCard' in matchingBalanceEvent
          ? (matchingBalanceEvent.backfilledStampsInCurrentCard ?? 0)
          : 0,
      cardNumber: 'cardNumber' in matchingBalanceEvent ? (matchingBalanceEvent.cardNumber ?? 1) : 1,
    },
  }
}

function getBalance(product: ProductV2, events: StampCardBalanceEvent[]) {
  const stampType = getStampType(product.game, product.contents[0].product.tier)

  if (!stampType) {
    return null
  }

  return findEventMatchingToStamp(events, stampType)
}

function getStampType(game: GameId, tier: ItemTier): StampType | null {
  switch (game) {
    case 'clashofclans':
      if (tier === 'BASIC') {
        return {
          game,
          type: 'SEASON_PASS',
        }
      }
      return null
    case 'clashroyale':
      if (tier === 'PREMIUM') {
        return {
          game,
          type: 'SEASON_PASS',
        }
      }
      return null
    default:
      return null
  }
}

export function hasStampExpired(timestamp: number) {
  const year = 365 * 24 * 60 * 60 * 1000
  return Date.now() - timestamp > year
}
