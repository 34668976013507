import { useState } from 'react'

import { useAppDispatch } from '@/utils/useAppDispatch'

import { setClaimedBonusItem } from './commonSlice'
import { StoreRewardItem } from '../../../../common/src/models/bonus'

export const useClaimBonus = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown | null>(null)

  const claimBonus = async (item: StoreRewardItem) => {
    const gameId = item.gameId
    const offerId = item.id
    setIsLoading(true)

    try {
      const response = await fetch('/api/rewards/claim/', {
        method: 'POST',
        body: JSON.stringify({ gameId, offerId }),
      })

      if (response.ok) {
        dispatch(setClaimedBonusItem(offerId))
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  return { claimBonus, isLoading, error }
}
