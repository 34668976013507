export const safariDeviceType = (): 'handheld' | 'desktop' | false => {
  if (typeof navigator === 'undefined') {
    return false
  }
  const isAppleHandheld = navigator.userAgent.match(/(iPod|iPhone|iPad)/)
  const isWebkit = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  if (isAppleHandheld && isWebkit) {
    return 'handheld' as const
  }

  return isWebkit ? ('desktop' as const) : false
}

export const isMac = (): boolean => {
  if (typeof navigator === 'undefined') {
    return false
  }
  return navigator.userAgent.indexOf('Mac') !== -1
}
