import React, { useCallback, useState } from 'react'

import cx from 'classnames'
import NextImage from 'next/legacy/image'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { useLocale } from '@/features/locale/useLocale'
import { localizedAppStoreButtons } from '@/fixtures/localizedAppStoreButtons'
import { useAppSelector } from '@/utils/useAppSelector'
import useInterval from '@/utils/useInterval'

import { LanguageSelector } from './LanguageSelector'
import { config } from '../../../config'
import { Pin } from '../../components/Icons'
import { useMarketContext } from '../../contexts/MarketContext'

import FacebookIcon from 'assets/images/logos/icon-social-facebook-white.svg'
import GlassdoorIcon from 'assets/images/logos/icon-social-glassdoor-white.svg'
import InstagramIcon from 'assets/images/logos/icon-social-instagram-white.svg'
import LinkedinIcon from 'assets/images/logos/icon-social-linkedin-white.svg'
import TwitterIcon from 'assets/images/logos/icon-social-twitter-white.svg'
import YoutubeIcon from 'assets/images/logos/icon-social-youtube-white.svg'
import SCLogo from 'assets/images/logos/supercell-logo-white.svg'

import styles from './Footer.module.scss'

const { COOKIE_CONSENT_ENABLED } = config

function getCookieConsentData() {
  if (typeof window === 'undefined') {
    return
  }
  return window.OneTrust?.GetDomainData()
}

const ToggleCookieConsentButton: React.FC = () => {
  const [text, setText] = useState('')
  const [count, setCount] = useState(0)
  const locale = useLocale()
  // Poll OneTrust window until
  // we get the proper text for the button
  // or count > 4
  const setTextIfChanged = useCallback((locale: string | undefined) => {
    const data = getCookieConsentData()
    const alertText = data?.AlertMoreInfoText
    const cookieConsentLanguage = data?.Language.Culture
    if (!alertText || cookieConsentLanguage !== locale) {
      setCount((c) => c + 1)
    } else {
      setText(alertText)
      setCount(0)
    }
  }, [])
  const cookieConsentData = getCookieConsentData()
  const maybeInterval = cookieConsentData?.Language.Culture === locale || count > 4 ? null : 200
  useInterval(() => setTextIfChanged(locale), maybeInterval)

  return (
    <a
      style={{ cursor: 'pointer' }}
      onClick={() => {
        window.OneTrust?.ToggleInfoDisplay()
      }}
    >
      {text}
    </a>
  )
}

const CompanyAddress: React.FC = () => {
  const t = useTranslations()
  return (
    <>
      <div className={styles.Address}>
        <p>{t('gen_add1')}</p>
        <p>{t('gen_add2')}</p>
        <p>{t('gen_add3')}</p>
        <p>{t('gen_add4')}</p>
        <p>{t('gen_add5')}</p>
      </div>
      <div className={styles.Logo}>
        <a href="https://supercell.com/" target="_blank" rel="noreferrer">
          <NextImage src={SCLogo} layout="fixed" width={59} height={50} />
        </a>
      </div>
    </>
  )
}

const DefaultFooter: React.FC<{ isStatic?: boolean }> = ({ isStatic }) => {
  const t = useTranslations()
  const locale = useLocale()
  const currentGame = useAppSelector((state) => state.common.currentGame)

  const socialLinks = [
    {
      icon: YoutubeIcon,
      url: t('gen_yt'),
    },
    {
      icon: FacebookIcon,
      url: t('gen_fb'),
    },
    {
      icon: InstagramIcon,
      url: t('gen_ig'),
    },
    {
      icon: TwitterIcon,
      url: t('gen_tw'),
    },
    {
      icon: LinkedinIcon,
      url: t('gen_li'),
    },
    {
      icon: GlassdoorIcon,
      url: t('gen_gd'),
    },
  ]

  return (
    <footer className={cx(styles.Footer, { [styles.storefront]: currentGame })}>
      <div className={styles.FooterContent}>
        <div className={styles.Lang}>
          <LanguageSelector id="language-selector" />
        </div>
        {isStatic ? null : <FooterCountry />}
        <div className={styles.Social}>
          <p>{t('foot_fol')}</p>
          <ul>
            {socialLinks.map((link, idx) => (
              <li className={styles.SocialLink} key={idx}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  <NextImage src={link.icon} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.Stores}>
          <p>{t('gen_down')}</p>
          <ul>
            <li>
              <a href={t('gen_apap')} target="_blank" rel="noreferrer">
                <NextImage src={localizedAppStoreButtons[locale].appStore} height={42} />
              </a>
            </li>
            <li>
              <a href={t('gen_apgl')} target="_blank" rel="noreferrer">
                <NextImage src={localizedAppStoreButtons[locale].playBadge} width={141} height={42} />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.Info}>
          {[
            {
              label: t('foot_tos'),
              url: t('foot_tos_li'),
            },
            {
              label: t('foot_pp'),
              url: t('foot_pp_li'),
            },
            {
              label: t('foot_pg'),
              url: t('foot_pg_li'),
            },
            {
              label: t('foot_saf'),
              url: t('foot_saf_li'),
            },
          ].map((el) => {
            return (
              <a key={el.url} href={el.url} target="_blank" rel="noreferrer">
                {el.label}
              </a>
            )
          })}
          {COOKIE_CONSENT_ENABLED && <ToggleCookieConsentButton />}
        </div>
        <CompanyAddress />
      </div>
    </footer>
  )
}

const FooterCountry: React.FC = () => {
  const { countryCode } = useMarketContext('FooterCountry')
  const countries = useTranslations('Countries')
  const countryName = countryCode ? countries(countryCode.toLowerCase() as keyof IntlMessages['Countries']) : ''
  return (
    <div className={styles.Country}>
      <Pin height={22} width={22} />
      <p>{countryName}</p>
    </div>
  )
}

const SimpleFooter: React.FC = () => {
  const currentGame = useAppSelector((state) => state.common.currentGame)
  return (
    <footer className={cx(styles.SimpleFooter, { [styles.storefront]: currentGame })}>
      <div className={styles.FooterContent}>
        <CompanyAddress />
      </div>
    </footer>
  )
}

const SIMPLE_ROUTES = /^\/(maintenance)/

export const Footer: React.FC<{ isStatic?: boolean }> = ({ isStatic }) => {
  const { asPath } = useRouter()

  const useSimple = SIMPLE_ROUTES.test(asPath)

  return useSimple ? <SimpleFooter /> : <DefaultFooter isStatic={isStatic} />
}
