import type {
  ProductV2,
  Profile,
  StoredPaymentMethod,
  BillingAddress,
  ProductsRequest,
  CheckoutPSPPaymentRequest,
  CheckoutPSPPaymentResponse,
  CheckoutPSPPaymentDetailsRequest,
  CheckoutPSPPaymentDetailsResponse,
  PurchasesGetResponse,
  FetchReceiptsResponse,
  FetchReceiptsRequest,
  PurchasesGetPendingReponse,
  AnnouncementQueryResult,
  AnnouncementRequest,
  PaymentGateway,
  GameId,
  StampCardGame,
} from '@common'
import { StoreBonusStatusResponse } from '@common/models/bonus'
import { SCIDRewardItem } from '@common/services/scid/types'
import { createApi, FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import fetch from 'cross-fetch'

import { hydrateMatcher } from './hydrate'
import type { RootState } from './store'
import { SESSION_COOKIE } from './utils/cookies'
import { isClient, isServer } from './utils/isClient'
import { config } from '../config'

const QUERY_TIMEOUT = 20000
const { API_URL, STAGE } = config

if (!isClient && STAGE === 'test') {
  process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0'
}

const unRetryableErrors: (number | 'FETCH_ERROR' | 'PARSING_ERROR' | 'TIMEOUT_ERROR' | 'CUSTOM_ERROR')[] = [
  400, 401, 403, 404,
]

const baseQuery = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: API_URL,
      fetchFn: isClient
        ? window.fetch
        : (request, init) => {
            if (typeof request === 'string') {
              return fetch(request, init)
            }
            const config = Object.assign({}, init, request, {
              credentials: 'include',
              headers: Object.fromEntries(request.headers.entries()),
              method: request.method,
            })
            return fetch(request.url, config)
          },
      prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState
        const token = state.user.token
        if (token) {
          headers.set('cookie', `${SESSION_COOKIE}=${token}`)
        }
        if (isServer && state.config.viewerCountry) {
          headers.set('X-Viewer-Country', state.config.viewerCountry)
        }
        return headers
      },
      timeout: QUERY_TIMEOUT,
    })(args, api, extraOptions)

    // Bail out if the error is not retryable
    if (result.error && unRetryableErrors.includes(result.error.status)) {
      retry.fail(result.error)
    }

    return result
  },
  { maxRetries: isClient ? 3 : 0 },
)

export const api = createApi({
  baseQuery,
  tagTypes: ['User', 'Session'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractRehydrationInfo(action): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (hydrateMatcher<{ api: any }>(action)) {
      return action.payload.api
    }
  },
  endpoints: (builder) => ({
    session: builder.query<
      | {
          ok: true
          profile: Profile['profile']
          inventory: Profile['inventory']
          helpshift:
            | {
                email: string
                name: string
                userAuthToken: string
              }
            | undefined
        }
      | { ok: false },
      void
    >({
      query: () => ({
        url: 'session',
        method: 'GET',
      }),
      providesTags: [{ type: 'User' }],
    }),
    logout: builder.mutation<void, void>({
      query: (body) => ({
        url: 'customers/logout',
        method: 'POST',
        body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    fetchPurchase: builder.query<PurchasesGetResponse, string>({
      query: (id: string) => ({
        url: `purchases/v3/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'User' }],
    }),
    fetchPendingPurchases: builder.query<PurchasesGetPendingReponse[], void>({
      query: () => ({
        url: `purchases/v3/pending`,
        method: 'GET',
      }),
      providesTags: [{ type: 'User' }],
    }),
    fetchReceipts: builder.query<FetchReceiptsResponse, FetchReceiptsRequest>({
      query: (params) => ({
        url: `receipts`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'User' }],
    }),
    fetchRewards: builder.query<
      {
        stampCard: StampCardGame[]
        bonus: SCIDRewardItem[]
      },
      { purchaseId: string; gameId: GameId }
    >({
      query: (arg: { purchaseId: string; gameId: GameId }) => ({
        url: `rewards/${arg.purchaseId}/${arg.gameId}`,
        method: 'GET',
      }),
    }),
    checkoutAdyenPayment: builder.mutation<CheckoutPSPPaymentResponse, CheckoutPSPPaymentRequest>({
      query: (body) => ({
        url: 'checkout/adyen/payment',
        method: 'POST',
        body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    checkoutAdyenPaymentDetails: builder.mutation<CheckoutPSPPaymentDetailsResponse, CheckoutPSPPaymentDetailsRequest>({
      query: (body) => ({
        url: 'checkout/adyen/payment/details',
        method: 'POST',
        body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    fetchProducts: builder.query<ProductV2[], ProductsRequest & { gameId: string }>({
      query: (params) => ({
        url: `v2/stores/${params.gameId}/products`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'User' }],
    }),
    removePaymentMethod: builder.mutation<unknown, { paymentGateway: PaymentGateway; paymentMethodId: string }>({
      query: (params) => ({
        method: 'DELETE',
        url: `/customers/me/paymentMethods/v2/${params.paymentGateway}/${params.paymentMethodId}`,
      }),
    }),
    fetchStoredPaymentMethods: builder.query<StoredPaymentMethod[], void>({
      query: () => ({
        url: '/customers/me/paymentMethods',
        method: 'GET',
      }),
      providesTags: [{ type: 'User' }],
    }),
    customerAddBillingAddress: builder.mutation<undefined | { statusCode: number; body: string }, BillingAddress>({
      query: (body) => ({
        method: 'POST',
        url: '/customers/me/address',
        body: { ...body },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    customerRemoveBillingAddress: builder.query<unknown, void>({
      query: () => ({
        method: 'DELETE',
        url: '/customers/me/address',
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    fetchAnnouncement: builder.query<AnnouncementQueryResult | undefined, AnnouncementRequest>({
      query: (params) => ({
        url: 'announcement',
        method: 'GET',
        params,
      }),
    }),
    bonusStatus: builder.query<StoreBonusStatusResponse, GameId>({
      query: (gameId) => ({
        url: `bonus/${gameId}/status`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useFetchStoredPaymentMethodsQuery,
  useFetchPurchaseQuery,
  useFetchPendingPurchasesQuery,
  useRemovePaymentMethodMutation,
  useFetchProductsQuery,
  useCustomerAddBillingAddressMutation,
  useLazyCustomerRemoveBillingAddressQuery,
  useCheckoutAdyenPaymentMutation,
  useCheckoutAdyenPaymentDetailsMutation,
  useFetchReceiptsQuery,
  useFetchRewardsQuery,
  useFetchAnnouncementQuery,
  useBonusStatusQuery,
} = api

export const useSessionQueryState = api.endpoints.session.useQueryState
