import { ProductV2, range } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import GoldStampPlaceholder from '@/assets/images/storefronts/clashofclans/stamp-bg.png'
import CocCardBackground from '@/assets/images/storefronts/clashofclans/stamp-card.png'
import CocFreeStamp from '@/assets/images/storefronts/clashofclans/stamp-free.png'
import GoldStamp from '@/assets/images/storefronts/clashofclans/stamp.png'
import CrCardBackground from '@/assets/images/storefronts/clashroyale/stamp-card.png'
import CrFreeStamp from '@/assets/images/storefronts/clashroyale/stamp-free.png'
import CrStampPlaceholder from '@/assets/images/storefronts/clashroyale/stamp-placeholder.svg'
import CrStamp from '@/assets/images/storefronts/clashroyale/stamp.png'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './StampCard.module.scss'

type StampCardProps = {
  product: ProductV2
  size: 'small' | 'medium' | 'large'
}

export const StampCard: React.FC<StampCardProps> = ({ product, size }) => {
  const stampCard = product.stampCard

  if (!stampCard) {
    return null
  }

  const game = product.game

  const { balance, backfilledStamps, cardNumber } = stampCard

  const maxNumberOfStamps = 6
  const isSeasonPass = !!product?.contentCounts.SEASON_PASS

  const cardBg = game === 'clashroyale' ? CrCardBackground : CocCardBackground
  const stampPlaceholder = game === 'clashroyale' ? CrStampPlaceholder : GoldStampPlaceholder
  const stamp = game === 'clashroyale' ? CrStamp : GoldStamp

  const FreeStamp = game === 'clashroyale' ? CrFreeStamp : CocFreeStamp

  if (!isSeasonPass) {
    return null
  }

  return (
    <div className={cx(styles.StampCard, styles[size], styles[game])}>
      <Image src={cardBg} alt="stampcard background" fill style={{ objectFit: 'contain' }} sizes="200px" />
      <StoreTypography
        tag="div"
        style="plain"
        textSize={size === 'large' ? 'sm' : 'xs'}
        overrideFont="supercellTextMedium"
        className={styles.StampCardNumber}
      >
        #{cardNumber}
      </StoreTypography>
      <div className={styles.Stamps}>
        {range(0, maxNumberOfStamps).map((i) => {
          const displayStamp = i < balance
          return (
            <div key={i} className={styles.Stamp}>
              <Image
                src={displayStamp ? (i === 0 || i < backfilledStamps ? FreeStamp : stamp) : stampPlaceholder}
                alt="stampcard stamp"
                fill
                sizes="50px"
              />
              {!displayStamp && (
                <StoreTypography
                  tag="span"
                  style="plain"
                  textSize={size === 'large' ? 'md' : 'body'}
                  className={styles.StampNumber}
                >
                  {i + 1}
                </StoreTypography>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
