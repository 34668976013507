import { useMemo } from 'react'

import { StoreOfferProduct, GameId } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import ClockIconBrawl from '@/assets/images/storefronts/brawlstars/icon-clock.png'
import ClockIconCoC from '@/assets/images/storefronts/clashofclans/icon-clock.png'
import ClockIconRoyale from '@/assets/images/storefronts/clashroyale/icon-clock.png'
import ClockIconSquad from '@/assets/images/storefronts/squadbusters/icon-clock.png'
import { useLocale } from '@/features/locale/useLocale'
import { useOfferCountdown } from '@/features/products/useOfferCountdown'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { TimeLeft, getTimeLeftCopyKeys } from '@/utils/timer'

import styles from './Countdown.module.scss'

type Props = {
  product: StoreOfferProduct
}

function clockVariant(game: GameId) {
  switch (game) {
    case 'clashofclans':
      return ClockIconCoC
    case 'brawlstars':
      return ClockIconBrawl
    case 'clashroyale':
      return ClockIconRoyale
    case 'squadbusters':
      return ClockIconSquad
    default:
      return ClockIconCoC
  }
}

function withoutLeadingZero(time: string) {
  return time[0] === '0' && time.length > 1 ? time.slice(1) : time
}

export const Countdown = ({ product }: Props) => {
  const t = useTranslations()
  const locale = useLocale()
  const { isTimedOffer, timeLeftText } = useOfferCountdown({ product, threshold: 90 })

  const displayTimeRemaining = useMemo(() => {
    return (timeLeft: TimeLeft) => {
      const [days, hours, minutes, seconds] = timeLeft.map((tl, idx) => {
        const timeLeftKey = getTimeLeftCopyKeys('mobile', idx)
        return `${tl}${timeLeftKey ? t(timeLeftKey) : ''}`
      })

      if (timeLeft[0] !== '0') {
        if (timeLeft[1] === '0') {
          return `${days} ${hours} ${minutes}`
        }
        return `${days} ${hours}`
      }

      if (timeLeft[1] !== '0') {
        return `${hours} ${minutes}`
      }

      if (timeLeft[2] !== '0') {
        return `${minutes} ${seconds}`
      }

      return seconds
    }
  }, [t])

  if (!isTimedOffer) {
    return null
  }

  return (
    <div className={cx(styles.Container, styles[product.game], styles[locale])}>
      <Image className={styles.ClockIcon} src={clockVariant(product.game)} alt="" width={32} height={32} />
      <div className={cx(styles.Countdown, styles[product.game], styles[locale])}>
        <StoreTypography
          tag="p"
          textSize="sm"
          style="outlined"
          className={cx(styles.Time, styles[locale], styles[product.game])}
        >
          {displayTimeRemaining(timeLeftText.map(withoutLeadingZero) as TimeLeft)}
        </StoreTypography>
      </div>
    </div>
  )
}
