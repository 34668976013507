import React, { useState } from 'react'

import NextImage, { ImageProps } from 'next/legacy/image'

import FallbackImage from '@/assets/images/fallback-image.png'

type DynamicImageProps = ImageProps & {
  fallback?: ImageProps['src']
}

const Image: React.FC<DynamicImageProps> = ({ src, className, ...rest }) => {
  const [error, setError] = useState(false)
  const fallback = rest.fallback || FallbackImage

  const onError = () => {
    if (!error) {
      setError(true)
    }
  }

  if (error) {
    return <NextImage className={className} alt={rest.alt} src={fallback} {...rest} />
  }
  return <NextImage className={className} {...rest} alt={rest.alt || 'image'} src={src} onError={onError} />
}

export default Image
