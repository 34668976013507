import { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import { useLocale } from '@/features/locale/useLocale'
import { gtmInitViewerCountry, gtmLoginEvent, gtmPageLoad } from '@/utils/gtm'
import { useAppSelector } from '@/utils/useAppSelector'

import { config } from '../../config'
import { snowplowInit, snowplowPageView, snowplowSetUser } from '../utils/snowplow'

const { TRACKING_ENABLED, GOOGLE_TAG_MANAGER_ID } = config
const Tracking: React.FC = () => {
  const { events } = useRouter()
  const locale = useLocale()
  const user = useAppSelector((state) => state.user.profile)
  const country = useAppSelector((state) => state.config.viewerCountry)

  useEffect(() => {
    if (!user) return
    gtmLoginEvent(user)
  }, [user])

  useEffect(() => {
    if (!country) return
    gtmInitViewerCountry(country)
  }, [country])

  const doGtmPageLoad = useCallback(
    (url: string) => {
      if (!locale) return
      gtmPageLoad(url, locale)
    },
    [locale],
  )

  const handlePageLoad = useCallback(
    (url: string) => {
      doGtmPageLoad(url)
      snowplowPageView()
    },
    [doGtmPageLoad],
  )

  useEffect(() => {
    events.on('routeChangeComplete', handlePageLoad)
    return () => {
      events.off('routeChangeComplete', handlePageLoad)
    }
  }, [handlePageLoad, events])

  useEffect(() => {
    doGtmPageLoad(window.location.pathname)
    snowplowInit()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    snowplowSetUser(user?.accountId)
  }, [user])

  if (!TRACKING_ENABLED || GOOGLE_TAG_MANAGER_ID == 'mock-gtm') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require('./MockTracking').default()
  }

  return (
    <Script
      id="gtm"
      dangerouslySetInnerHTML={{
        __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${GOOGLE_TAG_MANAGER_ID}');
  `,
      }}
    />
  )
}

export default Tracking
