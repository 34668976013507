import cx from 'classnames'
import Image from 'next/legacy/image'

import HeroImage from '@/assets/images/checkout/heroes.png'
import VillainImage from '@/assets/images/checkout/villain.png'
import AirportImage from '@/assets/images/error/error-page-bg.png'

import styles from './error.module.scss'

const HeroesVillain = () => (
  <div className={styles.Row}>
    <div className={styles.Heroes}>
      <Image src={HeroImage} alt="background-heroes" width={793} height={303} />
    </div>
    <div className={styles.Villain}>
      <Image src={VillainImage} alt="background-villain" layout="intrinsic" width={503} height={335} />
    </div>
  </div>
)

const Airport = () => (
  <div className={styles.Airport}>
    <Image src={AirportImage} alt="background-airport" />
  </div>
)

const Error: React.FC<React.PropsWithChildren<{ image: 'villain' | 'airport'; withGradient?: boolean }>> = ({
  children,
  image,
  withGradient,
}) => {
  return (
    <div className={cx(styles.Container, { [styles.WithGradient]: withGradient })}>
      <div className={styles.Background}>{image === 'villain' ? <HeroesVillain /> : <Airport />}</div>
      {children}
    </div>
  )
}

export default Error
