import { Offer, ProductV2 } from '../models/product'
import { isStoreOfferProduct } from './product'

export function sortProducts(a: ProductV2, b: ProductV2) {
  // Offer (Chronos or static) (priority -> pass -> diamonds -> coins)
  // Regular: pass -> diamonds -> price -> coins
  // Price descending
  const offerRes = checkOffer(a, b)
  if (offerRes) {
    return offerRes
  }
  const passRes = check('SEASON_PASS', a, b)
  if (passRes) {
    return passRes
  }
  const diamondRes = check('GEM', a, b)
  if (diamondRes) {
    return diamondRes
  }

  const coinRes = check('COIN', a, b)
  if (coinRes) {
    return coinRes
  }
  return 0
}

function checkOffer(a: ProductV2, b: ProductV2) {
  const [aOffer, aPriority] = mapOffer(a)
  const [bOffer, bPriority] = mapOffer(b)
  if (aOffer && !bOffer) {
    return -1
  }
  if (bOffer && !aOffer) {
    return 1
  }
  if (aOffer && bOffer && aPriority !== undefined && bPriority !== undefined) {
    return aPriority - bPriority
  }
  return undefined
}

function mapOffer(p: ProductV2): [boolean, number | undefined] {
  if (isProductOffer(p)) {
    return [true, p.priority]
  }
  if (isStoreOfferProduct(p)) {
    return [true, undefined]
  }
  return [false, undefined]
}

function check(type: keyof ProductV2['contentCounts'], a: ProductV2, b: ProductV2) {
  const cb = containsType(type)
  if (cb(a) && cb(b)) {
    const priceA = Object.values(a.prices)[0]
    const priceB = Object.values(b.prices)[0]

    if (priceA === undefined || priceB === undefined) {
      return 0
    }

    return priceB - priceA
  }
  if (cb(a) && !cb(b)) {
    return -1
  } else if (cb(b) && !cb(a)) {
    return 1
  } else {
    return undefined
  }
}

type StaticProductOffer = ProductV2 & { offerData: Offer }

const containsType = (type: keyof ProductV2['contentCounts']) => (p: ProductV2) => (p.contentCounts[type] || 0) > 0
const isProductOffer = (product: ProductV2): product is StaticProductOffer => product.offerData !== null
