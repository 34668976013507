import { skipToken } from '@reduxjs/toolkit/query'

import { useFetchAnnouncementQuery } from '@/api'
import { useLocale } from '@/features/locale/useLocale'
import { useTranslatable } from '@/localization/useTranslatable'
import { useAppSelector } from '@/utils/useAppSelector'

import styles from './AnnouncementBanner.module.scss'

const ONE_MINUTE = 60 * 1000

export const AnnouncementBanner = () => {
  const locale = useLocale()
  const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
  const translatable = useTranslatable()
  const { data } = useFetchAnnouncementQuery(!viewerCountry ? skipToken : { locale, viewerCountry }, {
    refetchOnMountOrArgChange: true,
    pollingInterval: ONE_MINUTE,
  })

  if (!data || data.description[locale]?.length === 0) {
    return null
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <p className={styles.Description}>{translatable(data.description, '')}</p>
      </div>
    </div>
  )
}
