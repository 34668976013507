import React from 'react'

import { GameId, ProductV2, StoreOfferProduct, isGemPackOffer } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import BrawlerHeroBg from '@/assets/images/components/BrawlerHero/brawler-hero-bg.png'
import BrawlBag from '@/assets/images/storefronts/brawlstars/bag.png'
import BrawlHyperchargeHeroBg from '@/assets/images/storefronts/brawlstars/bundle-hypercharge.png'
import BrawlFlare from '@/assets/images/storefronts/brawlstars/shop_item_bg_flare.png'
import CoCBag from '@/assets/images/storefronts/clashofclans/bag.png'
import CocHero1 from '@/assets/images/storefronts/clashofclans/bundle-hero.png'
import BgFlare from '@/assets/images/storefronts/clashofclans/shop_item_bg_flare.png'
import RoyaleBag from '@/assets/images/storefronts/clashroyale/bag.png'
import DynamicImage from '@/components/DynamicImage'
import { useGameImages } from '@/components/games/images'
import { isSeasonPass } from '@/utils/products'
import { resolveProductImage, isProductImageEmpty } from '@/utils/resolveProductImage'
import { useBreakpoints } from '@/utils/useBreakpoints'

import { ProductItemImage } from './ProductImage'
import { brawlHyperChargeAsset, isBrawlCharacter, useBrawlCharacterImage } from '../../brawlstars/bundle/helpers'
import { hyperchargeIdToCharacter } from '../../brawlstars/constants'
import { clashResources } from '../../clashofclans/contants'
import { isCrCardProduct } from '../../clashroyale/bundle/helpers'
import { useFormattedAmount, useProductImage } from '../card/utils'
import { ProductRibbon } from '../cardRibbon/ProductRibbon'
import { PASS_CARD_HERO_BG } from '../constants'
import { AnimatedImageWithFallback } from '../imageWithFallback/AnimatedImageWithFallback'
import { ImageWithFallback } from '../imageWithFallback/ImageWithFallback'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './productPageHeader.module.scss'

type OfferHeaderProps = {
  product: ProductV2 | StoreOfferProduct
  alt: string
  game: GameId
}

const getBagImg = (game: GameId) => {
  switch (game) {
    case 'brawlstars':
      return BrawlBag
    case 'clashroyale':
      return RoyaleBag
    case 'clashofclans':
      return CoCBag

    default:
      return CoCBag
  }
}

export const SpecialOfferHero = ({ alt, product, game }: OfferHeaderProps) => {
  const brawl = game === 'brawlstars'
  const characterIconId = brawl
    ? product.contents.find((content) => brawlHyperChargeAsset(content.product.id))?.product.id
    : undefined

  const characterName = characterIconId && hyperchargeIdToCharacter[characterIconId]
  const characterImage = useBrawlCharacterImage(characterIconId)
  const BrawlHero = characterIconId ? BrawlHyperchargeHeroBg : product.images[0]

  const hero = brawl ? BrawlHero : (product.images.at(0) ?? CocHero1)
  return (
    <div className={cx(styles.specialOfferHero, styles[game])}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <div className={styles.imageWrapper}>
        {characterImage && (
          <Image
            src={characterImage}
            alt={characterName ?? 'Offer character'}
            height={300}
            width={300}
            quality={100}
            className={styles.heroCharacter}
          />
        )}
        <Image
          src={hero}
          alt={alt}
          quality={100}
          fill
          sizes="(max-width: 600px) 400px, 840px"
          style={{ objectFit: 'cover' }}
        />
      </div>
    </div>
  )
}

export const GemPackOfferHero = ({ product, alt, game }: OfferHeaderProps) => {
  const brawl = game === 'brawlstars'

  return (
    <div className={cx(styles.gemPackOfferHero, styles[game])}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <div className={styles.imageWrapper}>
        <Image
          src={product.images.at(0) || getBagImg(game)}
          alt={alt}
          className={styles.gemPackOfferHeroImg}
          fill
          quality={100}
          sizes="400px"
        />
        <div className={styles.flareWrapper}>
          {game !== 'clashroyale' && (
            <div className={styles.bgFlare}>
              <Image
                src={brawl ? BrawlFlare : BgFlare}
                alt="Flare"
                fill
                style={{ objectFit: 'contain' }}
                sizes="200px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CosmeticsHero = ({ product, alt, game }: OfferHeaderProps) => {
  return (
    <div className={cx(styles.cosmeticsHero, styles[game])}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <div className={styles.imageWrapper}>
        <ImageWithFallback productImage={product.images[0]} title={alt} className={styles.cosmeticsHeroImg} />
        <div className={styles.flareWrapper}>
          {game !== 'clashroyale' && (
            <div className={styles.bgFlare}>
              <Image src={BgFlare} alt="Flare" fill style={{ objectFit: 'contain' }} sizes="200px" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const NormalOfferHero = ({ product, alt, game }: OfferHeaderProps) => {
  const from = useBreakpoints().from
  const isDesktop = from('tablet')

  const brawl = game === 'brawlstars'
  const royale = game === 'clashroyale'
  const Bag = brawl ? BrawlBag : royale ? RoyaleBag : CoCBag

  return (
    <div className={cx(styles.normalOfferHero, styles[game])}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <div className={styles.imageWrapper}>
        <Image src={Bag} alt={alt} width={isDesktop ? 200 : 134} className={styles.normalOfferImg} />
        <div className={styles.flareWrapper}>
          <div className={styles.bgFlare}>
            <Image src={brawl ? BrawlFlare : BgFlare} alt="Flare" fill style={{ objectFit: 'contain' }} sizes="200px" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const BrawlerHero = ({ product }: { product: ProductV2 }) => {
  const item = product.contents.find((c) => c.product.itemType === 'Hero' || c.product.itemType === 'Skin')

  if (!item) {
    throw new Error('Invalid itemType')
  }

  const { imageSrc, animatedSrc, safariAnimatedSrc } = useProductImage({
    game: 'brawlstars',
    productId: item.product.id,
    itemType: item.product.itemType,
    count: item.amount,
  })

  return (
    <div className={cx(styles.BrawlerHero)}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <Image className={styles.Background} src={BrawlerHeroBg} alt="Flare" fill />
      <div className={styles.Character}>
        <AnimatedImageWithFallback
          animationSrc={animatedSrc}
          safariAnimationSrc={safariAnimatedSrc}
          fallbackImage={imageSrc}
        />
      </div>
    </div>
  )
}

export const SingularOfferHero = ({ product, alt, game }: OfferHeaderProps) => {
  const from = useBreakpoints().from
  const isDesktop = from('tablet')
  const { gameImage } = useGameImages(game)

  const brawl = game === 'brawlstars'
  const royale = game === 'clashroyale'
  const Bag = brawl ? BrawlBag : royale ? RoyaleBag : CoCBag

  const theOnlyItem = product.contents.at(0)
  const gemPackOffer = isGemPackOffer(product)
  const productImage = !isProductImageEmpty(product)
    ? resolveProductImage(product)
    : gameImage('missingProductImageSmall')

  const formattedAmount = useFormattedAmount(theOnlyItem?.amount || 0)

  if (!theOnlyItem) {
    return null
  }

  const displayClashXmultiplier = game === 'clashofclans' && !clashResources.includes(theOnlyItem.product.id)
  const displayRoyaleXmultiplier = game === 'clashroyale' && theOnlyItem && isCrCardProduct(theOnlyItem.product.id)
  const isHyperCharge = brawl && brawlHyperChargeAsset(theOnlyItem.product.id)
  const brawlCharacter = brawl && isBrawlCharacter(theOnlyItem.product.id)

  return (
    <div className={cx(styles.normalOfferHero, styles[game])}>
      <ProductRibbon product={product} className={styles.productRibbon} />
      <div className={styles.imageWrapper}>
        {gemPackOffer ? (
          // CR specific, as gems are offers
          <Image
            src={productImage}
            alt={alt}
            quality={100}
            fill
            sizes="(max-width: 600px) 400px, 600px"
            style={{ objectFit: 'contain' }}
            className={styles.normalOfferImg}
          />
        ) : theOnlyItem ? (
          <div
            className={cx(styles.productImageInHero, {
              [styles.hyperChargeSingleOffer]: isHyperCharge,
              [styles.brawlCharacter]: brawlCharacter,
              [styles.brawlSingularOfferImage]: brawl,
            })}
          >
            <ProductItemImage game={game} item={theOnlyItem} />
          </div>
        ) : (
          <Image src={Bag} alt={alt} width={isDesktop ? 200 : 134} className={styles.normalOfferImg} />
        )}
        <div className={styles.flareWrapper}>
          <div className={styles.bgFlare}>
            <Image src={brawl ? BrawlFlare : BgFlare} alt="Flare" fill style={{ objectFit: 'contain' }} sizes="200px" />
          </div>
        </div>
      </div>
      {!gemPackOffer && (
        // All CR products are offers, so disable for gems
        <StoreTypography tag="div" textSize={'md'} style="outlined" className={cx(styles.amount, styles[game])}>
          {displayRoyaleXmultiplier && 'x'}
          {formattedAmount}
          {displayClashXmultiplier && 'x'}
        </StoreTypography>
      )}
    </div>
  )
}

interface SingleProductHeroProps {
  product: ProductV2
  alt: string
  className?: string | string[]
  'data-cy'?: string
  game: GameId
}

export const SingleProductHero: React.FC<SingleProductHeroProps> = ({
  product,
  alt,
  className: additionalClasses,
  'data-cy': dataCy,
  game,
}) => {
  const passBg = (isSeasonPass(product) && game === 'brawlstars') || (isSeasonPass(product) && game === 'clashroyale')

  return (
    <div
      className={cx(styles.singleProductHero, additionalClasses, styles[game])}
      data-cy={dataCy}
      style={{
        ...(passBg && { backgroundImage: PASS_CARD_HERO_BG(game) }),
      }}
    >
      <ProductRibbon product={product} className={styles.productRibbon} />

      <div className={styles.imageWrapper}>
        <div className={styles.flareWrapper}>
          <div className={styles.bgFlare}>
            <Image src={BgFlare} alt="Flare" fill style={{ objectFit: 'contain' }} sizes="200px" />
          </div>
        </div>

        <div className={cx(styles.ProductImage, styles[game])}>
          <DynamicImage
            src={resolveProductImage(product)}
            alt={alt}
            layout="fill"
            objectFit="contain"
            quality={100}
            sizes="(max-width: 600px) 50vw, 30vw"
          />
        </div>
      </div>
    </div>
  )
}
