import '@/styles/app.scss'
import React, { useEffect, useMemo } from 'react'

import { DDBCartItem, enabledIn, GameId, joins, ProductV2, supportedLanguages } from '@common'
import { AppProps } from 'next/app'
import { useRouter } from 'next/dist/client/router'
import dynamic from 'next/dynamic'
import { AbstractIntlMessages, IntlProvider as NextIntlProvider } from 'next-intl'
import { Provider } from 'react-redux'

import { AnnouncementBanner } from '@/components/AnnouncementBanner'
import { DefaultMetaTags } from '@/components/MetaTags'
import CookieConsent from '@/features/CookieConsent'
import ErrorBoundary from '@/features/ErrorBoundary'
import { Footer } from '@/features/Footer'
import { Header } from '@/features/header/Header'
import { useLocale } from '@/features/locale/useLocale'
import Tracking from '@/features/Tracking'
import { globalFormats } from '@/localization/globalFormats'
import { PreloadedState, useInitStore } from '@/store'
import logger from '@/utils/logger'
import '../polyfills'

import { config } from '../../config'
import { CartContextProvider } from '../contexts/CartContext'
import { MarketContextProvider } from '../contexts/MarketContext'
import { ModalContextProvider } from '../contexts/ModalContext'
import { useResolveViewerCountry } from '../features/common/useResolveViewerCountry'
import { useSetCookieConsentLocale } from '../features/common/useSetCookieConsentLocale'
import { Helpshift } from '../features/Helpshift'
import { BonusContextProvider } from '../features/storefronts/_storeBase/storeBonusV2/BonusContextProvider'
import { login, LOGIN_STATUS, resetUser, setLoginStatus } from '../features/user/userSlice'
import { useMessageContext, MessageContextProvider } from '../localization/MessageContext'
import { ddbCartItemsToCartItems } from '../utils/cart'
import { isClient } from '../utils/isClient'
import { useAppDispatch } from '../utils/useAppDispatch'
import { useAppSelector } from '../utils/useAppSelector'
import { useCaptureBrokenImages } from '../utils/useCaptureBrokenImages'

const { BUILD_ID, TRACKING_ENABLED, COOKIE_CONSENT_ENABLED, STAGE } = config

const EMPTY_OBJECT = {}

if (isClient && BUILD_ID) {
  logger.info(`Supercell Store\nBUILD ID: ${BUILD_ID}`)
}
const FeatureFlagMenuButton = dynamic<unknown>(() =>
  import('../features/featureFlags/FeatureFlags').then((mod) => mod.FeatureFlagMenuButton),
)

if (isClient) {
  window.SC_STORE_LOADED = false
  const observer = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      // Next.js marks this after hydration is completed
      if (entry.name === 'afterHydrate') {
        window.SC_STORE_LOADED = true
      }
    }
  })
  observer.observe({ entryTypes: ['mark'] })
}

function App({
  Component,
  pageProps,
}: Pick<
  AppProps<{ initialState?: PreloadedState; messages: IntlMessages; enabledStoreFronts: GameId[]; isStatic?: boolean }>,
  'Component' | 'pageProps'
>) {
  const router = useRouter()
  const {
    pathname,
    asPath,
    query: { x: cacheBuster, ...routerQuery },
  } = router
  const enabledStoreFronts = pageProps.enabledStoreFronts
  const isStoreFrontPage = enabledStoreFronts?.some((game) => asPath.includes(game))
  const isReceiptPage = pathname === '/receipt'
  const isMaintenancePage = pathname === '/maintenance'
  const showHeader = !isReceiptPage && !isMaintenancePage
  const isNonProd = enabledIn(STAGE, ['development', 'local-dev', 'integration', 'staging', 'test'])
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.user?.profile)

  const sso = useMemo(() => {
    if (isClient && window.SSOSDK) {
      return new window.SSOSDK({
        clientId: config.OAUTH_CLIENT_ID,
        scope: 'identify identity.email',
        ssoBaseUrl: config.OAUTH_LOGIN_URL,
        onLogin: async ({ authorizationCode }) => {
          if (window.location.pathname.includes('/403')) {
            return
          }

          try {
            const ssoLoginResponse = await fetch('/oauth/ssologin', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ authorizationCode }),
            })

            if (ssoLoginResponse.ok) {
              dispatch(setLoginStatus(LOGIN_STATUS.LOGGED_IN))
              const sessionResponse = await fetch('/api/session', { credentials: 'include' })
              const session = await sessionResponse.json()
              dispatch(login(session))
            }
          } catch (err) {
            logger.error(err)
            dispatch(setLoginStatus(LOGIN_STATUS.LOGGED_OUT))
          }
        },
        onLogout: async () => {
          if (window.location.pathname.includes('/403')) {
            return
          }
          try {
            const ssoLogoutResponse = await fetch('/api/customers/logout', { method: 'POST' })
            if (ssoLogoutResponse.ok) {
              dispatch(resetUser())
            }
          } catch (err) {
            logger.error(err)
          }
        },
        accountIdCookieName: config.SCID_SSO_COOKIE_NAME,
        debugLogEnabled: isNonProd,
      })
    }
  }, [dispatch, isNonProd])

  const [ssoStart, setSsoStart] = React.useState<undefined | { selectedAccountId: string | null }>()
  useEffect(() => {
    if (!ssoStart) {
      try {
        setSsoStart(sso?.start())
      } catch (err) {
        logger.error(err)
      }
    }

    if (ssoStart && profile && profile?.accountId !== ssoStart?.selectedAccountId) {
      dispatch(setLoginStatus(LOGIN_STATUS.LOGGED_IN))
    }
  }, [sso, profile, dispatch, ssoStart, setSsoStart])

  useSetCookieConsentLocale()
  useResolveViewerCountry()
  useCaptureBrokenImages()

  if (isClient) {
    if (cacheBuster) {
      router.replace(
        {
          query: { ...routerQuery },
        },
        undefined,
        { shallow: true },
      )
    }
  }

  return (
    <div id="main">
      {isNonProd && <FeatureFlagMenuButton />}
      <AnnouncementBanner />
      {!isStoreFrontPage && showHeader && <Header enabledStoreFronts={enabledStoreFronts} />}
      <Component {...pageProps} />
      <Footer isStatic={pageProps.isStatic} />
    </div>
  )
}

const IntlProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { messages } = useMessageContext()
  const locale = useLocale()

  return (
    <NextIntlProvider
      locale={locale}
      messages={messages as unknown as AbstractIntlMessages}
      defaultTranslationValues={globalFormats}
      onError={(err) => {
        logger.debug(err)
      }}
    >
      {children}
    </NextIntlProvider>
  )
}

function StoreApp({
  pageProps,
  Component,
}: AppProps<{
  initialState?: PreloadedState
  market: joins.Market
  cart?: { gameId: GameId; items: DDBCartItem[] }
  ineligibleFor?: { id: string; reason: string; key: string }
  products?: ProductV2[]
  messages: IntlMessages
  enabledStoreFronts: GameId[]
  isStatic?: boolean
}>) {
  // Currently nextjs responds 200 with '{}' as body for old builds in
  // _data/<build-id>/page-data/<page-path>.json
  // Fix is in next canary but has not made its way to a stable release yet
  // https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
  // Remove this and replace with /utils/useHandlePageLoadError.ts when fixed

  useEffect(() => {
    if (JSON.stringify(pageProps) === '{}') {
      logger.info({ msg: 'Empty pageProps triggering full page reload' })
      window.location.reload()
    }
  }, [pageProps])

  const locale = useLocale()
  const store = useInitStore(pageProps.initialState || EMPTY_OBJECT)
  const { market, ...rest } = pageProps
  const cart =
    pageProps.cart && pageProps.products
      ? {
          gameId: pageProps.cart.gameId,
          items: ddbCartItemsToCartItems(pageProps.cart.items, pageProps.products),
          ineligibleFor: pageProps.ineligibleFor,
        }
      : undefined

  return (
    <main dir={supportedLanguages[locale].rightToLeft ? 'rtl' : 'ltr'}>
      <MessageContextProvider messages={pageProps.messages}>
        <IntlProvider>
          <ErrorBoundary sentryEnabled={enabledIn(STAGE, ['integration', 'staging', 'production'])}>
            <Provider store={store}>
              <MarketContextProvider market={market}>
                <DefaultMetaTags />
                {COOKIE_CONSENT_ENABLED && <CookieConsent />}
                {TRACKING_ENABLED && <Tracking />}
                <CartContextProvider market={market} {...cart}>
                  <BonusContextProvider>
                    <ModalContextProvider>
                      <App pageProps={rest} Component={Component} />
                      {!pageProps.isStatic && <Helpshift />}
                    </ModalContextProvider>
                  </BonusContextProvider>
                </CartContextProvider>
              </MarketContextProvider>
            </Provider>
          </ErrorBoundary>
        </IntlProvider>
      </MessageContextProvider>
    </main>
  )
}

export default StoreApp
