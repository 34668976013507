'use client'

import { memo, useCallback, useRef, useState } from 'react'

import { StoreBonusStatusResponse } from '@common/models/bonus'
import cx from 'classnames'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'

import { useCartContext } from '@/contexts/CartContext'
import { useModalContext } from '@/contexts/ModalContext'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { useBreakpoints } from '@/utils/useBreakpoints'
import useOnClickOutside from '@/utils/useOnClickOutside'

import { BonusesAndPoints } from './BonusesAndPoints'
import { getNextItem } from './bonusUtils'
import { useIsRouteChanging } from '../../../../utils/useIsRouteChanging'
import { BonusStatusFooter } from '../bonusStatusFooter/BonusStatusFooter'
import { DrawerButton } from '../bonusStatusFooter/DrawerButton'
import { useMenu } from '../header/menu/menu-provider'

import styles from './bonusContainer.module.scss'

interface BonusContainerProps {
  bonusStatus: StoreBonusStatusResponse
}

const variants = {
  hidden: {
    y: '100vh',
    opacity: 0,
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      when: 'afterChildren',
      ease: 'easeIn',
      duration: 0.5,
    },
  },
  exit: {
    y: '100vh',
    transition: {
      ease: 'easeOut',
      duration: 0.5,
    },
  },
}

export const BonusContainer = memo(({ bonusStatus }: BonusContainerProps) => {
  const isRouteChanging = useIsRouteChanging()
  const features = () => import('@/animations/features').then((a) => a.default)
  const gameSlug = useGameSlug()

  const [isOpen, setIsOpen] = useState(false)
  const [animationComplete, setAnimationComplete] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const from = useBreakpoints().from
  const isDesktop = from('laptop-small')
  const { isOpen: isCartOpen, closeCart, items } = useCartContext()
  const { isOpen: menuOpen, setIsOpen: setMenu } = useMenu()

  const closeDrawer = useCallback(() => {
    setIsOpen(false)
    document.body.style.overflowY = 'auto'
  }, [])

  const toggleDrawer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (isRouteChanging) {
      return
    }

    if (isDesktop) {
      if (isCartOpen) {
        closeCart()
      }
      if (menuOpen) {
        setMenu(false)
      }
    }

    setIsOpen((wasOpen) => {
      document.body.style.overflowY = wasOpen ? 'auto' : 'hidden'
      return !wasOpen
    })
    setAnimationComplete(false)
  }

  const { modalType } = useModalContext()

  useOnClickOutside(ref, (event) => {
    if (isOpen && !modalType) {
      event.stopImmediatePropagation()
      closeDrawer()
    }
  })

  if (!bonusStatus) {
    return null
  }

  const nextItem = getNextItem({ bonusStatus, items })

  if (!nextItem) {
    return null
  }

  return (
    <LazyMotion features={features}>
      <AnimatePresence>
        {isOpen && (
          <m.div
            key="backdrop"
            className={styles.backdrop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { delay: 0.4 } }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isOpen && (
          <m.section
            key="bonus"
            className={cx(styles.bonusTrackContainer)}
            variants={variants}
            initial="hidden"
            animate="open"
            exit="exit"
            onAnimationComplete={() => setAnimationComplete(true)}
          >
            <m.div
              id="bonusTrackWrapper"
              className={cx(styles.scrollableWrapper)}
              initial={{ overflowY: 'hidden' }}
              animate={{ overflowY: 'auto' }}
              exit={{ overflowY: 'hidden' }}
            >
              <div ref={ref} className={cx(styles.contentContainer, styles[gameSlug])}>
                <BonusesAndPoints bonusStatus={bonusStatus} animationComplete={animationComplete} />
                <div className={styles.drawerButtonContainer}>
                  <DrawerButton isOpen={isOpen} onClick={toggleDrawer} />
                </div>
              </div>
            </m.div>
          </m.section>
        )}

        {!isOpen && <BonusStatusFooter bonusStatus={bonusStatus} onOpenDrawer={toggleDrawer} />}
      </AnimatePresence>
    </LazyMotion>
  )
})

BonusContainer.displayName = 'BonusContainer'
