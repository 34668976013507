import { GameId } from '@common'
import { StoreRewardItem } from '@common/models/bonus'
import Image from 'next/image'

import BsCheckMark from '@/assets/images/storefronts/brawlstars/bonus-item-checkmark.png'
import BsClaimedBg from '@/assets/images/storefronts/brawlstars/bonus-item-claimed-image-bg.png'
import BsCurrentBg from '@/assets/images/storefronts/brawlstars/bonus-item-current-image-bg.png'
import BsDarkCurrentBg from '@/assets/images/storefronts/brawlstars/bonus-item-current-image-dark-bg.png'
import BsUnavailableBg from '@/assets/images/storefronts/brawlstars/bonus-item-unavailable-image-bg.png'
import BsUnclaimedBg from '@/assets/images/storefronts/brawlstars/bonus-item-unclaimed-image-bg.png'
import CocCheckMark from '@/assets/images/storefronts/clashofclans/bonus-item-checkmark.png'
import CocClaimedBg from '@/assets/images/storefronts/clashofclans/bonus-item-claimed-image-bg.png'
import CocCurrentBg from '@/assets/images/storefronts/clashofclans/bonus-item-current-image-bg.png'
import CocUnavailableBg from '@/assets/images/storefronts/clashofclans/bonus-item-unavailable-image-bg.png'
import CocUnclaimedBg from '@/assets/images/storefronts/clashofclans/bonus-item-unclaimed-image-bg.png'
import UnavailableImg from '@/assets/images/storefronts/clashofclans/bonus-track-unavailable.png'
import CrCheckMark from '@/assets/images/storefronts/clashroyale/bonus-item-checkmark.png'
import { useCartContext } from '@/contexts/CartContext'

import { cartItemQuantity } from '../../../../utils/cart'
import { usePreviousWithDelayedReset } from '../../../../utils/usePrevious'
import { useGameSlug } from '../../game-provider'
import { CircleProgress } from '../bonusStatusFooter/CircleProgress'
import { BonusItemStatus } from '../bonusTrack/BonusTrackItem'

import styles from './bonusImage.module.scss'

const getGameImages = (gameSlug: GameId, bonusStatusFooter?: boolean) => {
  const defaults = {
    claimed: CocClaimedBg,
    unclaimed: CocUnclaimedBg,
    next: CocCurrentBg,
    unavailable: CocUnavailableBg,
    completed: CocCheckMark,
    delivered: CocUnclaimedBg,
  }

  if (gameSlug === 'brawlstars') {
    return {
      claimed: BsClaimedBg,
      unclaimed: BsUnavailableBg,
      next: bonusStatusFooter ? BsDarkCurrentBg : BsCurrentBg,
      unavailable: BsUnavailableBg,
      completed: BsCheckMark,
      delivered: BsUnclaimedBg,
    }
  }

  if (gameSlug === 'clashroyale') {
    return {
      ...defaults,
      completed: CrCheckMark,
    }
  }

  return defaults
}

interface BonusImageProps {
  item?: StoreRewardItem
  status: BonusItemStatus | 'completed'
  progressPercentage: number
  bonusStatusFooter?: boolean
}

export const BonusImage = ({ item, status, progressPercentage, bonusStatusFooter = false }: BonusImageProps) => {
  const { items } = useCartContext()
  const gameSlug = useGameSlug()
  const previousQuantity = usePreviousWithDelayedReset(cartItemQuantity(items), 500)

  const itemImageSrc = item?.image || UnavailableImg
  const bgImageMap = getGameImages(gameSlug, bonusStatusFooter)
  const checkMarkImg = getGameImages(gameSlug)['completed']

  const showAnimation = previousQuantity !== cartItemQuantity(items)

  return (
    <div className={styles.container}>
      <div className={styles.backgroundContainer}>
        <Image
          src={bgImageMap[status]}
          alt="reward-image-background"
          height={40}
          width={40}
          quality={100}
          placeholder="empty"
        />
        {status === 'next' && (
          <CircleProgress
            progressPercentage={progressPercentage}
            loading={showAnimation}
            bonusStatusFooter={bonusStatusFooter}
          />
        )}
      </div>
      {status !== 'completed' && (
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <Image
              className={styles.image}
              src={itemImageSrc}
              alt="reward-image"
              height={40}
              width={40}
              placeholder="empty"
              quality={100}
              priority
            />
          </div>
        </div>
      )}
      {item?.claimed && (
        <div className={styles.checkmarkContainer}>
          <Image
            className={styles.checkmark}
            src={checkMarkImg}
            alt="reward-check-mark"
            placeholder="empty"
            height={20}
            width={20}
          />
        </div>
      )}
    </div>
  )
}
