import { IconButton } from './IconButton'
import { ExitIcon } from '../Icons/ExitIcon'

interface ExitButtonProps {
  onClick: () => void
  className?: string
  'data-cy'?: string
}

export const ExitButton: React.FC<ExitButtonProps> = ({ onClick, className, 'data-cy': dataCy }) => {
  return (
    <IconButton data-cy={dataCy} onClick={onClick} className={className}>
      <ExitIcon />
    </IconButton>
  )
}
