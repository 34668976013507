import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import BsUnavailableImg from '@/assets/images/storefronts/brawlstars/bonus-track-unavailable.png'
import CocUnavailableImg from '@/assets/images/storefronts/clashofclans/bonus-track-unavailable.png'
import CrUnavailableImg from '@/assets/images/storefronts/clashroyale/bonus-track-unavailable.png'

import { useGameSlug } from '../../game-provider'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusUnavailable.module.scss'

export const BonusUnavailable = () => {
  const t = useTranslations()
  const gameSlug = useGameSlug()

  const img =
    gameSlug === 'brawlstars' ? BsUnavailableImg : gameSlug === 'clashroyale' ? CrUnavailableImg : CocUnavailableImg

  return (
    <div className={cx(styles.bonusUnavailableContainer, styles[gameSlug])}>
      <StoreTypography tag="h2" textSize="lg" style="plain">
        {t('bonus_track_unavailable')}
      </StoreTypography>
      <div className={styles.unavailableImg}>
        <Image src={img} alt="bonus-unavailable" fill />
      </div>
      <StoreTypography tag="h3" textSize="body" style="plain" overrideFont="supercellText">
        {t('bonus_track_check_back')}
      </StoreTypography>
    </div>
  )
}
