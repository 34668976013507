import { GameId } from '@common'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { HydrateAction, hydrateMatcher } from '../../hydrate'
import { RootState } from '../../store'

export interface CommonState {
  currentGame?: GameId
  claimedBonusItems: string[]
  bonusTrackCompleted: boolean
}

export const initialState: CommonState = {
  currentGame: undefined,
  claimedBonusItems: [],
  bonusTrackCompleted: false,
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCurrentGame: (state: CommonState, action: PayloadAction<GameId | undefined>) => {
      state.currentGame = action.payload
    },
    setClaimedBonusItem: (state: CommonState, action: PayloadAction<string>) => {
      if (!state.claimedBonusItems.includes(action.payload)) {
        state.claimedBonusItems = [...state.claimedBonusItems, action.payload]
      }
    },
    setBonusTrackCompleted: (state: CommonState, action: PayloadAction<boolean>) => {
      state.bonusTrackCompleted = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher<HydrateAction<Partial<RootState>>>(hydrateMatcher, (state, action) => {
      const serverState = action.payload?.common
      state.currentGame = serverState?.currentGame ?? state.currentGame
      return state
    })
  },
})

export const { setCurrentGame, setClaimedBonusItem, setBonusTrackCompleted } = commonSlice.actions

export default commonSlice.reducer
