import React from 'react'

import cx from 'classnames'
import Image from 'next/legacy/image'

import Shine from '@/assets/images/shine.png'
import DynamicImage from '@/components/DynamicImage'
import { useGameImages } from '@/components/games/images'
import { Game } from '@/fixtures/games'

import { Ribbon } from '../../components/Ribbon'

import styles from './ProductHeader.module.scss'

interface ProductHeaderProps {
  game: Game
  productImage: string
  size: 'Large' | 'Small'
  alt: string
  background?: string
  ribbonContent?: string[]
  className?: string | string[]
  variant?: 'passbasic' | 'passpremium'
  'data-cy'?: string
}

export const ProductHeader: React.FC<ProductHeaderProps> = ({
  game,
  productImage,
  size,
  alt,
  ribbonContent,
  className: additionalClasses,
  variant,
  'data-cy': dataCy,
}) => {
  const { gameImage } = useGameImages(game.slug, variant)
  const showShine = game.shortSlug !== 'coc'
  return (
    <div className={cx(styles.ProductHeader, styles[size], styles[game.className], additionalClasses)} data-cy={dataCy}>
      <div className={styles.Wrapper}>
        <div className={styles.RibbonContainer}>
          <Ribbon content={ribbonContent} />
        </div>
        <div className={styles.GameLogo}>
          <Image
            alt="Game logo"
            src={size === 'Large' ? gameImage('logo') : gameImage('badge')}
            layout="fill"
            objectFit="contain"
            sizes={size === 'Large' ? '(max-width: 1024px) 100px, 130px' : '(max-width: 1024px) 30px, 50px'}
            quality={100}
          />
        </div>
        {showShine && (
          <Image
            src={Shine}
            alt="shine"
            layout="fill"
            objectFit="contain"
            quality={50}
            sizes="(max-width: 600px) 30vw, 10vw"
          />
        )}
        <div className={styles.ProductImage}>
          <DynamicImage
            src={productImage}
            alt={alt}
            layout="fill"
            objectFit="contain"
            quality={100}
            sizes="(max-width: 600px) 50vw, 30vw"
          />
        </div>
      </div>
      <div className={cx(styles.BackgroundWrapper, styles[game.className])}>
        <Image
          alt="Background Image"
          src={gameImage('background')}
          layout="fill"
          objectFit="cover"
          sizes={size === 'Large' ? '(max-width: 1024px) 480px, 560px' : '(max-width: 1024px) 290px, 270px'}
          quality={100}
        />
      </div>
    </div>
  )
}
