import { ProductV2 } from '@common'

import { StoreSpecialHero } from '@/features/storefronts/_storeBase/card/v2/OfferCard/games/brawlstars/components/Hero/StoreSpecialHero'
import { isBrawlerOrSkin } from '@/features/storefronts/brawlstars/bundle/helpers'
import { isStoreSpecial } from '@/features/storefronts/productFilters'

import { BrawlerHero } from './Hero/BrawlerHero'
import { RegularHero } from './Hero/RegularHero'

type Props = {
  product: ProductV2
  className?: string
}

function isBrawlerOffer(product: ProductV2) {
  const isBrawlStars = product.game === 'brawlstars'
  const isItemTypeHero = product.contents.some((c) => c.product.itemType === 'Hero' || c.product.itemType === 'Skin')
  return isBrawlStars && isItemTypeHero
}

export function BrawlStarsHero({ product, className }: Props) {
  if (isBrawlerOrSkin(product.contents[0])) {
    return <BrawlerHero product={product} className={className} />
  }
  if (isStoreSpecial(product)) {
    return <StoreSpecialHero product={product} className={className} />
  }
  if (isBrawlerOffer(product)) {
    return <BrawlerHero product={product} className={className} />
  }
  return <RegularHero product={product} className={className} />
}
