'use client'

import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'

export type BonusContextType = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const initialBonusContext: BonusContextType = {
  isOpen: false,
  setIsOpen: () => '',
}

export const BonusContext = createContext<BonusContextType>(initialBonusContext)

export const BonusContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(initialBonusContext.isOpen)

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
  }, [isOpen])

  return <BonusContext.Provider value={{ isOpen, setIsOpen }}>{children}</BonusContext.Provider>
}

export const useBonus = () => useContext(BonusContext)
