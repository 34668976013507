import React, { useCallback } from 'react'

import { isStoreOfferProduct, ProductV2 } from '@common'
import cx from 'classnames'
import Image from 'next/legacy/image'
import router from 'next/router'
import { useTranslations } from 'next-intl'

import { Card } from '@/components/Card'
import { useGameImages } from '@/components/games/images'
import { useMarketContext } from '@/contexts/MarketContext'
import { useModalContext } from '@/contexts/ModalContext'
import { useLocale } from '@/features/locale/useLocale'
import { BuyButton } from '@/features/storefronts/_storeBase/BuyButton/BuyButton'
import { Game } from '@/fixtures/games'
import { resolveProductImage } from '@/utils/resolveProductImage'

import ProductDescription from './ProductDescription'
import { ProductHeader } from './ProductHeader'
import { useOfferCountdown } from './useOfferCountdown'
import { scrollModal } from '../../components/Modal'
import { useTranslatable } from '../../localization/useTranslatable'
import { formatNumbersInString } from '../../utils/formatNumbersInString'
import { resolveProductBackgroundVariant } from '../../utils/resolveProductBackgroundVariant'
import { useSnowplowViewImpression } from '../../utils/snowplow'
import { resolveRibbonKey } from '../common/useProducts'
import { OriginalPrice } from '../storefronts/_storeBase/productPage/OriginalPrice'

import styles from './ProductCard.module.scss'

interface ProductCardProps {
  type: 'Normal' | 'Feature'
  game: Game
  product: ProductV2
  products?: ProductV2[]
  openAs?: 'overlay' | 'page'
}

export function EmptyProductCard({ game, className: additionalClasses }: { game: Game; className: string | string[] }) {
  const { gameImage } = useGameImages(game.slug)
  return (
    <Card
      shadowSize="Small"
      className={cx(styles.ProductCard, styles.Empty, styles[game.className], styles['Normal'], additionalClasses)}
    >
      <div className={styles.Container}>
        <div className={styles.BadgeOutline}>
          <Image src={gameImage('badgeOutline')} alt="badge-outline" layout="responsive" />
        </div>
      </div>
    </Card>
  )
}

export default function ProductCard({ game, type, product, products, openAs = 'overlay' }: ProductCardProps) {
  const translatable = useTranslatable()
  const t = useTranslations()
  const locale = useLocale()
  const market = useMarketContext('ProductCard')

  const currencyCountry = market.currencyCountry
  const currency = market.currencyCode

  const size = type === 'Normal' ? 'Small' : 'Large'
  const ribbonData = resolveRibbonKey(product)
  const ribbonContent = ribbonData ? [t(ribbonData[0], ribbonData[1])] : []
  const { openModal } = useModalContext()
  const basePath = '/product/'

  const snowplowViewImpressionRef = useSnowplowViewImpression<HTMLDivElement>(
    product,
    locale,
    currencyCountry,
    currency,
    'productcard',
    'ProductCard',
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // Resort to default behavior if user is holding down the ctrl or meta key
      if (event.ctrlKey || event.metaKey) {
        return
      }
      // Prevent opening the product page on background when user clicks the card
      event.preventDefault()

      const pathname = basePath + product.slug
      if (openAs === 'overlay') {
        openModal({ type: 'PRODUCT', props: { product, products } })
        // Scroll modal to top
        scrollModal(0, 0)
      } else {
        router.push({ pathname })
      }
    },
    [product, products, openAs, openModal, basePath],
  )

  const title = formatNumbersInString(translatable(product.title), locale, product.game === 'squadbusters')
  const { isTimedOffer, countdownExpired } = useOfferCountdown({ product: product })
  const offerNotStarted =
    isStoreOfferProduct(product) && product.schedule !== null && product.schedule.purchaseStart > Date.now()

  return (
    <Card
      className={cx(styles.ProductCard, styles[type], { [styles.Discount]: product.offerData?.isDiscount })}
      shadowSize={size}
      href={`/${locale}${basePath}${product.slug}`}
      onClick={handleClick}
    >
      <ProductHeader
        className={styles.ProductHeader}
        game={game}
        size="Small"
        alt={translatable(product.title)}
        ribbonContent={ribbonContent}
        productImage={resolveProductImage(product)}
        variant={resolveProductBackgroundVariant(product)}
        data-cy={`product-header-${product.id}`}
      />
      <div className={styles.Footer} ref={snowplowViewImpressionRef}>
        <div className={styles.ProductContents}>
          <div className={styles.Title}>{title}</div>
          <ProductDescription
            product={product}
            className={styles.Description}
            align={size === 'Small' ? 'center' : 'left'}
            card
          />
        </div>
        <BuyButton
          disabled={(isTimedOffer && countdownExpired) || offerNotStarted}
          additionalClasses={styles.BuyButton}
          product={product}
          data-cy={`product-${product.id}`}
        />
        <OriginalPrice product={product} className={styles.OriginalPrice} />
      </div>
    </Card>
  )
}
