import React, { useState } from 'react'

import cx from 'classnames'
import Image from 'next/legacy/image'
import { useTranslations } from 'next-intl'

import ArcherImage from '@/assets/images/characters/archer-queen-autumn.png'
import { ExitButton } from '@/components/buttons/ExitButton'
import { StripedContainer } from '@/components/StripedContainer'
import { LOGIN_STATUS } from '@/features/user/userSlice'
import { useAppSelector } from '@/utils/useAppSelector'

import { useModalContext } from '../../../contexts/ModalContext'
import { LoginButton } from '../../header/LoginButton'

import SCIDLogo from 'assets/images/logos/supercell_id.svg'

import styles from './Login.module.scss'

interface LoginProps {
  onSuccess?: () => void
  customTitle?: string
}

const SCID: React.FC<{ close: () => void }> = ({ close }) => {
  const t = useTranslations()

  return (
    <StripedContainer containerClass={cx(styles.Container, styles.SCID)}>
      <ExitButton className={styles.ExitButton} onClick={close} />
      <div>
        <h1>{t('scid_modal_title')}</h1>
        <p>{t('scid_modal_desc')}</p>
        <h1>{t('scid_modal_how')}</h1>
        <p>{t('scid_modal_instruction_1')}</p>
        <p>{t('scid_modal_instruction_2')}</p>
        <p>{t('scid_modal_instruction_3')}</p>
        <p>{t('scid_modal_instruction_4')}</p>
        <p>{t('scid_modal_instruction_5')}</p>
      </div>
      <div className={styles.SCIDArcher}>
        <Image src={ArcherImage} alt="background-left" height={375} width={255} />
      </div>
    </StripedContainer>
  )
}

export const Login: React.FC<LoginProps> = ({ customTitle }) => {
  const { closeModal } = useModalContext()
  const loginStatus = useAppSelector((state) => state.user.loginStatus)
  const t = useTranslations()
  const [viewSCID, setViewSCID] = useState(false)

  const BottomText: React.FC = () => {
    return (
      <div className={styles.BottomTextContainer}>
        {loginStatus === LOGIN_STATUS.LOGGED_OUT ? (
          <div className={styles.LoginNewToSupercellID}>
            <p>{t('login_new')}</p>
            <p>
              {t.rich('login_register', {
                TextButton: (children) => (
                  <a href="#" onClick={() => setViewSCID(true)}>
                    {children}
                  </a>
                ),
              })}
            </p>
          </div>
        ) : null}
      </div>
    )
  }

  if (viewSCID) {
    return <SCID close={() => setViewSCID(false)} />
  }

  return (
    <StripedContainer containerClass={styles.Container}>
      <ExitButton className={styles.ExitButton} onClick={closeModal} data-cy="login-modal-close-btn" />
      <div className={styles.Logo}>
        <Image alt="logo" src={SCIDLogo} height={46} width={212} />
      </div>
      {customTitle ? <p className={styles.CustomTitle}>{customTitle}</p> : null}
      <LoginButton data-cy="header-login" />
      <BottomText />
    </StripedContainer>
  )
}
