import { useState } from 'react'

import { StoreBonusStatusResponse } from '@common/models/bonus'

import { BonusPoints } from './BonusPoints'
import { BonusTrack } from '../bonusTrack/BonusTrack'
import { BonusTrackHeader } from '../bonusTrack/BonusTrackHeader'

import styles from './bonusesAndPoints.module.scss'

export type BonusView = 'bonuses' | 'points'

interface BonusesAndPointsProps {
  bonusStatus: StoreBonusStatusResponse
  animationComplete?: boolean
}

export const BonusesAndPoints = ({ bonusStatus, animationComplete }: BonusesAndPointsProps) => {
  const [view, setView] = useState<BonusView>('bonuses')

  return (
    <div id="bonuses-points" className={styles.container}>
      <div className={styles.header}>
        <BonusTrackHeader onClick={setView} selectedView={view} />
      </div>

      {view === 'bonuses' && <BonusTrack bonusStatus={bonusStatus} animationComplete={animationComplete} />}
      {view === 'points' && <BonusPoints bonusStatus={bonusStatus} />}
    </div>
  )
}
