import { logger as baseLogger } from '@common/utils/logger'
import { Logger } from 'pino'

let logger: Logger = baseLogger

if (typeof window === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const requestContext = require('./requestContext').requestContext
  const ssrLogger = new Proxy(baseLogger, {
    get(target, property, receiver) {
      target = requestContext.getStore()?.get('logger') || target
      return Reflect.get(target, property, receiver)
    },
  })
  logger = ssrLogger
}

export default logger
