import { StoreMissions } from '@common/models/bonus'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import ChallengeBg from '@/assets/images/storefronts/clashroyale/bonus-challenge-daily-bg.png'
import ChallengeUncheckmark from '@/assets/images/storefronts/clashroyale/challenge-checkmark-uncompleted.svg'
import ChallengeCheckmark from '@/assets/images/storefronts/clashroyale/challenge-checkmark.svg'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusChallengeCR.module.scss'

type MissionRow = {
  missionId: string
  title: string
  isToday: boolean
  isCompleted: boolean
  rewardPoints?: number
}

interface BonusChallengeProps {
  mission: MissionRow
}

export function toMissionRow(
  missionLike: StoreMissions | { missionId: string; timestamp: number; rewardPoints: number },
) {
  if ('timestamp' in missionLike) {
    return [
      {
        missionId: missionLike.missionId,
        timestamp: missionLike.timestamp,
        rewardPoints: missionLike.rewardPoints,
        isCompleted: true,
      },
    ]
  } else {
    return missionLike.missions.map((mission) => ({
      missionId: mission.missionId,
      timestamp: missionLike.endTimeMillis,
      isCompleted: mission.current >= mission.target,
      rewardPoints: mission.rewardPoints,
    }))
  }
}

export const BonusChallengeCR = ({ mission }: BonusChallengeProps) => {
  const t = useTranslations()

  if (!mission) {
    return null
  }

  const isComplete = mission.isCompleted
  const pointsText = mission.rewardPoints
    ? `+` + t('bonus_number_of_points').replace('[n]', mission.rewardPoints.toString())
    : ''

  const helpSubTitle = isComplete ? t('mission_completed') : t('mission_tasks_info')

  return (
    <div className={styles.challengeContainer}>
      <div className={styles.challengeLeft}>
        <div className={cx(styles.challengeImage)}>
          <Image
            src={isComplete ? ChallengeCheckmark : ChallengeUncheckmark}
            alt="challenge-checkmark"
            width={40}
            height={40}
          />
        </div>
        <div className={styles.challengeItemsContainer}>
          <div className={styles.challengeTitle}>
            <StoreTypography tag="span" textSize="body" style="outlined">
              {mission.title}
            </StoreTypography>
            <span className={styles.challengeSubtitle}>{helpSubTitle}</span>
          </div>
          <div className={styles.challengeBgContainer}>
            <Image
              src={ChallengeBg}
              alt="bonus-challenge-background"
              fill
              sizes="(max-width: 600px) 50vw, 30vw"
              placeholder="empty"
            />
          </div>
        </div>
      </div>

      <div className={cx(styles.challengePoints, { [styles.plainTitle]: !isComplete })}>
        <StoreTypography tag="span" textSize="body" style={isComplete ? 'outlined' : 'plain'}>
          {pointsText}
        </StoreTypography>
      </div>
    </div>
  )
}
