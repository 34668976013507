import { MouseEventHandler, memo } from 'react'

import cx from 'classnames'

import base from './cardBase.module.scss'

type Props = React.PropsWithChildren<{
  offerContent?: boolean
  className?: string | string[]
  backgroundImage?: string
  href?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}>

export const CardBase: React.FC<Props> = memo(
  ({ offerContent = false, className: additionalClasses, backgroundImage, children, href, onClick }) => {
    if (offerContent) {
      return <div className={cx(base.cardBase, additionalClasses)}>{children}</div>
    }

    return (
      <a
        href={href}
        className={cx(base.cardBase, additionalClasses)}
        onClick={onClick}
        style={{ ...(backgroundImage && { backgroundImage: backgroundImage }) }}
      >
        {children}
      </a>
    )
  },
)

CardBase.displayName = 'CardBase'
