import React from 'react'

import { GameId, ProductV2 } from '@common'
import cx from 'classnames'
import Image from 'next/legacy/image'
import { useTranslations } from 'next-intl'

import { ProductCurrencyType, getProductCurrencyType, isSeasonPass } from '@/utils/products'

import { useTranslatable } from '../../localization/useTranslatable'
import { formatNumbersInString } from '../../utils/formatNumbersInString'
import { resolveProductCurrencyDescription } from '../common/useProducts'
import { useLocale } from '../locale/useLocale'

import BSGemIcon from 'assets/images/icons/bs_icon_gem.png'
import CocGemIcon from 'assets/images/icons/coc_icon_gem.png'
import CoinIcon from 'assets/images/icons/coin.png'
import CrGemIcon from 'assets/images/icons/cr_icon_gem.png'
import HdGemIcon from 'assets/images/icons/hd_icon_gem.png'
import SbGemIcon from 'assets/images/icons/sb_icon_gem.png'

import styles from './ProductDescription.module.scss'

const resolveProductIcon = (gameId: GameId, productType: ProductCurrencyType) => {
  if (productType === 'COIN') {
    return CoinIcon
  } else {
    switch (gameId) {
      case 'hayday':
        return HdGemIcon
      case 'clashroyale':
        return CrGemIcon
      case 'clashofclans':
        return CocGemIcon
      case 'brawlstars':
        return BSGemIcon
      case 'squadbusters':
        return SbGemIcon
    }
  }
}

const ProductDescription: React.FC<{
  product: ProductV2
  tag?: 'h2' | 'p'
  card?: boolean
  className?: string | string[]
  align?: 'left' | 'center' | 'right'
}> = ({ product, tag = 'p', className: additionalClasses, align = 'left', card }) => {
  const translatable = useTranslatable()
  const locale = useLocale()
  const t = useTranslations()

  if (isSeasonPass(product)) {
    const title = translatable(product.title)
    const description = t('product_amount', {
      amount: product.contentCounts.SEASON_PASS,
      title: title,
    })
    return (
      <div className={cx(styles.Description, additionalClasses, styles[align])}>
        {React.createElement(tag, undefined, description)}
      </div>
    )
  }

  const productType = getProductCurrencyType(product)

  if (!productType) {
    return null
  }

  const description = resolveProductCurrencyDescription(product, productType)

  if (!description) {
    return null
  }

  return (
    <div className={cx(styles.CurrencyDescription, additionalClasses, { [styles.Card]: card })}>
      <div className={cx(styles.Row, styles[align])}>
        <div className={styles.Icon}>
          <Image
            src={resolveProductIcon(product.game, productType)}
            alt={productType}
            layout="fill"
            objectFit="contain"
            quality={100}
            sizes="30px"
          />
        </div>
        <p>{formatNumbersInString(description.toString(), locale, product.game === 'squadbusters')}</p>
      </div>
    </div>
  )
}

export default ProductDescription
