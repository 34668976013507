import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { ExitButton } from '@/components/buttons/ExitButton'
import { StripedContainer } from '@/components/StripedContainer'

import { useModalContext } from '../../contexts/ModalContext'

import styles from './OfferExpired.module.scss'

export const OfferExpired = ({ expired }: { expired?: boolean }) => {
  const t = useTranslations()
  const router = useRouter()
  const { closeModal } = useModalContext()

  const onClose = () => {
    if (router.query.notFound) {
      router.replace(router.pathname, undefined, { shallow: true })
    }
    closeModal()
  }

  return (
    <StripedContainer containerClass={styles.Container}>
      <ExitButton data-cy="button-close-unavailable-modal" className={styles.ExitButton} onClick={onClose} />
      <h3 className={styles.Headline}>{expired ? t('OTHER_OFFER_EXPIRED_txt') : t('wf_offer_404_modal_title')}</h3>
      <p className={styles.BodyText}>{t('wf_offer_404_modal_body')}</p>
    </StripedContainer>
  )
}
