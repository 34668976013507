import React from 'react'

import { RoundButton } from '@/components/buttons/RoundButton'
import { ExitIcon } from '@/components/Icons/ExitIcon'
import { GameStoreProduct } from '@/features/storefronts/_storeBase/productPage/GameStoreProduct'

import { GameId, ProductV2 } from '../../../../common/src'

import styles from './ProductOverlay.module.scss'

type Props = {
  product: ProductV2
  closeModal: () => void
  enableNewVisuals?: GameId[]
}

export function ProductOverlayV2({ product, closeModal, enableNewVisuals }: Props) {
  return (
    <div className={styles.Content}>
      <RoundButton additionalClasses={styles.CloseButton} onClick={closeModal} data-cy="product-modal-close-button">
        <ExitIcon />
      </RoundButton>

      <GameStoreProduct
        product={product}
        isProductPage={false}
        closeModal={closeModal}
        enableNewVisuals={enableNewVisuals}
      />
    </div>
  )
}
