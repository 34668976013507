import React from 'react'

import { supportedLanguages } from '@common'
import { AnimatePresence } from 'framer-motion'
import { useTranslations } from 'next-intl'

import { IconButton } from '@/components/buttons/IconButton'
import { CartButtonIcon, Cross } from '@/components/Icons'
import { CartItem } from '@/utils/cart'

import { EligibilityCopy } from './EligibilityCopy'
import { useLocale } from '../../../locale/useLocale'

import styles from './CartHeader.module.scss'

type Props = {
  items: CartItem[]
  ineligibleItem?: {
    product: CartItem['product']
    reason?: string
  }
  onClose: () => void
}

export function CartHeader({ items, ineligibleItem, onClose }: Props) {
  const t = useTranslations()
  const locale = useLocale()

  const itemCount = items.reduce((acc, item) => acc + item.quantity, 0)
  const formattedItemCount = new Intl.NumberFormat(supportedLanguages[locale].locale).format(Math.min(itemCount, 99))

  return (
    <div className={styles.HeaderContent}>
      <div className={styles.Header}>
        <div>
          <CartButtonIcon />
          <p>{t.rich('cart_items', { count: itemCount, num: formattedItemCount })}</p>
        </div>
        <IconButton className={styles.HeaderCloseButton} onClick={onClose} data-cy="cart-button-close">
          <Cross height={32} width={32} />
        </IconButton>
      </div>
      <div className={styles.HeaderEligibility}>
        <AnimatePresence>{ineligibleItem ? <EligibilityCopy ineligibleItem={ineligibleItem} /> : null}</AnimatePresence>
      </div>
    </div>
  )
}
