import { useEffect, useState } from 'react'

import { GameId } from '@common'
import Script from 'next/script'

import { useLocale } from './locale/useLocale'
import { config } from '../../config'
import { useMarketContext } from '../contexts/MarketContext'
import { useAppSelector } from '../utils/useAppSelector'

const HELPSHIFT_DOMAIN = config.HELPSHIFT_DOMAIN
const HELPSHIFT_PLATFORM_ID = config.HELPSHIFT_PLATFORM_ID
const BASE_CONFIG =
  HELPSHIFT_DOMAIN && HELPSHIFT_PLATFORM_ID ? { domain: HELPSHIFT_DOMAIN, platformId: HELPSHIFT_PLATFORM_ID } : null

type HelpshiftBaseConfig = Exclude<typeof BASE_CONFIG, null>

const gamedIdToHelpshiftGameCode = (gameId: GameId) => {
  switch (gameId) {
    case 'clashofclans':
      return 'CC'
    case 'brawlstars':
      return 'BS'
    case 'clashroyale':
      return 'CR'
    case 'squadbusters':
      return 'SB'
    case 'hayday':
      return 'HD'
    default:
      throw new Error('Invalid game id')
  }
}

const storeLocaleToHelpshiftLocale = (locale: ReturnType<typeof useLocale>) => {
  switch (locale) {
    case 'en':
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'ru':
    case 'pt':
    case 'ko':
    case 'ja':
    case 'tr':
    case 'nl':
    case 'id':
    case 'th':
    case 'vi':
    case 'ar':
    case 'pl':
    case 'no':
    case 'sv':
    case 'fi':
    case 'da':
    case 'ms':
    case 'fa':
      return [locale, locale.toUpperCase()] as const
    case 'zh-cn':
      return ['zh-Hans', 'CN'] as const
    case 'zh-tw':
      return ['zh-Hant', 'CNT'] as const
    default:
      return ['en', 'EN'] as const
  }
}

const HelpshiftChat = ({ domain, platformId }: HelpshiftBaseConfig) => {
  const helpshift = useAppSelector((state) => state.user.helpshift)
  const gameStoresEnabled = useAppSelector((state) => state.config.storefront)
  const userId = useAppSelector((state) => state.user.profile?.accountId)
  const locale = storeLocaleToHelpshiftLocale(useLocale())
  const [ready, setReady] = useState(false)
  const gameId = useAppSelector((state) => state.common.currentGame) as GameId
  const { countryCode } = useMarketContext('HelpshiftChat')

  useEffect(() => {
    if (!ready || !helpshift || !gameStoresEnabled) return
    window.helpshiftConfig.widgetOptions.showLauncher = false
    window.Helpshift?.('updateHelpshiftConfig')
  }, [helpshift, ready, gameStoresEnabled])

  useEffect(() => {
    if (helpshift && userId && ready && gameId && countryCode) {
      window.helpshiftConfig.userAuthToken = helpshift.userAuthToken
      window.helpshiftConfig.userName = helpshift.name
      window.helpshiftConfig.userId = userId
      window.helpshiftConfig.language = locale[0]
      window.Helpshift?.('setCustomIssueFields', {
        ig_language: locale[1],
        supercell_id: userId,
        game: { type: 'dropdown', value: gamedIdToHelpshiftGameCode(gameId) },
        country_code: { type: 'singleline', value: countryCode },
      })
    } else {
      window.helpshiftConfig.userAuthToken = ''
      window.helpshiftConfig.userName = ''
      window.helpshiftConfig.userId = ''
      window.Helpshift?.('setCustomIssueFields', {})
    }
    window.Helpshift?.('updateHelpshiftConfig')
  }, [helpshift, userId, locale, ready, gameId, countryCode])

  return (
    <Script id="helpshift" onReady={() => setReady(true)}>
      {`(function () {
        var PLATFORM_ID = "${platformId}",
            DOMAIN = "${domain}";
        window.helpshiftConfig = {
          platformId: PLATFORM_ID,
          domain: DOMAIN,
          widgetOptions: {
            showLauncher: false, 
          }
        };
      }) ();
      !function(t,e){if("function"!=typeof window.Helpshift){var n=function(){n.q.push(arguments)};n.q=[],window.Helpshift=n;var i,a=t.getElementsByTagName("script")[0];if(t.getElementById(e))return;i=t.createElement("script"),i.async=!0,i.id=e,i.src="https://webchat.helpshift.com/webChat.js";var o=function(){window.Helpshift("init")};window.attachEvent?i.attachEvent("onload",o):i.addEventListener("load",o,!1),a.parentNode.insertBefore(i,a)}else window.Helpshift("update")}(document,"hs-chat");`}
    </Script>
  )
}

export const Helpshift = () => {
  if (!BASE_CONFIG) {
    return null
  }
  return <HelpshiftChat domain={BASE_CONFIG.domain} platformId={BASE_CONFIG.platformId} />
}
