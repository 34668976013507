import React, { useCallback } from 'react'

import { GameId, isStoreOfferProduct, ProductV2, StoreOfferProduct } from '@common'
import cx from 'classnames'
import Image from 'next/image'

import BrawlPassCardBg from '@/assets/images/storefronts/brawlstars/brawl-pass-bg.png'
import { scrollModal } from '@/components/Modal'
import { useMarketContext } from '@/contexts/MarketContext'
import { useModalContext } from '@/contexts/ModalContext'
import { BuyButton } from '@/features/storefronts/_storeBase/BuyButton/BuyButton'
import { getRibbonsFromTags } from '@/features/storefronts/_storeBase/card/v2/OfferCard'
import { Ribbon, Ribbons } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/Ribbons'
import { Countdown, Tags, ValueBadge } from '@/features/storefronts/_storeBase/card/v2/shared'
import { getButtonVariant } from '@/features/storefronts/_storeBase/card/v2/shared/Footer'
import { Flare } from '@/features/storefronts/_storeBase/flare/Flare'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { useTranslatable } from '@/localization/useTranslatable'
import { resolveProductImage } from '@/utils/resolveProductImage'

import { StoreTypography } from '../../../_storeBase/typography/StoreTypography'

import styles from './BrawlPassCard.module.scss'

interface PassCardProps {
  product: ProductV2
  productPage?: boolean
  enableNewVisuals?: GameId[]
}
export const BrawlPassCard: React.FC<PassCardProps> = ({ product }) => {
  return (
    <>
      <div className={styles.BgImageContainer}>
        <Image src={BrawlPassCardBg} alt="passcard-background" fill quality={100} sizes="360px" objectFit={'cover'} />
      </div>
      <Flare game={'brawlstars'} />
      <div className={styles.ProductImageContainer}>
        <div className={styles.ProductImage}>
          <Image
            className={styles.PassImage}
            src={resolveProductImage(product)}
            fill
            sizes={'360px'}
            quality={100}
            alt="passcard-background"
            placeholder="empty"
          />
        </div>
      </div>
    </>
  )
}

export const BrawlPassCardItem: React.FC<PassCardProps> = ({ product, enableNewVisuals }) => {
  const translatable = useTranslatable()
  const gameSlug = useGameSlug()
  const market = useMarketContext('BrawlPass')

  const { openModal } = useModalContext()
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
      // Resort to default behavior if user is holding down the ctrl or meta key
      console.log('handling')
      if (event.ctrlKey || event.metaKey) {
        return
      }
      // Prevent opening the product page on background when user clicks the card
      event.preventDefault()

      openModal({ type: 'PRODUCT', props: { product, enableNewVisuals } })
      // Scroll modal to top
      scrollModal(0, 0)
    },
    [product, openModal, enableNewVisuals],
  )
  const pathname = `/${gameSlug}/product/${product.slug}`

  const isSeasonPass = !!product.contentCounts.SEASON_PASS

  let ribbons: Ribbon[] = []
  if (isStoreOfferProduct(product) && product.tags !== undefined) {
    ribbons = getRibbonsFromTags(product)
  }
  return isSeasonPass ? (
    <a href={pathname} className={styles.PassCard} onClick={handleClick}>
      <div className={styles.PassContainer}>
        <BrawlPassCard product={product} />
        <Countdown product={product as StoreOfferProduct} />
        {ribbons.length === 0 ? <ValueBadge product={product as StoreOfferProduct} /> : <Ribbons ribbons={ribbons} />}
        <StoreTypography tag="h3" textSize="md" style="outlined" className={styles.Title}>
          {translatable(product.title, 'title')}
        </StoreTypography>
        <div className={cx(styles.Footer, styles[market.currencyCode])}>
          <Tags product={product} />
          <BuyButton
            style={{ height: '48px' }}
            variant={getButtonVariant(product)}
            product={product}
            data-cy={`bundle-${product.id}`}
          />
        </div>
      </div>
    </a>
  ) : null
}
