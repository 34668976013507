import { isSquadCoinProduct, StoreOfferProduct } from '@common'
import cx from 'classnames'

import { SquadBustersHeading } from './components/SquadBustersHeading'
import { SquadBustersOfferCardContent } from './components/SquadBustersOfferCardContent'
import { SquadPattern } from './components/SquadPattern'
import { Countdown, Footer, ValueBadge } from '../../../shared'
import { Container } from '../../shared/Container'
import { Ribbon, Ribbons } from '../../shared/Ribbons'

import styles from './SquadBustersCard.module.scss'

type Props = {
  product: StoreOfferProduct
  ribbons: Ribbon[]
}

export function SquadBustersOfferCard({ product, ribbons }: Props) {
  const isCoinProduct = isSquadCoinProduct(product)
  const containerStyles = cx(styles.Container, isCoinProduct ? styles.CoinBg : styles.ItemBg)
  return (
    <Container product={product} className={containerStyles}>
      <SquadPattern />
      <Countdown product={product as StoreOfferProduct} />
      {ribbons.length === 0 ? <ValueBadge product={product} /> : <Ribbons ribbons={ribbons} />}
      <SquadBustersHeading product={product} />
      <SquadBustersOfferCardContent product={product} />
      <Footer product={product} />
    </Container>
  )
}
