import type { SCIDTranslatable, Translatable } from './translatable'
import { type ApplicationEnv } from '../utils/application'
import { GameId, gameIdSchema } from './game'
import { PRODUCT_CONFIGURATION } from './imported/productConfiguration'
import { columnTypes } from './db'
import { isSupportedLanguage } from './countries'

import z from 'zod'
import { SCIDProductContent } from './offer'

export type BillingPackageId = keyof typeof PRODUCT_CONFIGURATION
export type BillingPackage = Record<columnTypes.CurrencyCountry, number>
export type BillingPackages = Record<BillingPackageId, (typeof PRODUCT_CONFIGURATION)[BillingPackageId]>

type BaseProductV2<T = Translatable<string>> = {
  id: string
  description: T
  images: string[]
  title: T
}

export const itemTierSchema = z.union([
  z.literal('DEFAULT'),
  z.literal('BASIC'),
  z.literal('PREMIUM'),
  z.literal('EVENT'),
])

export type ItemTier = z.infer<typeof itemTierSchema>

export const contentCountsMapSchema = z
  .object({
    GEM: z.number().int(),
    COIN: z.number().int(),
    SEASON_PASS: z.number().int(),
    ITEM: z.number().int(),
  })
  .partial()

type ContentCountsMap = z.infer<typeof contentCountsMapSchema>

export type ProductContentType = keyof ContentCountsMap

export type SCIDProduct = BaseProductV2<SCIDTranslatable<string>> & {
  billingPackageId: BillingPackageId
  application: ApplicationEnv
  contentCounts: ContentCountsMap
  contents: SCIDProductContent[]
  available?: boolean
  tags?: string[]
  priority?: number
}

export enum ProductAvailability {
  AVAILABLE,
  UNAVAILABLE,
  UNKNOWN,
}

export type Prices = Partial<Record<columnTypes.CurrencyCountry, number>>

export type BaseOffer = {
  isDiscount: boolean
  isBestDeal: boolean
  priority: number
  expiresAt: number | null
}

export type DiscountUnion =
  | {
      discountType: 'simple'
    }
  | { discountType: 'percentage'; percentage: number; originalPrice: Prices }
  | { discountType: 'bonus'; bonusAmount: number; percentage: number }

export type SimpleOffer = BaseOffer & {
  discountType: 'simple'
}

export type PercentageOffer = BaseOffer & {
  discountType: 'percentage'
  percentage: number
  originalPrice: Prices
}

export type BonusOffer = BaseOffer & {
  discountType: 'bonus'
  bonusAmount: number
  percentage: number
}

export type Offer = SimpleOffer | PercentageOffer | BonusOffer

export type ProductContent = {
  product: {
    id: string
    title: Translatable<string>
    description: Translatable<string>
    type: ProductContentType
    itemType: string | null
    tier: ItemTier
  }
  amount: number
}

export interface ProductSchedule {
  visibilityStart: number
  visibilityEnd: number
  purchaseStart: number
  purchaseEnd: number
}

export type ProductBonus = {
  baseBonus: number
  totalBonus: number
  modifier?: {
    type: 'add'
    value: number
  }
}

export type BrawlStarsChainOffer = {
  chain: string
  index: number
}

export type ProductV2 = BaseProductV2 & {
  prices: Prices
  game: GameId
  contentCounts: ContentCountsMap
  contents: ProductContent[]
  availability: ProductAvailability
  slug: string
  hash: string
  bonus?: number
  bonusData?: ProductBonus
  stampCard?: {
    balance: number
    backfilledStamps: number
    cardNumber: number
  }
  priority: number
} & (
    | { type: 'product'; offerData: Offer | null }
    | {
        type: 'offer'
        offerData: Offer | null
        schedule: ProductSchedule | null
        tags?: string[]
        quotaConsumed?: number
        quotaLimit?: number
        brawlStarsChainOffer: BrawlStarsChainOffer | null
      }
  )

export type StoreOfferProduct = Extract<ProductV2, { type: 'offer' }>

const currencyCountryToPriceSchema = z.record(z.number().int().gt(0))
export const pricesRecordSchema = z.record(currencyCountryToPriceSchema)

export const priceSchema = z.object({
  sku: z.string(),
  price: z.number().int().gt(0),
})
export type Price = z.infer<typeof priceSchema>

export const dbPriceSchema = z.object({
  sku: z.string(),
  countryCode: z.string(),
  currencyCode: z.string(),
  price: z.number().int().gt(0),
})

export type DBPrice = z.infer<typeof dbPriceSchema>

export const productsRequestSchema = z.object({
  locale: z.string().refine(isSupportedLanguage),
  gameId: gameIdSchema,
  viewerCountry: columnTypes.countryCodeSchema.nullable().transform((value, ctx) => {
    if (value === null) {
      ctx.addIssue({
        code: 'invalid_type',
        expected: 'string',
        received: 'null',
      })
      return z.NEVER
    }
    return value
  }),
  includePurchased: z.string().optional(),
})

export const offersRequestSchema = productsRequestSchema.extend({
  tag: z.string().optional(),
  offerId: z.string().optional(),
})

export const bonusSchema = z.object({
  baseBonus: z.number().int(),
  totalBonus: z.number().int(),
  modifier: z
    .object({
      type: z.literal('add'),
      value: z.number().int(),
    })
    .optional(),
})

export type ProductsRequest = z.input<typeof productsRequestSchema>
