import { GameId } from '@common'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import BsEmptyCartBackground from '@/assets/images/brawlstars/bs-empty-cart-bg.png'
import CocEmptyCartBackground from '@/assets/images/clashofclans/coc-empty-cart-bg.png'
import CrEmptyCartBackground from '@/assets/images/clashroyale/cr-empty-cart-bg.png'
import HdEmptyCartBackground from '@/assets/images/hayday/hd-empty-cart-bg.png'
import SbEmptyCartBackground from '@/assets/images/squadbusters/sb-empty-cart-bg.png'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './EmptyCart.module.scss'

type Props = {
  game: GameId
}

export function EmptyCart(props: Props) {
  const t = useTranslations()
  const cartBackground = emptyCartBackgrounds[props.game]

  return (
    <div className={styles.EmptyCart}>
      <StoreTypography tag="p" style="plain" textSize="xl" overrideFont="supercellHeadline" lineHeight="normal">
        {t('summary_no_products')}
      </StoreTypography>
      <div className={styles.EmptyCartBackground}>
        {cartBackground ? (
          <Image src={cartBackground} alt="empty cart" width={390} style={{ objectFit: 'cover' }} />
        ) : null}
      </div>
    </div>
  )
}

const emptyCartBackgrounds = {
  clashofclans: CocEmptyCartBackground,
  brawlstars: BsEmptyCartBackground,
  clashroyale: CrEmptyCartBackground,
  hayday: HdEmptyCartBackground,
  squadbusters: SbEmptyCartBackground,
} as const
