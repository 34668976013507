import { ProductV2 } from '@common'
import Image from 'next/image'

import { getHeroFallbackImage } from '@/features/storefronts/_storeBase/card/v2/shared/RegularHero'

import styles from './SquadBustersHero.module.scss'

type Props = {
  product: ProductV2
}

export function SquadBustersHero({ product }: Props) {
  const heroImage = product.images.at(0) ?? getHeroFallbackImage(product.game)

  if (!heroImage) {
    return null
  }

  return (
    <div className={styles.SquadBustersHero}>
      <Image className={styles.HeroImage} src={heroImage} alt="Squad Busters Hero" width={200} height={160} />
    </div>
  )
}
