import { isSquadCoinProduct, ProductV2 } from '@common'
import cx from 'classnames'
import { useLocale } from 'next-intl'

import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useTranslatable } from '@/localization/useTranslatable'

import styles from './SquadBustersHeading.module.scss'

type Props = {
  product: ProductV2
}

export function SquadBustersHeading({ product }: Props) {
  const translatable = useTranslatable()
  const locale = useLocale()

  if (isSquadCoinProduct(product)) {
    return null
  }

  const title = product.contents.length === 1 ? product.contents[0].product.title : product.title
  const cardTitle = translatable(title, 'Offer')

  return (
    <div className={styles.Container}>
      <div className={styles.RowContainer}>
        <StoreTypography tag="h3" style="outlined" textSize="md" className={cx(styles.Title, styles[locale])}>
          {cardTitle}
        </StoreTypography>
      </div>
    </div>
  )
}
