import { SupportedLanguage } from '@common'

import AppStoreBadge_AR from 'assets/images/logos/app-store_badge_ar.svg'
import AppStoreBadge_DA from 'assets/images/logos/app-store_badge_da.svg'
import AppStoreBadge_DE from 'assets/images/logos/app-store_badge_de.svg'
import AppStoreBadge_EN from 'assets/images/logos/app-store_badge_en.svg'
import AppStoreBadge_ES from 'assets/images/logos/app-store_badge_es.svg'
import AppStoreBadge_FA from 'assets/images/logos/app-store_badge_fa.svg'
import AppStoreBadge_FI from 'assets/images/logos/app-store_badge_fi.svg'
import AppStoreBadge_FR from 'assets/images/logos/app-store_badge_fr.svg'
import AppStoreBadge_HE from 'assets/images/logos/app-store_badge_he.svg'
import AppStoreBadge_ID from 'assets/images/logos/app-store_badge_id.svg'
import AppStoreBadge_IT from 'assets/images/logos/app-store_badge_it.svg'
import AppStoreBadge_JA from 'assets/images/logos/app-store_badge_ja.svg'
import AppStoreBadge_KO from 'assets/images/logos/app-store_badge_ko.svg'
import AppStoreBadge_MS from 'assets/images/logos/app-store_badge_ms.svg'
import AppStoreBadge_NL from 'assets/images/logos/app-store_badge_nl.svg'
import AppStoreBadge_NO from 'assets/images/logos/app-store_badge_no.svg'
import AppStoreBadge_PL from 'assets/images/logos/app-store_badge_pl.svg'
import AppStoreBadge_PT from 'assets/images/logos/app-store_badge_pt.svg'
import AppStoreBadge_RU from 'assets/images/logos/app-store_badge_ru.svg'
import AppStoreBadge_SV from 'assets/images/logos/app-store_badge_sv.svg'
import AppStoreBadge_TH from 'assets/images/logos/app-store_badge_th.svg'
import AppStoreBadge_TR from 'assets/images/logos/app-store_badge_tr.svg'
import AppStoreBadge_VI from 'assets/images/logos/app-store_badge_vi.svg'
import AppStoreBadge_ZH_CN from 'assets/images/logos/app-store_badge_zh-cn.svg'
import AppStoreBadge_ZH_TW from 'assets/images/logos/app-store_badge_zh-tw.svg'
import GooglePlayBadge_AR from 'assets/images/logos/google-play-badge_ar.png'
import GooglePlayBadge_DA from 'assets/images/logos/google-play-badge_da.png'
import GooglePlayBadge_DE from 'assets/images/logos/google-play-badge_de.png'
import GooglePlayBadge_EN from 'assets/images/logos/google-play-badge_en.png'
import GooglePlayBadge_ES from 'assets/images/logos/google-play-badge_es.png'
import GooglePlayBadge_FA from 'assets/images/logos/google-play-badge_fa.png'
import GooglePlayBadge_FI from 'assets/images/logos/google-play-badge_fi.png'
import GooglePlayBadge_FR from 'assets/images/logos/google-play-badge_fr.png'
import GooglePlayBadge_HE from 'assets/images/logos/google-play-badge_he.png'
import GooglePlayBadge_ID from 'assets/images/logos/google-play-badge_id.png'
import GooglePlayBadge_IT from 'assets/images/logos/google-play-badge_it.png'
import GooglePlayBadge_JA from 'assets/images/logos/google-play-badge_ja.png'
import GooglePlayBadge_KO from 'assets/images/logos/google-play-badge_ko.png'
import GooglePlayBadge_MS from 'assets/images/logos/google-play-badge_ms.png'
import GooglePlayBadge_NL from 'assets/images/logos/google-play-badge_nl.png'
import GooglePlayBadge_NO from 'assets/images/logos/google-play-badge_no.png'
import GooglePlayBadge_PL from 'assets/images/logos/google-play-badge_pl.png'
import GooglePlayBadge_PT from 'assets/images/logos/google-play-badge_pt.png'
import GooglePlayBadge_RU from 'assets/images/logos/google-play-badge_ru.png'
import GooglePlayBadge_SV from 'assets/images/logos/google-play-badge_sv.png'
import GooglePlayBadge_TH from 'assets/images/logos/google-play-badge_th.png'
import GooglePlayBadge_TR from 'assets/images/logos/google-play-badge_tr.png'
import GooglePlayBadge_VI from 'assets/images/logos/google-play-badge_vi.png'
import GooglePlayBadge_ZH_CN from 'assets/images/logos/google-play-badge_zh-cn.png'
import GooglePlayBadge_ZH_TW from 'assets/images/logos/google-play-badge_zh-tw.png'

export const localizedAppStoreButtons: Record<
  SupportedLanguage,
  { appStore: typeof AppStoreBadge_EN; playBadge: typeof GooglePlayBadge_EN }
> = {
  en: {
    appStore: AppStoreBadge_EN,
    playBadge: GooglePlayBadge_EN,
  },
  de: {
    appStore: AppStoreBadge_DE,
    playBadge: GooglePlayBadge_DE,
  },
  fi: {
    appStore: AppStoreBadge_FI,
    playBadge: GooglePlayBadge_FI,
  },
  fr: {
    appStore: AppStoreBadge_FR,
    playBadge: GooglePlayBadge_FR,
  },
  it: {
    appStore: AppStoreBadge_IT,
    playBadge: GooglePlayBadge_IT,
  },
  es: {
    appStore: AppStoreBadge_ES,
    playBadge: GooglePlayBadge_ES,
  },
  nl: {
    appStore: AppStoreBadge_NL,
    playBadge: GooglePlayBadge_NL,
  },
  pt: {
    appStore: AppStoreBadge_PT,
    playBadge: GooglePlayBadge_PT,
  },
  ja: {
    appStore: AppStoreBadge_JA,
    playBadge: GooglePlayBadge_JA,
  },
  'zh-cn': {
    appStore: AppStoreBadge_ZH_CN,
    playBadge: GooglePlayBadge_ZH_CN,
  },
  'zh-tw': {
    appStore: AppStoreBadge_ZH_TW,
    playBadge: GooglePlayBadge_ZH_TW,
  },
  da: {
    appStore: AppStoreBadge_DA,
    playBadge: GooglePlayBadge_DA,
  },
  ko: {
    appStore: AppStoreBadge_KO,
    playBadge: GooglePlayBadge_KO,
  },
  ms: {
    appStore: AppStoreBadge_MS,
    playBadge: GooglePlayBadge_MS,
  },
  no: {
    appStore: AppStoreBadge_NO,
    playBadge: GooglePlayBadge_NO,
  },
  pl: {
    appStore: AppStoreBadge_PL,
    playBadge: GooglePlayBadge_PL,
  },
  ru: {
    appStore: AppStoreBadge_RU,
    playBadge: GooglePlayBadge_RU,
  },
  sv: {
    appStore: AppStoreBadge_SV,
    playBadge: GooglePlayBadge_SV,
  },
  th: {
    appStore: AppStoreBadge_TH,
    playBadge: GooglePlayBadge_TH,
  },
  tr: {
    appStore: AppStoreBadge_TR,
    playBadge: GooglePlayBadge_TR,
  },
  vi: {
    appStore: AppStoreBadge_VI,
    playBadge: GooglePlayBadge_VI,
  },
  id: {
    appStore: AppStoreBadge_ID,
    playBadge: GooglePlayBadge_ID,
  },
  he: {
    appStore: AppStoreBadge_HE,
    playBadge: GooglePlayBadge_HE,
  },
  fa: {
    appStore: AppStoreBadge_FA,
    playBadge: GooglePlayBadge_FA,
  },
  ar: {
    appStore: AppStoreBadge_AR,
    playBadge: GooglePlayBadge_AR,
  },
}
