import cx from 'classnames'

import styles from './RoundButton.module.scss'

export type Props = React.PropsWithChildren<{
  additionalClasses?: string | string[]
  content?: string
  'data-cy'?: string
  onClick: () => void
}>

export const RoundButton: React.FC<Props> = ({ additionalClasses, content, onClick, 'data-cy': dataCy, children }) => {
  return (
    <button className={cx(styles.RoundButton, additionalClasses)} onClick={onClick} data-cy={dataCy}>
      {content ? content : children}
    </button>
  )
}
