import { GameId, StoreOfferProduct } from '@common'

import { BrawlValueIndicator } from '../valueIndicator/BrawlValueIndicator'
import { ClashValueIndicator } from '../valueIndicator/ClashValueIndicator'
import { RoyaleValueIndicator } from '../valueIndicator/RoyaleValueIndicator'

type BundleProps = {
  children?: React.ReactNode
  game: GameId
  offerProduct: StoreOfferProduct
  variant?: 'catalogOffer' | 'bundle'
}

export const defaultTagValueExtractor = (valueTagStrings: string[]) => (product: { tags?: string[] }) =>
  product?.tags
    ?.find((tag) => valueTagStrings.some((t) => tag.includes(t)))
    ?.split(':')
    .filter(Boolean)
    .pop()

export const valueTagExtractors: Record<GameId, (product: { tags?: string[] }) => string | undefined> = {
  clashofclans: defaultTagValueExtractor(['magic:value:']),
  clashroyale: defaultTagValueExtractor(['scroll:multiplier:']),
  brawlstars: (product) => {
    // Brawl Stars have the tag defined as "laser:tagType:multiplier" and "laser:tagValue:VALUE"
    if (product.tags?.some((a) => a === 'laser:tagType:multiplier')) {
      const multiplierTag = product.tags.find((tag) => tag.includes('laser:tagValue:'))
      return multiplierTag?.split(':').filter(Boolean).pop()
    }
    return undefined
  },
  squadbusters: () => '3', //TODO: defaultTagValueExtractor(['squad:value:']),
  hayday: () => undefined,
} as const

export const OfferValueBadge = ({ offerProduct: product, game, variant }: BundleProps) => {
  const offerValue = Number(valueTagExtractors[game](product))
  const hasValue = !isNaN(offerValue) && offerValue > 0

  const isCatalogOffer = variant === 'catalogOffer'
  const isBundle = variant === 'bundle'

  if (!hasValue) {
    return null
  }

  if (game === 'brawlstars') {
    return <BrawlValueIndicator value={offerValue} variant={isCatalogOffer ? 'catalogOffer' : undefined} />
  }

  if (game === 'clashroyale') {
    return <RoyaleValueIndicator value={offerValue} />
  }

  return <ClashValueIndicator value={offerValue} variant={isBundle ? 'bundle' : undefined} />
}

OfferValueBadge.displayName = 'OfferValueBadge'
