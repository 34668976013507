import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import ClashImg from '@/assets/images/components/ValueBadge/coc-value-indicator.png'
import { useLocale } from '@/features/locale/useLocale'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'

import styles from './ClashValueBadge.module.scss'

type Props = {
  value: string
}

export const ClashValueBadge = ({ value }: Props) => {
  const t = useTranslations()
  const locale = useLocale()
  return (
    <div className={styles.ClashValueBadge}>
      <Image src={ClashImg} alt="" width={19} height={30} />
      <div className={styles.TextContainer}>
        <StoreTypography tag="div" textSize="md" style="outlined" lineHeight="tight" className={styles.Value}>
          {value}
          <StoreTypography tag="span" textSize="sm" style="outlined" lineHeight="tight" className={styles.Multiplier}>
            x
          </StoreTypography>
        </StoreTypography>
        <StoreTypography
          tag="div"
          textSize="sm"
          style="outlined"
          lineHeight="tight"
          className={cx(styles.ValueText, styles[locale])}
        >
          {t('value')}
        </StoreTypography>
      </div>
    </div>
  )
}
