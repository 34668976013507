import { ProductV2, STORE_SPECIAL, isStoreOfferProduct } from '@common'
import cx from 'classnames'

import { useMarketContext } from '@/contexts/MarketContext'
import { useOfferCountdown } from '@/features/products/useOfferCountdown'
import { BuyButton } from '@/features/storefronts/_storeBase/BuyButton/BuyButton'

import { Tags } from '../Tags'

import styles from './Footer.module.scss'

type Props = {
  product: ProductV2
  className?: string
}

const storeSpecialButtonVariants = {
  clashofclans: 'Dark',
  clashroyale: 'Light',
  squadbusters: 'LightBordered',
  brawlstars: 'LightBordered',
  hayday: 'Light',
} as const

export function getButtonVariant(product: ProductV2) {
  const isStoreSpecial = isStoreOfferProduct(product) && product.tags?.includes(STORE_SPECIAL)

  if (isStoreSpecial) {
    return storeSpecialButtonVariants[product.game]
  }

  /** OfferCard variants. */
  switch (product.game) {
    case 'clashofclans':
    case 'clashroyale':
      return 'Dark'
    case 'squadbusters':
    case 'brawlstars':
      if (product.contents.length > 1) {
        return 'LightBordered'
      }
      return 'DarkBordered'
    default:
      return 'Light'
  }
}

export const Footer = ({ product, className }: Props) => {
  const { isTimedOffer, countdownExpired } = useOfferCountdown({ product: product })
  const market = useMarketContext('Footer')
  const offerNotStarted =
    isStoreOfferProduct(product) && product.schedule !== null && product.schedule.purchaseStart > Date.now()
  const disabled = (isTimedOffer && countdownExpired) || offerNotStarted

  return (
    <div className={cx(styles.Footer, className, styles[market.currencyCode])}>
      <Tags product={product} />
      <BuyButton
        style={{ height: '48px' }}
        variant={getButtonVariant(product)}
        disabled={disabled}
        product={product}
        data-cy={`bundle-${product.id}`}
      />
    </div>
  )
}
