import { useCallback, useEffect, useMemo, useState } from 'react'
interface Size {
  width: number | undefined
  height: number | undefined
}

export type BREAKPOINTS =
  | 'mobile'
  | 'laptop-small'
  | 'tablet'
  | 'desktop'
  | 'unknown'
  | 'laptop'
  | 'tablet-small'
  | 'mobile-regular'

type Breakpoint = Record<string, BREAKPOINTS>
const breakpoints: Breakpoint = {
  '0': 'unknown',
  '375': 'mobile-regular',
  '420': 'mobile',
  '560': 'tablet-small',
  '736': 'tablet',
  '1024': 'laptop-small',
  '1280': 'laptop',
  '1800': 'desktop',
}

interface HookReturnType {
  windowSize: Size
  breakpoint: BREAKPOINTS | undefined
  from: (breakpoint: BREAKPOINTS) => boolean
  to: (breakpoint: BREAKPOINTS) => boolean
}

export function useBreakpoints(): HookReturnType {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })
  const [breakpoint, setBreakpoint] = useState<BREAKPOINTS | undefined>()

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })

      const newBreakpoint = Object.keys(breakpoints).reduce((prev, curr) => {
        return Number(curr) <= window.innerWidth ? curr : prev
      }, '0')

      setBreakpoint(breakpoints[newBreakpoint])
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const from = useCallback(
    (b: BREAKPOINTS): boolean => {
      if (!windowSize || !windowSize.width) {
        return false
      }
      return (
        windowSize.width >=
        Number(Object.keys(breakpoints).reduce((curr, prev) => (breakpoints[curr] === b ? curr : prev), '0'))
      )
    },
    [windowSize],
  )

  const to = useCallback(
    (b: BREAKPOINTS): boolean => {
      if (!windowSize || !windowSize.width) {
        return false
      }
      return (
        windowSize.width <=
        Number(Object.keys(breakpoints).reduce((curr, prev) => (breakpoints[curr] === b ? curr : prev), '0'))
      )
    },
    [windowSize],
  )

  const res = useMemo(() => {
    return { windowSize, breakpoint, from, to }
  }, [windowSize, breakpoint, from, to])

  return res
}
