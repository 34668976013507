import { ProductV2 } from '@common'
import cx from 'classnames'

import { SquadBustersHero } from './SquadBustersHero'
import { SquadBustersQuantity } from './SquadBustersQuantity'
import { useFormattedAmount } from '../../../../../utils'

import styles from './SquadBustersOfferCardContent.module.scss'

type Props = {
  product: ProductV2
  showQuantity?: boolean
  className?: string
}

export function SquadBustersOfferCardContent({ product, className, showQuantity = true }: Props) {
  const quantity = useFormattedAmount(product.contentCounts.GEM ?? product.contents[0].amount)
  return (
    <div className={cx(styles.Content, className)}>
      <SquadBustersHero product={product} />
      {showQuantity && quantity ? <SquadBustersQuantity quantity={quantity} size="lg" /> : null}
    </div>
  )
}
