import { GameId, ProductV2 } from '@common'
import dynamic from 'next/dynamic'

import { useTranslatable } from '@/localization/useTranslatable'

import { brawlHyperChargeAsset } from '../../brawlstars/bundle/helpers'
import { HyperchargeAsset } from '../card/BrawlCardProduct'
import { useProductImage } from '../card/utils'
import { ImageWithFallback } from '../imageWithFallback/ImageWithFallback'

export const AnimatedImageWithFallbackNoSSR = dynamic<{
  animationSrc: string
  fallbackImage: string
  safariAnimationSrc?: string | undefined
}>(
  () =>
    import('../../_storeBase/imageWithFallback/AnimatedImageWithFallback').then((mod) => mod.AnimatedImageWithFallback),
  { ssr: false },
)
interface Props {
  game: GameId
  item: ProductV2['contents'][number]
}

export const ProductItemImage = ({ game, item }: Props) => {
  const translatable = useTranslatable()
  const { imageSrc, isAnimated, animatedSrc, safariAnimatedSrc } = useProductImage({
    game: game,
    productId: item.product.id,
    itemType: item.product.itemType,
    count: item.amount,
  })

  const title = translatable(item.product.title)
  const brawl = game === 'brawlstars'
  const isBrawlHypercharge = brawl && brawlHyperChargeAsset(item.product.id)

  if (isAnimated) {
    return (
      <AnimatedImageWithFallbackNoSSR
        animationSrc={animatedSrc}
        fallbackImage={imageSrc}
        safariAnimationSrc={safariAnimatedSrc}
      />
    )
  }

  if (isBrawlHypercharge) {
    return <HyperchargeAsset title={translatable(item.product.title)} assetId={item.product.id} />
  }

  return <ImageWithFallback productImage={imageSrc} title={title} />
}
