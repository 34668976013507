import React, { useRef } from 'react'

import { getBonusPointBannerVariant } from '@common'
import { ProductV2 } from '@common/models/product'
import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './cardRibbon.module.scss'

type Props = {
  product: ProductV2
}

export const CardRibbon = ({ product }: Props) => {
  const ribbonRef = useRef<HTMLDivElement>(null)
  const t = useTranslations()
  const bonus = product.bonusData

  if (!bonus) {
    return null
  }

  const ribbonContent = t('bonus_number_of_points').replace('[n]', bonus.totalBonus.toString())
  const ribbonVariant = getBonusPointBannerVariant(product)
  const bonusModifier = !!bonus.modifier

  const RibbonContainer = ({ children }: { children: React.ReactNode }) => (
    <div className={styles.Ribbon} ref={ribbonRef}>
      <StoreTypography tag="div" style="plain" textSize="sm" overrideFont="supercellTextBold">
        {children}
      </StoreTypography>
    </div>
  )

  return (
    <div className={cx(styles.Container)}>
      {bonusModifier ? (
        <div className={cx(styles.ribbonVariantWrapper, styles[ribbonVariant ?? ''])}>
          <RibbonContainer>
            <span className={styles.originalBonus}>+{bonus.baseBonus}</span>
            <span className={cx(styles.newBonus, styles[ribbonVariant ?? ''])}> +{ribbonContent}</span>
          </RibbonContainer>
        </div>
      ) : (
        <div className={styles.Shadow}>
          <RibbonContainer>+{ribbonContent}</RibbonContainer>
        </div>
      )}
    </div>
  )
}
