import cx from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import BsCelebrationImg from '@/assets/images/storefronts/brawlstars/bonus-completed-celebration.png'
import CocCelebrationImg from '@/assets/images/storefronts/clashofclans/bonus-completed-celebration.png'
import CrCelebrationImg from '@/assets/images/storefronts/clashroyale/bonus-completed-celebration.png'

import { useGameSlug } from '../../game-provider'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusCelebration.module.scss'

const Confetti = dynamic(() => import('@/components/Animations/Confetti'), { ssr: false })

export const BonusCelebration = () => {
  const t = useTranslations()
  const gameSlug = useGameSlug()

  const celebrationImg =
    gameSlug === 'brawlstars' ? BsCelebrationImg : gameSlug === 'clashroyale' ? CrCelebrationImg : CocCelebrationImg

  return (
    <div className={cx(styles.bonusCelebrationContainer, styles[gameSlug])}>
      <Confetti className={styles.confetti} />
      <StoreTypography tag="h2" textSize="lg" style="outlined">
        {t('bonus_completed_title').toUpperCase()}
      </StoreTypography>
      <div className={styles.celebrationImg}>
        <Image src={celebrationImg} alt="bonus-completed-celebration" fill />
      </div>
      <StoreTypography tag="h3" textSize="body" style="plain" overrideFont="supercellText">
        {t('bonus_completed_body1')}
        <br />
        {t('bonus_completed_body2')}
      </StoreTypography>
    </div>
  )
}
