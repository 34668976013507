import * as React from 'react'

import { GameId, STAMP_CARD_CLAIM_COST } from '@common'
import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { getStampCardTranslationKeys } from '@/localization/utils'

import { getBonusItemImage, getStampImage } from './utils'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './StampCardRewardInfo.module.scss'

type Props = {
  game: GameId
  hideInfo?: boolean
  itemsNeeded?: number
  arrowPosition?: 'left' | 'right'
}

export function StampCardRewardInfo(props: Props) {
  const t = useTranslations()
  const keys = getStampCardTranslationKeys(props.game)
  const description = useDescription(keys, props.itemsNeeded)

  if (!keys) {
    return null
  }

  const title = t(`stamps_${keys.product}`)
  const info = t(`stamps_info`)

  const image = getBonusItemImage(props.game)
  const stamp = getStampImage(props.game)

  return (
    <div
      className={cx(
        styles.stampCardDescription,
        props.arrowPosition === 'right' ? styles.rightArrow : styles.leftArrow,
      )}
    >
      <div className={styles.infoSection}>
        <div className={styles.textWrapper}>
          <StoreTypography
            tag="h5"
            style="plain"
            textSize="sm"
            overrideFont="supercellTextBold"
            lineHeight="normal"
            className={styles.stampDescriptionHeading}
          >
            {title}
          </StoreTypography>
          <StoreTypography
            tag="p"
            style="plain"
            textSize="sm"
            overrideFont="supercellText"
            lineHeight="normal"
            className={styles.stampDescriptionText}
          >
            {description}
          </StoreTypography>
        </div>
        <div className={styles.bonusItemWrapper}>
          {image && (
            <Image
              src={image}
              alt="bonus item"
              className={styles.bonusImage}
              style={{ objectFit: 'contain' }}
              sizes="100px"
            />
          )}
          {image && props.itemsNeeded !== undefined && props.itemsNeeded <= -STAMP_CARD_CLAIM_COST + 1 && (
            <Image
              src={image}
              alt="bonus item"
              className={styles.bonusImage}
              style={{ objectFit: 'contain' }}
              sizes="100px"
            />
          )}
        </div>
      </div>
      {!props.hideInfo && (
        <div className={styles.infoSection}>
          <div className={styles.stampItemWrapper}>
            {stamp && <Image src={stamp} alt="stamp item" fill style={{ objectFit: 'contain' }} sizes="100px" />}
          </div>
          <div className={styles.textWrapper}>
            <StoreTypography
              tag="p"
              style="plain"
              textSize="sm"
              overrideFont="supercellText"
              lineHeight="normal"
              className={styles.stampDescriptionText}
            >
              {info}
            </StoreTypography>
          </div>
        </div>
      )}
    </div>
  )
}

function useDescription(keys: ReturnType<typeof getStampCardTranslationKeys>, itemsNeeded?: number) {
  const t = useTranslations()

  if (keys === null) {
    return null
  }

  if (itemsNeeded === undefined) {
    return t(`stamps_info_${keys.item}`)
  }

  if (itemsNeeded <= 0) {
    return t(`stamps_${keys.product}_delivered`)
  }

  return t(`stamps_${keys.product}_buy_more`).replace('[n]', itemsNeeded.toString())
}
