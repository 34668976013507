import { GameId } from '@common'

import { Game } from '@/fixtures/games'

export const shortSlugToGameNameKey = (shortSlug: Game['shortSlug']) =>
  shortSlug === 'sb'
    ? (`${shortSlug}_game_name` as any) //eslint-disable-line
    : (`${shortSlug}_game_name` as const)

export const gameIdToGameNameKey = (gameId: GameId) => shortSlugToGameNameKey(gameIdToShortSlug(gameId))

export const gameIdToShortSlug = (gameId: GameId) => {
  switch (gameId) {
    case 'clashofclans':
      return 'coc' as const
    case 'clashroyale':
      return 'cr' as const
    case 'brawlstars':
      return 'bs' as const
    case 'hayday':
      return 'hd' as const
    case 'squadbusters':
      return 'sb' as const
  }
}

export const errorKeys = (statusCode: number) => {
  switch (statusCode) {
    case 404:
      return {
        title: '404_sub',
        description: '404_bod',
      } as const
    default:
      return {
        title: 'failed_tit',
        description: 'failed_bod',
      } as const
  }
}

export function getStampCardTranslationKeys(game: GameId) {
  switch (game) {
    case 'clashofclans':
      return { product: 'gold_pass', item: 'book_of_everything' } as const
    case 'clashroyale':
      return { product: 'diamond_pass', item: 'royal_wild_chest' } as const
    default:
      return null
  }
}
