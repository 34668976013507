import { Offer, ProductV2, STORE_KEY, StoreOfferProduct, isStoreOfferProduct } from '@common'

type StaticProductOffer = ProductV2 & { offerData: Offer }

export const isProductOffer = (product: ProductV2): product is StaticProductOffer => product.offerData !== null
export const isOffer = (product: ProductV2): product is StoreOfferProduct | StaticProductOffer =>
  isStoreOfferProduct(product) || isProductOffer(product)
export const isSeasonPass = (product: ProductV2) => !!product.contentCounts.SEASON_PASS

export type ProductCurrencyType = keyof Omit<ProductV2['contentCounts'], 'SEASON_PASS'>
export const getProductCurrencyType = (product: ProductV2): ProductCurrencyType | undefined => {
  if (product.contentCounts.COIN) {
    return 'COIN'
  }
  if (product.contentCounts.GEM) {
    return 'GEM'
  }
}

export const isSameProductType = (a: ProductV2, b: ProductV2) => {
  return Object.keys(a.contentCounts).some((t) => Object.keys(b.contentCounts).includes(t))
}

export const findProductBySlugOrId =
  <T extends ProductV2>(slugOrId: string) =>
  (product: T) =>
    product.hash === slugOrId || product.id === slugOrId || product.slug === slugOrId

export const getProductKey = (product: ProductV2) =>
  (product.type === 'offer' &&
    product.tags?.filter((tag) => tag.startsWith(STORE_KEY)).map((tag) => tag.split(':')[2])[0]) ||
  product.id
