import { enabledIn } from '@common'
import Script from 'next/script'

import { config } from '../../config'

const { STAGE } = config
const dataDomainScript = `010ca8c2-5d7a-43f5-ac8f-8c0d21467334${
  enabledIn(STAGE, ['staging', 'integration', 'development', 'local-dev', 'test']) ? '-test' : ''
}`

export default function CookieConsent() {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-document-language="true"
        data-domain-script={dataDomainScript}
      />
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="optanonwrapper"
        dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() { }` }}
      />
    </>
  )
}
