import BrawlFallbackHero from '@/assets/images/storefronts/brawlstars/bundle-hero1.png'
import CoCFallbackHero from '@/assets/images/storefronts/clashofclans/bundle-hero.png'

export const getHeroFallbackImage = (game: string) => {
  switch (game) {
    case 'clashofclans':
      return CoCFallbackHero
    case 'brawlstars':
      return BrawlFallbackHero
    case 'squad':
    case 'clashroyale':
    case 'hayday':
    default:
      return null
  }
}
