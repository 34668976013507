import { useMemo } from 'react'

import { GameId, SupportedLanguage, supportedLanguages } from '@common'

import { useLocale } from '../../../locale/useLocale'
import {
  badAssetsBS,
  badStackableAssetsBS,
  stackableAssetsMap as bsStackableAssetMap,
} from '../../brawlstars/constants'
import { badAssetsCoC, badStackableAssetsCoC } from '../../clashofclans/contants'
import { badAssetsCR, animatedWebMs, badStackableAssetsCR } from '../../clashroyale/constants'

const CR_EMOTE_ID_FORMAT_REGEX = /^LogicLong\((\d+),(\d+)\)$/

type UseSmallerFontProps = {
  contentAmount: number
  size?: string
}

export const useSmallerFont = ({ contentAmount, size }: UseSmallerFontProps) => {
  return useMemo(() => {
    if (!size) {
      return contentAmount > Math.pow(10, 6)
    }
    return contentAmount > Math.pow(10, 6) && (size === 'size-bundle-sm' || size === 'size-product-page')
  }, [contentAmount, size])
}

const parseClashRoyaleEmojiId = (productId: string) => {
  const match = productId.match(CR_EMOTE_ID_FORMAT_REGEX)
  if (!match) {
    return null
  }

  return {
    hiIndex: parseInt(match[1], 10),
    loIndex: parseInt(match[2], 10),
  }
}

const getStackableAssetFileName = (game: GameId, gloalId: string, count: number) => {
  if (game !== 'brawlstars') {
    return null
  }

  const limits = bsStackableAssetMap[gloalId]
  if (!limits) {
    return null
  }

  const stackVariation = limits.findIndex(([hi, lo]) => count >= hi && count <= lo)
  if (stackVariation === -1) {
    return null
  }

  return `${gloalId}_${stackVariation}`
}

const resolveClashRoyaleEvoShardAssetName = (productId: string, itemType?: string | null) => {
  if (itemType === 'Shard') {
    return `shard_${productId}`
  }

  return null
}

type GetProductImageProps = {
  game: GameId
  productId: string
  itemType?: string | null
  count?: number
}

function isAnimatedAsset(game: GameId, productId: string, itemType?: string | null) {
  if (game === 'clashroyale' && animatedWebMs.includes(productId)) {
    return true
  }

  if (game === 'brawlstars' && (itemType === 'Hero' || itemType === 'Skin')) {
    return true
  }

  return false
}

export const useProductImage = ({ game, productId, itemType, count }: GetProductImageProps) => {
  return useMemo(() => {
    const isBadAsset =
      game === 'clashofclans'
        ? badAssetsCoC.includes(productId)
        : game === 'clashroyale'
          ? badAssetsCR.includes(productId)
          : game === 'brawlstars'
            ? badAssetsBS.includes(productId)
            : false

    const evoShardName = game === 'clashroyale' && resolveClashRoyaleEvoShardAssetName(productId, itemType)
    if (evoShardName) {
      return {
        isAnimated: false,
        animatedSrc: '',
        imageSrc: `/images/${game}/assets/${evoShardName}.png`,
      }
    }

    const isRoyaleEmote = productId.startsWith('LogicLong')

    if (isRoyaleEmote) {
      const emoteIndexes = parseClashRoyaleEmojiId(productId)

      if (emoteIndexes) {
        return {
          isAnimated: false,
          animatedSrc: '',
          imageSrc: `/images/${game}/assets/emotes/emote_${emoteIndexes.hiIndex}-${emoteIndexes.loIndex}.png`,
        }
      }
    }

    const stackableAssetFileName = getStackableAssetFileName(game, productId, count ?? 0)
    if (stackableAssetFileName) {
      const isBadStackableAsset =
        game === 'clashofclans'
          ? badStackableAssetsCoC.includes(stackableAssetFileName)
          : game === 'clashroyale'
            ? badStackableAssetsCR.includes(stackableAssetFileName)
            : game === 'brawlstars'
              ? badStackableAssetsBS.includes(stackableAssetFileName)
              : false
      return {
        isAnimated: false,
        animatedSrc: '',
        imageSrc: isBadStackableAsset
          ? `/images/${game}/assets/overrides/stacks/${stackableAssetFileName}.png`
          : `/images/${game}/assets/stacks/${stackableAssetFileName}.png`,
      }
    }

    const isAnimated = isAnimatedAsset(game, productId, itemType)
    return {
      isAnimated,
      animatedSrc: `/images/${game}/assets/${productId}.webm`,
      imageSrc: isBadAsset
        ? `/images/${game}/assets/overrides/${productId}.png`
        : `/images/${game}/assets/${productId}.png`,
      safariAnimatedSrc: isAnimated
        ? game === 'brawlstars'
          ? `/images/${game}/assets/${productId}.mov`
          : `/images/${game}/assets/${productId}.webp`
        : undefined,
    }
  }, [game, productId, itemType, count])
}

export const useFormattedAmount = (amount: number) => {
  const locale = useLocale()
  return useMemo(() => formatAmount(amount, locale), [amount, locale])
}

export function formatAmount(amount: number, locale: SupportedLanguage) {
  if (amount <= 0) {
    return null
  }
  const language = supportedLanguages[locale]
  // TODO: remove the numberingSystem when it's merged to TS lib, included in 5.5.0-dev
  // https://github.com/microsoft/TypeScript/issues/52072
  const formatOptions: Intl.NumberFormatOptions & { numberingSystem: string } = {
    numberingSystem: 'latn',
  }

  if (language.rightToLeft) {
    const rtlAmount = new Intl.NumberFormat(language.locale, formatOptions).format(amount)
    return rtlAmount
  }

  // inserts a space before every group of three digits, starting from the end of the string
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
