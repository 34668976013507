import { StoreBonusStatusResponse } from '@common/models/bonus'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import TopImage from '@/assets/images/storefronts/brawlstars/challenges-header.png'
import { useGameImages } from '@/components/games/images'
import { BonusMissionBS } from '@/features/storefronts/_storeBase/bonusMission/BonusMissionBS'
import { BONUS_TRACK_DIVIDER_BG, STORE_BG } from '@/features/storefronts/_storeBase/constants'
import { TimeRemaining } from '@/features/storefronts/_storeBase/timeRemaining/TimeRemaining'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useGameSlug } from '@/features/storefronts/game-provider'
import { getTimeRemaining } from '@/utils/timer'

import styles from './bonusPointsBS.module.scss'

interface BonusPointsProps {
  bonusStatus: StoreBonusStatusResponse
}

export const BonusPointsBS = ({ bonusStatus }: BonusPointsProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const { gameImage } = useGameImages(gameSlug)

  const remainingTime = getTimeRemaining(bonusStatus?.endTimeMillis || 0)
  const missions = bonusStatus?.missions?.missions || []

  return (
    <div className={styles.background} style={{ backgroundImage: STORE_BG(gameSlug) }}>
      <div className={styles.headerPlaceholder} />
      <div className={styles.dividerContainer}>
        <div className={styles.divider} style={{ backgroundImage: BONUS_TRACK_DIVIDER_BG(gameSlug) }} />
        <div className={styles.dividerImageContainer}>
          <div className={styles.dividerImageWrapper}>
            <Image src={gameImage('badge')} alt={gameSlug} height={60} quality={100} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.remainingTime}>
          <TimeRemaining
            className={styles.time}
            textSize={gameSlug === 'brawlstars' ? 'sm' : undefined}
            timeLeftText={remainingTime}
            type="secondary"
            textStyle="outlined"
          />
        </div>
        <div className={styles.topImage}>
          <Image src={TopImage} alt={gameSlug} width={90} height={90} />
        </div>
        <div className={styles.topInfo}>
          <StoreTypography
            className={styles.infoText}
            tag="span"
            textSize="sm"
            style="plain"
            overrideFont="supercellTextBold"
          >
            {t('bonus_earn_quests')}
          </StoreTypography>
        </div>
        <div className={styles.missions}>
          {missions.map((mission) => (
            <BonusMissionBS key={mission.missionId} mission={mission} />
          ))}
        </div>
        <div className={styles.bottomInfo}>
          <StoreTypography
            className={styles.infoText}
            tag="span"
            textSize="xs"
            style="plain"
            overrideFont="supercellText"
          >
            <span className={styles.infoCircle}>!</span>
            <span>{t('bonus_time_appear')}</span>
          </StoreTypography>
        </div>
      </div>
    </div>
  )
}
