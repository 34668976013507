import { z } from 'zod'

const gameIdLiterals = [
  z.literal('clashofclans'),
  z.literal('hayday'),
  z.literal('brawlstars'),
  z.literal('clashroyale'),
  z.literal('squadbusters'),
] as const

export const gameIds = gameIdLiterals.map((game) => game.value)

export const gameIdSchema = z.union(gameIdLiterals)
export type GameId = z.infer<typeof gameIdSchema>
export type ShortGameId = 'coc' | 'hd' | 'bs' | 'cr' | 'sb'

export const COC_PROGRESS = {
  TOWN_HALL: 0,
  TROPHIES: 1,
} as const

export const HD_PROGRESS = {
  FARM_LEVEL: 0,
} as const

export const BS_PROGRESS = {
  EXP_LEVEL: 0,
  TROPHIES: 1,
} as const

export const CR_PROGRESS = {
  ARENA_NAME: 0,
  TROPHIES: 1,
} as const

export const isGameId = (s: string): s is GameId => gameIds.includes(s as GameId)
