import cx from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import SCLogoSecondary from 'assets/images/logos/supercell-id-black.svg'
import SCLogo from 'assets/images/logos/supercell-id-white.svg'

import styles from './LoginButton.module.scss'

interface LoginButtonProps {
  additionalClasses?: string
  onClick?: () => void
  'data-cy'?: string
  variant?: 'Primary' | 'Secondary'
}

export const LoginButton: React.FC<LoginButtonProps> = ({
  additionalClasses,
  onClick,
  'data-cy': dataCy,
  variant = 'Primary',
}) => {
  const t = useTranslations()
  const { asPath, query } = useRouter()
  query.redirectUri = asPath
  const pathname = `/oauth/begin`

  return (
    <Link
      className={cx(styles.LoginButton, styles[variant], additionalClasses)}
      href={{ pathname, query }}
      onClick={() => {
        onClick?.()
      }}
      data-cy={dataCy}
    >
      <span className={styles.LoginText}>{t('login_title')}</span>
      <div className={styles.IdLogo}>
        <Image
          alt="logo"
          src={variant === 'Primary' ? SCLogo : SCLogoSecondary}
          layout="fill"
          objectFit="contain"
          sizes="5vw"
          quality={100}
        />
      </div>
    </Link>
  )
}
