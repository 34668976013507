import { useCallback, useMemo } from 'react'

import { GameId, ProductV2, sortProducts } from '@common'
import { useRouter } from 'next/router'

import { useFetchProductsQuery } from '@/api'
import { useLocale } from '@/features/locale/useLocale'
import { GAME_STATUS } from '@/fixtures/games'
import { useAppSelector } from '@/utils/useAppSelector'

import { useGames } from './useGames'
import { ProductCurrencyType } from '../../utils/products'
import { LOGIN_STATUS } from '../user/userSlice'

export const useProducts = (
  filters?: { game?: GameId },
  waitForLogin?: boolean,
  refetchOnMountOrArgChange?: boolean,
  getPurchased?: boolean,
) => {
  const router = useRouter()
  const { gameSlug } = router.query

  const isLoggedIn = useAppSelector((state) => state.user.loginStatus === LOGIN_STATUS.LOGGED_IN)
  const locale = useLocale()
  const viewerCountry = useAppSelector((state) => state.config.viewerCountry)
  const games = useGames()

  const enabledGames = useMemo(
    () => games.filter((g) => g.status === GAME_STATUS.ENABLED && (filters?.game ? filters.game === g.slug : true)),
    [games, filters?.game],
  )

  const gameFilter = useCallback(
    (product: ProductV2) => enabledGames.map((game) => game.slug).includes(product.game),
    [enabledGames],
  )

  // When viewing receipts from account purchase history we don't get the gameSlug from router
  // PurchasedProducts component uses filters.game to get the correct products, by default gameSlug from router will be used
  const gameId = filters?.game ?? gameSlug
  const includePurchased = getPurchased ? 'true' : undefined

  const { data, ...rest } = useFetchProductsQuery(
    { locale, viewerCountry, gameId: gameId as GameId, includePurchased },
    {
      skip: waitForLogin ? !isLoggedIn : false,
      refetchOnMountOrArgChange,
    },
  )

  const products = data?.filter(gameFilter).sort(sortProducts) ?? []

  return { products, ...rest }
}

export const resolveRibbonKey = (product: ProductV2) => {
  const offerData = product.offerData

  if (!offerData || !offerData.discountType) {
    return undefined
  }
  const { discountType, expiresAt } = offerData
  if (expiresAt) {
    return ['offer_ribbon_ltd', undefined] as const
  }
  if (discountType === 'percentage') {
    return ['offer_ribbon_minus_percent', { percent: offerData.percentage }] as const
  }
  if (discountType === 'bonus') {
    return ['offer_ribbon_more', { percent: offerData.percentage }] as const
  }
  if (discountType === 'simple') {
    return undefined
  }
  return ['offer_ribbon', undefined] as const
}

export const resolveProductCurrencyDescription = (product: ProductV2, productType: ProductCurrencyType) => {
  if (product.offerData && product.offerData.discountType === 'bonus') {
    const amountWithBonus = product.contentCounts[productType]
    if (!amountWithBonus) {
      return null
    }

    const originalAmount = amountWithBonus - product.offerData.bonusAmount
    return `${originalAmount} + ${product.offerData.bonusAmount}`
  }
  return product.contentCounts[productType]
}
