import React from 'react'

import { formatProductPrice, STAMP_CARD_CLAIM_COST, GameId } from '@common'
import cx from 'classnames'
import { AnimatePresence, m } from 'framer-motion'
import Image from 'next/image'

import { PillCounter } from '@/components/buttons/PillCounter'
import { useCartContext } from '@/contexts/CartContext'
import { useMarketContext } from '@/contexts/MarketContext'
import { useLocale } from '@/features/locale/useLocale'
import { useTranslatable } from '@/localization/useTranslatable'
import { CartItem } from '@/utils/cart'
import { resolveProductImage } from '@/utils/resolveProductImage'

import { StampCardRewardInfo } from '../stampCard/StampCardRewardInfo'

import styles from './CartProducts.module.scss'

type Props = {
  game: GameId
  items: CartItem[]
  className?: string
}
export function CartProducts({ game, items, className }: Props) {
  return items.map((item) => <CartProduct key={item.product.id} game={game} item={item} className={className} />)
}

type ProductRowProps = {
  game: GameId
  item: CartItem
  className?: string
}

const TITLE_FALLBACK = 'title'

function CartProduct({ game, item, className }: ProductRowProps) {
  const translatable = useTranslatable()
  const locale = useLocale()
  const market = useMarketContext('CartProduct')
  const { updateItem, quotaForItem } = useCartContext()

  const quantity = item.quantity
  const stampCard = item.product.stampCard

  const hasStampCard = stampCard !== undefined
  const showItemAdded = true // FIXME CART

  const productTitle = translatable(item.product.title, TITLE_FALLBACK)
  const firstItemTitle = translatable(item.product.contents[0].product.title, TITLE_FALLBACK)
  const title = productTitle === TITLE_FALLBACK ? firstItemTitle : productTitle

  return (
    <AnimatePresence initial={showItemAdded}>
      <div className={cx(styles.Item, { [styles.WithStampCardInfo]: hasStampCard }, className)}>
        <m.div
          initial={{ x: '50%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          className={styles.Row}
        >
          <div className={styles.ItemImageContainer}>
            <div className={styles.ItemImageBackground}>
              <div className={cx(styles.MinWidth, styles.ItemImage)}>
                <Image
                  src={resolveProductImage(item.product)}
                  fill
                  style={{ objectFit: 'contain' }}
                  alt=""
                  placeholder="empty"
                />
              </div>
            </div>
          </div>
          <div className={styles.ItemInfo}>
            <div className={styles.ItemTitle}>
              <strong title={title}>{title}</strong>
            </div>
            <div className={styles.BottomRow}>
              <div className={cx(styles.ItemPrice)}>{formatProductPrice(item.product, market, locale, quantity)}</div>
              <div className={styles.PillCounter}>
                <PillCounter
                  error={item.ineligible}
                  increment={() => updateItem(item.product, 1)}
                  decrement={() => updateItem(item.product, -1)}
                  min={0}
                  max={quotaForItem(item.product).quota}
                  count={quantity}
                  data-cy={`cart-pillcounter-${item.product.id}`}
                />
              </div>
            </div>
          </div>
        </m.div>
        {hasStampCard && (
          <div className={styles.StampCardInfo}>
            <StampCardRewardInfo
              game={game}
              itemsNeeded={STAMP_CARD_CLAIM_COST - (stampCard.balance + quantity)}
              arrowPosition="right"
              hideInfo
            />
          </div>
        )}
      </div>
    </AnimatePresence>
  )
}
