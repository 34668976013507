import * as React from 'react'

import { enabledIn } from '@common'
import dynamic from 'next/dynamic'

import { config } from '../../config'
import { Modal } from '../components/Modal'
import { useModalContext } from '../contexts/ModalContext'

const { STAGE } = config

export type FeatureFlagsModalProps = {
  type: 'FEATURE_FLAGS'
}

const FeatureFlags = dynamic<unknown>(() =>
  import('../features/featureFlags/FeatureFlags').then((mod) => mod.FeatureFlags),
)

export function FeatureFlagsModal() {
  const { closeModal } = useModalContext()

  if (!enabledIn(STAGE, ['development', 'local-dev', 'integration', 'staging', 'test'])) {
    return null
  }
  return (
    <Modal position="Middle" data-cy="notify-modal" onClose={closeModal} closeOnClickOutside>
      <FeatureFlags />
    </Modal>
  )
}
