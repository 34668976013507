import * as React from 'react'

import { Modal } from '../components/Modal'
import { useModalContext } from '../contexts/ModalContext'
import { Login } from '../features/user/login/Login'

export type LoginModalProps = {
  type: 'LOGIN'
  props?: Props
}

type Props = {
  customTitle?: string
  onClose?: () => void
}

export function LoginModal(props: Props) {
  const { closeModal } = useModalContext()
  const onClose = () => {
    closeModal()
    if (props.onClose) {
      props.onClose()
    }
  }
  return (
    <Modal position="Middle" data-cy="login-modal" onClose={onClose} closeOnClickOutside>
      <Login customTitle={props.customTitle} />
    </Modal>
  )
}
