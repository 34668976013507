import { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'

import styles from './Ribbon.module.scss'

function useParentWidth() {
  const [width, setWidth] = useState<number | undefined>(undefined)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const parentWidth = entries[0].contentRect.width
        setWidth(parentWidth)
      }
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return { ref, width }
}

type Props = {
  content: string[] | undefined
  compact?: boolean
}

export const Ribbon = ({ content, compact }: Props) => {
  const { ref, width } = useParentWidth()
  const isMedium = !compact && width !== undefined && width <= 270

  if (!content?.length) {
    return null
  }

  return (
    <div ref={ref} className={styles.Container}>
      <div className={styles.Shadow}>
        <div
          className={cx(styles.Ribbon, {
            [styles.Compact]: compact,
            [styles.Medium]: isMedium,
          })}
        >
          {content.map((p) => (
            <p key={p}>{p}</p>
          ))}
        </div>
      </div>
    </div>
  )
}
