import * as React from 'react'

import { useModalContext } from '@/contexts/ModalContext'
import { BonusInfo } from '@/features/storefronts/_storeBase/bonus/BonusInfo'
import { StorefrontModal } from '@/features/storefronts/_storeBase/modal/StorefrontModal'
import styles from '@/features/storefronts/_storeBase/modal/storefrontModal.module.scss'

export type BonusModalProps = {
  type: 'BONUS_INFO'
}

export function BonusModal() {
  const { closeModal } = useModalContext()
  return (
    <StorefrontModal
      position="Middle"
      data-cy="notify-modal"
      onClose={closeModal}
      closeOnClickOutside
      className={styles.bonusModal}
    >
      <BonusInfo />
    </StorefrontModal>
  )
}
