export type IconProps = {
  width?: number
  height?: number
  className?: string
  fill?: string
}

export const SIcon = ({ height = 33, width = 37, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 37 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.47476 8.82974L0 32.6191H25.1985C30.2971 32.6191 34.6114 28.7943 35.2981 23.6653L36.8947 11.7412C37.7263 5.52966 33.5479 0 27.3731 0H13.5571C8.5053 0 4.21576 3.75665 3.47476 8.82974Z"
      fill="#FFCC4D"
    />
    <path
      d="M16.6134 25.2172C14.8241 25.2172 13.4966 23.7955 12.804 22.8756C12.3422 22.2623 12.0536 21.6769 11.8228 21.0636L15.1127 20.2273C15.5167 20.9242 15.9785 21.5654 16.5845 21.9556H20.9422C20.9711 21.9556 21.202 21.7884 21.5194 21.4817C21.8369 21.1751 22.01 20.9521 22.01 20.9242V19.3352C22.01 19.2516 21.9812 19.1959 21.9234 19.168L14.6221 17.2724C13.006 16.8542 12.2268 15.4046 12.2268 14.0386V12.5891C12.2268 11.8364 12.3133 11.3346 12.8328 10.6377C13.2657 10.0523 14.1603 9.18809 14.7375 8.79781C15.459 8.29603 15.9785 8.2124 16.7577 8.2124H20.798C22.5872 8.2124 23.9436 9.63412 24.6074 10.554C25.0691 11.1952 25.3577 11.7527 25.5886 12.366L22.2986 13.2023C21.8946 12.5054 21.4329 11.8643 20.8268 11.474H16.8154C16.7865 11.474 16.5557 11.6412 16.2382 11.9479C15.9208 12.2545 15.7476 12.4775 15.7476 12.5054V13.9271C15.7476 14.0386 15.8342 14.0944 15.8342 14.0944L23.1644 16.0179C24.7517 16.436 25.5597 17.8856 25.5597 19.2516V20.8406C25.5597 21.5932 25.4731 22.095 24.9537 22.792C24.5208 23.3774 23.6262 24.2415 23.049 24.6318C22.3275 25.1336 21.808 25.2172 21.0288 25.2172H16.6134Z"
      fill="white"
    />
  </svg>
)

export const SupportIcon = ({ height = 37, width = 37, className, fill = '#F5F5F5' }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.5" cy="18.5" r="18.5" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4999 28.7777C24.1762 28.7777 28.7777 24.1762 28.7777 18.4999C28.7777 12.8237 24.1762 8.22217 18.4999 8.22217C12.8237 8.22217 8.22217 12.8237 8.22217 18.4999C8.22217 20.5047 8.79617 22.3755 9.78881 23.9568L8.22217 28.7777L12.8991 27.119C14.5103 28.1681 16.4339 28.7777 18.4999 28.7777Z"
      fill="black"
    />
    <ellipse cx="14.3889" cy="18.4999" rx="1.23333" ry="1.23333" fill="#F2F2F2" />
    <circle cx="18.4999" cy="18.4999" r="1.23333" fill="#F2F2F2" />
    <circle cx="22.611" cy="18.4999" r="1.23333" fill="#F2F2F2" />
  </svg>
)

export const ArrowIcon = ({ height = 17, width = 12, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 1L2 6L7 11" stroke="black" strokeWidth="2" />
    <rect width="15" height="2" transform="matrix(-1 0 0 1 17 5)" fill="black" />
  </svg>
)

export const CartButtonIcon = ({ height = 33, width = 32, className }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 33"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 26.0002H5L7.14285 12.0715L20 6.04834V26.0002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 6.04834L25 10.1126L27 26.0002H20"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5239 9.79013L16.5239 8.00974C16.5239 6.20552 15.2184 4.60759 13.3811 5.08582C10.9769 5.71158 10.2382 8.1223 10.2382 11.4349L10.2382 12.4651"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const MenuIcon = ({ height = 24, width = 24 }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} fill="none">
    <path d="M4 12.2202H28" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 20.3672H28" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const ShoppingBag = ({ height = 24, width = 24 }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 6H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Help = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668Z"
      stroke="#191919"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.06006 5.99989C6.21679 5.55434 6.52616 5.17863 6.93336 4.93931C7.34056 4.7 7.81932 4.61252 8.28484 4.69237C8.75036 4.77222 9.1726 5.01424 9.47678 5.37558C9.78095 5.73691 9.94743 6.19424 9.94672 6.66656C9.94672 7.99989 7.94672 8.66656 7.94672 8.66656"
      stroke="#191919"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 11.3335H8.00667" stroke="#191919" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

interface AlertProps {
  type: 'error' | 'default' | 'warning'
}

const colors: Record<AlertProps['type'], string> = {
  error: '#F94343',
  default: '#000',
  warning: '#f1bb00',
}

export const Alert: React.FC<{
  type: AlertProps['type']
  height?: number
  width?: number
  strokeWidth?: number
  dataCy?: string
}> = ({ type, height = 24, width = 24, strokeWidth = 2, dataCy }) => {
  return (
    <svg
      width={width}
      height={height}
      data-cy={dataCy}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={colors[type]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 8V12" stroke={colors[type]} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="12" cy="16" r="1" fill={colors[type]} />
    </svg>
  )
}

export const CreditCard = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
      stroke="#191919"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 10H23" stroke="#191919" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const TrashBin = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
      <path
        d="M7.39163e-10 3.76744V2.85174C0.0178571 2.52035 0.151786 2.24128 0.401786 2.01453C0.651786 1.78779 0.946429 1.67442 1.28571 1.67442H2.57143V1.25581C2.57143 0.906977 2.69643 0.610465 2.94643 0.366279C3.19643 0.122093 3.5 0 3.85714 0H8.14286C8.5 0 8.80357 0.122093 9.05357 0.366279C9.30357 0.610465 9.42857 0.906977 9.42857 1.25581V1.67442H10.7143C11.0536 1.67442 11.3482 1.78779 11.5982 2.01453C11.8482 2.24128 11.9821 2.52035 12 2.85174V3.76744C12 3.99419 11.9152 4.19041 11.7455 4.35611C11.5759 4.52181 11.375 4.60466 11.1429 4.60465V11.7209C11.1429 12.1919 10.9777 12.5887 10.6473 12.9113C10.317 13.234 9.91072 13.3954 9.42857 13.3953H2.57143C2.08929 13.3953 1.68303 13.234 1.35267 12.9113C1.02231 12.5887 0.857134 12.1919 0.857143 11.7209V4.60465C0.625 4.60465 0.424105 4.5218 0.254457 4.35611C0.08481 4.19042 -9.1427e-06 3.9942 7.39163e-10 3.76744ZM0.857143 3.76744H11.1429V2.93023C11.1429 2.80814 11.1027 2.70785 11.0223 2.62935C10.942 2.55086 10.8393 2.51161 10.7143 2.51161H1.2857C1.1607 2.51161 1.05802 2.55086 0.977658 2.62935C0.897296 2.70785 0.857115 2.80814 0.857115 2.93023L0.857143 3.76744ZM1.71429 11.7209C1.71429 11.9477 1.7991 12.1439 1.96874 12.3096C2.13838 12.4753 2.33928 12.5581 2.57143 12.5581H9.42857C9.66071 12.5581 9.86161 12.4753 10.0313 12.3096C10.2009 12.1439 10.2857 11.9477 10.2857 11.7209V4.60465H1.71429V11.7209ZM3.42857 1.67442H8.57143V1.25581C8.57143 1.13372 8.53125 1.03343 8.45089 0.954935C8.37053 0.876442 8.26784 0.837196 8.14284 0.837196H3.85713C3.73213 0.837196 3.62945 0.876442 3.54909 0.954935C3.46872 1.03343 3.42854 1.13372 3.42854 1.25581L3.42857 1.67442Z"
        fill="black"
      />
      <rect x="3.34888" y="5.58142" width="0.893023" height="6.13953" rx="0.446512" fill="black" />
      <rect x="5.5813" y="5.58142" width="0.893023" height="6.13953" rx="0.446512" fill="black" />
      <rect x="7.81396" y="5.58142" width="0.893023" height="6.13953" rx="0.446512" fill="black" />
    </g>
  </svg>
)

export const Lock = ({ height = 24, width = 24, className }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 24 24" width={width} className={className}>
    <g fill="none">
      <path d="M0 0h24v24H0V0z" />
      <path d="M0 0h24v24H0V0z" opacity=".87" />
    </g>
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
  </svg>
)

export const Globe: React.FC<{
  className?: string
  height?: number
  width?: number
}> = ({ className, height = 24, width = 24 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 20.1668C16.0626 20.1668 20.1667 16.0628 20.1667 11.0002C20.1667 5.93755 16.0626 1.8335 11 1.8335C5.93738 1.8335 1.83333 5.93755 1.83333 11.0002C1.83333 16.0628 5.93738 20.1668 11 20.1668Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.83333 11H20.1667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11 1.8335C13.2928 4.34365 14.5959 7.60119 14.6667 11.0002C14.5959 14.3991 13.2928 17.6567 11 20.1668C8.70715 17.6567 7.40414 14.3991 7.33333 11.0002C7.40414 7.60119 8.70715 4.34365 11 1.8335Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Pin: React.FC<{
  className?: string
  height?: number
  width?: number
}> = ({ className, height = 24, width = 24 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10.6084 1.06348C6.29681 1.06348 2.80151 4.55878 2.80151 8.87041C2.80151 10.7953 3.50132 12.5544 4.6562 13.9156L10.6084 20.9256L16.5605 13.9154C17.7154 12.5544 18.4152 10.7951 18.4152 8.87019C18.4154 4.55878 14.9201 1.06348 10.6084 1.06348ZM10.6084 11.8122C8.8352 11.8122 7.39784 10.3749 7.39784 8.60161C7.39784 6.82836 8.8352 5.391 10.6084 5.391C12.3817 5.391 13.8191 6.82836 13.8191 8.60161C13.8191 10.3749 12.3817 11.8122 10.6084 11.8122Z"
          fill="#D2D2D2"
        />
      </g>
    </svg>
  )
}
export const Cross = ({ height = 24, width = 24 }: IconProps) => (
  <svg fill="currentColor" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0z" />
      <path d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414z" />
    </g>
  </svg>
)

export const ArrowRight = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.5 6.25L11.1862 8.83109" stroke="white" strokeWidth="1.75" strokeLinecap="round" />
    <path d="M11.2869 8.94995L7.60068 11.531" stroke="white" strokeWidth="1.75" strokeLinecap="round" />
  </svg>
)

export const CreatorsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6074_79708)">
      <g clipPath="url(#clip1_6074_79708)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.21012 2.99992C0.995117 4.44992 0.995117 6.28492 0.995117 10.4999V19.9999L10.8701 18.6149C14.2201 18.2149 15.8951 18.0149 17.0651 17.0799C17.5151 16.7149 17.9051 16.2799 18.2101 15.7899C19.0051 14.5249 19.0051 12.8399 19.0051 9.46992C19.0051 5.28992 19.0051 3.46992 17.9401 2.07492C17.5301 1.53992 17.0201 1.08992 16.4401 0.744917C14.9201 -0.145083 13.9951 -0.170083 9.77512 0.324917C9.24012 0.389917 8.75012 0.439917 8.30512 0.489917C5.24512 0.819917 4.14512 0.939917 2.41012 2.77492C2.34012 2.84492 2.27512 2.92492 2.21012 2.99992ZM7.42512 14.9149C7.63512 15.0449 7.82012 15.1249 7.99512 15.1499C8.16512 15.1799 8.38012 15.1749 8.63012 15.1499L10.9601 14.8749C11.7751 14.7799 12.5351 14.1899 13.2401 13.1049C13.4851 12.7299 13.6751 12.3199 13.8251 11.8749L11.8551 11.5699C11.5701 12.1399 11.2751 12.5449 10.9751 12.7799L8.66012 13.0499C8.62512 13.0499 8.51012 12.9649 8.31512 12.7849C8.12012 12.6049 8.02012 12.4999 8.02012 12.4599L8.01012 7.05992C8.01012 7.02492 8.11012 6.89492 8.30512 6.66992C8.50012 6.44492 8.61512 6.32992 8.65012 6.32492L10.9651 6.05492C11.2651 6.22492 11.5601 6.55992 11.8501 7.05992L13.8201 6.29492C13.6801 5.90492 13.4851 5.54492 13.2301 5.20492C12.9651 4.84492 12.6351 4.53492 12.2451 4.27492C11.8501 4.01492 11.4201 3.91492 10.9501 3.96992L8.61012 4.24992C8.36012 4.27992 8.14512 4.32992 7.97012 4.39492C7.80012 4.45992 7.60512 4.58492 7.40012 4.76492C7.23012 4.90492 7.02512 5.10492 6.79512 5.36992C6.56512 5.63492 6.38512 5.86992 6.26012 6.07492C6.11012 6.30492 6.01512 6.51492 5.96512 6.69992C5.92012 6.88492 5.90012 7.10492 5.90012 7.36992L5.91012 12.6599C5.91012 12.9199 5.93512 13.1399 5.98012 13.3099C6.02512 13.4849 6.12512 13.6699 6.27512 13.8649C6.40012 14.0299 6.57512 14.2149 6.80012 14.4299C7.03512 14.6449 7.24012 14.8099 7.42512 14.9149Z"
          fill="#46E0AE"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6074_79708">
        <rect width="20" height="20" fill="white" />
      </clipPath>
      <clipPath id="clip1_6074_79708">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
