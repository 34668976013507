import { z } from 'zod'
import { GameId } from '../models/game'

const applicationNames = [
  z.literal('magic'),
  z.literal('scroll'),
  z.literal('soil'),
  z.literal('laser'),
  z.literal('squad'),
] as const
const envNames = [z.literal('integration'), z.literal('stage'), z.literal('production')] as const

export const applications = applicationNames.map((name) => name.value)
export const envs = envNames.map((name) => name.value)

export const applicationSchema = z.union(applicationNames)
export type Application = z.infer<typeof applicationSchema>

export const applicationEnvSchema = z.union([
  ...applicationNames,
  ...applicationNames.flatMap((app) => envNames.map((env) => z.literal(`${app.value}-${env.value}`))),
])
export type ApplicationEnv = z.infer<typeof applicationEnvSchema>

export const GAME_ID_APPLICATION: Record<Application, GameId> = {
  magic: 'clashofclans',
  scroll: 'clashroyale',
  soil: 'hayday',
  laser: 'brawlstars',
  squad: 'squadbusters',
} as const

export const CACHE_BUST_URL_PARAM_NAME = 'x' as const

export function gameIdToApplication(gameId: GameId): Application {
  switch (gameId) {
    case 'clashofclans':
      return 'magic'
    case 'clashroyale':
      return 'scroll'
    case 'hayday':
      return 'soil'
    case 'brawlstars':
      return 'laser'
    case 'squadbusters':
      return 'squad'
  }
}

export function applicationEnvToGameId(env: string): GameId {
  const [app] = env.split('-')
  return GAME_ID_APPLICATION[app as Application]
}
