export const clashResources = ['3000000', '3000001', '3000002', '3000003', '3000007', '3000008']

export const badAssetsCoC = [
  '3000000',
  '3000001',
  '3000002',
  '3000003',
  '3000007',
  '3000008',
  '3000019',
  '3000020',
  '3000021',
]
export const badStackableAssetsCoC: string[] = []
