import { StoreBonusStatusResponse } from '@common/models/bonus'
import cx from 'classnames'
import { useTranslations } from 'next-intl'

import { useCartContext } from '@/contexts/CartContext'
import { StoreTypography } from '@/features/storefronts/_storeBase/typography/StoreTypography'
import { useGameSlug } from '@/features/storefronts/game-provider'

import { BonusTrackItem } from './BonusTrackItem'
import { getBonusItemIndex, getDeliveredBonuses } from '../bonus/bonusUtils'

import styles from './bonusTrackTentativeBonus.module.scss'

interface BonusTrackTentativeBonusProps {
  bonusStatus: StoreBonusStatusResponse
}

export const BonusTrackTentativeBonus = ({ bonusStatus }: BonusTrackTentativeBonusProps) => {
  const t = useTranslations()
  const gameSlug = useGameSlug()
  const { items } = useCartContext()
  const deliveredBonuses = getDeliveredBonuses({ bonusStatus, items })
  const numberOfDeliveredItems = deliveredBonuses.length

  return numberOfDeliveredItems > 0 ? (
    <div className={cx(styles.bonusTrackTentativeBonus, styles[gameSlug])}>
      <div className={styles.title}>
        <StoreTypography
          tag="h3"
          textSize="body"
          style="plain"
          overrideFont={gameSlug !== 'clashroyale' ? 'supercellTextMedium' : undefined}
          className={cx(styles.centered, styles.titleText)}
        >
          {t('bonus_delivered_on_checkout')}
        </StoreTypography>
      </div>
      <div className={styles.deliveredContainer}>
        {deliveredBonuses.map((item) => (
          <BonusTrackItem
            key={item.id}
            balance={item.cost}
            index={getBonusItemIndex({ bonusStatus, item })}
            item={item}
            status={'delivered'}
            progressPercentage={0}
          />
        ))}
      </div>
    </div>
  ) : null
}
