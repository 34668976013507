import { GameId } from '@common'
import cx from 'classnames'
import { getImageProps } from 'next/image'

import BrawlFlare from '@/assets/images/storefronts/brawlstars/shop_item_bg_flare.png'
import BgFlare from '@/assets/images/storefronts/clashofclans/shop_item_bg_flare.png'

import { useGameSlug } from '../../game-provider'
import { getBackgroundImage } from '../imageWithFallback/imageUtils'

import styles from './flare.module.scss'

const BlurryEllipse = () => {
  return (
    <svg viewBox="0 0 315 289" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_772_1541)">
        <ellipse cx="157.5" cy="144.5" rx="99.5" ry="86.5" fill="#FFF8BA" fillOpacity="0.7" />
      </g>
      <defs>
        <filter
          id="filter0_f_772_1541"
          x="0.146114"
          y="0.146114"
          width="314.708"
          height="288.708"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="28.9269" result="effect1_foregroundBlur_772_1541" />
        </filter>
      </defs>
    </svg>
  )
}

export const Flare = ({ game }: { game?: GameId }) => {
  const gameSlug = useGameSlug()
  const usedSlug = game ?? gameSlug
  const brawl = usedSlug === 'brawlstars'
  const Flare = brawl ? BrawlFlare : BgFlare

  const {
    props: { srcSet },
  } = getImageProps({ alt: '', width: 400, height: 400, src: Flare })
  const backgroundImage = getBackgroundImage(srcSet)

  return (
    <>
      <div className={styles.imageContainer}>
        <div className={cx(styles.bgFlare, styles[usedSlug])} style={{ backgroundImage: backgroundImage }} />
      </div>
      {!brawl && (
        <div className={styles.imageContainer}>
          <BlurryEllipse />
        </div>
      )}
    </>
  )
}
