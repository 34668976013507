import { forwardRef } from 'react'

import { StoreRewardItem } from '@common/models/bonus'
import cx from 'classnames'
import Image from 'next/image'
import { useLocale, useTranslations } from 'next-intl'

import BsClaimedBg from '@/assets/images/storefronts/brawlstars/bonus-item-claimed-bg.png'
import CocClaimedBg from '@/assets/images/storefronts/clashofclans/bonus-item-claimed-bg.png'
import CrClaimedBg from '@/assets/images/storefronts/clashroyale/bonus-item-claimed-bg.png'
import { useTranslatable } from '@/localization/useTranslatable'
import { useAppSelector } from '@/utils/useAppSelector'

import { ClaimButton } from './ClaimButton'
import { useGameSlug } from '../../game-provider'
import { BonusImage } from '../bonus/BonusImage'
import { useFormattedAmount } from '../card/utils'
import { rtlLocales } from '../constants'
import { StoreTypography } from '../typography/StoreTypography'

import styles from './bonusTrackItem.module.scss'

export type BonusItemStatus = 'claimed' | 'unclaimed' | 'next' | 'unavailable' | 'delivered'

interface BonusTrackItemProps {
  balance: number
  index: number
  item: StoreRewardItem
  status: BonusItemStatus
  progressPercentage: number
}

export const BonusTrackItem = forwardRef<HTMLDivElement, BonusTrackItemProps>(
  ({ balance, index, item, status, progressPercentage }, ref) => {
    const t = useTranslations()
    const gameSlug = useGameSlug()
    const translatable = useTranslatable()
    const locale = useLocale()

    const claimedBonusItems = useAppSelector((state) => state.common.claimedBonusItems)
    const mode = claimedBonusItems.includes(item.id) ? 'claimed' : status

    const isClaimedMode = mode === 'claimed'
    const isUnclaimedMode = mode === 'unclaimed'
    const isCurrentMode = mode === 'next'
    const isUnavailableMode = mode === 'unavailable'

    const showBorder = isUnclaimedMode && gameSlug === 'clashofclans'
    const showButton = isUnclaimedMode && !item.claimed

    const remainingPoints = useFormattedAmount(item.cost - balance)

    const claimedBg = gameSlug === 'clashroyale' ? CrClaimedBg : gameSlug === 'brawlstars' ? BsClaimedBg : CocClaimedBg

    return (
      <div className={cx(styles.item, showBorder && styles.gradientBorder, styles[mode], styles[gameSlug])} ref={ref}>
        <div className={cx(styles.claimedBgContainer, { [styles.isClaimed]: isClaimedMode })}>
          <Image
            src={claimedBg}
            alt="bonus-item-background"
            fill
            sizes="(max-width: 600px) 50vw, 30vw"
            placeholder="empty"
          />
        </div>

        <div className={styles.content}>
          <div className={styles.indexContainer}>
            <StoreTypography className={styles.index} tag="span" textSize="body" style="outlined">
              {index > 0 && index}
            </StoreTypography>
          </div>
          <div className={styles.imageContainer}>
            <BonusImage item={item} status={mode} progressPercentage={progressPercentage} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textGroup}>
              <StoreTypography
                className={styles.title}
                tag="span"
                textSize="body"
                style={rtlLocales.includes(locale) ? 'plain' : 'outlined'}
              >
                {translatable(item.title, 'title')}
              </StoreTypography>
              {(isClaimedMode || isUnclaimedMode) && (
                <StoreTypography
                  className={styles.claim}
                  tag="span"
                  textSize="sm"
                  style={isClaimedMode ? 'outlined' : 'plain'}
                  overrideFont="supercellTextMedium"
                >
                  {t(isClaimedMode ? 'bonus_claimed' : 'bonus_unclaimed')}
                </StoreTypography>
              )}
              {(isCurrentMode || isUnavailableMode) && (
                <StoreTypography
                  className={styles.claim}
                  tag="span"
                  textSize="sm"
                  style={'plain'}
                  overrideFont="supercellTextMedium"
                >
                  {t('bonus_number_of_points').replace('[n]', remainingPoints ? remainingPoints.toString() : '0')}
                </StoreTypography>
              )}
            </div>
            {showButton && <ClaimButton content={t('bonus_claim')} item={item} type="Primary" />}
          </div>
        </div>
      </div>
    )
  },
)

BonusTrackItem.displayName = 'BonusTrackItem'
