import { DynamoDBClient, GetItemCommand, PutItemCommand } from '@aws-sdk/client-dynamodb'
import { unmarshall } from '@aws-sdk/util-dynamodb'
import * as dynamoDbConfig from './config'
import { StoreError, StoreErrorMessage } from '../../utils/StoreError'
import { customerSchema, Customer } from '../../models/customer'
import { MetricsClient } from '../cloudwatch/metrics'

const { PARTITION_KEY, SORT_KEY, TABLE_NAME, tablePartitionKey } = dynamoDbConfig

const CUSTOMER_TYPE = 'CUSTOMER' as const
const CUSTOMER_SORT_KEY = 'CUSTOMER' as const

type Dependencies = {
  metricsClient: MetricsClient
}

export type CustomerClient = ReturnType<typeof createCustomerClient>

export function createCustomerClient(dynamoDbClient: DynamoDBClient, { metricsClient }: Dependencies) {
  return {
    getCustomer: metricsClient.withDurationMetrics('Cusotmer_GetCustomer', getCustomer(dynamoDbClient)),
    putCustomer: metricsClient.withDurationMetrics('Cusotmer_PutCustomer', putCustomer(dynamoDbClient)),
  }

  function getCustomer(dynamoDbClient: DynamoDBClient) {
    return async (customerId: string) => {
      const command = new GetItemCommand({
        TableName: TABLE_NAME,
        Key: {
          [PARTITION_KEY]: { S: tablePartitionKey(customerId) },
          [SORT_KEY]: { S: CUSTOMER_TYPE },
        },
      })
      try {
        const res = await dynamoDbClient.send(command)
        if (!res.Item) {
          return undefined
        }
        const unmarshalled = unmarshall(res.Item)
        return customerSchema.parse(JSON.parse(unmarshalled.data))
      } catch (err) {
        throw StoreError.withMessage(StoreErrorMessage.DB_CUSTOMER_GET_FAILED, err)
      }
    }
  }

  function putCustomer(dynamoDbClient: DynamoDBClient) {
    return async (customer: Customer) => {
      const command = new PutItemCommand({
        TableName: TABLE_NAME,
        Item: {
          [PARTITION_KEY]: { S: tablePartitionKey(customer.id) },
          [SORT_KEY]: { S: CUSTOMER_SORT_KEY },
          TYPE: { S: CUSTOMER_TYPE },
          createdAt: { N: Date.now().toString() },
          data: { S: JSON.stringify(customer) },
        },
      })
      try {
        await dynamoDbClient.send(command)
      } catch (err) {
        throw StoreError.withMessage(StoreErrorMessage.DB_CUSTOMER_UPDATE_FAILED, err)
      }
    }
  }
}
