export const STORE_SPECIAL = 'store:special'
export const STORE_TEST_TAG = 'store:test'
export const STORE_KEY = 'store:key:'
export const STORE_CATEGORY_GEM = 'store:category:gem'
export const STORE_SKIN = 'store:skin'
export const STORE_SCENERY = 'store:scenery'
export const STORE_BONUS_POINT_OVERRIDE = /^store:bonus-points=(\d+)$/
export const STORE_REWARD_TAG = 'reward'

export const STORE_BONUS_TAG_RE = /^store:bonus-(\d\d?)p$/
export const STORE_DISCOUNT_TAG_RE = /^store:discount-(\d\d?)p$/
export const STORE_BONUS_BANNER_VARIANT_RE = /^store:bonus-point-banner-variant:(.+)$/

// tags supported by cards v2
export const STORE_POPULAR_TAG = 'store:banner:Popular'
export const STORE_EXCLUSIVE_TAG = 'store:banner:Exclusive'
export const STORE_PERCENT_TAG_RE = /^store:percent=(\d+)$/
export const STORE_STRIKE_THROUGH_TAG_RE = /^store:strikethrough=(\d+)$/

export const TAG_TRANSLATIONS_KEYS = {
  [STORE_POPULAR_TAG]: 'offer_popular',
} as const

// Brawl specific tags
export const LASER_TAG_PERCENT_EXTRA = 'laser:tagType:percentExtra'
export const LASER_TAG_PERCENT_DISCOUNT = 'laser:tagType:percentDiscount'
export const LASER_TAG_VALUE = 'laser:tagValue:'
