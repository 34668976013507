import { z } from 'zod'

export const billingAddressSchema = z.object({
  address: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  stateOrProvince: z.string().optional(),
})

export const customerSchema = z.object({
  id: z.string(),
  billingAddress: billingAddressSchema.optional(),
  paymentGatewayCustomerships: z
    .object({
      paddle: z
        .object({
          customer: z.string(),
          address: z.string().optional(),
        })
        .optional(),
      fastSpring: z.string().optional(),
    })
    .optional(),
  paddleSubscriptionIds: z.array(z.string()).optional(),
})

export type BillingAddress = z.infer<typeof billingAddressSchema>
export type Customer = z.infer<typeof customerSchema>
