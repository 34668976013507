import React from 'react'

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs'
import { logger } from '@sentry/utils'
import { useTranslations } from 'next-intl'

import ErrorLayout from '../ErrorLayout'

import styles from './ErrorBoundary.module.scss'

const Fallback: React.FC = () => {
  const t = useTranslations()
  return (
    <ErrorLayout image="airport" withGradient>
      <div className={styles.Container}>
        <h1 data-cy="error-title">
          <br />
          {t('failed_tit')}
        </h1>
        <p>{t('failed_bod')}</p>
      </div>
    </ErrorLayout>
  )
}

class DevErrorBoundary extends React.Component<React.PropsWithChildren, { hasError: boolean; error?: Error }> {
  constructor(props: React.PropsWithChildren) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(errorInfo)
    logger.error(error)
    this.setState({ error })
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />
    }
    return this.props.children
  }
}

const ProdErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => (
  <SentryErrorBoundary fallback={<Fallback />}>{children}</SentryErrorBoundary>
)

const ErrorBoundary: React.FC<React.PropsWithChildren<{ sentryEnabled: boolean }>> = ({ sentryEnabled, children }) =>
  sentryEnabled ? <ProdErrorBoundary>{children}</ProdErrorBoundary> : <DevErrorBoundary>{children}</DevErrorBoundary>

export default ErrorBoundary
