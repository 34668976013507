import { useEffect } from 'react'

import { useLocale } from '@/features/locale/useLocale'

import { useAppDispatch } from '../../utils/useAppDispatch'

export function useSetCookieConsentLocale() {
  const dispatch = useAppDispatch()
  const locale = useLocale()
  useEffect(() => {
    window.OneTrust?.changeLanguage(locale)
  }, [locale, dispatch])
}
