import cx from 'classnames'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import RoyaleRibbon from '@/assets/images/storefronts/clashroyale/value-ribbon.png'
import { useLocale } from '@/features/locale/useLocale'

import { StoreTypography } from '../typography/StoreTypography'

import styles from './valueIndicator.module.scss'

interface Props {
  value: number
  variant?: 'onboarding'
}

export const RoyaleValueIndicator = ({ value, variant }: Props) => {
  const t = useTranslations()
  const locale = useLocale()

  const isOnboarding = variant === 'onboarding'

  return (
    <div className={cx(styles.royaleValueIndicator, { [styles.onboarding]: isOnboarding })}>
      {!isOnboarding && (
        <div className={styles.royaleRibbon}>
          <Image src={RoyaleRibbon} alt="royale ribbon" fill style={{ objectFit: 'contain' }} sizes="100px" />
        </div>
      )}
      <div className={styles.textWrapper}>
        <div className={styles.textContainer}>
          <StoreTypography tag="div" textSize="lg" style="outlined" lineHeight="tight" className={styles.valueNumber}>
            {value}
            <StoreTypography tag="span" textSize="sm" style="outlined" lineHeight="tight">
              x
            </StoreTypography>
          </StoreTypography>
          <StoreTypography
            tag="div"
            textSize="xs"
            style="outlined"
            lineHeight="tight"
            className={cx(styles.valueText, styles[locale])}
          >
            {t('value').toLowerCase()}
          </StoreTypography>
        </div>
      </div>
    </div>
  )
}
