/**
 * Data model
 * single purchase = single document
 * each event (update) is stored in the DB
 * using optimistic updates ie require version number to match the current version number of the document to complete the update
 *
 */

import {
  AttributeValue,
  BatchWriteItemCommand,
  DeleteItemCommand,
  DynamoDBClient,
  GetItemCommand,
  QueryCommand,
  TransactWriteItemsCommand,
  WriteRequest,
} from '@aws-sdk/client-dynamodb'
import chunk from 'lodash.chunk'

import {
  CreatePurchaseEvent,
  DeletePurchaseEvent,
  MigratePurchaseEvent,
  PurchaseEvent,
  PurchaseUpdateEvent,
} from './purchaseEvents'
import { MetricsClient } from '../../cloudwatch/metrics'
import * as dynamoDbConfig from '../config'
import { GameId } from '../../../models/game'
import { Receipt } from '../../../models/receipt'
import { isDefined } from '../../../utils/isDefined'
import { Purchase, PURCHASE_STATUS, PurchaseDraft } from '../../../models/purchase'
import { buildPutReceiptTransactWriteItem } from '../receiptClient'
import { logger } from '../../../utils/logger'

const { GSIPK, GSISK, GSI_NAME, PARTITION_KEY, SORT_KEY, tablePartitionKey, TABLE_NAME, GSI2PK, GSI2_NAME } =
  dynamoDbConfig
const PURCHASE_PREFIX = 'PURCHASE' as const
const PURCHASE_TYPE = 'PURCHASE' as const
const purchaseSK = (id: string) => `${PURCHASE_PREFIX}#${id}`

const purchaseGSIPK = (customerId: string) => tablePartitionKey(customerId)
const purchaseGSISK = (createdAt: number, gameId?: GameId) => {
  if (gameId) {
    return `${PURCHASE_PREFIX}#${gameId}#${createdAt}`
  } else {
    return `${PURCHASE_PREFIX}#${createdAt}`
  }
}

const purchaseGSI2PK = (purchaseId: string) => purchaseSK(purchaseId)

const GSISKPrefix = (gameId: GameId) => `${PURCHASE_PREFIX}#${gameId}`

const PURCHASE_EVENT_PREFIX = 'E'

function purchaseEventPK(purchaseId: string) {
  return `${PURCHASE_EVENT_PREFIX}#${purchaseId}`
}
function purchaseEventSK(timestamp: number) {
  return 'REV#' + timestamp
}

type Dependencies = {
  metricsClient: MetricsClient
}
export function createPurchaseClient(dynamoDbClient: DynamoDBClient, { metricsClient }: Dependencies) {
  return {
    deletePurchaseDraft: metricsClient.withDurationMetrics(
      'Purchase_DeletePurchaseDraft',
      deletePurchaseDraft(dynamoDbClient),
    ),
    createPurchase: metricsClient.withDurationMetrics('Purchase_CreatePurchase', createPurchase(dynamoDbClient)),
    migratePurchase: metricsClient.withDurationMetrics('Purchase_MigratePurchase', migratePurchase(dynamoDbClient)),
    updatePurchase: metricsClient.withDurationMetrics('Purchase_UpdatePurchase', updatePurchase(dynamoDbClient)),
    updatePurchaseAndCreateReceipt: metricsClient.withDurationMetrics(
      'Purchase_UpdatePurchaseAndCreateReceipt',
      updatePurchaseAndCreateReceipt(dynamoDbClient),
    ),
    getPurchaseById: metricsClient.withDurationMetrics('Purchase_GetPurchaseById', getPurchaseById(dynamoDbClient)),
    getLatestPurchase: metricsClient.withDurationMetrics(
      'Purchase_GetLatestPurchase',
      getLatestPurchase(dynamoDbClient),
    ),
    queryPurchasesWithStatus: metricsClient.withDurationMetrics(
      'Purchase_QueryPurchasesWithStatus',
      queryPurchasesWithStatus(dynamoDbClient),
    ),
    queryPurchasesForCustomer: metricsClient.withDurationMetrics(
      'Purchase_QueryPurchasesForCustomer',
      queryPurchasesForCustomer(dynamoDbClient),
    ),
    queryPurchasesById: metricsClient.withDurationMetrics(
      'Purchase_QueryPurchasesById',
      queryPurchasesById(dynamoDbClient),
    ),
    queryPurchaseEventsByPurchaseId: metricsClient.withDurationMetrics(
      'Purchase_QueryPurchaseEventsByPurchaseId',
      queryPurchaseEventsByPurchaseId(dynamoDbClient),
    ),
  }
}

function deletePurchaseDraft(dynamoDbClient: DynamoDBClient) {
  const _queryPurchaseEventsByPurchaseId = queryPurchaseEventsByPurchaseId(dynamoDbClient)
  const _deletePurchaseEvents = deletePurchaseEvents(dynamoDbClient)
  return async (event: DeletePurchaseEvent) => {
    const purchaseEvents = await _queryPurchaseEventsByPurchaseId(event.payload.purchaseId)
    await dynamoDbClient.send(buildDeletePurchaseCommand(event))
    try {
      await _deletePurchaseEvents(event.payload.purchaseId, purchaseEvents)
    } catch (err) {
      logger.error('Failed to delete purchase events', err)
    }
  }
}

function deletePurchaseEvents(dynamoDbClient: DynamoDBClient) {
  return async (purchaseId: string, events: Record<string, AttributeValue>[]) => {
    const cmds = chunk(
      buildDeletePurchaseEventWriteRequests(purchaseId, events.map((row) => row.SK.S).filter(isDefined)),
      25,
    ).map(
      (chunk) =>
        new BatchWriteItemCommand({
          RequestItems: {
            [TABLE_NAME]: chunk,
          },
        }),
    )
    await Promise.all(cmds.map((cmd) => dynamoDbClient.send(cmd)))
  }
}

function createPurchase(dynamoDbClient: DynamoDBClient) {
  return async (purchase: PurchaseDraft, event: CreatePurchaseEvent) => {
    logger.info({ purchase, event }, 'Create purchase for customer')
    const command = buildPutPurchaseCommand(purchase, event)
    await dynamoDbClient.send(command)
  }
}

function migratePurchase(dynamoDbClient: DynamoDBClient) {
  return async (purchase: Purchase, event: MigratePurchaseEvent) => {
    const command = buildUpdatePurchaseCommand(purchase, event)
    await dynamoDbClient.send(command)
  }
}

function updatePurchase(dynamoDbClient: DynamoDBClient) {
  return async (purchase: Purchase, event: PurchaseUpdateEvent) => {
    logger.info({ purchase, event }, 'Update purchase for customer')
    const command = buildUpdatePurchaseCommand(purchase, event)
    await dynamoDbClient.send(command)
  }
}

function updatePurchaseAndCreateReceipt(dynamoDbClient: DynamoDBClient) {
  return async (purchase: Purchase, receipt: Receipt, event: PurchaseUpdateEvent) => {
    logger.info({ purchase, receipt, event }, 'Update purchase and create receipt for customer')
    const command = new TransactWriteItemsCommand({
      TransactItems: [
        buildUpdatePurchaseTransactWriteItem(purchase),
        buildPutPurchaseEventTransactWriteItem(event, purchase.id),
        buildPutReceiptTransactWriteItem(receipt),
      ],
    })
    await dynamoDbClient.send(command)
  }
}

function getPurchaseById(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, purchaseId: string) => {
    const command = new GetItemCommand({
      TableName: TABLE_NAME,
      Key: {
        [PARTITION_KEY]: { S: tablePartitionKey(customerId) },
        [SORT_KEY]: { S: purchaseSK(purchaseId) },
      },
    })
    const result = await dynamoDbClient.send(command)
    return result.Item
  }
}

function getLatestPurchase(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, gameId: GameId) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      IndexName: GSI_NAME,
      KeyConditionExpression: '#GSIPK = :GSIPK and begins_with(#GSISK, :GSISK)',
      ExpressionAttributeNames: {
        '#GSIPK': GSIPK,
        '#GSISK': GSISK,
      },
      ExpressionAttributeValues: {
        ':GSIPK': { S: tablePartitionKey(customerId) },
        ':GSISK': { S: GSISKPrefix(gameId) },
      },
      ScanIndexForward: false,
    })
    const result = await dynamoDbClient.send(command)
    if (!result || !result.Items || result.Items.length === 0) {
      return undefined
    }
    return result.Items[0]
  }
}

function queryPurchasesWithStatus(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string, status: PURCHASE_STATUS) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      IndexName: GSI_NAME,
      KeyConditionExpression: '#GSIPK = :GSIPK and begins_with(#GSISK, :GSISK)',
      FilterExpression: '#status = :status',
      ExpressionAttributeNames: {
        '#GSIPK': GSIPK,
        '#GSISK': GSISK,
        '#status': 'status',
      },
      ExpressionAttributeValues: {
        ':GSIPK': { S: tablePartitionKey(customerId) },
        ':GSISK': { S: PURCHASE_PREFIX },
        ':status': { S: status },
      },
      ScanIndexForward: false,
    })
    const result = await dynamoDbClient.send(command)
    return result?.Items ?? []
  }
}

function queryPurchasesForCustomer(dynamoDbClient: DynamoDBClient) {
  return async (customerId: string) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      IndexName: GSI_NAME,
      KeyConditionExpression: '#GSIPK = :GSIPK and begins_with(#GSISK, :GSISK)',
      ExpressionAttributeNames: {
        '#GSIPK': GSIPK,
        '#GSISK': GSISK,
      },
      ExpressionAttributeValues: {
        ':GSIPK': { S: tablePartitionKey(customerId) },
        ':GSISK': { S: PURCHASE_PREFIX },
      },
      ScanIndexForward: false,
    })
    const result = await dynamoDbClient.send(command)
    return result.Items ?? []
  }
}

function queryPurchasesById(dynamoDbClient: DynamoDBClient) {
  const _getPurchaseById = getPurchaseById(dynamoDbClient)
  return async (purchaseId: string) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      IndexName: GSI2_NAME,
      KeyConditionExpression: '#GSI2PK = :GSI2PK',
      ExpressionAttributeNames: {
        '#GSI2PK': GSI2PK,
      },
      ExpressionAttributeValues: {
        ':GSI2PK': { S: purchaseGSI2PK(purchaseId) },
      },
      ScanIndexForward: false,
      Limit: 1,
    })
    const result = await dynamoDbClient.send(command)
    const item = result?.Items?.[0]
    if (!item) {
      return undefined
    }
    const customerId = item.PK?.S?.split('#')[1]
    if (!customerId) {
      return undefined
    }
    return _getPurchaseById(customerId, purchaseId)
  }
}

function queryPurchaseEventsByPurchaseId(dynamoDbClient: DynamoDBClient) {
  return async (purchaseId: string) => {
    const command = new QueryCommand({
      TableName: TABLE_NAME,
      KeyConditionExpression: '#PK = :PK AND begins_with(#SK, :SK)',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: purchaseEventPK(purchaseId) },
        ':SK': { S: 'REV#' },
      },
    })
    const response = await dynamoDbClient.send(command)
    return response.Items ?? []
  }
}

// Utils

function buildPutPurchaseEventTransactWriteItem(event: PurchaseEvent, purchaseId: string) {
  const timestamp = Date.now()
  return {
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PARTITION_KEY]: { S: purchaseEventPK(purchaseId) },
        [SORT_KEY]: { S: purchaseEventSK(timestamp) },
        /**
         * Attributes
         */
        TYPE: { S: event.type },
        /**
         * Content
         */
        data: { S: JSON.stringify(event) },
      },
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: purchaseEventPK(purchaseId) },
        ':SK': { S: purchaseEventSK(timestamp) },
      },
      ConditionExpression: '#PK <> :PK AND #SK <> :SK',
    },
  }
}

function buildPutPurchaseTransactWriteItem(purchase: Purchase) {
  const partitionKey = tablePartitionKey(purchase.customerId)
  const sortKey = purchaseSK(purchase.id)
  return {
    Put: {
      TableName: TABLE_NAME,
      Item: {
        /**
         * Keys
         */
        [PARTITION_KEY]: { S: partitionKey },
        [SORT_KEY]: { S: sortKey },
        [GSIPK]: { S: purchaseGSIPK(purchase.customerId) },
        [GSISK]: { S: purchaseGSISK(purchase.createdAt, purchase.gameId) },
        [GSI2PK]: { S: purchaseGSI2PK(purchase.id) },
        /**
         * Other attributes
         */
        status: { S: purchase.status },
        createdAt: { N: purchase.createdAt.toString() },
        updatedAt: { N: purchase.updatedAt.toString() },
        TYPE: { S: PURCHASE_TYPE },
        /**
         * Content
         */
        data: { S: JSON.stringify(purchase) },
        ...(purchase.gameId ? { ':gameId': { S: purchase.gameId } } : {}),
      },
      ConditionExpression: '#PK <> :PK AND #SK <> :SK',
      ExpressionAttributeNames: {
        '#PK': PARTITION_KEY,
        '#SK': SORT_KEY,
      },
      ExpressionAttributeValues: {
        ':PK': { S: partitionKey },
        ':SK': { S: sortKey },
      },
    },
  }
}

function buildPutPurchaseCommand(purchase: Purchase, event: CreatePurchaseEvent | MigratePurchaseEvent) {
  return new TransactWriteItemsCommand({
    TransactItems: [
      buildPutPurchaseTransactWriteItem(purchase),
      buildPutPurchaseEventTransactWriteItem(event, purchase.id),
    ],
  })
}

function buildUpdatePurchaseTransactWriteItem(purchase: Purchase) {
  const gameIdSetExpression = purchase.gameId ? '#gameId = :gameId,' : ''
  return {
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PARTITION_KEY]: { S: tablePartitionKey(purchase.customerId) },
        [SORT_KEY]: { S: purchaseSK(purchase.id) },
      },
      UpdateExpression: `SET #status = :status, #data = :data, ${gameIdSetExpression} #updatedAt = :updatedAt`,
      ExpressionAttributeNames: {
        '#status': 'status',
        '#data': 'data',
        '#updatedAt': 'updatedAt',
        ...(purchase.gameId ? { '#gameId': 'gameId' } : {}),
      },
      ExpressionAttributeValues: {
        ':status': { S: purchase.status },
        ':data': { S: JSON.stringify(purchase) },
        ':updatedAt': { N: purchase.updatedAt.toString() },
        ...(purchase.gameId ? { ':gameId': { S: purchase.gameId } } : {}),
      },
    },
  }
}

function buildUpdatePurchaseCommand(purchase: Purchase, event: PurchaseUpdateEvent) {
  return new TransactWriteItemsCommand({
    TransactItems: [
      buildUpdatePurchaseTransactWriteItem(purchase),
      buildPutPurchaseEventTransactWriteItem(event, purchase.id),
    ],
  })
}

function buildDeletePurchaseCommand(event: DeletePurchaseEvent) {
  return new DeleteItemCommand({
    TableName: TABLE_NAME,
    Key: {
      [PARTITION_KEY]: { S: tablePartitionKey(event.payload.customerId) },
      [SORT_KEY]: { S: purchaseSK(event.payload.purchaseId) },
    },
  })
}

function buildDeletePurchaseEventWriteRequests(purchaseId: string, timestamps: string[]): WriteRequest[] {
  return timestamps.map((rev) => {
    return {
      DeleteRequest: {
        Key: {
          [PARTITION_KEY]: { S: purchaseEventPK(purchaseId) },
          [SORT_KEY]: { S: rev },
        },
      },
    }
  })
}
