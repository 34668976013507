import { GameId } from '@common'
import { StoreRewardItem } from '@common/models/bonus'
import Image from 'next/image'

import BsCompletedImg from '@/assets/images/storefronts/brawlstars/bonus-completed-celebration.png'
import BsUnavailableImg from '@/assets/images/storefronts/brawlstars/bonus-track-unavailable.png'
import CocCheckMark from '@/assets/images/storefronts/clashofclans/bonus-item-checkmark.png'
import CocUnavailableImg from '@/assets/images/storefronts/clashofclans/bonus-track-unavailable.png'
import SquadCompletedImg from '@/assets/images/storefronts/squadbusters/bonus-completed-celebration.gif'
import SquadUnavailableImg from '@/assets/images/storefronts/squadbusters/bonus-track-unavailable.gif'
import { useGameSlug } from '@/features/storefronts/game-provider'

import { BonusItemStatus } from '../types'

import styles from './bonusItemImage.module.scss'

const unavailableImage = (gameSlug: GameId) => {
  if (gameSlug === 'brawlstars') {
    return BsUnavailableImg
  }

  if (gameSlug === 'squadbusters') {
    return SquadUnavailableImg
  }

  return CocUnavailableImg
}

const getCompletedImage = (gameSlug: GameId) => {
  if (gameSlug === 'brawlstars') {
    return BsCompletedImg
  }

  if (gameSlug === 'squadbusters') {
    return SquadCompletedImg
  }

  return CocCheckMark
}

interface BonusImageProps {
  item?: StoreRewardItem
  status: BonusItemStatus | 'completed'
}

export const BonusItemImage = ({ item, status }: BonusImageProps) => {
  const gameSlug = useGameSlug()

  const itemImageSrc = item?.image || unavailableImage(gameSlug)
  const completedImageSrc = getCompletedImage(gameSlug)

  return (
    <div className={styles.bonusItemImage}>
      <div className={styles.imageWrapper}>
        {status === 'completed' ? (
          <Image
            className={styles.image}
            src={completedImageSrc}
            alt="reward-image"
            height={100}
            width={100}
            placeholder="empty"
            quality={100}
            priority
          />
        ) : (
          <Image
            className={styles.image}
            src={itemImageSrc}
            alt="reward-image"
            height={100}
            width={100}
            placeholder="empty"
            quality={100}
            priority
          />
        )}
      </div>
    </div>
  )
}
