import * as React from 'react'

import { GameId } from '@common'
import cx from 'classnames'
import Image from 'next/legacy/image'
import { useTranslations } from 'next-intl'

import Checkmark from '@/assets/images/icons/icon-checkmark-black.svg'
import { CreatorsIcon, Alert } from '@/components/Icons'
import { Loader } from '@/components/Loader'
import * as nextApi from '@/nextApi'
import { useApi } from '@/utils/useApi'
import { useDebouncedFn } from '@/utils/useDebouncedFn'

import { CreatorBoostHelp } from './CreatorBoostHelp'
import { getCreatorBoostCookie } from '../../../utils/cookies'

import styles from './CreatorCode.module.scss'

type Props = {
  gameId: GameId
  setIsLoading: (isLoading: boolean) => void
}
export function CreatorCode({ gameId, setIsLoading }: Props) {
  const t = useTranslations()
  const [creatorCode, setCreatorCode] = React.useState(getCreatorBoostCookie())
  const [isValid, setIsValid] = React.useState(true)
  const [isActive, setIsActive] = React.useState(false)
  const [upsertCreatorCode, { isLoading }] = useApi(nextApi.upsertCreatorCode, () => ({ isValid: false, code: '' }))

  const onChange = React.useCallback(
    async (value: string) => {
      setIsLoading(true)
      const response = await upsertCreatorCode(gameId, value)
      setIsLoading(false)
      setIsValid(response.isValid)
      setCreatorCode(response.code)
    },
    [upsertCreatorCode, gameId, setIsValid, setIsLoading],
  )

  const debouncedOnChange = useDebouncedFn(onChange, 500)

  const showError = creatorCode && !isValid && !isLoading
  const showSuccess = creatorCode && isValid && !isLoading
  const placeholder = t('summary_add_creator_code')

  return (
    <div className={styles.Container}>
      <div className={cx(styles.Content, { [styles.Active]: isActive })}>
        <div className={styles.CreatorsIcon}>
          <CreatorsIcon />
        </div>
        <div className={styles.TooltipContainer}>
          <input
            className={cx(styles.CreatorBoostInput, { [styles.Error]: showError })}
            placeholder={placeholder}
            defaultValue={creatorCode ?? undefined}
            onChange={(e) => debouncedOnChange(e.currentTarget.value)}
            onBlur={() => setIsActive(false)}
            onFocus={() => setIsActive(true)}
            type="text"
          />
          <div className={styles.IconContainer}>
            {isLoading && <Loader strokeColor="#2d85f3" />}
            {showSuccess && <Image alt="success" className={styles.Icon} src={Checkmark} />}
            {showError && <Alert type="error" height={21} width={21} strokeWidth={1.5} />}
            {!isLoading && !creatorCode && <CreatorBoostHelp />}
          </div>
        </div>
      </div>
    </div>
  )
}
