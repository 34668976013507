import z from 'zod'

import * as tables from './tables'
import * as columnTypes from './columnTypes'

export const extendedPaymentMethod = tables.paymentMethod.schema.extend({
  paymentGatewayName: z.string(),
  countryCodes: columnTypes.countryCodeSchema.array(),
  currencyCodes: columnTypes.currencyCodeSchema.array(),
})
export type ExtendedPaymentMethod = z.infer<typeof extendedPaymentMethod>

export const extendedPaymentGatewaySchema = tables.paymentGateway.schema.extend({
  countryCodes: columnTypes.countryCodeSchema.array(),
  currencyCodes: columnTypes.currencyCodeSchema.array(),
})
export type ExtendedPaymentGateway = z.infer<typeof extendedPaymentGatewaySchema>

export const countryWithCurrency = tables.country.schema.extend({
  currency: tables.currency.schema,
})
export type CountryWithCurrency = z.infer<typeof countryWithCurrency>

export const market = tables.country.schema
  .extend({
    taxRate: tables.tax.schema.shape.rate,
    currencyDecimals: tables.currency.schema.shape.decimals,
    paymentGateway: tables.paymentGateway.schema.shape.id,
  })
  .omit({ appStoreTerritory: true, countryCodeLong: true, name: true })
export type Market = z.infer<typeof market>

export const countryWithPaymentMethods = tables.country.schema.extend({
  paymentMethods: z
    .object({
      id: z.string(),
      name: z.string(),
      externalId: z.string(),
      paymentGatewayId: columnTypes.paymentGatewaySchema,
      paymentGatewayName: z.string(),
      priority: z.number(),
      pinned: z.boolean(),
      categories: z.string().array(),
    })
    .array(),
})

export const countryWithTaxes = tables.country.schema.extend({
  taxes: tables.tax.schema.omit({ countryCode: true }).array(),
})
export type CountryWithTaxes = z.infer<typeof countryWithTaxes>

export const announcementWithCountries = tables.announcement.schema.extend({
  countries: columnTypes.countryCodeSchema.array(),
})
export type AnnouncementWithCountries = z.infer<typeof announcementWithCountries>

export const creatorGiveawayAccountWithCreatorCode = tables.creatorGiveawayAccount.schema.extend({
  creatorCode: z.string().optional(),
})

export type CreatorGiveawayAccountWithCreatorCode = z.infer<typeof creatorGiveawayAccountWithCreatorCode>
