import { ReactNode } from 'react'

import { ProductV2 } from '@common'

import { OfferCardType } from '@/features/storefronts/_storeBase/card/v2/OfferCard'
import { Grid } from '@/features/storefronts/_storeBase/card/v2/OfferCard/shared/ItemsGrid'

import { BrawlItem } from './BrawlItem'
import { BrawlStarsHero } from './BrawlStarsHero'

import styles from './BrawlStarsOfferCardContent.module.scss'

type Props = {
  product: ProductV2
  offerCardType?: OfferCardType
}

const BrawlStarSingleItemCardContent = ({ product }: Props): ReactNode => {
  return (
    <div className={styles.SingleItemContainer}>
      <div className={styles.Items}>
        <div className={styles.Item} key={`product-${product.id}-content`}>
          <BrawlItem content={product.contents[0]} isSingleItemOffer={true} />
        </div>
      </div>
    </div>
  )
}

const BrawlStarMultipleItemCardContent = ({ product }: Props): ReactNode => {
  return (
    <div className={styles.MultiItemContent}>
      <BrawlStarsHero product={product} />
      <div className={styles.MultiItemGridContainer}>
        <Grid>
          {product.contents.map((content, index) => (
            <div className={styles.Item} key={`product-${product.id}-content-${index}`}>
              <BrawlItem content={content} isSingleItemOffer={false} isSubItem={true} />
            </div>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export function BrawlContent({
  product,
  offerCardType,
}: {
  product: ProductV2
  offerCardType?: OfferCardType
}): ReactNode {
  const isMultiItemOffer = product.contents.length > 1

  if (offerCardType === 'special') {
    return <BrawlStarMultipleItemCardContent product={product} />
  }
  if (isMultiItemOffer) {
    return <BrawlStarMultipleItemCardContent product={product} />
  }
  return <BrawlStarSingleItemCardContent product={product} />
}

export function BrawlStarsOfferCardContent({ product, offerCardType }: Props): ReactNode {
  return (
    <div className={styles.Content}>
      <BrawlContent product={product} offerCardType={offerCardType} />
    </div>
  )
}
