/**
 * Store table key schema:
 *
 * Purchase:
 *  PK: A#<scid>
 *  SK: PURCHASE#<purchaseId>
 *  GSIPK: A#<scid>
 *  GSISK: PURCHASE#<timestamp>
 *
 *  TODO add following keys for purchases -> search purposes from pandora
 *  GSI2PK: PURCHASE#<purchaseId>
 *  GSI2SK: -
 *
 * PurchaseEvent:
 *  PK: E#<purchaseId>
 *  SK: REV#<timestamp>
 *
 * Receipt:
 *  PK: A#<scid>
 *  SK: RECEIPT#<purchaseId>#<status>
 *  GSIPK: A#<scid>
 *  GSISK: RECEIPT#<timestamp>
 *
 * Cache
 *   PK: A#<scid>
 *   SK: CACHE#<cacheId>
 *
 * Customer:
 *  PK: A#<scid>
 *  SK: CUSTOMER
 *
 */
export const PARTITION_KEY = 'PK' as const
export const SORT_KEY = 'SK' as const
export const TABLE_NAME = 'store' as const
export const GSIPK = 'GSIPK' as const
export const GSISK = 'GSISK' as const
export const GSI_NAME = 'GSI' as const

export const GSI2PK = 'GSI2PK' as const
export const GSI2SK = 'GSI2SK' as const
export const GSI2_NAME = 'GSI2' as const

export const tablePartitionKey = (customerId: string) => `A#${customerId}` as const
