import { GameId, isStoreOfferProduct, ProductV2, STORE_STRIKE_THROUGH_TAG_RE } from '@common'

import { config } from '../../../../../../../config'

export { Countdown } from './Countdown'
export { Footer } from './Footer'
export { Tags } from './Tags'
export { ValueBadge } from './ValueBadge'

export const isNewVisualsEnabled = (gameId: GameId, gamesEnabled: GameId[] = []) => gamesEnabled.includes(gameId)

export const getStrikeThroughTag = (product: ProductV2): string | null => {
  if (!isStoreOfferProduct(product)) {
    return null
  }

  if (!product.tags || product.tags.length === 0) {
    return null
  }
  for (const tag of product.tags) {
    const matchStrikeThrough = tag.match(STORE_STRIKE_THROUGH_TAG_RE)
    if (matchStrikeThrough) {
      return matchStrikeThrough[1]
    }
  }
  return null
}
