import { z } from 'zod'
import { isSupportedLanguage } from './countries'

import * as tables from './db/tables'
import { columnTypes } from './db'

export const announcementRequestSchema = z.object({
  locale: z.string().refine(isSupportedLanguage),
  viewerCountry: columnTypes.countryCodeSchema.nullable().transform((value, ctx) => {
    if (value === null) {
      ctx.addIssue({
        code: 'invalid_type',
        expected: 'string',
        received: 'null',
      })
      return z.NEVER
    }
    return value
  }),
})
export type AnnouncementRequest = z.input<typeof announcementRequestSchema>

export const announcementQueryResultSchema = tables.announcement.schema.pick({ description: true })
export type AnnouncementQueryResult = z.infer<typeof announcementQueryResultSchema>
